import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/capacity/${id}`);
};

export const create = (capacity) => {
  return api.post(`/capacity`, capacity);
};

export const update = (capacity) => {
  return api.put(`/capacity`, capacity);
};

export const remove = (capacity) => {
  return api.delete(`/capacity/${capacity.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/capacities/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/capacities/${offset}/${amount}`, searchParameters);
};
