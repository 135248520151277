import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateTeamContainer from '@containers/team/crud/CreateTeamContainer';

import * as navigationActions from '@actions/navigationActions';
import * as teamActions from '@actions/teamActions';

let searchTimeout = null;

export const transformTeamToValue = (team) => ({
  value: team.id,
  label: `${team.name}`,
  team: team,
});

class TeamsInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      teams: this.props.value
        ? this.props.value.map((team) => transformTeamToValue(team))
        : [],
    };
  }

  onChange = (newTeams) => {
    this.setState({
      team: newTeams,
    });

    const { onChange } = this.props;
    onChange &&
      onChange(newTeams ? newTeams.map((newTeam) => newTeam.team) || [] : []);
  };

  render() {
    const { t, isFetchingTeams, searchTeams, addToStack } = this.props;
    const { teams } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={true}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.selectTeams')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingTeams}
          isDisabled={isFetchingTeams}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchTeams({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.teams.map((team) => transformTeamToValue(team))
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('team.new'),
              component: (
                <CreateTeamContainer
                  targetName={inputValue}
                  callback={(newTeam) => {
                    this.onChange([...teams, transformTeamToValue(newTeam)]);
                  }}
                />
              ),
            });
          }}
          value={teams}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(teamActions.changeValue(name, value)),
    searchTeams: (searchParameters, from, amount) =>
      dispatch(teamActions.searchTeams(searchParameters, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TeamsInput));
