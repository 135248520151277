export default class Association {
  associationType;
  entity;
  uuid;

  constructor(associationType = 'inline', entity = null) {
    this.associationType = associationType;
    this.entity = entity;
  }
}
