export const actionConstants = {
  ACTION_CHANGE_VALUE: 'ACTION_CHANGE_VALUE',

  GET_ACTION_STARTED: 'GET_ACTION_STARTED',
  GET_ACTION_SUCCESS: 'GET_ACTION_SUCCESS',
  GET_ACTION_FAILURE: 'GET_ACTION_FAILURE',

  GET_ACTIONS_STARTED: 'GET_ACTIONS_STARTED',
  GET_ACTIONS_SUCCESS: 'GET_ACTIONS_SUCCESS',
  GET_ACTIONS_FAILURE: 'GET_ACTIONS_FAILURE',

  SEARCH_ACTIONS_STARTED: 'SEARCH_ACTIONS_STARTED',
  SEARCH_ACTIONS_SUCCESS: 'SEARCH_ACTIONS_SUCCESS',
  SEARCH_ACTIONS_FAILURE: 'SEARCH_ACTIONS_FAILURE',

  CREATING_ACTION_STARTED: 'CREATING_ACTION_STARTED',
  CREATING_ACTION_SUCCESS: 'CREATING_ACTION_SUCCESS',
  CREATING_ACTION_FAILURE: 'CREATING_ACTION_FAILURE',

  UPDATING_ACTION_STARTED: 'UPDATING_ACTION_STARTED',
  UPDATING_ACTION_SUCCESS: 'UPDATING_ACTION_SUCCESS',
  UPDATING_ACTION_FAILURE: 'UPDATING_ACTION_FAILURE',

  DELETE_ACTION_STARTED: 'DELETE_ACTION_STARTED',
  DELETE_ACTION_SUCCESS: 'DELETE_ACTION_SUCCESS',
  DELETE_ACTION_FAILURE: 'DELETE_ACTION_FAILURE',
};
