import { contractConstants } from '@constants';

import * as contractApi from '@api/contractApi';

export const changeValue = (name, value) => ({
  type: contractConstants.CONTRACT_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching contract
 */
export const startGetContract = () => ({
  type: contractConstants.GET_CONTRACT_STARTED,
});

export const getContractSuccess = (result) => ({
  type: contractConstants.GET_CONTRACT_SUCCESS,
  ...result,
});

export const getContractFailure = (error) => ({
  type: contractConstants.GET_CONTRACT_FAILURE,
  ...error,
});

export const getContract = (id) => {
  return (dispatch) => {
    dispatch(startGetContract());

    const promise = contractApi.get(id);

    promise
      .then((result) => {
        dispatch(getContractSuccess(result));
      })
      .catch((error) => {
        dispatch(getContractFailure(error));
      });

    return promise;
  };
};

/**
 * Creating contract
 */
export const changeCreateContract = (name, value) => ({
  type: contractConstants.CREATING_CONTRACT_CHANGE_VALUE,
  name,
  value,
});
export const startCreateContract = () => ({
  type: contractConstants.CREATING_CONTRACT_STARTED,
});

export const createContractSuccess = (result) => ({
  type: contractConstants.CREATING_CONTRACT_SUCCESS,
  ...result,
});

export const createContractFailure = (error) => ({
  type: contractConstants.CREATING_CONTRACT_FAILURE,
  ...error,
});

export const createContract = (contract) => {
  return (dispatch) => {
    dispatch(startCreateContract());

    const promise = contractApi.create(contract);

    promise
      .then((result) => {
        dispatch(createContractSuccess(result));
      })
      .catch((error) => {
        dispatch(createContractFailure(error));
      });

    return promise;
  };
};

/**
 * Updating contract
 */
export const changeUpdateContract = (name, value) => ({
  type: contractConstants.UPDATING_CONTRACT_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateContract = () => ({
  type: contractConstants.UPDATING_CONTRACT_STARTED,
});

export const updateContractSuccess = (result) => ({
  type: contractConstants.UPDATING_CONTRACT_SUCCESS,
  ...result,
});

export const updateContractFailure = (error) => ({
  type: contractConstants.UPDATING_CONTRACT_FAILURE,
  ...error,
});

export const updateContract = (contract) => {
  return (dispatch) => {
    dispatch(startUpdateContract());

    const promise = contractApi.update(contract);

    promise
      .then((result) => {
        dispatch(updateContractSuccess(result));
      })
      .catch((error) => {
        dispatch(updateContractFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting contract
 */
export const startDeleteContract = () => ({
  type: contractConstants.DELETE_CONTRACT_STARTED,
});

export const deleteContractSuccess = (result) => ({
  type: contractConstants.DELETE_CONTRACT_SUCCESS,
  ...result,
});

export const deleteContractFailure = (error) => ({
  type: contractConstants.DELETE_CONTRACT_FAILURE,
  ...error,
});
export const deleteContract = (contract) => {
  return (dispatch) => {
    dispatch(startDeleteContract());

    const promise = contractApi.remove(contract);

    promise
      .then((result) => {
        dispatch(deleteContractSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteContractFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching contracts
 */
export const startGetContracts = () => ({
  type: contractConstants.GET_CONTRACTS_STARTED,
});

export const getContractsSuccess = (result, from) => ({
  type: contractConstants.GET_CONTRACTS_SUCCESS,
  from: from,
  ...result,
});

export const getContractsFailure = (error) => ({
  type: contractConstants.GET_CONTRACTS_FAILURE,
  ...error,
});

export const getContracts = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetContracts());

    const promise = contractApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getContractsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getContractsFailure(error));
      });

    return promise;
  };
};

export const searchContracts = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetContracts());

    const promise = contractApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getContractsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getContractsFailure(error));
      });

    return promise;
  };
};
