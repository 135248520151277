import api from '../api';

export const search = (query, offset, amount) => {
  return api.post(`/general/${offset}/${amount}`, { query: query });
};

export const getTaxRates = () => {
  return api.get(`/taxRates`);
};

export const updateTaxRates = (taxRates) => {
  return api.put(`/taxRates`, taxRates);
};

export const getAttributes = (offset = 0, amount = 20) => {
  return api.get(`/attributes/${offset}/${amount}`);
};

export const updateAttributes = (attributes) => {
  return api.put(`/attributes`, attributes);
};

export const getPricingCategories = (includeDisabled = false) => {
  return api.get(`/pricingCategories?includeDisabled=${includeDisabled}`);
};

export const updatePricingCategories = (pricingCategories) => {
  return api.put(`/pricingCategories`, pricingCategories);
};

export const getEntityStructure = (entityType) => {
  return api.get(`/entity/${entityType}`);
};

export const mappingToJson = (mapping) => {
  return api.post('/mappingToJson', mapping);
};
