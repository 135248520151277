import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import NoData from '@uicomponents/NoData';

import ContactDetailView from './ContactDetailView';

class ContactDetailsView extends Component {
  render() {
    const { t, contactDetails } = this.props;

    if (contactDetails.length > 0) {
      return (
        <div className="contact-details">
          {contactDetails
            .map((association) => association.entity)
            .sort((a, b) =>
              moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt))
            )
            .map((contactDetail) => (
              <ContactDetailView
                key={contactDetail.id}
                contactDetail={contactDetail}
              />
            ))}
        </div>
      );
    }

    return <NoData>{t('contactDetails.notFound')}</NoData>;
  }
}
export default withTranslation('translation')(ContactDetailsView);
