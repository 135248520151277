import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateAdministrationPage from '@components/administration/crud/CreateAdministrationPage.js';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';

class CreateAdministrationContainer extends PureComponent {
  render() {
    const { changeValue, createAdministration, popStack, ...props } =
      this.props;
    return (
      <CreateAdministrationPage
        {...props}
        onChange={changeValue}
        createAdministration={(administration) =>
          createAdministration(administration).then(() => {
            popStack();
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.administration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAdministration: (administration) =>
      dispatch(administrationActions.createAdministration(administration)),
    changeValue: (name, value) =>
      dispatch(administrationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateAdministrationContainer));
