import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateChauffeurPage from '@components/chauffeur/crud/UpdateChauffeurPage.js';

import * as fileApi from '@api/fileApi';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as navigationActions from '@actions/navigationActions';

class UpdateChauffeurContainer extends PureComponent {
  componentDidMount() {
    const { changeValue, ownProps } = this.props;
    const { chauffeur } = ownProps;

    changeValue('formChauffeur', chauffeur);
  }

  render() {
    const { changeValue, updateChauffeur, callback, ...props } = this.props;
    const { popStack } = this.props;

    return (
      <UpdateChauffeurPage
        {...props}
        onChange={changeValue}
        updateChauffeur={(chauffeur) =>
          updateChauffeur(chauffeur).then((response) => {
            callback?.(response);
            popStack?.();
          })
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,

    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.chauffeur,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateChauffeur: (chauffeur) => dispatch(chauffeurActions.updateChauffeur(chauffeur)),
    changeValue: (name, value) => dispatch(chauffeurActions.changeValue(name, value)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateChauffeurContainer));
