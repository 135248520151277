import { managementConstants } from '@constants';

const initialState = {
  isFetchingFinancialRecords: false,
  financialRecords: {},

  isFetchingOperationalRecords: false,
  operationalRecords: {},

  tripsPerDay: [],
  isFetchingTripsPerDay: false,
};

export default function management(state = initialState, action) {
  switch (action.type) {
    case managementConstants.GET_OPERATIONAL_RECORDS_STARTED:
      return {
        ...state,
        isFetchingOperationalRecords: true,
      };
    case managementConstants.GET_OPERATIONAL_RECORDS_FAILURE:
      return { ...state, isFetchingOperationalRecords: false, error: action.message || '' };
    case managementConstants.GET_OPERATIONAL_RECORDS_SUCCESS:
      return {
        ...state,
        isFetchingFinancialRecords: false,
        operationalRecords: { ...action.operationalOverview },
      };

    case managementConstants.GET_FINANCIAL_RECORDS_STARTED:
      return {
        ...state,
        isFetchingFinancialRecords: true,
      };
    case managementConstants.GET_FINANCIAL_RECORDS_FAILURE:
      return { ...state, isFetchingFinancialRecords: false, error: action.message || '' };
    case managementConstants.GET_FINANCIAL_RECORDS_SUCCESS:
      return {
        ...state,
        isFetchingFinancialRecords: false,
        financialRecords: { ...action.financialOverview },
      };

    case managementConstants.GET_TRIPS_PER_DAY_STARTED:
      return {
        ...state,
        isFetchingTripsPerDay: true,
      };
    case managementConstants.GET_TRIPS_PER_DAY_FAILURE:
      return { ...state, isFetchingTripsPerDay: false, error: action.message || '' };
    case managementConstants.GET_TRIPS_PER_DAY_SUCCESS:
      return {
        ...state,
        isFetchingTripsPerDay: false,
        tripsPerDay: [...action.tripsPerDay],
      };
    default:
      return state;
  }
}
