import api from '../api';

// CRUD operation
export const get = (id = '') => {
  return api.get(`/goods/${id}`);
};
export const create = (goods) => {
  return api.post(`/goods`, goods);
};
export const update = (goods) => {
  return api.put(`/goods`, goods);
};

export const remove = (goods) => {
  return api.delete(`/goods/${goods.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/goodsList/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/goodsList/${from}/${amount}`, searchParameters);
};
