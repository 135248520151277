import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateMessageTemplatePage from '@components/messageTemplate/crud/CreateMessageTemplatePage.js';

import * as fieldActions from '@actions/fieldActions';
import * as messageTemplateActions from '@actions/messageTemplateActions';
import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class CreateMessageTemplateContainer extends PureComponent {
  render() {
    const {
      changeValue,
      createMessageTemplate,
      searchVehicles,
      popStack,
      callback,
      ...props
    } = this.props;
    return (
      <CreateMessageTemplatePage
        {...props}
        onChange={changeValue}
        createMessageTemplate={(messageTemplate) =>
          createMessageTemplate(messageTemplate).then((response) => {
            popStack?.();
            callback?.(response);
          })
        }
        searchVehicles={(searchParameters, from, amount) =>
          searchVehicles(searchParameters, from, amount)
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    languages: state.platform.languages,
    ...state.messageTemplate,
    fields: state.platform.fields,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createMessageTemplate: (messageTemplate) =>
      dispatch(messageTemplateActions.createMessageTemplate(messageTemplate)),
    changeValue: (name, value) =>
      dispatch(messageTemplateActions.changeValue(name, value)),
    searchVehicles: (searchParameters, from, amount) =>
      dispatch(vehicleActions.searchVehicles(searchParameters, from, amount)),

    searchFieldsRequest: (entityType, keyword, limit) =>
      dispatch(fieldActions.searchFieldsRequest(entityType, keyword, limit)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateMessageTemplateContainer));
