export const chauffeurTimeslotConstants = {
  CHAUFFEURTIMESLOT_CHANGE_VALUE: 'CHAUFFEURTIMESLOT_CHANGE_VALUE',

  GET_CHAUFFEURTIMESLOT_STARTED: 'GET_CHAUFFEURTIMESLOT_STARTED',
  GET_CHAUFFEURTIMESLOT_SUCCESS: 'GET_CHAUFFEURTIMESLOT_SUCCESS',
  GET_CHAUFFEURTIMESLOT_FAILURE: 'GET_CHAUFFEURTIMESLOT_FAILURE',

  GET_CHAUFFEURTIMESLOTS_STARTED: 'GET_CHAUFFEURTIMESLOTS_STARTED',
  GET_CHAUFFEURTIMESLOTS_SUCCESS: 'GET_CHAUFFEURTIMESLOTS_SUCCESS',
  GET_CHAUFFEURTIMESLOTS_FAILURE: 'GET_CHAUFFEURTIMESLOTS_FAILURE',

  SEARCH_CHAUFFEURTIMESLOTS_STARTED: 'SEARCH_CHAUFFEURTIMESLOTS_STARTED',
  SEARCH_CHAUFFEURTIMESLOTS_SUCCESS: 'SEARCH_CHAUFFEURTIMESLOTS_SUCCESS',
  SEARCH_CHAUFFEURTIMESLOTS_FAILURE: 'SEARCH_CHAUFFEURTIMESLOTS_FAILURE',

  CREATING_CHAUFFEURTIMESLOT_STARTED: 'CREATING_CHAUFFEURTIMESLOT_STARTED',
  CREATING_CHAUFFEURTIMESLOT_SUCCESS: 'CREATING_CHAUFFEURTIMESLOT_SUCCESS',
  CREATING_CHAUFFEURTIMESLOT_FAILURE: 'CREATING_CHAUFFEURTIMESLOT_FAILURE',

  UPDATING_CHAUFFEURTIMESLOT_STARTED: 'UPDATING_CHAUFFEURTIMESLOT_STARTED',
  UPDATING_CHAUFFEURTIMESLOT_SUCCESS: 'UPDATING_CHAUFFEURTIMESLOT_SUCCESS',
  UPDATING_CHAUFFEURTIMESLOT_FAILURE: 'UPDATING_CHAUFFEURTIMESLOT_FAILURE',

  DELETE_CHAUFFEURTIMESLOT_STARTED: 'DELETE_CHAUFFEURTIMESLOT_STARTED',
  DELETE_CHAUFFEURTIMESLOT_SUCCESS: 'DELETE_CHAUFFEURTIMESLOT_SUCCESS',
  DELETE_CHAUFFEURTIMESLOT_FAILURE: 'DELETE_CHAUFFEURTIMESLOT_FAILURE',
};
