import * as transportEquipmentApi from '@api/transportEquipmentApi';

import { transportEquipmentConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: transportEquipmentConstants.TRANSPORT_EQUIPMENT_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching transportEquipment
 */
export const startGetTransportEquipment = () => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_STARTED,
});

export const getTransportEquipmentSuccess = (result) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
});

export const getTransportEquipmentFailure = (error) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
});

export const getTransportEquipment = (id) => {
  return (dispatch) => {
    dispatch(startGetTransportEquipment());

    const promise = transportEquipmentApi.get(id);

    promise
      .then((result) => {
        dispatch(getTransportEquipmentSuccess(result));
      })
      .catch((error) => {
        dispatch(getTransportEquipmentFailure(error));
      });

    return promise;
  };
};

/**
 * Creating transportEquipment
 */
export const startCreateTransportEquipment = () => ({
  type: transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_STARTED,
});

export const createTransportEquipmentSuccess = (result) => ({
  type: transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
});

export const createTransportEquipmentFailure = (error) => ({
  type: transportEquipmentConstants.CREATING_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
});

export const createTransportEquipment = (transportEquipment) => {
  return (dispatch) => {
    dispatch(startCreateTransportEquipment());

    const promise = transportEquipmentApi.create(transportEquipment);

    promise
      .then((result) => {
        dispatch(createTransportEquipmentSuccess(result));
      })
      .catch((error) => {
        dispatch(createTransportEquipmentFailure(error));
      });

    return promise;
  };
};

/**
 * Updating transportEquipment
 */
export const startUpdateTransportEquipment = () => ({
  type: transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_STARTED,
});

export const updateTransportEquipmentSuccess = (result) => ({
  type: transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
});

export const updateTransportEquipmentFailure = (error) => ({
  type: transportEquipmentConstants.UPDATING_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
});

export const updateTransportEquipment = (transportEquipment) => {
  return (dispatch) => {
    dispatch(startUpdateTransportEquipment());

    const promise = transportEquipmentApi.update(transportEquipment);

    promise
      .then((result) => {
        dispatch(updateTransportEquipmentSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTransportEquipmentFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting transportEquipment
 */
export const startDeleteTransportEquipment = () => ({
  type: transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_STARTED,
});

export const deleteTransportEquipmentSuccess = (result) => ({
  type: transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
});

export const deleteTransportEquipmentFailure = (error) => ({
  type: transportEquipmentConstants.DELETE_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
});
export const deleteTransportEquipment = (transportEquipment) => {
  return (dispatch) => {
    dispatch(startDeleteTransportEquipment());

    const promise = transportEquipmentApi.remove(transportEquipment);

    promise
      .then((result) => {
        dispatch(deleteTransportEquipmentSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTransportEquipmentFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transportEquipments
 */
export const startGetTransportEquipments = (from) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_STARTED,
  from: from,
});

export const getTransportEquipmentsSuccess = (result, from) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_SUCCESS,
  from: from,
  ...result,
});

export const getTransportEquipmentsFailure = (error) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENTS_FAILURE,
  ...error,
});

export const getTransportEquipments = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTransportEquipments(from));

    const promise = transportEquipmentApi.all(from, amount);

    promise
      .then((result) => {
        dispatch(getTransportEquipmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTransportEquipmentsFailure(error));
      });

    return promise;
  };
};

export const searchTransportEquipments = (searchParameters = {}, from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTransportEquipments(from));

    const promise = transportEquipmentApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getTransportEquipmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTransportEquipmentsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching extendedTransportEquipments
 */
export const startGetExtendedTransportEquipments = (from) => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_STARTED,
  from: from,
});

export const getExtendedTransportEquipmentsSuccess = (result, from) => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_SUCCESS,
  from: from,
  ...result,
});

export const getExtendedTransportEquipmentsFailure = (error) => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_FAILURE,
  ...error,
});

export const getExtendedTransportEquipments = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetExtendedTransportEquipments(from));

    const promise = transportEquipmentApi.extended(from, amount);

    promise
      .then((result) => {
        dispatch(getExtendedTransportEquipmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getExtendedTransportEquipmentsFailure(error));
      });

    return promise;
  };
};

export const searchExtendedTransportEquipments = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetExtendedTransportEquipments(from));

    const promise = transportEquipmentApi.searchExtended(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getExtendedTransportEquipmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getExtendedTransportEquipmentsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transportEquipment trips
 */

export const getTransportEquipmentTripsSuccess = (result) => ({
  type: transportEquipmentConstants.GET_TRANSPORT_EQUIPMENT_TRIPS_SUCCESS,
  ...result,
});

export const getTransportEquipmentTrips = (transportEquipment, fromDate, toDate) => {
  return async (dispatch) => {
    await transportEquipmentApi.trips(transportEquipment.id, fromDate, toDate).then((result) =>
      dispatch(
        getTransportEquipmentTripsSuccess({
          ...result,
          transportEquipment: { ...transportEquipment, trips: result.trips },
        })
      )
    );
  };
};

/**
 * Fetching extendedTransportEquipments
 */
export const startGetExtendedTransportEquipmentsTrips = () => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_STARTED,
});

export const getExtendedTransportEquipmentsTripsSuccess = (result) => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_SUCCESS,
  ...result,
});

export const getExtendedTransportEquipmentsTripsFailure = (error) => ({
  type: transportEquipmentConstants.GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_FAILURE,
  ...error,
});

export const getExtendedTransportEquipmentsTrips = (transportEquipment, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetExtendedTransportEquipmentsTrips());

    const promise = transportEquipmentApi.getExtendedTransportEquipmentTrips(
      transportEquipment,
      fromDate,
      toDate
    );

    promise
      .then((result) => {
        dispatch(getExtendedTransportEquipmentsTripsSuccess(result));
      })
      .catch((error) => {
        dispatch(getExtendedTransportEquipmentsTripsFailure(error));
      });

    return promise;
  };
};
