import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateCapacityContractPage from '@components/capacityContract/crud/CreateCapacityContractPage.js';

import * as capacityContractActions from '@actions/capacityContractActions';
import * as navigationActions from '@actions/navigationActions';

class CreateCapacityContractContainer extends PureComponent {
  render() {
    const {
      changeValue,
      createCapacityContract,
      popStack,
      callback,
      ...props
    } = this.props;
    return (
      <CreateCapacityContractPage
        {...props}
        onChange={changeValue}
        createCapacityContract={(capacityContract) =>
          createCapacityContract(capacityContract).then((response) => {
            popStack?.();
            callback?.(response.capacityContract);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.capacityContract,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCapacityContract: (capacityContract) =>
      dispatch(
        capacityContractActions.createCapacityContract(capacityContract)
      ),
    changeValue: (name, value) =>
      dispatch(capacityContractActions.changeValue(name, value)),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateCapacityContractContainer));
