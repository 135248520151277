import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import CloseButton from '@uicomponents/CloseButton';

import Consignment from '@models/consignment/Consignment';
import Association from '@models/general/Association';

import { administrativeReferenceToString } from '@utils/locationUtils';

import ConsignmentForm from './ConsignmentForm';
import ConsignmentFormPage from './ConsignmentFormPage';

class ConsignmentsInput extends Component {
  state = {
    consignments: this.props.consignments || [new Association('inline', new Consignment())],

    originalPhysicalSender: false,
    originalLegalSender: false,
    originalPhysicalAddressee: false,
    originalLegalAddressee: false,
  };

  addNewConsignment = () => {
    const { consignments } = this.state;
    this.setState({
      consignments: [...consignments, new Association('inline', new Consignment())],
    });
  };

  updateConsignment = (newConsignment, index) => {
    const { onChange } = this.props;

    const { consignments } = this.state;
    const newConsignments = [...consignments];
    const newAssociation = { ...newConsignments[index] };
    newAssociation.entity = newConsignment;
    newConsignments[index] = newAssociation;

    this.setState({
      consignments: newConsignments,
    });

    onChange && onChange(newConsignments);
  };

  render() {
    const { t, addToStack, popStack, onChange, platform, simpleForms } = this.props;
    const { consignments } = this.state;

    if (simpleForms || consignments[0]?.entity?.isOpen) {
      return (
        <div className="list-sector">
          <ConsignmentForm
            {...this.props}
            consignment={consignments ? consignments[0].entity : null}
            onChange={(newConsignment) => {
              this.updateConsignment(newConsignment, 0);
            }}
          />
        </div>
      );
    }
    return (
      <>
        {consignments
          .map((association) => association.entity)
          .map((consignment, index) => (
            <div
              className="list-sector consignment-form-element"
              key={`input-consignment-${index}-${consignment.id}-${consignment.nonce}`}
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: '',
                  component: (
                    <ConsignmentFormPage
                      platform={platform}
                      popStack={popStack}
                      addToStack={addToStack}
                      headerName={t('consignment.header', { count: index + 1 })}
                      consignment={consignment}
                      onChange={(newConsignment) => {
                        this.updateConsignment(newConsignment, index);
                      }}
                    />
                  ),
                });
              }}
            >
              <div className="flex-container justify-between">
                <h2>
                  {t('consignment.header', { count: index + 1 })}{' '}
                  {consignment.name ? ` - ${consignment.name}` : null}
                </h2>

                <div className="list-actions">
                  <div></div>
                  <ReactSVG
                    src="/icons/duplicate.svg"
                    className="close-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const newConsignments = [...consignments];
                      newConsignments.splice(
                        index,
                        0,
                        new Association('inline', { ...consignment, id: null, nonce: null })
                      );

                      this.setState({ consignments: newConsignments });
                      onChange?.(newConsignments);
                    }}
                  />
                  {index !== 0 && (
                    <CloseButton
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();

                        const newConsignments = [...consignments];
                        newConsignments.splice(index, 1);

                        this.setState({ consignments: newConsignments });
                        onChange && onChange(newConsignments);
                      }}
                    />
                  )}
                  <div className="go">
                    <ReactSVG src="/icons/back.svg" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-container value-list">
                  {consignment.type && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('consignment.type')}:</b> {consignment.type}
                    </p>
                  )}
                  {consignment.combined !== null && consignment.combined !== undefined && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('consignment.combined')}:</b>{' '}
                      {consignment.combined ? t('yes') : t('no')}
                    </p>
                  )}
                </div>
                {consignment.description && (
                  <p className="no-margin">
                    <b>{t('consignment.description')}:</b> {consignment.description}
                  </p>
                )}
                {consignment.remark && (
                  <p className="no-margin">
                    <b>{t('consignment.remark')}:</b> {consignment.remark}
                  </p>
                )}
                {consignment.actions && consignment.actions.length > 0 && (
                  <p className="">
                    {[...consignment.actions]
                      .map((association) => association.entity)
                      .sort((a, b) => a.sequenceNr - b.sequenceNr)
                      .map((action) => {
                        const startDateTimeConstraint = action.constraints
                          ?.map((association) => association.entity)
                          ?.find(
                            (constraint) => constraint.value.type === 'startDateTimeConstraint'
                          );
                        const endDateTimeConstraint = action.constraints
                          ?.map((association) => association.entity)
                          ?.find((constraint) => constraint.value.type === 'endDateTimeConstraint');

                        return (
                          <div className="block">
                            <b>
                              {action.actions
                                .map((association) => t(`actionType.${association.entity.type}`))
                                .join(' & ')}
                            </b>{' '}
                            -{' '}
                            {administrativeReferenceToString(
                              action?.location?.entity?.administrativeReference,
                              true
                            )}
                            (
                            {startDateTimeConstraint
                              ? `${moment(startDateTimeConstraint?.value?.startDateTime).format(
                                  'DD/MM/YYYY HH:mm'
                                )} - `
                              : ''}
                            {endDateTimeConstraint
                              ? moment(endDateTimeConstraint?.value?.endDateTime).format(
                                  'DD/MM/YYYY HH:mm'
                                )
                              : ''}
                            )
                          </div>
                        );
                      })}
                  </p>
                )}
              </div>
            </div>
          ))}
        <div className="input-group left">
          <button type="button" onClick={(e) => this.addNewConsignment(e)}>
            {t('form.label.addConsignment')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ConsignmentsInput);
