import Money from '../general/Money';

export default class TransportOrder {
  id;
  nonce;

  startingPrice;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.startingPrice = new Money();
  }
}
