import React, { Component } from 'react';

import ActionIcon from './ActionIcon';

export default class ActionIcons extends Component {
  render() {
    const { name, icons } = this.props;

    return (
      <div className="actions action-icons self-center align-items-center">
        {icons
          .filter((icon) => icon)
          .map((icon) => (
            <ActionIcon key={`${name}-${icon?.name}-${icon?.type}-${icon?.icon}`} name={name} {...icon} />
          ))}
      </div>
    );
  }
}
