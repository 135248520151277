import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import NoData from '@uicomponents/NoData';

import NoteView from './NoteView';

class NotesView extends Component {
  render() {
    const { t, notes } = this.props;

    if (notes && notes.length > 0) {
      return (
        <div className="notes">
          {[...notes]
            .sort((a, b) =>
              moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt))
            )
            .map((note) => (
              <NoteView key={note.id} note={note} />
            ))}
        </div>
      );
    }

    return <NoData>{t('notes.notFound')}</NoData>;
  }
}
export default withTranslation('translation')(NotesView);
