
export const tireConstants = {
    TIRE_CHANGE_VALUE: "TIRE_CHANGE_VALUE",

    GET_TIRE_STARTED: "GET_TIRE_STARTED",
    GET_TIRE_SUCCESS: "GET_TIRE_SUCCESS",
    GET_TIRE_FAILURE: "GET_TIRE_FAILURE",

    GET_TIRES_STARTED: "GET_TIRES_STARTED",
    GET_TIRES_SUCCESS: "GET_TIRES_SUCCESS",
    GET_TIRES_FAILURE: "GET_TIRES_FAILURE",

    SEARCH_TIRES_STARTED: "SEARCH_TIRES_STARTED",
    SEARCH_TIRES_SUCCESS: "SEARCH_TIRES_SUCCESS",
    SEARCH_TIRES_FAILURE: "SEARCH_TIRES_FAILURE",

    CREATING_TIRE_STARTED: "CREATING_TIRE_STARTED",
    CREATING_TIRE_SUCCESS: "CREATING_TIRE_SUCCESS",
    CREATING_TIRE_FAILURE: "CREATING_TIRE_FAILURE",

    UPDATING_TIRE_STARTED: "UPDATING_TIRE_STARTED",
    UPDATING_TIRE_SUCCESS: "UPDATING_TIRE_SUCCESS",
    UPDATING_TIRE_FAILURE: "UPDATING_TIRE_FAILURE",

    DELETE_TIRE_STARTED: "DELETE_TIRE_STARTED",
    DELETE_TIRE_SUCCESS: "DELETE_TIRE_SUCCESS",
    DELETE_TIRE_FAILURE: "DELETE_TIRE_FAILURE",
};
