import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Integration from '@models/integration/Integration';

import IntegrationForm from './IntegrationForm';

export default class CreateIntegrationPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    const newIntegration = new Integration();
    onChange('formIntegration', newIntegration);
  }

  render() {
    const { t, formIntegration, isFetchingIntegration, createIntegration } =
      this.props;

    if (!formIntegration || isFetchingIntegration) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('integration.new')}</h1>
        <IntegrationForm
          {...this.props}
          key={formIntegration.id || formIntegration.nonce}
          onSubmit={(integration) => createIntegration(integration)}
        />
      </AsideView>
    );
  }
}
