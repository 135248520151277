import { todoMainConstants } from '../constants';

export const setView = (payload) => ({
  type: todoMainConstants.HANDLE_SET_VIEW,
  payload,
});

export const isEdit = (payload) => ({
  type: todoMainConstants.HANDLE_SET_EDIT,
  payload,
});

export const changeValue = (name, value) => ({
  type: todoMainConstants.TODO_CHANGE_VALUE,
  name,
  value,
});
