import * as boardComputerApi from '@api/boardComputerApi';

import { boardComputerConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: boardComputerConstants.BOARD_COMPUTER_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching boardComputer
 */
export const startGetBoardComputer = () => ({
  type: boardComputerConstants.GET_BOARD_COMPUTER_STARTED,
});

export const getBoardComputerSuccess = (result) => ({
  type: boardComputerConstants.GET_BOARD_COMPUTER_SUCCESS,
  ...result,
});

export const getBoardComputerFailure = (error) => ({
  type: boardComputerConstants.GET_BOARD_COMPUTER_FAILURE,
  ...error,
});

export const getBoardComputer = (id) => {
  return (dispatch) => {
    dispatch(startGetBoardComputer());

    const promise = boardComputerApi.get(id);

    promise
      .then((result) => {
        dispatch(getBoardComputerSuccess(result));
      })
      .catch((error) => {
        dispatch(getBoardComputerFailure(error));
      });

    return promise;
  };
};

/**
 * Creating boardComputer
 */
export const changeCreateBoardComputer = (name, value) => ({
  type: boardComputerConstants.CREATING_BOARD_COMPUTER_CHANGE_VALUE,
  name,
  value,
});
export const startCreateBoardComputer = () => ({
  type: boardComputerConstants.CREATING_BOARD_COMPUTER_STARTED,
});

export const createBoardComputerSuccess = (result) => ({
  type: boardComputerConstants.CREATING_BOARD_COMPUTER_SUCCESS,
  ...result,
});

export const createBoardComputerFailure = (error) => ({
  type: boardComputerConstants.CREATING_BOARD_COMPUTER_FAILURE,
  ...error,
});

export const createBoardComputer = (boardComputer) => {
  return (dispatch) => {
    dispatch(startCreateBoardComputer());

    const promise = boardComputerApi.create(boardComputer);

    promise
      .then((result) => {
        dispatch(createBoardComputerSuccess(result));
      })
      .catch((error) => {
        dispatch(createBoardComputerFailure(error));
      });

    return promise;
  };
};

/**
 * Updating boardComputer
 */
export const changeUpdateBoardComputer = (name, value) => ({
  type: boardComputerConstants.UPDATING_BOARD_COMPUTER_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateBoardComputer = () => ({
  type: boardComputerConstants.UPDATING_BOARD_COMPUTER_STARTED,
});

export const updateBoardComputerSuccess = (result) => ({
  type: boardComputerConstants.UPDATING_BOARD_COMPUTER_SUCCESS,
  ...result,
});

export const updateBoardComputerFailure = (error) => ({
  type: boardComputerConstants.UPDATING_BOARD_COMPUTER_FAILURE,
  ...error,
});

export const updateBoardComputer = (boardComputer) => {
  return (dispatch) => {
    dispatch(startUpdateBoardComputer());

    const promise = boardComputerApi.update(boardComputer);

    promise
      .then((result) => {
        dispatch(updateBoardComputerSuccess(result));
      })
      .catch((error) => {
        dispatch(updateBoardComputerFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting boardComputer
 */
export const startDeleteBoardComputer = () => ({
  type: boardComputerConstants.DELETE_BOARD_COMPUTER_STARTED,
});

export const deleteBoardComputerSuccess = (result) => ({
  type: boardComputerConstants.DELETE_BOARD_COMPUTER_SUCCESS,
  ...result,
});

export const deleteBoardComputerFailure = (error) => ({
  type: boardComputerConstants.DELETE_BOARD_COMPUTER_FAILURE,
  ...error,
});
export const deleteBoardComputer = (boardComputer) => {
  return (dispatch) => {
    dispatch(startDeleteBoardComputer());

    const promise = boardComputerApi.remove(boardComputer);

    promise
      .then((result) => {
        dispatch(deleteBoardComputerSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteBoardComputerFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching boardComputers
 */
export const startGetBoardComputers = (from) => ({
  type: boardComputerConstants.GET_BOARD_COMPUTERS_STARTED,
  from: from,
});

export const getBoardComputersSuccess = (result, from) => ({
  type: boardComputerConstants.GET_BOARD_COMPUTERS_SUCCESS,
  from: from,
  ...result,
});

export const getBoardComputersFailure = (error) => ({
  type: boardComputerConstants.GET_BOARD_COMPUTERS_FAILURE,
  ...error,
});

export const getBoardComputers = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetBoardComputers(from));

    const promise = boardComputerApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getBoardComputersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getBoardComputersFailure(error));
      });

    return promise;
  };
};

export const searchBoardComputers = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetBoardComputers(from));

    const promise = boardComputerApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getBoardComputersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getBoardComputersFailure(error));
      });

    return promise;
  };
};
