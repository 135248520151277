import api from '../api';

// CRUD operation
export const get = (id = '') => {
  return api.get(`/chauffeur/${id}`);
};

export const create = (chauffeur) => {
  return api.post(`/chauffeur`, chauffeur);
};

export const update = (chauffeur) => {
  return api.put(`/chauffeur`, chauffeur);
};

export const remove = (chauffeur) => {
  return api.delete(`/chauffeur/${chauffeur.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/chauffeurs/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/chauffeurs/${from}/${amount}`, searchParameters);
};

export const searchExtended = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return api.post(`/chauffeurs:extended/${from}/${amount}`, searchParameters);
};

export const getChauffeurVehicles = (id) => {
  return api.get(`/chauffeur/${id}/vehicles`);
};

export const getChauffeurTrips = (id, fromDate, toDate) => {
  return api.get(`/chauffeur/${id}/trips:byDate/${fromDate}/${toDate}`);
};

export const timeslots = (id, fromDate, toDate) => {
  return api.get(`/chauffeur/${id}/timeslots/${fromDate}/${toDate}`);
};

export const extended = (offset, amount) => {
  return api.get(`/chauffeurs:extended/${offset}/${amount}`);
};
// business logic

export const getActivities = (chauffeur, fromDate, toDate) => {
  return api.get(
    `/chauffeur/${chauffeur.id}/activities:byDate/${fromDate ? fromDate.toISOString() : null
    }/${toDate ? toDate.toISOString() : null}`
  );
};


export const getExtendedChauffeurTrips = (chauffeurs, fromDate, toDate) => {
  return api.post(`/chauffeurs/trips:byDate/${fromDate}/${toDate}`, { chauffeurs })
}

export const getExtendedChauffeurTimeslots = (chauffeurs, fromDate, toDate) => {
  return api.post(`/chauffeurs/timeslots`, { chauffeurIds: chauffeurs, start: fromDate, end: toDate })
}