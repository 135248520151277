import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import CloseButton from '@uicomponents/CloseButton';

import Association from '@models/general/Association';
import Goods from '@models/goods/Goods';

import ItemsFormPage from './ItemsFormPage';

class ItemsInput extends Component {
  state = {
    goodsCreated: false,
    goodsList: this.props.goods
      ? this.props.goods
      : this.props.defaultEnabled
      ? [new Association('inline', new Goods('items'))]
      : [],
  };

  componentDidUpdate() {
    const { createGoods } = this.props;

    if (createGoods && !this.state.goodsCreated) {
      this.addNewGoods();
      this.setState({ goodsCreated: true });
    }
  }

  addNewGoods = () => {
    const { goodsList } = this.state;
    this.setState({
      goodsList: [...goodsList, new Association('inline', new Goods('items'))],
    });
  };

  updateGoods = (newGoods, index, renumberActions) => {
    const { onChange } = this.props;

    const { goodsList } = this.state;
    const newGoodsList = [...goodsList];
    const newAssociation = { ...newGoodsList[index] };

    newAssociation.entity = newGoods;
    newGoodsList[index] = newAssociation;

    this.setState({
      goodsList: newGoodsList,
    });

    onChange?.(newGoodsList, renumberActions);
  };

  render() {
    const { t, addToStack, popStack, defaultEnabled, isOpenForm } = this.props;
    const { goodsList } = this.state;

    const goodsWithoutAssociation = goodsList.map((association) => association.entity);

    if (isOpenForm || goodsWithoutAssociation[0]?.isOpen) {
      return (
        <div className="list-sector">
          <ItemsFormPage
            {...this.props}
            initialSequenceNr={this.props.initialSequenceNr}
            headerName={t('goods.header', { count: index + 1 })}
            goods={goodsWithoutAssociation}
            onChange={(newGoods, renumberActions) => {
              this.updateGoods(newGoods, index, renumberActions);
            }}
          />
        </div>
      );
    }

    return (
      <>
        {goodsList
          .map((association) => association.entity)
          .map((goods, index) => (
            <div
              className="list-sector goods-form-element"
              key={`input-goods-${index}`}
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: '',
                  component: (
                    <ItemsFormPage
                      popStack={popStack}
                      headerName={t('goods.header', { count: index + 1 })}
                      goods={goods}
                      initialSequenceNr={this.props.initialSequenceNr}
                      onChange={(newGoods, renumberActions) => {
                        this.updateGoods(newGoods, index, renumberActions);
                      }}
                    />
                  ),
                });
              }}
            >
              <div className="flex-container justify-between">
                <h2>
                  {t('goods.header', { count: index + 1 })} {goods.name ? ` - ${goods.name}` : null}
                </h2>
                <div className="list-actions">
                  <div></div>
                  <ReactSVG
                    src="/icons/duplicate.svg"
                    className="close-button"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      const newGoodsList = [...goodsList];
                      newGoodsList.splice(
                        index,
                        0,
                        new Association('inline', { ...goods, id: null, nonce: null })
                      );

                      this.setState({ goodsList: newGoodsList });
                      onChange?.(newConsignments);
                    }}
                  />
                  {index !== 0 || defaultEnabled ? (
                    <CloseButton
                      onClick={(e) => {
                        const { onChange } = this.props;
                        e.preventDefault();
                        e.stopPropagation();

                        const newGoodsList = [...goodsList];
                        newGoodsList.splice(index, 1);

                        this.setState({
                          goodsList: newGoodsList,
                        });
                        onChange && onChange(newGoodsList, true);
                      }}
                    />
                  ) : null}
                  <div className="go">
                    <ReactSVG src="/icons/back.svg" />
                  </div>
                </div>
              </div>
              <div>
                <div className="flex-container value-list">
                  {goods.quantity && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.quantity')}:</b> {goods.quantity}
                    </p>
                  )}
                  {goods.barCode && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.barCode')}:</b> {goods.barCode}
                    </p>
                  )}
                  {goods.productsType && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.productsType')}:</b> {goods.productsType}
                    </p>
                  )}
                  {goods.packagingMaterial && (
                    <p className="no-margin-bottom value-list-item">
                      <b>{t('goods.packagingMaterial')}:</b> {goods.packagingMaterial}
                    </p>
                  )}
                </div>
                <div className="flex-container value-list">
                  {goods.width && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.width')}:</b> {`${goods.width.value}${goods.width.unit}`}
                    </p>
                  )}
                  {goods.height && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.height')}:</b> {`${goods.height.value}${goods.height.unit}`}
                    </p>
                  )}
                  {goods.length && (
                    <p className="no-margin value-list-item">
                      <b>{t('goods.length')}:</b> {`${goods.length.value}${goods.length.unit}`}
                    </p>
                  )}
                </div>
                {goods.weight && (
                  <p className="no-margin">
                    <b>{t('goods.weight')}:</b> {`${goods.weight.value}${goods.weight.unit}`}
                  </p>
                )}
                {goods.description && (
                  <p className="no-margin">
                    <b>{t('goods.description')}:</b> {goods.description}
                  </p>
                )}
                {goods.remark && (
                  <p className="no-margin">
                    <b>{t('goods.remark')}:</b> {goods.remark}
                  </p>
                )}
              </div>
            </div>
          ))}
        <div className={`input-group left${goodsList.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={(e) => this.addNewGoods(e)}>
            {t('form.label.addGoods')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ItemsInput);
