import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/business/${id}`);
};

export const create = (business) => {
  return api.post(`/business`, business);
};

export const update = (business) => {
  return api.put(`/business`, business);
};

export const remove = (business) => {
  return api.delete(`/business/${business.id}`);
};

export const verify = (business) => {
  return api.get(`/business/${business.id}/verify`);
};

export const unverify = (business) => {
  return api.get(`/business/${business.id}/unverify`);
};

// Pictures
export const updateBusinessLogo = (logo) => {
  return api.put(`/business/logo`, { logo: logo });
};

export const updateBusinessBanner = (banner) => {
  return api.put(`/business/banner`, { banner: banner });
};

// Fetching all and searching
export const latest = (from = 0, amount = 20) => {
  return api.get(`/businesses/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/businesses/${from}/${amount}`, searchParameters);
};
