import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

import * as fleetActions from '@actions/fleetActions';
import * as vehicleGroupActions from '@actions/vehicleGroupActions';

import { flatten } from '@utils/arrayUtils';
import { reactSelectCustomStyle } from '@/constants/dashboard';
class VehicleGroupSelector extends Component {
  state = {
    vehicles: this.props.value || [],
  };

  componentDidMount() {
    const {
      vehicleLightGroups,
      fleetsLight,
      getVehicleLightGroups,
      getFleetsLight,
    } = this.props;
    vehicleLightGroups.length < 1 && getVehicleLightGroups();
    fleetsLight.length < 1 && getFleetsLight();
  }

  render() {
    const { t, onChange, vehicleLightGroups, fleetsLight } = this.props;
    const { vehicles } = this.state;

    const ids = [];
    ids.push(
      ...vehicleLightGroups
        .filter((vg) =>
          vg.vehicles.every((vehicleId) => vehicles.includes(vehicleId))
        )
        .map((vg) => vg.id)
    );
    ids.push(
      ...fleetsLight
        .filter((f) =>
          f.vehicles.every((vehicleId) => vehicles.includes(vehicleId))
        )
        .map((vg) => vg.id)
    );

    const selectedVehicleGroups = vehicleLightGroups.filter((group) =>
      ids?.includes(group.id)
    );
    const selectedFleets = fleetsLight.filter((fleet) =>
      ids?.includes(fleet.id)
    );

    return (
      <Select
        isMulti={true}
        styles={reactSelectCustomStyle({ controlRounded: true, controlBackground: 'var(--lightgray)', controlPaddingLeft: 3 })}
        options={[
          {
            value: 'all',
            label: t('vehicleGroup.allVehicles'),
            vehicles: [],
          },
          ...vehicleLightGroups.map((group) => ({
            value: group.id,
            label: group.name,
            vehicles: group.vehicles,
          })),
          ...fleetsLight.map((fleet) => ({
            value: fleet.id,
            label: fleet.name,
            vehicles: fleet.vehicles,
          })),
        ]}
        value={
          (selectedVehicleGroups && selectedVehicleGroups.length > 0) ||
          (selectedFleets && selectedFleets.length > 0)
            ? [
                ...(selectedVehicleGroups && selectedVehicleGroups.length > 0
                  ? selectedVehicleGroups.map((selectedVehicleGroup) => ({
                      value: selectedVehicleGroup.id,
                      label: selectedVehicleGroup.name,
                      vehicles: selectedVehicleGroup.vehicles,
                    }))
                  : []),
                ...(selectedFleets && selectedFleets.length > 0
                  ? selectedFleets.map((selectedFleet) => ({
                      value: selectedFleet.id,
                      label: selectedFleet.name,
                      vehicles: selectedFleet.vehicles,
                    }))
                  : []),
              ]
            : [
                {
                  value: 'all',
                  label: t('vehicleGroup.allVehicles'),
                  vehicles: [],
                },
              ]
        }
        onChange={(e) => {
          if (e == null || e.value === 'all' || e.length < 1) {
            this.setState({
              vehicles: [],
            });

            onChange([]);
          } else {
            this.setState({
              vehicles: e ? [...flatten(e.map((a) => a.vehicles))] : [],
            });

            onChange(e ? [...flatten(e.map((a) => a.vehicles))] : []);
          }
        }}
      />
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,

    vehicleLightGroups: state.vehicleGroup.vehicleLightGroups,
    isFetchingVehicleLightGroups:
      state.vehicleGroup.isFetchingVehicleLightGroups,

    fleetsLight: state.fleet.fleetsLight,
    isFetchingFleetsLight: state.fleet.isFetchingFleetsLight,

    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicleLightGroups: () =>
      dispatch(vehicleGroupActions.getVehicleLightGroups()),
    getFleetsLight: () => dispatch(fleetActions.getFleetsLight()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleGroupSelector));
