import api from '../api';

export const get = (id = '') => {
  return api.get(`/contract/${id}`);
};

export const create = (contract) => {
  return api.post(`/contract`, contract);
};

export const update = (contract) => {
  return api.put(`/contract`, contract);
};

export const remove = (contract) => {
  return api.delete(`/contract/${contract.id}`);
};

export const latest = (from = 0, amount = 20) => {
  return api.get(`/contracts/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/contracts/${from}/${amount}`, searchParameters);
};
