export const widgetConstants = {
  WIDGET_CHANGE_VALUE: 'WIDGET_CHANGE_VALUE',

  GET_WIDGET_STARTED: 'GET_WIDGET_STARTED',
  GET_WIDGET_SUCCESS: 'GET_WIDGET_SUCCESS',
  GET_WIDGET_FAILURE: 'GET_WIDGET_FAILURE',

  GET_PUBLIC_WIDGET_STARTED: 'GET_PUBLIC_WIDGET_STARTED',
  GET_PUBLIC_WIDGET_SUCCESS: 'GET_PUBLIC_WIDGET_SUCCESS',
  GET_PUBLIC_WIDGET_FAILURE: 'GET_PUBLIC_WIDGET_FAILURE',

  GET_WIDGETS_STARTED: 'GET_WIDGETS_STARTED',
  GET_WIDGETS_SUCCESS: 'GET_WIDGETS_SUCCESS',
  GET_WIDGETS_FAILURE: 'GET_WIDGETS_FAILURE',

  SEARCH_WIDGETS_STARTED: 'SEARCH_WIDGETS_STARTED',
  SEARCH_WIDGETS_SUCCESS: 'SEARCH_WIDGETS_SUCCESS',
  SEARCH_WIDGETS_FAILURE: 'SEARCH_WIDGETS_FAILURE',

  CREATING_WIDGET_STARTED: 'CREATING_WIDGET_STARTED',
  CREATING_WIDGET_SUCCESS: 'CREATING_WIDGET_SUCCESS',
  CREATING_WIDGET_FAILURE: 'CREATING_WIDGET_FAILURE',

  UPDATING_WIDGET_STARTED: 'UPDATING_WIDGET_STARTED',
  UPDATING_WIDGET_SUCCESS: 'UPDATING_WIDGET_SUCCESS',
  UPDATING_WIDGET_FAILURE: 'UPDATING_WIDGET_FAILURE',

  DELETE_WIDGET_STARTED: 'DELETE_WIDGET_STARTED',
  DELETE_WIDGET_SUCCESS: 'DELETE_WIDGET_SUCCESS',
  DELETE_WIDGET_FAILURE: 'DELETE_WIDGET_FAILURE',
};
