import * as vehicleGroupApi from '@api/vehicleGroupApi';

import { vehicleGroupConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: vehicleGroupConstants.VEHICLE_GROUP_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching vehicleGroup
 */
export const startGetVehicleGroup = () => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUP_STARTED,
});

export const getVehicleGroupSuccess = (result) => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUP_SUCCESS,
  ...result,
});

export const getVehicleGroupFailure = (error) => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUP_FAILURE,
  ...error,
});

export const getVehicleGroup = (id) => {
  return (dispatch) => {
    dispatch(startGetVehicleGroup());

    const promise = vehicleGroupApi.get(id);

    promise
      .then((result) => {
        dispatch(getVehicleGroupSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleGroupFailure(error));
      });

    return promise;
  };
};

/**
 * Creating vehicleGroup
 */
export const startCreateVehicleGroup = () => ({
  type: vehicleGroupConstants.CREATING_VEHICLE_GROUP_STARTED,
});

export const createVehicleGroupSuccess = (result) => ({
  type: vehicleGroupConstants.CREATING_VEHICLE_GROUP_SUCCESS,
  ...result,
});

export const createVehicleGroupFailure = (error) => ({
  type: vehicleGroupConstants.CREATING_VEHICLE_GROUP_FAILURE,
  ...error,
});

export const createVehicleGroup = (vehicleGroup) => {
  return (dispatch) => {
    dispatch(startCreateVehicleGroup());

    const promise = vehicleGroupApi.create(vehicleGroup);

    promise
      .then((result) => {
        dispatch(createVehicleGroupSuccess(result));
      })
      .catch((error) => {
        dispatch(createVehicleGroupFailure(error));
      });

    return promise;
  };
};

/**
 * Updating vehicleGroup
 */
export const startUpdateVehicleGroup = () => ({
  type: vehicleGroupConstants.UPDATING_VEHICLE_GROUP_STARTED,
});

export const updateVehicleGroupSuccess = (result) => ({
  type: vehicleGroupConstants.UPDATING_VEHICLE_GROUP_SUCCESS,
  ...result,
});

export const updateVehicleGroupFailure = (error) => ({
  type: vehicleGroupConstants.UPDATING_VEHICLE_GROUP_FAILURE,
  ...error,
});

export const updateVehicleGroup = (vehicleGroup) => {
  return (dispatch) => {
    dispatch(startUpdateVehicleGroup());

    const promise = vehicleGroupApi.update(vehicleGroup);

    promise
      .then((result) => {
        dispatch(updateVehicleGroupSuccess(result));
      })
      .catch((error) => {
        dispatch(updateVehicleGroupFailure(error));
      });

    return promise;
  };
};

/**
 * Delete vehicleGroup
 */
export const startDeleteVehicleGroup = () => ({
  type: vehicleGroupConstants.DELETE_VEHICLE_GROUP_STARTED,
});

export const deleteVehicleGroupSuccess = (result) => ({
  type: vehicleGroupConstants.DELETE_VEHICLE_GROUP_SUCCESS,
  ...result,
});

export const deleteVehicleGroupFailure = (error) => ({
  type: vehicleGroupConstants.DELETE_VEHICLE_GROUP_FAILURE,
  ...error,
});

export const deleteVehicleGroup = (vehicleGroup) => {
  return (dispatch) => {
    dispatch(startDeleteVehicleGroup());

    const promise = vehicleGroupApi.remove(vehicleGroup);

    promise
      .then((result) => {
        dispatch(deleteVehicleGroupSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteVehicleGroupFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching vehicleGroups
 */
export const startGetVehicleGroups = (from) => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUPS_STARTED,
  from: from,
});

export const getVehicleGroupsSuccess = (result, from) => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUPS_SUCCESS,
  from: from,
  ...result,
});

export const getVehicleGroupsFailure = (error) => ({
  type: vehicleGroupConstants.GET_VEHICLE_GROUPS_FAILURE,
  ...error,
});

export const getVehicleGroups = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetVehicleGroups(from));

    const promise = vehicleGroupApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleGroupsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleGroupsFailure(error));
      });

    return promise;
  };
};

export const searchVehicleGroups = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetVehicleGroups(from));

    const promise = vehicleGroupApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleGroupsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleGroupsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching light vehicleGroups
 */
export const startGetVehicleLightGroups = () => ({
  type: vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_STARTED,
});

export const getVehicleLightGroupsSuccess = (result) => ({
  type: vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_SUCCESS,
  ...result,
});

export const getVehicleLightGroupsFailure = (error) => ({
  type: vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_FAILURE,
  ...error,
});

export const getVehicleLightGroups = () => {
  return (dispatch) => {
    dispatch(startGetVehicleLightGroups());

    const promise = vehicleGroupApi.light();

    promise
      .then((result) => {
        dispatch(getVehicleLightGroupsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleLightGroupsFailure(error));
      });

    return promise;
  };
};
