import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import EntityTypeSelector from '@components/general/EntityTypeSelector';
import FieldsSelector from '@components/general/FieldsSelector';
import SortDirectionSelector from '@components/general/SortDirectionSelector';
import IntegrationFiltersInput from '@components/integration/filter/IntegrationFiltersInput';

import * as fieldActions from '@actions/fieldActions';
import * as generalActions from '@actions/generalActions';
import * as navigationActions from '@actions/navigationActions';

class TQLInput extends Component {
  state = this.props.value ? this.props.value : {};

  render() {
    const { t, onChange, getFieldsRequest, isFetchingFields } = this.props;
    const { entityType, fieldOptions, fields, filters, sortFields, sortDirection } = this.state;

    return (
      <>
        <div className="input-group">
          <div className="input-grou no-margin-top">
            <EntityTypeSelector
              value={entityType}
              placeholder={t('tql.entityType')}
              onChange={(event) => {
                this.setState({
                  entityType: event.value,
                  isFetchingFields: true,
                });
                getFieldsRequest(event.value).then((response) => {
                  this.setState({
                    fieldOptions: response.fields,
                    isFetchingFields: false,
                  });

                  onChange && onChange(this.state);
                });

                onChange && onChange(this.state);
              }}
            />
          </div>
        </div>

        {entityType ? (
          <div className="input-group">
            <IntegrationFiltersInput
              value={filters ?? []}
              entityType={entityType}
              onChange={(newFilters) => {
                this.setState({
                  filters: newFilters,
                });

                onChange && onChange(this.state);
              }}
            />
          </div>
        ) : null}

        <div className="input-group">
          <div className="input-group no-margin-top">
            <FieldsSelector
              values={fields}
              options={fieldOptions}
              isFetchingFields={isFetchingFields}
              placeholder={t('tql.fields')}
              onChange={(event) => {
                this.setState({
                  fields: event?.length ? event.map((value) => ({ name: value.value })) : [],
                });

                onChange && onChange(this.state);
              }}
            />
          </div>
        </div>

        <div className="input-group">
          <div className="input-group no-margin-top">
            <FieldsSelector
              values={sortFields}
              options={fieldOptions}
              isFetchingFields={isFetchingFields}
              placeholder={t('tql.sortFields')}
              onChange={(event) => {
                this.setState({
                  sortFields: event?.length ? event.map((value) => ({ name: value.value })) : [],
                });

                onChange && onChange(this.state);
              }}
            />
          </div>
        </div>

        <div className="input-group">
          <div className="input-group no-margin-top">
            <SortDirectionSelector
              value={sortDirection}
              placeholder={t('tql.sortDirection')}
              onChange={(event) => {
                this.setState({
                  sortDirection: event.value,
                });
                onChange && onChange(this.state);
              }}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    darkMode: state.settings.darkMode,
    user: state.auth.user,
    dataTypes: state.platform.dataTypes,

    fields: state.platform.fields,
    isFetchingFields: state.platform.isFetchingFields,

    entityStructure: state.platform.entityStructure,
    triggerEvents: state.platform.triggerEvents,

    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFieldsRequest: (entityType) => dispatch(fieldActions.getFieldsRequest(entityType)),

    searchFieldsRequest: (entityType, keyword, limit) =>
      dispatch(fieldActions.searchFieldsRequest(entityType, keyword, limit)),

    updateTopStack: (args) => dispatch(navigationActions.updateTopStack(args)),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),

    getEntityStructure: (entityType) => dispatch(generalActions.getEntityStructure(entityType)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TQLInput));
