import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class LocationGranularitySelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;

    const options = [
      { value: 'country', label: t('locationGranularity.country') },
      { value: 'city', label: t('locationGranularity.city') },
      { value: 'postal_code', label: t('locationGranularity.postalCode') },
      { value: 'street', label: t('locationGranularity.street') },
      { value: 'exact', label: t('locationGranularity.exact') },
    ];

    return (
      <Select
        value={options.find((option) => option.value === value)}
        noOptionsMessage={() => t('noOptions')}
        options={options}
        onChange={onChange}
        placeholder={placeholder || t('form.label.selectGranularity')}
      />
    );
  }
}

export default withTranslation('translation')(LocationGranularitySelector);
