import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/team/${id}`);
};

export const create = (team) => {
  return api.post(`/team`, team);
};

export const update = (team) => {
  return api.put(`/team`, team);
};

export const remove = (team) => {
  return api.delete(`/team/${team.id}`);
};

// Get and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/teams/${from}/${amount}`);
};

export const light = () => {
  return api.get(`/teams/light`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/teams/${from}/${amount}`, searchParameters);
};
