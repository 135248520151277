import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/route/${id}`);
};

export const create = (route) => {
  return api.post(`/route`, route);
};

export const update = (route) => {
  return api.put(`/route`, route);
};

export const remove = (route) => {
  return api.delete(`/route/${route.id}`);
};

// Fetch and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/routes/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/routes/${from}/${amount}`, searchParameters);
};

// Business functions

export const calculate = (calculate) => {
  return api.post(`/route/calculate`, calculate);
};

export const calculateWithGeoreferences = (calculate) => {
  return api.post(`/route/calculate:georeferences`, calculate);
};
