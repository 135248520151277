import ConstraintValue from './ConstraintValue';

export default class Constraint {
  id;
  nonce;

  value;

  constructor(value) {
    this.nonce = Math.random().toString(36).substring(7);

    this.value = value || new ConstraintValue();
  }
}
