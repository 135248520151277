import { flatten } from './arrayUtils';

class Cell {
  x;
  y;
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

const trim = (str) => {
  return str.replace(/^\s+|\s+$/g, '');
};

export const numberToLetter = (number) => {
  var s = '',
    t;

  while (number > 0) {
    t = (number - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    number = ((number - t) / 26) | 0;
  }
  return s || undefined;
};

export const letterToNumber = (str) => {
  var out = 0,
    len = str.length;
  for (var pos = 0; pos < len; pos++) {
    out +=
      (str.toUpperCase().charCodeAt(pos) - 64) * Math.pow(26, len - pos - 1);
  }
  return out;
};

const range = (min, max) =>
  Array.from({ length: max - min + 1 }, (_, i) => min + i);

export const splitStringAndNumber = (str) => {
  const arr = [];
  const num_re = /(\D+)?(\d+)?/y;
  let match;
  do {
    match = num_re.exec(str);
    if (match[1] !== undefined) {
      arr.push(match[1]);
    }
    if (match[2] !== undefined) {
      arr.push(Number(match[2]));
    }
  } while (match[0]);
  return arr;
};

export const consolidateCells = () => {};

export const interpretMatrixSelectionString = (str) => {
  var cells = [];
  if (!str) return cells;
  str.split(':').forEach((partial) => {
    if (partial.length >= 2) {
      const split = splitStringAndNumber(partial);
      cells.push(
        new Cell(parseInt(letterToNumber(split[0]) - 1), parseInt(split[1]) - 1)
      );
    }
  });

  // Is an actual range call
  if (str.includes(':') && cells.length === 2) {
    const sortedCells = cells.sort((a, b) => a.x - b.x && a.y - b.y);

    const rangeX = range(sortedCells[0].x, sortedCells[1].x);
    const rangeY = range(sortedCells[0].y, sortedCells[1].y);

    cells = [];
    rangeX.forEach((x) => {
      rangeY.forEach((y) => {
        cells.push(new Cell(x, y));
      });
    });
  }

  return cells;
};

export const interpretMatrixSelectionStrings = (strs) => {
  if (!strs) return [];
  return flatten(strs.map((str) => interpretMatrixSelectionString(str)));
};

export const interpretTextVariablePositionSelectionString = (
  matrix,
  str,
  top,
  right,
  bottom,
  left
) => {
  var cells = [];

  matrix.forEach((x, indexY) => {
    x.forEach((y, indexX) => {
      if (y && str && trim(y.toLowerCase()) === trim(str.toLowerCase())) {
        cells.push(new Cell(indexX, indexY));
      }
    });
  });

  return cells.map((cell) => {
    cell.x += -(left || 0) + (right || 0);
    cell.y += -(top || 0) + (bottom || 0);
    return cell;
  });
};

export const interpretTextVariablePositionSelectionStrings = (args) => {
  return flatten(
    args.map((arg) =>
      interpretTextVariablePositionSelectionString(
        arg.matrix,
        arg.str,
        arg.top,
        arg.right,
        arg.bottom,
        arg.left
      )
    )
  );
};
