export const goodConstants = {
  GOOD_CHANGE_VALUE: 'GOOD_CHANGE_VALUE',

  GET_GOOD_STARTED: 'GET_GOOD_STARTED',
  GET_GOOD_SUCCESS: 'GET_GOOD_SUCCESS',
  GET_GOOD_FAILURE: 'GET_GOOD_FAILURE',

  GET_GOODS_STARTED: 'GET_GOODS_STARTED',
  GET_GOODS_SUCCESS: 'GET_GOODS_SUCCESS',
  GET_GOODS_FAILURE: 'GET_GOODS_FAILURE',

  SEARCH_GOODS_STARTED: 'SEARCH_GOODS_STARTED',
  SEARCH_GOODS_SUCCESS: 'SEARCH_GOODS_SUCCESS',
  SEARCH_GOODS_FAILURE: 'SEARCH_GOODS_FAILURE',

  CREATING_GOOD_STARTED: 'CREATING_GOOD_STARTED',
  CREATING_GOOD_SUCCESS: 'CREATING_GOOD_SUCCESS',
  CREATING_GOOD_FAILURE: 'CREATING_GOOD_FAILURE',

  UPDATING_GOOD_STARTED: 'UPDATING_GOOD_STARTED',
  UPDATING_GOOD_SUCCESS: 'UPDATING_GOOD_SUCCESS',
  UPDATING_GOOD_FAILURE: 'UPDATING_GOOD_FAILURE',

  DELETE_GOOD_STARTED: 'DELETE_GOOD_STARTED',
  DELETE_GOOD_SUCCESS: 'DELETE_GOOD_SUCCESS',
  DELETE_GOOD_FAILURE: 'DELETE_GOOD_FAILURE',
};
