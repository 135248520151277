import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateLocationContainer from '@containers/location/crud/CreateLocationContainer';

import * as locationActions from '@actions/locationActions';
import * as navigationActions from '@actions/navigationActions';
import { search as searchLocations } from '@api/locationApi'

let searchTimeout = null;

export const transformLocationToValue = (location) => ({
  value: location.id,
  label: `${location.name} - ${
    location?.administrativeReference
      ? `${location.administrativeReference.street || ''} ${
          location.administrativeReference.houseNumber || ''
        }${location.administrativeReference.houseNumberAddition || ''}, ${
          location.administrativeReference.city || ''
        }, ${location.administrativeReference.country || ''}`
      : ''
  }`,
  id: location.id,
  location: location,
});

class LocationInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      location: this.props.location ? transformLocationToValue(this.props.location) : null,
      searchParameters: this.props.searchParameters ? this.props.searchParameters : {},
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  onChange = (newLocation) => {
    this.setState({
      location: newLocation,
    });
    const { onChange } = this.props;
    onChange && onChange(newLocation ? newLocation.location : null);
  };

  render() {
    const { t, placeholder, isFetchingLocations, addToStack, isDisabled } =
      this.props;
    const { location, searchParameters } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={placeholder ? placeholder : `${t('form.label.location')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) => t('form.addCreatable', { value: inputValue })}
          isLoading={isFetchingLocations}
          isDisabled={isDisabled || isFetchingLocations}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchLocations({ query: inputValue, ...searchParameters }, 0, 40).then(
                (response) => {
                  callback(
                    response.locations.map((location) => transformLocationToValue(location))
                  );
                }
              );
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('location.new'),
              component: (
                <CreateLocationContainer
                  targetName={inputValue}
                  callback={(newLocation) => {
                    this.onChange(transformLocationToValue(newLocation));
                  }}
                />
              ),
            });
          }}
          value={location}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(locationActions.changeValue(name, value)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(LocationInput));
