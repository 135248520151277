import { tenderBidConstants } from '@constants/tenderBid.constants';

const initialState = {
  isFetchingTenderBid: false,
  formTenderBid: null,
  tenderBid: null,

  isFetchingTenderBids: false,
  isFetchingTenderBidsFrom: 0,
  tenderBidsTotalResults: 0,
  tenderBidsLoaded: 0,
  tenderBids: [],
};

export default function tenderBid(state = initialState, action) {
  switch (action.type) {
    case tenderBidConstants.TENDER_BID_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case tenderBidConstants.GET_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBid: true };
    case tenderBidConstants.GET_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBid: false,
        error: action.message || '',
      };
    case tenderBidConstants.GET_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBid: false,
        tender: action.tender,
      };

    case tenderBidConstants.GET_TENDER_BIDS_STARTED:
      return {
        ...state,
        isFetchingTenderBids: true,
        isFetchingTenderBidsFrom: action.from || 0,
      };
    case tenderBidConstants.GET_TENDER_BIDS_FAILURE:
      return {
        ...state,
        isFetchingTenderBids: false,
        error: action.message || '',
      };
    case tenderBidConstants.GET_TENDER_BIDS_SUCCESS:
      return {
        ...state,
        isFetchingTenderBids: false,
        tenderBids:
          action.from > 0
            ? [...state.tenderBids, ...action.tenderBids]
            : action.tenderBids,
        tenderBidsLoaded:
          action.from > 0
            ? state.tenderBids.length + action.tenderBids.length
            : action.tenderBids.length,
        tenderBidsTotalResults: action.totalResults || 0,
      };

    case tenderBidConstants.SEARCH_TENDER_BIDS_STARTED:
      return {
        ...state,
        isFetchingTenderBids: true,
        isFetchingTenderBidsFrom: action.from || 0,
      };
    case tenderBidConstants.SEARCH_TENDER_BIDS_FAILURE:
      return {
        ...state,
        isFetchingTenderBids: false,
        error: action.message || '',
      };
    case tenderBidConstants.SEARCH_TENDER_BIDS_SUCCESS:
      return {
        ...state,
        isFetchingTenderBids: false,
        tenderBids:
          action.from > 0
            ? [...state.tenderBids, ...action.tenderBids]
            : action.tenderBids,
        tenderBidsLoaded:
          action.from > 0
            ? state.tenderBids.length + action.tenderBids.length
            : action.tenderBids.length,
        tenderBidsTotalResults: action.totalResults || 0,
      };

    case tenderBidConstants.CREATING_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBid: true };
    case tenderBidConstants.CREATING_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBid: false,
        error: action.message || '',
      };
    case tenderBidConstants.CREATING_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBid: false,
        tenderBid: action.tenderBid,
        tenderBids: [action.tenderBid, ...state.tenderBids],
      };

    case tenderBidConstants.UPDATING_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBid: true };
    case tenderBidConstants.UPDATING_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBid: false,
        error: action.message || '',
      };
    case tenderBidConstants.UPDATING_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBid: false,
        tender: action.tender,
        tenders: [...state.tenders].map((tender) => {
          if (action.tender.id === tender.id) {
            return action.tender;
          }
          return tender;
        }),
      };

    case tenderBidConstants.DELETE_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBid: true };
    case tenderBidConstants.DELETE_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBid: false,
        message: action.message || '',
      };
    case tenderBidConstants.DELETE_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBid: false,
        tender: action.tender,
        tenders: [...state.tenders].filter(
          (tender) => action.tender.id !== tender.id
        ),
      };
    default:
      return state;

    case tenderBidConstants.ACCEPT_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBids: true };
    case tenderBidConstants.ACCEPT_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBids: false,
        error: action.message || '',
      };
    case tenderBidConstants.ACCEPT_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBids: false,
        tenderBid: action.tenderBid,
        tenderBids: [...state.tenderBids].map((tenderBid) => {
          if (action.tenderBid.id === tenderBid.id) {
            return action.tenderBid;
          }
          return tenderBid;
        }),
      };

    case tenderBidConstants.DECLINE_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBids: true };
    case tenderBidConstants.DECLINE_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBids: false,
        error: action.message || '',
      };
    case tenderBidConstants.DECLINE_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBids: false,
        tenderBid: action.tenderBid,
        tenderBids: [...state.tenderBids].map((tenderBid) => {
          if (action.tenderBid.id === tenderBid.id) {
            return action.tenderBid;
          }
          return tenderBid;
        }),
      };

    case tenderBidConstants.COUNTER_TENDER_BID_STARTED:
      return { ...state, isFetchingTenderBids: true };
    case tenderBidConstants.COUNTER_TENDER_BID_FAILURE:
      return {
        ...state,
        isFetchingTenderBids: false,
        error: action.message || '',
      };
    case tenderBidConstants.COUNTER_TENDER_BID_SUCCESS:
      return {
        ...state,
        isFetchingTenderBids: false,
        tenderBid: action.tenderBid,
        tenderBids: [...state.tenderBids].map((tenderBid) => {
          if (action.tenderBid.id === tenderBid.id) {
            return action.tenderBid;
          }
          return tenderBid;
        }),
      };
  }
}
