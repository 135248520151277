import { terminalConstants } from '../constants/terminal.constants';

const initialState = {
  isFetchingTerminal: false,
  formTerminal: null,
  terminal: null,

  isFetchingTerminals: false,
  isFetchingTerminalsFrom: 0,
  terminalsTotalResults: 0,
  terminalsLoaded: 0,
  terminals: [],
};

export default function terminal(state = initialState, action) {
  switch (action.type) {
    case terminalConstants.TERMINAL_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case terminalConstants.GET_TERMINAL_STARTED:
      return { ...state, isFetchingTerminal: true };
    case terminalConstants.GET_TERMINAL_FAILURE:
      return {
        ...state,
        isFetchingTerminal: false,
        error: action.message || '',
      };
    case terminalConstants.GET_TERMINAL_SUCCESS:
      return {
        ...state,
        isFetchingTerminal: false,
        termainl: action.terminal,
      };

    case terminalConstants.GET_TERMINALS_STARTED:
      return {
        ...state,
        isFetchingTerminals: true,
        isFetchingTerminalsFrom: action.from || 0,
      };
    case terminalConstants.GET_TERMINALS_FAILURE:
      return {
        ...state,
        isFetchingTerminals: false,
        error: action.message || '',
      };
    case terminalConstants.GET_TERMINALS_SUCCESS:
      return {
        ...state,
        isFetchingTerminals: false,
        terminals:
          action.from > 0
            ? [...state.terminals, ...action.terminals]
            : action.terminals,
        terminalsLoaded:
          action.from > 0
            ? state.terminals.length + action.terminals.length
            : action.terminals.length,
        terminalsTotalResults: action.totalResults || 0,
      };

    case terminalConstants.SEARCH_TERMINALS_STARTED:
      return {
        ...state,
        isFetchingTerminals: true,
        isFetchingTerminalsFrom: action.from || 0,
      };
    case terminalConstants.SEARCH_TERMINALS_FAILURE:
      return {
        ...state,
        isFetchingTerminals: false,
        error: action.message || '',
      };
    case terminalConstants.SEARCH_TERMINALS_SUCCESS:
      return {
        ...state,
        isFetchingTerminals: false,
        terminals:
          action.from > 0
            ? [...state.terminals, ...action.terminals]
            : action.terminals,
        terminalsLoaded:
          action.from > 0
            ? state.terminals.length + action.terminals.length
            : action.terminals.length,
        terminalsTotalResults: action.totalResults || 0,
      };

    case terminalConstants.CREATING_TERMINAL_STARTED:
      return { ...state, isFetchingTerminal: true };
    case terminalConstants.CREATING_TERMINAL_FAILURE:
      return {
        ...state,
        isFetchingTerminal: false,
        error: action.message || '',
      };
    case terminalConstants.CREATING_TERMINAL_SUCCESS:
      return {
        ...state,
        isFetchingTerminal: false,
        terminal: action.terminal,
        terminals: [action.terminal, ...state.terminals],
      };

    case terminalConstants.UPDATING_TERMINAL_STARTED:
      return { ...state, isFetchingTerminal: true };
    case terminalConstants.UPDATING_TERMINAL_FAILURE:
      return {
        ...state,
        isFetchingTerminal: false,
        error: action.message || '',
      };
    case terminalConstants.UPDATING_TERMINAL_SUCCESS:
      return {
        ...state,
        isFetchingTerminal: false,
        terminal: action.terminal,
        terminals: [...state.terminals].map((terminal) => {
          if (action.terminal.id === terminal.id) {
            return action.terminal;
          }
          return terminal;
        }),
      };

    case terminalConstants.DELETE_TERMINAL_STARTED:
      return { ...state, isFetchingTerminal: true };
    case terminalConstants.DELETE_TERMINAL_FAILURE:
      return {
        ...state,
        isFetchingTerminal: false,
        message: action.message || '',
      };
    case terminalConstants.DELETE_TERMINAL_SUCCESS:
      return {
        ...state,
        isFetchingTerminal: false,
        terminal: action.terminal,
        terminals: [...state.terminals].filter(
          (terminal) => action.terminal.id !== terminal.id
        ),
      };
    default:
      return state;
  }
}
