export const todoConstants = {
  TODO_ACTION_FAILED: 'TODO_ACTION_FAILED',
  TODO_ACTION_STARTED: 'TODO_ACTION_STARTED',

  CREATE_TODO_SUCCESS: 'CREATE_TODO_SUCCESS',

  UPDATING_TODO_SUCCESS: 'UPDATING_TODO_SUCCESS',

  DELETE_TODO_STARTED: 'DELETE_TODO_STARTED',
  DELETE_TODO_SUCCESS: 'DELETE_TODO_SUCCESS',
  DELETE_TODO_FAILURE: 'DELETE_TODO_FAILURE',

  GET_TODOS_STARTED: 'GET_TODOS_STARTED',
  GET_TODOS_SUCCESS: 'GET_TODOS_SUCCESS',

  GET_TODO_STARTED: 'GET_TODO_STARTED',
  GET_TODO_SUCCESS: 'GET_TODO_SUCCESS',
  GET_TODO_FAILURE: 'GET_TODO_FAILURE',

  GET_ENTITY_TODOS_STARTED: 'GET_ENTITY_TODOS_STARTED',
  GET_ENTITY_TODOS_SUCCESS: 'GET_ENTITY_TODOS_SUCCESS',

  GET_TODO_STATISTICS_SUCCESS: 'GET_TODO_STATISTICS_SUCCESS',
};
