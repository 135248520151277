import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

export default class Collapsible extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen:
        this.props.isOpen !== undefined && this.props.isOpen !== null
          ? this.props.isOpen
          : this.props.defaultIsOpen !== undefined && this.props.defaultIsOpen !== null
          ? this.props.defaultIsOpen
          : true,
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  render() {
    const { isOpen } = this.state;
    const {
      name,
      children,
      className,
      childPadding,
      childClassName,
      childStyle,
      onOpenChange,
      rightSideElements,
    } = this.props;

    return (
      <div className={`collapsible-container ${isOpen ? ' open' : ' closed'}`}>
        <div
          className={`collapsible${
            isOpen ? ' open' : ' closed'
          } flex-container ${className} align-items-center`}
          onClick={(e) => {
            onOpenChange && onOpenChange(!isOpen);
            this.setState({ isOpen: !isOpen });
          }}
        >
          <div className="name">{name}</div>
          <div className="flex-container right-elements">
            {rightSideElements}
            <ReactSVG className="icon" src="/icons/back.svg" />
          </div>
        </div>
        {isOpen && (
          <div
            className={childClassName}
            style={childPadding ? { padding: '10px 0 0 20px', ...childStyle } : childStyle || {}}
          >
            {children}
          </div>
        )}
      </div>
    );
  }
}
