export const commentConstants = {
  HANDLE_COMMENT_FORM: 'HANDLE_COMMENT_FORM',

  GETTING_COMMENTS_STARTED: 'GETTING_COMMENTS_STARTED',
  GETTING_COMMENTS_SUCCESS: 'GETTING_COMMENTS_SUCCESS',
  GETTING_COMMENTS_FAILURE: 'GETTING_COMMENTS_FAILURE',

  CREATING_COMMENT_STARTED: 'CREATING_COMMENT_STARTED',
  CREATING_COMMENT_SUCCESS: 'CREATING_COMMENT_SUCCESS',
  CREATING_COMMENT_FAILURE: 'CREATING_COMMENT_FAILURE',

  UPDATING_COMMENT_STARTED: 'UPDATING_COMMENT_STARTED',
  UPDATING_COMMENT_SUCCESS: 'UPDATING_COMMENT_SUCCESS',
  UPDATING_COMMENT_FAILURE: 'UPDATING_COMMENT_FAILURE',

  DELETE_COMMENT_STARTED: 'DELETE_COMMENT_STARTED',
  DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
  DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
};
