import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';

import { getBase64, getCroppedImg } from '@utils/imageUtils';

import Loader from './Loader';
import OverlayModal from './OverlayModal';

const initialState = {
  src: null,
  crop: { x: 0, y: 0 },
  zoom: 1,
  zoomSpeed: 0.2,
  aspect: 1 / 1,
  croppedAreaPixels: null,
  isCropping: false,
};

export default class AvatarPicker extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;

    this.dropzoneRef = React.createRef();
    this.cropperRef = React.createRef();
  }

  componentWillUnmount() {
    this.dropzoneRef = null;
    this.cropperRef = null;
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  handleSubmit = (e) => {
    const { handleSubmit } = this.props;
    e.preventDefault();
    try {
      this.setState({
        isCropping: true,
      });
      getCroppedImg(this.state.src, this.state.croppedAreaPixels).then(
        (croppedImage) => {
          this.setState({
            croppedImage,
            isCropping: false,
          });
          handleSubmit(croppedImage);
          this.setState(initialState);
        }
      );
    } catch (e) {
      this.setState({
        isCropping: false,
      });
    }
  };

  render() {
    const { t, active, onClose, title, isLoading } = this.props;
    const { src, crop, zoom, aspect, zoomSpeed, isCropping } = this.state;

    return (
      <OverlayModal
        active={active}
        onClose={(e) => {
          this.setState(initialState);
          onClose && onClose(e);
        }}
      >
        <form className="overlay-component center" onSubmit={this.handleSubmit}>
          <h1>{title || 'Profielfoto'}</h1>
          <div className="input-container fixed-width">
            {isCropping || isLoading ? (
              <div className="input-group">
                <Loader />
              </div>
            ) : src ? (
              <div className="input-group image-cropper avatar">
                <Cropper
                  image={src}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                  zoomSpeed={zoomSpeed}
                  cropShape={'round'}
                  aspect={aspect}
                  showGrid={false}
                />
              </div>
            ) : (
              <Dropzone
                ref={this.dropzoneRef}
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    getBase64(acceptedFiles[0]).then((result) => {
                      this.setState({ src: result });
                    });
                  }
                }}
                accept="image/*"
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => {
                  return (
                    <div
                      className={`input-group drag-and-drop${
                        isDragActive ? ' dragging' : ''
                      }${isDragReject ? ' rejected' : ''}`}
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <p>
                        {isDragActive
                          ? isDragReject
                            ? t('dragAndDrop.rejected')
                            : t('dragAndDrop.dragging')
                          : t('dragAndDrop.label')}
                      </p>
                    </div>
                  );
                }}
              </Dropzone>
            )}
          </div>
          {src && (
            <div className="input-container">
              <div className="input-group">
                <div className="input-group half center no-margin-top">
                  <button
                    className="dark"
                    onClick={(e) => {
                      this.setState(initialState);
                    }}
                  >
                    {t('form.reset')}
                  </button>
                </div>
                <div className="input-group half center no-margin-top">
                  <input type="submit" value={t('form.save')} />
                </div>
              </div>
            </div>
          )}
        </form>
      </OverlayModal>
    );
  }
}
