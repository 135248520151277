import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DeveloperDashboardPage from '@components/developer/DeveloperDashboardPage';

import * as navigationActions from '@actions/navigationActions';

class DeveloperDashboardContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <DeveloperDashboardPage {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(DeveloperDashboardContainer));
