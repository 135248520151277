import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateMessageTemplateContainer from '@containers/messageTemplate/crud/CreateMessageTemplateContainer';

import * as messageTemplateActions from '@actions/messageTemplateActions';
import * as navigationActions from '@actions/navigationActions';
import { search as searchMessageTemplates } from '@api/messageTemplateApi';

let searchTimeout = null;

export const transformMessageTemplateToValue = (messageTemplate) => ({
  value: messageTemplate.id,
  label: `${messageTemplate.name}`,
  id: messageTemplate.id,
  messageTemplate: messageTemplate,
});

class MessageTemplateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageTemplate: this.props.messageTemplate
        ? transformMessageTemplateToValue(this.props.messageTemplate)
        : null,
    };
  }

  static defaultProps = {
    isDisabled: false,
  };

  onChange = (newMessageTemplate) => {
    this.setState({
      messageTemplate: newMessageTemplate,
    });
    const { onChange } = this.props;
    onChange &&
      onChange(newMessageTemplate ? newMessageTemplate.messageTemplate : null);
  };

  render() {
    const {
      t,
      isFetchingMessageTemplates,
      addToStack,
      isDisabled,
      language,
    } = this.props;
    const { messageTemplate } = this.state;

    let searchParams = {};
    if (language) {
      searchParams = { ...searchParams, language: language.id };
    }
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.messageTemplate')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingMessageTemplates}
          isDisabled={isDisabled || isFetchingMessageTemplates}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchMessageTemplates(
                { ...searchParams, query: inputValue },
                0,
                40
              ).then((response) => {
                callback(
                  response.messageTemplates.map((messageTemplate) =>
                    transformMessageTemplateToValue(messageTemplate)
                  )
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('messageTemplate.new'),
              component: (
                <CreateMessageTemplateContainer
                  targetName={inputValue}
                  callback={(newMessageTemplate) => {
                    this.onChange(
                      transformMessageTemplateToValue(newMessageTemplate)
                    );
                  }}
                />
              ),
            });
          }}
          value={messageTemplate}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.messageTemplate,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(messageTemplateActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(MessageTemplateInput));
