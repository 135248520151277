export const chauffeurConstants = {
  CHAUFFEUR_CHANGE_VALUE: 'CHAUFFEUR_CHANGE_VALUE',

  GET_CHAUFFEUR_STARTED: 'GET_CHAUFFEUR_STARTED',
  GET_CHAUFFEUR_SUCCESS: 'GET_CHAUFFEUR_SUCCESS',
  GET_CHAUFFEUR_FAILURE: 'GET_CHAUFFEUR_FAILURE',

  GET_CHAUFFEURS_STARTED: 'GET_CHAUFFEURS_STARTED',
  GET_CHAUFFEURS_SUCCESS: 'GET_CHAUFFEURS_SUCCESS',
  GET_CHAUFFEURS_FAILURE: 'GET_CHAUFFEURS_FAILURE',

  GET_CHAUFFEUR_TRIPS_SUCCESS: 'GET_CHAUFFEUR_TRIPS_SUCCESS',

  GET_CHAUFFEUR_TIMESLOTS_SUCCESS: 'GET_CHAUFFEUR_TIMESLOTS_SUCCESS',

  GET_EXTENDED_CHAUFFEURS_STARTED: 'GET_EXTENDED_CHAUFFEURS_STARTED',
  GET_EXTENDED_CHAUFFEURS_SUCCESS: 'GET_EXTENDED_CHAUFFEURS_SUCCESS',
  GET_EXTENDED_CHAUFFEURS_FAILURE: 'GET_EXTENDED_CHAUFFEURS_FAILURE',

  GET_EXTENDED_CHAUFFEURS_TRIPS_STARTED: 'GET_EXTENDED_CHAUFFEURS_TRIPS_STARTED',
  GET_EXTENDED_CHAUFFEURS_TRIPS_SUCCESS: 'GET_EXTENDED_CHAUFFEURS_TRIPS_SUCCESS',
  GET_EXTENDED_CHAUFFEURS_TRIPS_FAILURE: 'GET_EXTENDED_CHAUFFEURS_TRIPS_FAILURE',


  GET_EXTENDED_CHAUFFEURS_TIMESLOTS_STARTED: 'GET_EXTENDED_CHAUFFEURS_TIMESLOTS_STARTED',
  GET_EXTENDED_CHAUFFEURS_TIMESLOTS_SUCCESS: 'GET_EXTENDED_CHAUFFEURS_TIMESLOTS_SUCCESS',
  GET_EXTENDED_CHAUFFEURS_TIMESLOTS_FAILURE: 'GET_EXTENDED_CHAUFFEURS_TIMESLOTS_FAILURE',

  SEARCH_CHAUFFEURS_STARTED: 'SEARCH_CHAUFFEURS_STARTED',
  SEARCH_CHAUFFEURS_SUCCESS: 'SEARCH_CHAUFFEURS_SUCCESS',
  SEARCH_CHAUFFEURS_FAILURE: 'SEARCH_CHAUFFEURS_FAILURE',

  CREATING_CHAUFFEUR_STARTED: 'CREATING_CHAUFFEUR_STARTED',
  CREATING_CHAUFFEUR_SUCCESS: 'CREATING_CHAUFFEUR_SUCCESS',
  CREATING_CHAUFFEUR_FAILURE: 'CREATING_CHAUFFEUR_FAILURE',

  UPDATING_CHAUFFEUR_STARTED: 'UPDATING_CHAUFFEUR_STARTED',
  UPDATING_CHAUFFEUR_SUCCESS: 'UPDATING_CHAUFFEUR_SUCCESS',
  UPDATING_CHAUFFEUR_FAILURE: 'UPDATING_CHAUFFEUR_FAILURE',

  DELETE_CHAUFFEUR_STARTED: 'DELETE_CHAUFFEUR_STARTED',
  DELETE_CHAUFFEUR_SUCCESS: 'DELETE_CHAUFFEUR_SUCCESS',
  DELETE_CHAUFFEUR_FAILURE: 'DELETE_CHAUFFEUR_FAILURE',

  GET_CHAUFFEUR_ACTIVITIES_STARTED: 'GET_CHAUFFEUR_ACTIVITIES_STARTED',
  GET_CHAUFFEUR_ACTIVITIES_SUCCESS: 'GET_CHAUFFEUR_ACTIVITIES_SUCCESS',
  GET_CHAUFFEUR_ACTIVITIES_FAILURE: 'GET_CHAUFFEUR_ACTIVITIES_FAILURE',
};
