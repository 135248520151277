import moment from 'moment';

import { store } from '@/index';

export default class Trip {
  id;
  uuid;

  name;
  documents;
  actions;

  startDate;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);
    this.documents = [];
    this.actions = [];

    this.status = 'accepted';
    this.planStatus = 'ok';
    this.startDate = moment();

    this.linkedTrip =
      store.getState().settings?.settings?.planning?.defaultLinkTrips || false;
  }
}
