import { samlConstants } from '@constants/saml.constants';

const initialState = {
  isFetchingSaml: false,
  formSaml: null,
  saml: null,

  isFetchingSamls: false,
  isFetchingSamlsFrom: 0,
  samlsTotalResults: 0,
  samlsLoaded: 0,
  samls: [],
};

export default function saml(state = initialState, action) {
  switch (action.type) {
    case samlConstants.SAML_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case samlConstants.GET_SAML_STARTED:
      return { ...state, isFetchingSaml: true };
    case samlConstants.GET_SAML_FAILURE:
      return { ...state, isFetchingSaml: false, saml: null, error: action.message || '' };
    case samlConstants.GET_SAML_SUCCESS:
      return {
        ...state,
        isFetchingSaml: false,
        saml: action.saml,
      };

    case samlConstants.GET_SAMLS_STARTED:
      return { ...state, isFetchingSamls: true, isFetchingSamlsFrom: action.from || 0 };
    case samlConstants.GET_SAMLS_FAILURE:
      return { ...state, isFetchingSamls: false, samls: [], error: action.message || '' };
    case samlConstants.GET_SAMLS_SUCCESS:
      return {
        ...state,
        isFetchingSamls: false,
        samls: action.from > 0 ? [...state.samls, ...action.samls] : action.samls,
        samlsLoaded:
          action.from > 0 ? state.samls.length + action.samls.length : action.samls.length,
        samlsTotalResults: action.totalResults || 0,
      };

    case samlConstants.SEARCH_SAMLS_STARTED:
      return { ...state, isFetchingSamls: true, isFetchingSamlsFrom: action.from || 0 };
    case samlConstants.SEARCH_SAMLS_FAILURE:
      return { ...state, isFetchingSamls: false, samls: [], error: action.message || '' };
    case samlConstants.SEARCH_SAMLS_SUCCESS:
      return {
        ...state,
        isFetchingSamls: false,
        samls: action.from > 0 ? [...state.samls, ...action.samls] : action.samls,
        samlsLoaded:
          action.from > 0 ? state.samls.length + action.samls.length : action.samls.length,
        samlsTotalResults: action.totalResults || 0,
      };

    case samlConstants.CREATING_SAML_STARTED:
      return { ...state, isFetchingSaml: true };
    case samlConstants.CREATING_SAML_FAILURE:
      return { ...state, isFetchingSaml: false, saml: null, error: action.message || '' };
    case samlConstants.CREATING_SAML_SUCCESS:
      return {
        ...state,
        isFetchingSaml: false,
        saml: action.saml,
        samls: [action.saml, ...state.samls],
      };

    case samlConstants.UPDATING_SAML_STARTED:
      return { ...state, isFetchingSaml: true };
    case samlConstants.UPDATING_SAML_FAILURE:
      return { ...state, isFetchingSaml: false, saml: null, error: action.message || '' };
    case samlConstants.UPDATING_SAML_SUCCESS:
      return {
        ...state,
        isFetchingSaml: false,
        saml: action.saml,
        samls: [...state.samls].map((saml) => {
          if (action.saml.id === saml.id) {
            return action.saml;
          }
          return saml;
        }),
      };

    case samlConstants.DELETE_SAML_STARTED:
      return { ...state, isFetchingSaml: true };
    case samlConstants.DELETE_SAML_FAILURE:
      return { ...state, isFetchingSaml: false, saml: null, message: action.message || '' };
    case samlConstants.DELETE_SAML_SUCCESS:
      return {
        ...state,
        isFetchingSaml: false,
        saml: action.saml,
        samls: [...state.samls].filter((saml) => action.saml.id !== saml.id),
      };
    default:
      return state;
  }
}
