import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class EntityTypeSelector extends Component {
  
  render() {
    const { t, onChange, placeholder, value, enabledOptions } = this.props;
    const options = [
      { value: 'transportOrder', label: t('entityType.transportOrder') },
      { value: 'business', label: t('entityType.business') },
      { value: 'vehicle', label: t('entityType.vehicle') },
      { value: 'location', label: t('entityType.location') },
      { value: 'chauffeur', label: t('entityType.chauffeur') },
      { value: 'trip', label: t('entityType.trip') },
      { value: 'consignment', label: t('entityType.consignment') },
      {
        value: 'transportEquipment',
        label: t('entityType.transportEquipment'),
      },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(EntityTypeSelector);
