import { renderRoutes } from '@routes';
import { lazy } from 'react';

const IntegrationsContainer = lazy(() =>
  import('@containers/integration/IntegrationsContainer')
);
const IntegrationEnableOpenIdContainer = lazy(() =>
  import('@containers/integration/IntegrationEnableOpenIdContainer')
);

const routes = [
  {
    paths: ['/openId'],
    component: IntegrationEnableOpenIdContainer,
    type: 'portal',
  },
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: IntegrationsContainer,
    type: 'portal',
  },
];

const IntegrationRoutes = (props) => renderRoutes(props, routes);
export default IntegrationRoutes;
