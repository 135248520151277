import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/capacityContract/${id}`);
};

export const create = (capacityContract) => {
  return api.post(`/capacityContract`, capacityContract);
};

export const update = (capacityContract) => {
  return api.put(`/capacityContract`, capacityContract);
};

export const remove = (capacityContract) => {
  return api.delete(`/capacityContract/${capacityContract.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/capacity/contracts/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/capacity/contracts/${offset}/${amount}`, searchParameters);
};
