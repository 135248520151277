export const vehicleConstants = {
  VEHICLE_CHANGE_VALUE: 'VEHICLE_CHANGE_VALUE',

  GET_VEHICLE_STARTED: 'GET_VEHICLE_STARTED',
  GET_VEHICLE_SUCCESS: 'GET_VEHICLE_SUCCESS',
  GET_VEHICLE_FAILURE: 'GET_VEHICLE_FAILURE',

  GET_EXTENDED_VEHICLE_STARTED: 'GET_EXTENDED_VEHICLE_STARTED',
  GET_EXTENDED_VEHICLE_SUCCESS: 'GET_EXTENDED_VEHICLE_SUCCESS',
  GET_EXTENDED_VEHICLE_FAILURE: 'GET_EXTENDED_VEHICLE_FAILURE',

  GET_VEHICLES_STARTED: 'GET_VEHICLES_STARTED',
  GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
  GET_VEHICLES_FAILURE: 'GET_VEHICLES_FAILURE',

  GET_EXTENDED_VEHICLES_STARTED: 'GET_EXTENDED_VEHICLES_STARTED',
  GET_EXTENDED_VEHICLES_SUCCESS: 'GET_EXTENDED_VEHICLES_SUCCESS',
  GET_EXTENDED_VEHICLES_FAILURE: 'GET_EXTENDED_VEHICLES_FAILURE',

  GET_EXTENDED_VEHICLES_TRIPS_STARTED: 'GET_EXTENDED_VEHICLES_TRIPS_STARTED',
  GET_EXTENDED_VEHICLES_TRIPS_SUCCESS: 'GET_EXTENDED_VEHICLES_TRIPS_SUCCESS',
  GET_EXTENDED_VEHICLES_TRIPS_FAILURE: 'GET_EXTENDED_VEHICLES_TRIPS_FAILURE',

  GET_VEHICLES_NEXT_TRIPS_STARTED: 'GET_VEHICLES_NEXT_TRIPS_STARTED',
  GET_VEHICLES_NEXT_TRIPS_SUCCESS: 'GET_VEHICLES_NEXT_TRIPS_SUCCESS',
  GET_VEHICLES_NEXT_TRIPS_FAILURE: 'GET_VEHICLES_NEXT_TRIPS_FAILURE',

  SEARCH_VEHICLES_STARTED: 'SEARCH_VEHICLES_STARTED',
  SEARCH_VEHICLES_SUCCESS: 'SEARCH_VEHICLES_SUCCESS',
  SEARCH_VEHICLES_FAILURE: 'SEARCH_VEHICLES_FAILURE',

  SEARCH_EXTENDED_VEHICLES_STARTED: 'SEARCH_VEHICLES_STARTED',
  SEARCH_EXTENDED_VEHICLES_SUCCESS: 'SEARCH_VEHICLES_SUCCESS',
  SEARCH_EXTENDED_VEHICLES_FAILURE: 'SEARCH_VEHICLES_FAILURE',

  GET_VEHICLES_LOCATION_STARTED: 'GET_VEHICLES_LOCATION_STARTED',
  GET_VEHICLES_LOCATION_SUCCESS: 'GET_VEHICLES_LOCATION_SUCCESS',
  GET_VEHICLES_LOCATION_FAILURE: 'GET_VEHICLES_LOCATION_FAILURE',

  GET_VEHICLE_ACTIVITIES_STARTED: 'GET_VEHICLE_ACTIVITIES_STARTED',
  GET_VEHICLE_ACTIVITIES_SUCCESS: 'GET_VEHICLE_ACTIVITIES_SUCCESS',
  GET_VEHICLE_ACTIVITIES_FAILURE: 'GET_VEHICLE_ACTIVITIES_FAILURE',

  GET_VEHICLE_CHAUFFEURS_STARTED: 'GET_VEHICLE_CHAUFFEURS_STARTED',
  GET_VEHICLE_CHAUFFEURS_SUCCESS: 'GET_VEHICLE_CHAUFFEURS_SUCCESS',
  GET_VEHICLE_CHAUFFEURS_FAILURE: 'GET_VEHICLE_CHAUFFEURS_FAILURE',

  VEHICLE_ATTACH_CHAUFFEUR_STARTED: 'VEHICLE_ATTACH_CHAUFFEUR_STARTED',
  VEHICLE_ATTACH_CHAUFFEUR_SUCCESS: 'VEHICLE_ATTACH_CHAUFFEUR_SUCCESS',
  VEHICLE_ATTACH_CHAUFFEUR_FAILURE: 'VEHICLE_ATTACH_CHAUFFEUR_FAILURE',

  VEHICLE_DETACH_CHAUFFEUR_STARTED: 'VEHICLE_DETACH_CHAUFFEUR_STARTED',
  VEHICLE_DETACH_CHAUFFEUR_SUCCESS: 'VEHICLE_DETACH_CHAUFFEUR_SUCCESS',
  VEHICLE_DETACH_CHAUFFEUR_FAILURE: 'VEHICLE_DETACH_CHAUFFEUR_FAILURE',

  GET_VEHICLE_TRANSPORT_EQUIPMENTS_STARTED: 'GET_VEHICLE_TRANSPORT_EQUIPMENTS_STARTED',
  GET_VEHICLE_TRANSPORT_EQUIPMENTS_SUCCESS: 'GET_VEHICLE_TRANSPORT_EQUIPMENTS_SUCCESS',
  GET_VEHICLE_TRANSPORT_EQUIPMENTS_FAILURE: 'GET_VEHICLE_TRANSPORT_EQUIPMENTS_FAILURE',

  VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_STARTED: 'VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_STARTED',
  VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_SUCCESS: 'VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_SUCCESS',
  VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_FAILURE: 'VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_FAILURE',

  VEHICLE_DETACH_TRANSPORT_EQUIPMENT_STARTED: 'VEHICLE_DETACH_TRANSPORT_EQUIPMENT_STARTED',
  VEHICLE_DETACH_TRANSPORT_EQUIPMENT_SUCCESS: 'VEHICLE_DETACH_TRANSPORT_EQUIPMENT_SUCCESS',
  VEHICLE_DETACH_TRANSPORT_EQUIPMENT_FAILURE: 'VEHICLE_DETACH_TRANSPORT_EQUIPMENT_FAILURE',

  GET_VEHICLE_EVENTS_STARTED: 'GET_VEHICLE_EVENTS_STARTED',
  GET_VEHICLE_EVENTS_SUCCESS: 'GET_VEHICLE_EVENTS_SUCCESS',
  GET_VEHICLE_EVENTS_FAILURE: 'GET_VEHICLE_EVENTS_FAILURE',

  GET_VEHICLE_LOCATION_UPDATE_EVENTS_STARTED: 'GET_VEHICLE_LOCATION_UPDATE_EVENTS_STARTED',
  GET_VEHICLE_LOCATION_UPDATE_EVENTS_SUCCESS: 'GET_VEHICLE_LOCATION_UPDATE_EVENTS_SUCCESS',
  GET_VEHICLE_LOCATION_UPDATE_EVENTS_FAILURE: 'GET_VEHICLE_LOCATION_UPDATE_EVENTS_FAILURE',

  GET_VEHICLE_ACTIONS_STARTED: 'GET_VEHICLE_ACTIONS_STARTED',
  GET_VEHICLE_ACTIONS_SUCCESS: 'GET_VEHICLE_ACTIONS_SUCCESS',
  GET_VEHICLE_ACTIONS_FAILURE: 'GET_VEHICLE_ACTIONS_FAILURE',

  GET_VEHICLE_TRIPS_STARTED: 'GET_VEHICLE_TRIPS_STARTED',
  GET_VEHICLE_TRIPS_SUCCESS: 'GET_VEHICLE_TRIPS_SUCCESS',
  GET_VEHICLE_TRIPS_FAILURE: 'GET_VEHICLE_TRIPS_FAILURE',

  GET_VEHICLES_TRIPS_STARTED: 'GET_VEHICLES_TRIPS_STARTED',
  GET_VEHICLES_TRIPS_SUCCESS: 'GET_VEHICLES_TRIPS_SUCCESS',
  GET_VEHICLES_TRIPS_FAILURE: 'GET_VEHICLES_TRIPS_FAILURE',

  CREATING_VEHICLE_STARTED: 'CREATING_VEHICLE_STARTED',
  CREATING_VEHICLE_SUCCESS: 'CREATING_VEHICLE_SUCCESS',
  CREATING_VEHICLE_FAILURE: 'CREATING_VEHICLE_FAILURE',

  UPDATING_VEHICLE_STARTED: 'UPDATING_VEHICLE_STARTED',
  UPDATING_VEHICLE_SUCCESS: 'UPDATING_VEHICLE_SUCCESS',
  UPDATING_VEHICLE_FAILURE: 'UPDATING_VEHICLE_FAILURE',

  UPDATING_VEHICLE_TRIPS_ORDER_STARTED: 'UPDATING_VEHICLE_TRIPS_ORDER_STARTED',
  UPDATING_VEHICLE_TRIPS_ORDER_SUCCESS: 'UPDATING_VEHICLE_TRIPS_ORDER_SUCCESS',
  UPDATING_VEHICLE_TRIPS_ORDER_FAILURE: 'UPDATING_VEHICLE_TRIPS_ORDER_FAILURE',

  DELETE_VEHICLE_STARTED: 'DELETE_VEHICLE_STARTED',
  DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
  DELETE_VEHICLE_FAILURE: 'DELETE_VEHICLE_FAILURE',
};
