import React, { Component } from 'react';

import ReactSVG from 'react-svg';

import { hasParentNode } from '@utils/commonUtils';

export default class OptionsMenu extends Component {
  state = {
    optionsMenuOpen: false,
  };
  closeOptionsMenusOnClick = (e) => {
    const target = e.target;
    const { optionsMenuOpen } = this.state;
    if (optionsMenuOpen) {
      if (!hasParentNode(target, 'options-menu')) {
        this.setState({
          optionsMenuOpen: false,
        });
      }
    }
  };

  componentDidMount() {
    document.body.addEventListener('click', this.closeOptionsMenusOnClick);
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.closeOptionsMenusOnClick);
  }

  render() {
    const { items, className } = this.props;
    const { optionsMenuOpen } = this.state;

    if (!items || items.length < 1) return null;

    return (
      <div className={`options-menu${optionsMenuOpen ? ' options-menu--open' : ''} ${className}`}>
        <ReactSVG
          src="/icons/three-dots.svg"
          className="options-menu__dots"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ optionsMenuOpen: !optionsMenuOpen });
          }}
        />
        <div className="options-menu__options">
          {items && items.length > 0
            ? items
                .filter((item) => item !== null)
                .map((item) => (
                  <div
                    className="options-menu__options__item"
                    onClick={(e) => {
                      this.setState({ optionsMenuOpen: !optionsMenuOpen });
                      item.onClick(e);
                    }}
                    key={`options-menu__option-${item.name}`}
                  >
                    {item.name}
                  </div>
                ))
            : null}
        </div>
      </div>
    );
  }
}
