import api from '../api';

export const createTodo = (laneId, todo) => {
  return api.post(`/board/lane/${laneId}/todo`, todo);
};

export const updateTodo = (todo) => {
  return api.put(`/board/lane/todo/${todo.id}`, todo);
};

export const updateTodoPosition = (lane, todo, position) => {
  return api.put(`/board/lane/${lane.id}/todo/${todo.id}/change-position/${position}`);
};

export const deleteTodo = (todo) => {
  return api.delete(`/board/lane/todo/${todo.id}`);
};

export const getTodo = (todo) => {
  return api.get(`/board/lane/todo/${todo.id}`);
};

export const getTodos = (offset, amount) => {
  return api.get(`/board/lane/todos/${offset}/${amount}`);
};

export const getUserTodos = (user, offset, amount) => {
  return api.get(`/todos/user/${user.id}/${offset}/${amount}`);
};

export const getEntityTodos = (entityType, entityId, offset, amount) => {
  return api.get(`/todos:byEntity/${entityType}/${entityId}/${offset}/${amount}`);
};

export const getTodosInDateRange = (fromDate, toDate) => {
  return api.get(`/todos:byDateRange/${fromDate}/${toDate}`);
};

export const getUserTodosInDateRange = (user, fromDate, toDate) => {
  return api.get(`/todos:byDateRange/user/${user.id}/${fromDate}/${toDate}`);
};

export const searchTodos = (searchParameters = {}, from, amount) => {
  return api.post(`/board/lane/todos/${from}/${amount}`, searchParameters);
};

export const getTodoStatistics = () => {
  return api.get(`/todo:statistics`);
};

export const getUserTodoStatistics = (user) => {
  return api.get(`/todo:statistics/user/${user.id}`);
};
