import { capacityContractConstants } from '@constants/capacityContract.constants';

const initialState = {
  isFetchingCapacityContract: false,
  formCapacityContract: null,
  capacityContract: null,

  isFetchingCapacityContracts: false,
  isFetchingCapacityContractsFrom: 0,
  capacityContractsTotalResults: 0,
  capacityContractsLoaded: 0,
  capacityContracts: [],
};

export default function capacityContract(state = initialState, action) {
  switch (action.type) {
    case capacityContractConstants.CAPACITYCONTRACT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case capacityContractConstants.GET_CAPACITYCONTRACT_STARTED:
      return { ...state, isFetchingCapacityContract: true };
    case capacityContractConstants.GET_CAPACITYCONTRACT_FAILURE:
      return {
        ...state,
        isFetchingCapacityContract: false,
        error: action.message || '',
      };
    case capacityContractConstants.GET_CAPACITYCONTRACT_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContract: false,
        capacityContract: action.capacityContract,
      };

    case capacityContractConstants.GET_CAPACITYCONTRACTS_STARTED:
      return {
        ...state,
        isFetchingCapacityContracts: true,
        isFetchingCapacityContractsFrom: action.from || 0,
      };
    case capacityContractConstants.GET_CAPACITYCONTRACTS_FAILURE:
      return {
        ...state,
        isFetchingCapacityContracts: false,
        error: action.message || '',
      };
    case capacityContractConstants.GET_CAPACITYCONTRACTS_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContracts: false,
        capacityContracts:
          action.from > 0
            ? [...state.capacityContracts, ...action.capacityContracts]
            : action.capacityContracts,
        capacityContractsLoaded:
          action.from > 0
            ? state.capacityContracts.length + action.capacityContracts.length
            : action.capacityContracts.length,
        capacityContractsTotalResults: action.totalResults || 0,
      };

    case capacityContractConstants.SEARCH_CAPACITYCONTRACTS_STARTED:
      return {
        ...state,
        isFetchingCapacityContracts: true,
        isFetchingCapacityContractsFrom: action.from || 0,
      };
    case capacityContractConstants.SEARCH_CAPACITYCONTRACTS_FAILURE:
      return {
        ...state,
        isFetchingCapacityContracts: false,
        error: action.message || '',
      };
    case capacityContractConstants.SEARCH_CAPACITYCONTRACTS_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContracts: false,
        capacityContracts:
          action.from > 0
            ? [...state.capacityContracts, ...action.capacityContracts]
            : action.capacityContracts,
        capacityContractsLoaded:
          action.from > 0
            ? state.capacityContracts.length + action.capacityContracts.length
            : action.capacityContracts.length,
        capacityContractsTotalResults: action.totalResults || 0,
      };

    case capacityContractConstants.CREATING_CAPACITYCONTRACT_STARTED:
      return { ...state, isFetchingCapacityContract: true };
    case capacityContractConstants.CREATING_CAPACITYCONTRACT_FAILURE:
      return {
        ...state,
        isFetchingCapacityContract: false,
        error: action.message || '',
      };
    case capacityContractConstants.CREATING_CAPACITYCONTRACT_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContract: false,
        capacityContract: action.capacityContract,
        capacityContracts: [
          action.capacityContract,
          ...state.capacityContracts,
        ],
      };

    case capacityContractConstants.UPDATING_CAPACITYCONTRACT_STARTED:
      return { ...state, isFetchingCapacityContract: true };
    case capacityContractConstants.UPDATING_CAPACITYCONTRACT_FAILURE:
      return {
        ...state,
        isFetchingCapacityContract: false,
        error: action.message || '',
      };
    case capacityContractConstants.UPDATING_CAPACITYCONTRACT_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContract: false,
        capacityContract: action.capacityContract,
        capacityContracts: [...state.capacityContracts].map(
          (capacityContract) => {
            if (action.capacityContract.id === capacityContract.id) {
              return action.capacityContract;
            }
            return capacityContract;
          }
        ),
      };

    case capacityContractConstants.DELETE_CAPACITYCONTRACT_STARTED:
      return { ...state, isFetchingCapacityContract: true };
    case capacityContractConstants.DELETE_CAPACITYCONTRACT_FAILURE:
      return {
        ...state,
        isFetchingCapacityContract: false,
        message: action.message || '',
      };
    case capacityContractConstants.DELETE_CAPACITYCONTRACT_SUCCESS:
      return {
        ...state,
        isFetchingCapacityContract: false,
        capacityContract: action.capacityContract,
        capacityContracts: [...state.capacityContracts].filter(
          (capacityContract) =>
            action.capacityContract.id !== capacityContract.id
        ),
      };
    default:
      return state;
  }
}
