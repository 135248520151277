import { attributeConstants } from '@constants';

import * as attributeApi from '@api/attributeApi';

/**
 * Fetching Attribute
 */
export const startGetAttribute = () => ({
  type: attributeConstants.GET_ATTRIBUTE_STARTED,
});

export const getAttributeSuccess = (result) => ({
  type: attributeConstants.GET_ATTRIBUTE_SUCCESS,
  ...result,
});

export const getAttributeFailure = (error) => ({
  type: attributeConstants.GET_ATTRIBUTE_FAILURE,
  ...error,
});

export const getAttribute = (id) => {
  return (dispatch) => {
    dispatch(startGetAttribute());

    const promise = attributeApi.get(id);

    promise
      .then((result) => {
        dispatch(getAttributeSuccess(result));
      })
      .catch((error) => {
        dispatch(getAttributeFailure(error));
      });

    return promise;
  };
};

/**
 * Creating Attribute
 */
export const changeCreateAttribute = (name, value) => ({
  type: attributeConstants.CREATING_ATTRIBUTE_CHANGE_VALUE,
  name,
  value,
});
export const startCreateAttribute = () => ({
  type: attributeConstants.CREATING_ATTRIBUTE_STARTED,
});

export const createAttributeSuccess = (result) => ({
  type: attributeConstants.CREATING_ATTRIBUTE_SUCCESS,
  ...result,
});

export const createAttributeFailure = (error) => ({
  type: attributeConstants.CREATING_ATTRIBUTE_FAILURE,
  ...error,
});

export const createAttribute = (business) => {
  return (dispatch) => {
    dispatch(startCreateAttribute());

    const promise = attributeApi.create(business);

    promise
      .then((result) => {
        dispatch(createAttributeSuccess(result));
      })
      .catch((error) => {
        dispatch(createAttributeFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching Attributes
 */
export const startGetAttributes = (from) => ({
  type: attributeConstants.GET_ATTRIBUTES_STARTED,
  from,
});

export const getAttributesSuccess = (result, from) => ({
  type: attributeConstants.GET_ATTRIBUTES_SUCCESS,
  ...result,
  from,
});

export const getAttributesFailure = (error) => ({
  type: attributeConstants.GET_ATTRIBUTES_FAILURE,
  ...error,
});

export const getAttributes = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetAttributes(from));

    const promise = attributeApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getAttributesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getAttributesFailure(error));
      });

    return promise;
  };
};

export const searchAttributes = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetAttributes(from));

    const promise = attributeApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getAttributesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getAttributesFailure(error));
      });

    return promise;
  };
};
