import { navigationConstants, platformConstants } from '../constants';

const initialState = {
  components: [],
  componentsPreviousSize: 0,

  alerts: [],
  alertsPreviousSize: 0,

  popOvers: [],
  popOversPreviousSize: 0,

  planBoardType: 'trip',
  plannerType: 'vehicle',
};

export default function navigation(state = initialState, action) {
  switch (action.type) {
    case navigationConstants.ADD_TO_STACK:
      return {
        ...state,
        components: [...state.components, action.component],
        componentsPreviousSize: 0,
      };
    case navigationConstants.ANIMATE_LAST_OUT:
      return {
        ...state,
        components: [...state.components].map((component, index) =>
          index === state.components.length - 1 ? { ...component, animateOut: true } : component
        ),
      };
    case navigationConstants.UPDATE_STACK_ELEMENT:
      return {
        ...state,
        components: [...state.components].map((component) =>
          action.component.name === component.name ? action.component : component
        ),
      };
    case navigationConstants.UPDATE_LATEST_STACK_ELEMENT:
      [...state.components].map((component, index) => {
        return index === state.components.length - 1
          ? { ...component, ...action.arguments }
          : component;
      });
      return {
        ...state,
        components: [...state.components].map((component, index) =>
          index === state.components.length - 1 ? { ...component, ...action.arguments } : component
        ),
      };
    case navigationConstants.POP_STACK:
      const newComponents = [...state.components];
      newComponents.pop();
      return {
        ...state,
        componentsPreviousSize: state.components.length,
        components: newComponents,
      };
    case navigationConstants.CLEAR_STACK:
      return {
        ...state,
        componentsPreviousSize: state.components.length,
        components: [],
      };

    case navigationConstants.CREATE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts, action.alert],
        alertsPreviousSize: 0,
      };
    case navigationConstants.REMOVE_ALERT:
      return {
        ...state,
        alerts: [...state.alerts].filter((alert) => action.alert.id !== alert.id),
      };
    case navigationConstants.ANIMATE_ALERT_OUT:
      return {
        ...state,
        alerts: [...state.alerts].map((alert) =>
          action.alert.id === alert.id ? action.alert : alert
        ),
        alertsPreviousSize: state.alerts.length,
      };
    case navigationConstants.POP_ALERT:
      const newAlerts = [...state.alerts];
      newAlerts.pop();
      return {
        ...state,
        alerts: newAlerts,
        alertsPreviousSize: state.alerts.length,
      };

    case navigationConstants.ADD_POPOVER:
      return {
        ...state,
        popOvers: [...state.popOvers, action.popOver],
        popOversPreviousSize: 0,
      };
    case navigationConstants.REMOVE_POPOVER:
      return {
        ...state,
        popOvers: [...state.popOvers].filter((popOver) => action.popOver.id !== popOver.id),
      };
    case navigationConstants.POP_POPOVER:
      const newPopOvers = [...state.popOvers];
      newPopOvers.pop();
      return {
        ...state,
        popOversPreviousSize: state.popOvers.length,
        popOvers: newPopOvers,
      };

    case navigationConstants.SET_PLAN_BOARD_TYPE:
      return { ...state, planBoardType: action.planBoardType };

    case navigationConstants.SET_PLANNER_TYPE:
      return { ...state, plannerType: action.plannerType };

    case platformConstants.GET_PLATFORM_SUCCESS:
      return {
        ...state,
        planBoardType: action.platform?.features
          ?.map((feature) => feature.name)
          ?.includes('vehiclePlanBoard')
          ? 'vehicle'
          : 'trip',
        plannerType: action.platform?.features
          ?.map((feature) => feature.name)
          ?.includes('vehicle-planner')
          ? 'vehicle'
          : 'chauffeur',
      };
    default:
      return state;
  }
}
