export const managementConstants = {
  GET_FINANCIAL_RECORDS_STARTED: 'GET_FINANCIAL_RECORDS_STARTED',
  GET_FINANCIAL_RECORDS_SUCCESS: 'GET_FINANCIAL_RECORDS_SUCCESS',
  GET_FINANCIAL_RECORDS_FAILURE: 'GET_FINANCIAL_RECORDS_FAILURE',

  GET_OPERATIONAL_RECORDS_STARTED: 'GET_OPERATIONAL_RECORDS_STARTED',
  GET_OPERATIONAL_RECORDS_SUCCESS: 'GET_OPERATIONAL_RECORDS_SUCCESS',
  GET_OPERATIONAL_RECORDS_FAILURE: 'GET_OPERATIONAL_RECORDS_FAILURE',

  GET_TRIPS_PER_DAY_STARTED: 'GET_TRIPS_PER_DAY_STARTED',
  GET_TRIPS_PER_DAY_FAILURE: 'GET_TRIPS_PER_DAY_FAILURE',
  GET_TRIPS_PER_DAY_SUCCESS: 'GET_TRIPS_PER_DAY_SUCCESS',
};
