import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import CloseButton from '@uicomponents/CloseButton';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';
import ContactPerson from '@models/general/ContactPerson';

class ContactPersonsInput extends Component {
  state = {
    contactPersons: this.props.contactPersons
      ? this.props.contactPersons
      : this.props.defaultEnabled
      ? [new Association('inline', new ContactPerson())]
      : [],
  };

  componentDidUpdate(prevProps) {
    if (prevProps.contactPersons !== this.props.contactPersons) {
      this.setState({
        contactPersons: this.props.contactPersons,
      });
    }
  }

  onChange = (contactPersons) => {
    const { onChange } = this.props;
    onChange?.(contactPersons);
  };

  addNewContactPerson = () => {
    const { contactPersons } = this.state;
    this.setState({
      contactPersons: [...contactPersons, new Association('inline', new ContactPerson())],
    });
  };

  updateContactPerson = (newContactPerson, index) => {
    const { contactPersons } = this.state;
    const newContactPersons = [...contactPersons];

    const newAssociation = { ...newContactPersons[index] };
    newAssociation.entity = newContactPerson;
    newContactPersons[index] = newAssociation;

    this.setState(
      {
        contactPersons: newContactPersons,
      },
      () => this.onChange(newContactPersons)
    );
  };

  render() {
    const { t } = this.props;
    const { contactPersons } = this.state;

    return (
      <>
        {[...contactPersons]
          .map((association) => association.entity)
          .map((contactPerson, index) => (
            <div
              className="list-sector"
              key={`input-contactPerson-${contactPerson.id}-${contactPerson.nonce}`}
            >
              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={() => {
                    const newContactPersons = [...contactPersons];
                    newContactPersons.splice(index, 1);

                    this.setState({
                      contactPersons: newContactPersons,
                    });
                    this.onChange(newContactPersons);
                  }}
                />
              </div>
              <div className="input-group no-margin-top">
                <div className="input-group half no-margin-top">
                  <FormInput
                    className="small"
                    type="text"
                    label="form.label.firstName"
                    value={contactPerson.firstName}
                    onChange={(event) => {
                      const newContactPerson = { ...contactPerson };
                      newContactPerson.firstName = event.target.value;

                      this.updateContactPerson(newContactPerson, index);
                    }}
                  />
                </div>
                <div className="input-group half no-margin-top">
                  <FormInput
                    className="small"
                    type="text"
                    label="form.label.lastName"
                    value={contactPerson.lastName}
                    onChange={(event) => {
                      const newContactPerson = { ...contactPerson };
                      newContactPerson.lastName = event.target.value;

                      this.updateContactPerson(newContactPerson, index);
                    }}
                  />
                </div>
              </div>
              <FormInput
                className="small"
                type="email"
                label="form.label.email"
                value={contactPerson.email}
                onChange={(event) => {
                  const newContactPerson = { ...contactPerson };
                  newContactPerson.email = event.target.value;

                  this.updateContactPerson(newContactPerson, index);
                }}
              />
              <div className="input-group no-margin-top">
                <div className="input-group half no-margin-top">
                  <FormInput
                    className="small"
                    type="text"
                    label="form.label.position"
                    value={contactPerson.position}
                    onChange={(event) => {
                      const newContactPerson = { ...contactPerson };
                      newContactPerson.position = event.target.value;

                      this.updateContactPerson(newContactPerson, index);
                    }}
                  />
                </div>
                <div className="input-group half no-margin-top">
                  <FormInput
                    className="small"
                    type="number"
                    label="form.label.phoneNumber"
                    value={contactPerson.phone}
                    onChange={(event) => {
                      const newContactPerson = { ...contactPerson };
                      newContactPerson.phone = event.target.value;

                      this.updateContactPerson(newContactPerson, index);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        <div className={`input-group left${contactPersons.length > 0 ? '' : ' no-margin-top'}`}>
          <button type="button" onClick={() => this.addNewContactPerson()}>
            {t('form.label.addContactDetail')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(ContactPersonsInput);
