import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class BoundsTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = [
      { label: t('form.label.minimum'), value: 'minimum' },
      { label: t('form.label.maximum'), value: 'maximum' },
      { label: t('form.label.range'), value: 'range' },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(BoundsTypeSelector);
