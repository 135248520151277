import { filterConstants } from '../constants/filter.constants';

const initialState = {
  entityTypes: [],

  globalFilters: JSON.parse(localStorage.getItem('globalFilters')) || {},
  isUpdatingFilters: false,
  contextFilters: [],
};

export default function filter(state = initialState, action) {
  switch (action.type) {
    case filterConstants.SET_ENTITY_TYPES:
      return {
        ...state,
        entityTypes: [...action.entityTypes],
      };
    case filterConstants.ADD_ENTITY_TYPE:
      return {
        ...state,
        entityTypes: [...state.entityTypes, action.entityType],
      };
    case filterConstants.REPLACE_ENTITY_TYPE:
      const newEntityTypes = [...state.entityTypes];
      newEntityTypes[state.entityTypes.indexOf(action.oldEntityType)] = action.newEntityType;
      return {
        ...state,
        entityTypes: newEntityTypes,
        // following code replaces all instances of oldEntity
        // entityTypes: [...state.entityTypes].map((entityType) =>
        //   action.oldEntityType === entityType ? action.newEntityType : entityType
        // ),
      };
    case filterConstants.REMOVE_ENTITY_TYPE:
      return {
        ...state,
        entityTypes: [...state.entityTypes].filter(
          (entityType) => action.entityType !== entityType
        ),
      };

    case filterConstants.SET_FILTERS_STARTED:
      return {
        ...state,
        isUpdatingFilters: true,
      };

    case filterConstants.SET_FILTERS:
      localStorage && localStorage.setItem('globalFilters', JSON.stringify(action.filters));
      return {
        ...state,
        globalFilters: action.filters,
        isUpdatingFilters: false,
      };

    default:
      return state;
  }
}
