import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateVehiclePage from '@components/vehicle/crud/CreateVehiclePage.js';

import * as fileApi from '@api/fileApi';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class CreateVehicleContainer extends PureComponent {
  render() {
    const { changeValue, createVehicle, ...props } = this.props;
    const { popStack} = this.props;
    return (
      <CreateVehiclePage
        {...props}
        onChange={changeValue}
        createVehicle={(vehicle) =>
          createVehicle(vehicle).then((response) => {
            popStack();
          })
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,

    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.vehicle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createVehicle: (vehicle) => dispatch(vehicleActions.createVehicle(vehicle)),
    changeValue: (name, value) =>
      dispatch(vehicleActions.changeValue(name, value)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateVehicleContainer));
