import { documentConstants } from '../constants/document.constants';

const initialState = {
  isFetchingDocument: false,
  formDocument: null,
  document: null,

  isFetchingDocuments: false,
  isFetchingDocumentsFrom: 0,
  documentsTotalResults: 0,
  documentsLoaded: 0,
  documents: [],
};

export default function document(state = initialState, document) {
  switch (document.type) {
    case documentConstants.DOCUMENT_CHANGE_VALUE:
      return { ...state, error: '', [document.name]: document.value };

    case documentConstants.GET_DOCUMENT_STARTED:
      return { ...state, isFetchingDocument: true };
    case documentConstants.GET_DOCUMENT_FAILURE:
      return {
        ...state,
        isFetchingDocument: false,
        document: null,
        error: document.message || '',
      };
    case documentConstants.GET_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetchingDocument: false,
        document: document.document,
      };

    case documentConstants.GET_DOCUMENTS_STARTED:
      return { ...state, isFetchingDocuments: true };
    case documentConstants.GET_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFetchingDocuments: false,
        documents: [],
        error: document.message || '',
      };
    case documentConstants.GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isFetchingDocuments: false,
        documents:
          document.from > 0 ? [...state.documents, ...document.documents] : document.documents,
        documentsLoaded:
          document.from > 0
            ? state.documents.length + document.documents.length
            : document.documents.length,
        documentsTotalResults: document.totalResults || 0,
      };

    case documentConstants.SEARCH_DOCUMENTS_STARTED:
      return { ...state, isFetchingDocuments: true };
    case documentConstants.SEARCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        isFetchingDocuments: false,
        documents: [],
        error: document.message || '',
      };
    case documentConstants.SEARCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isFetchingDocuments: false,
        documents:
          document.from > 0 ? [...state.documents, ...document.documents] : document.documents,
        documentsLoaded:
          document.from > 0
            ? state.documents.length + document.documents.length
            : document.documents.length,
        documentsTotalResults: document.totalResults || 0,
      };

    case documentConstants.CREATING_DOCUMENT_STARTED:
      return { ...state, isFetchingDocument: true };
    case documentConstants.CREATING_DOCUMENT_FAILURE:
      return { ...state, isFetchingDocument: false, error: document.message || '' };
    case documentConstants.CREATING_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetchingDocument: false,
        document: document.document,
        documents: [document.document, ...state.documents],
      };

    case documentConstants.UPDATING_DOCUMENT_STARTED:
      return { ...state, isFetchingDocument: true };
    case documentConstants.UPDATING_DOCUMENT_FAILURE:
      return {
        ...state,
        document: null,
        isFetchingDocument: false,
        error: document.message || '',
      };
    case documentConstants.UPDATING_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetchingDocument: false,
        document: document.document,
        documents: [...state.documents].map((document) => {
          if (document.document.id === document.id) {
            return document.document;
          }
          return document;
        }),
      };

    case documentConstants.DELETE_DOCUMENT_STARTED:
      return { ...state, isFetchingDocument: true };
    case documentConstants.DELETE_DOCUMENT_FAILURE:
      return {
        ...state,
        document: null,
        isFetchingDocument: false,
        message: document.message || '',
      };
    case documentConstants.DELETE_DOCUMENT_SUCCESS:
      return {
        ...state,
        isFetchingDocument: false,
        document: document.document,
        documents: [...state.documents].filter((document) => document.document.id !== document.id),
      };
    default:
      return state;
  }
}
