import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Tender from '@models/tender/Tender';

import TenderForm from './TenderForm';

export default class CreateTenderPage extends Component {
  componentDidMount() {
    const { onChange, targetTender, transportOrder } = this.props;

    const tender = targetTender ? targetTender : new Tender();
    if (transportOrder) {
      tender.transportOrder = transportOrder;
    }

    onChange('formTender', tender);
  }

  render() {
    const { t, formTender, isFetchingTender, createTender } = this.props;

    if (!formTender || isFetchingTender) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('tender.new')}</h1>
        <TenderForm
          {...this.props}
          key={formTender.id}
          onSubmit={(tender) => createTender(tender)}
        />
      </AsideView>
    );
  }
}
