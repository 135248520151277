import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/chat/${id}`);
};
export const create = (chat) => {
  return api.post(`/chat`, chat);
};
export const update = (chat) => {
  return api.put(`/chat`, chat);
};
export const remove = (chat) => {
  return api.delete(`/chat/${chat.id}`);
};

// Getting and searching
export const getChats = (from = 0, amount = 20) => {
  return api.get(`/chats/${from}/${amount}`);
};

export const searchChats = (searchParameters, from = 0, amount = 20) => {
  return api.post(`/chats/${from}/${amount}`, searchParameters);
};

// chat functions
export const unreadChat = (chat) => {
  return api.get(`/chat/${chat}/unread`);
};

// Messages
export const createMessage = (chat, message) => {
  return api.post(`/chat/message`, { chat, ...message });
};
export const getLatest = (id) => {
  return api.get(`/chats/${id}/latest`);
};

export const getMessages = (id, from = 0, amount = 20) => {
  return api.get(`/chat/messages/${id}/${from}/${amount}`);
};

export const deleteMessage = (id) => {
  return api.delete(`/chat/message/${id}`);
};

export const receiveMessage = (id) => {
  return api.get(`/chat/messages/${id}/receive`);
};

export const readMessage = (id) => {
  return api.get(`/chat/message/${id}/read`);
};

export const readMessages = (messages) => {
  return api.post(`/chat/messages/read`, { messages: messages });
};

export const translateMessage = (messageId) => {
  return api.get(`/chat/message:translate/${messageId}`);
};
