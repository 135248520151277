import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Tag from '@uicomponents/Tag';

import { stringToColor } from '@utils/colorUtils';
import { hoursOfDay, navigateToActiveDate } from '@utils/dateUtils';

const dayInMilliseconds = 86400000;
const hourInMiliseoncds = dayInMilliseconds / 24;
let timer = null;

class ChauffeurActivitiesTimeline extends Component {
  static defaultProps = {
    chauffeur: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      now: moment(),
      currentDate: moment(),
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    navigateToActiveDate();

    this.fetchActivities();

    timer = setInterval(() => {
      this.setState({
        now: moment(),
      });
    }, 60000);
  }

  fetchActivities() {
    const { chauffeur, getChauffeurActivities } = this.props;
    const { currentDate } = this.state;

    getChauffeurActivities(
      chauffeur,
      currentDate.clone().startOf('day'),
      currentDate.clone().endOf('day')
    );
  }

  componentWillUnmount() {
    if (timer) clearInterval(timer);
  }

  activityCard = (activity, width, maxWidth, offset, isSpacer) => {
    if (isSpacer)
      return (
        <div
          id={activity.id}
          key={activity.id}
          className="activity-card spacer"
        ></div>
      );

    return (
      <div
        id={activity.id}
        key={activity.id}
        className={`activity-card ${activity.type}`}
        style={{
          width: `${Math.abs(parseInt(width)) + 'px'}`,
          maxWidth: maxWidth,
          marginLeft: offset,
        }}
      ></div>
    );
  };

  render() {
    const { t, chauffeur, activities, dateFormat } = this.props;
    const { currentDate } = this.state;

    return (
      <main className="calendar static-left">
        <section className="calendar__header">
          <h2>{t('chauffeur.activities')}</h2>
          <div className="header__current-date center">
            <big className="text-primary">
              <b>{currentDate.format('dddd')} </b>
            </big>
            <big className="text-primary font-bold">
              {currentDate.format('D')}
            </big>
            <br />
            <b className="text-darkgray">{currentDate.format('MMMM')} </b>
            <b className="text-darkgray">{currentDate.format('YYYY')}</b>
          </div>
          <div className="header__date-switcher cursor-pointer">
            <div
              className="date-switcher__btn-left"
              onClick={() =>
                this.setState(
                  (prevState) => ({
                    currentDate: prevState.currentDate.subtract(1, 'days'),
                  }),
                  () => {
                    this.fetchActivities();
                  }
                )
              }
            >
              <ReactSVG src="/icons/arrow-down.svg" />
            </div>
            <div
              className="date-switcher__btn-center"
              onClick={() => {
                this.setState(
                  () => ({ currentDate: moment() }),
                  () => {
                    navigateToActiveDate();
                    this.fetchActivities();
                  }
                );
              }}
            >
              {t('today')}
            </div>
            <div
              className="date-switcher__btn-right"
              onClick={() =>
                this.setState(
                  (prevState) => ({
                    currentDate: prevState.currentDate.add(1, 'days'),
                  }),
                  () => {
                    this.fetchActivities();
                  }
                )
              }
            >
              <ReactSVG src="/icons/arrow-down.svg" />
            </div>
          </div>
        </section>
        <section className="calendar__content">
          <div className="calendar__navigate-right"></div>

          <div className="calendar__header-lane max-content">
            <div className="header-lane__content-box">
              {t('chauffeur.activities')}
            </div>

            {hoursOfDay(currentDate.format('DD/MM/YYYY[T]')).map(
              (hour, index) => (
                <div
                  className={`header-lane__content-box header-lane__information-box  ${
                    moment(hour).format('DD/MM/YYYY[T]HH') ===
                    this.state.now.format('DD/MM/YYYY[T]HH')
                      ? 'active'
                      : ''
                  } `}
                  style={{ width: '120px' }}
                  key={index}
                >
                  {moment(hour).format('DD/MM/YYYY[T]HH') ===
                  this.state.now.format('DD/MM/YYYY[T]HH') ? (
                    <div
                      style={{ left: `${Math.round(moment().minute() * 2)}px` }}
                      className="time_indicator"
                    ></div>
                  ) : null}
                  {hour.format('HH') + ':00'}
                </div>
              )
            )}
          </div>

          {['driving', 'work', 'available', 'rest'].map((activityType) => (
            <div className="calendar__body-lane max-content" key={chauffeur.id}>
              <div
                className={`body-lane__content-box body-lane__content-box--small vehicle`}
              >
                <span className="text-uppercase">{activityType} </span>
              </div>

              {hoursOfDay(currentDate.format('DD/MM/YYYY[T]')).map(
                (hour, index) => (
                  <div
                    className={`body-lane__content-box body-lane__content-box--small`}
                    key={index}
                    style={{ width: '120px' }}
                  >
                    {moment(hour).clone().format('DD/MM/YYYY[T]HH') ===
                    this.state.now.format('DD/MM/YYYY[T]HH') ? (
                      <div
                        style={{
                          left: `${Math.round(moment().minute() * 2)}px`,
                        }}
                        className="time_indicator"
                      ></div>
                    ) : null}

                    {activities?.length > 0
                      ? activities
                          .filter((activity) => {
                            return (
                              hour.clone().isSame(activity.start, 'hour') &&
                              activity.type === activityType
                            );
                          })
                          .sort((a, b) => moment(a.start).diff(moment(b.start)))

                          .map((activity) => {
                            const startDate = moment(activity.start).isBefore(
                              hour.clone().startOf('day')
                            )
                              ? hour.clone().startOf('day')
                              : moment(activity.start);
                            const endDate = activity.end
                              ? moment(activity.end)
                              : moment();

                            const offset = `${Math.round(
                              ((startDate - hour.clone().toDate()) /
                                hourInMiliseoncds) *
                                121
                            )}px`;
                            const fullDuration =
                              (endDate - startDate) / hourInMiliseoncds;
                            const width = `${Math.round(fullDuration * 121)}px`;
                            const maxWidth = `${Math.round(
                              ((hour.clone().endOf('day') - startDate) /
                                hourInMiliseoncds) *
                                121
                            )}px`;

                            return this.activityCard(
                              activity,
                              width,
                              maxWidth,
                              offset,
                              false
                            );
                          })
                      : null}
                  </div>
                )
              )}
            </div>
          ))}
        </section>
      </main>
    );
  }
}

export default withTranslation('translation')(ChauffeurActivitiesTimeline);
