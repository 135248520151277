import React, { Component } from 'react';

import ReactSVG from 'react-svg';

export default class Avatar extends Component {
  getInitials(name) {
    const parts = name.split(' ');
    if (parts.length > 1) {
      if (parts[0].length > 0 && parts[1].length > 0) {
        return `${parts[0][0]}${parts[1][0]}`;
      }
    }

    if (parts[0].length > 1) {
      return `${parts[0][0]}${parts[0][1]}`;
    }

    if (parts[0].length > 0) {
      return `${parts[0][0]}`;
    }
    return '';
  }

  render() {
    const { imageUrl, name } = this.props;
    return (
      <div className="avatar">
        {imageUrl ? (
          imageUrl.split('.').pop() === 'svg' ? (
            <ReactSVG src={imageUrl} />
          ) : (
            <img src={`${imageUrl}`} alt="" />
          )
        ) : (
          this.getInitials(name)
        )}
      </div>
    );
  }
}
