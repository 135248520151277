import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import ComponentStack from '@uicomponents/ComponentStack';

import * as navigationActions from '@actions/navigationActions';

class ComponentStackContainer extends PureComponent {
  render() {
    const { components, popStack, ...props } = this.props;

    return (
      <ComponentStack components={components} popStack={popStack} {...props} />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.navigation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ComponentStackContainer);
