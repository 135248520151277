import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ConsignmentFormPage from '@components/consignment/crud/ConsignmentFormPage.js';

import * as fileApi from '@api/fileApi';

import * as businessActions from '@actions/businessActions';
import * as consignmentActions from '@actions/consignmentActions';
import * as navigationActions from '@actions/navigationActions';

class CreateConsignmentContainer extends PureComponent {
  render() {
    const { createConsignment, searchBusinesses, callback, ...props } =
      this.props;
    const { popStack } = this.props;

    return (
      <ConsignmentFormPage
        {...props}
        onChange={(consignment) =>
          createConsignment(consignment).then((response) => {
            popStack?.();
            callback?.(response.consignment);
          })
        }
        searchBusinesses={(searchParameters, from, amount) =>
          searchBusinesses(searchParameters, from, amount)
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,
    currencies: state.platform.currencies,
    isFetchingCurrencies: state.platform.isFetchingCurrencies,
    ...state.business,
    ...state.consignment,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createConsignment: (consignment) =>
      dispatch(consignmentActions.createConsignment(consignment)),

    searchBusinesses: (searchParameters, from, amount) =>
      dispatch(
        businessActions.searchBusinesses(searchParameters, from, amount)
      ),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateConsignmentContainer));
