import {
  boardConstants,
  commentConstants,
  laneConstants,
  todoConstants,
  todoMainConstants,
} from '@constants/index.js';

const initialState = {
  // main init
  error: null,
  isEdit: false,
  activeView: todoMainConstants.OVERVIEW_VIEW,

  board: null,
  boardForm: null,
  isFetchingBoard: false,

  boards: [],
  boardsLoaded: 0,
  boardsTotalResults: 0,
  isFetchingBoards: false,
  isFetchingBoardsFrom: 0,

  lane: null,
  laneForm: null,
  isFetchingLane: false,

  todo: null,
  todoForm: null,
  isFetchingTodo: false,

  todoStatistics: {},

  todos: [],
  todosLoaded: 0,
  todosTotalResults: 0,
  isFetchingTodos: false,
  isFetchingTodosFrom: 0,

  entityTodos: [],
  entityTodosLoaded: 0,
  entityTodosTotalResults: 0,
  isFetchingEntityTodos: false,
  isFetchingEntityTodosFrom: 0,

  comments: [],
  commentForm: null,
  commentsLoaded: 0,
  commentsTotalResults: 0,
  isFetchingComments: false,
  isFetchingCommentsFrom: 0,
};

export default function todo(state = initialState, action) {
  switch (action.type) {
    // Main Actions
    case todoMainConstants.TODO_CHANGE_VALUE:
      return { ...state, [action.name]: action.value };
    case todoMainConstants.HANDLE_SET_VIEW:
      return { ...state, activeView: action.payload };

    case todoMainConstants.HANDLE_SET_EDIT:
      return { ...state, isEdit: action.payload };

    // Board Actions
    case boardConstants.GETTING_BOARDS_STARTED:
      return {
        ...state,
        isFetchingBoards: true,
        isFetchingBoardsFrom: action.from || 0,
      };
    case boardConstants.GETTING_BOARDS_FAILURE:
      return { ...state, isFetchingBoards: false, error: action.message || '' };
    case boardConstants.GETTING_BOARDS_SUCCESS:
      return {
        ...state,
        isFetchingBoards: false,
        boards: action.from > 0 ? [...state.boards, ...action.boards] : action.boards,
        boardsLoaded:
          action.from > 0 ? state.boards.length + action.boards.length : action.boards.length,
        boardsTotalResults: action.totalResults || 0,
      };

    case boardConstants.GET_BOARD_STARTED:
      return { ...state, isFetchingBoard: true };
    case boardConstants.GET_BOARD_FAILURE:
      return { ...state, isFetchingBoard: false, error: action.message || '' };
    case boardConstants.GET_BOARD_SUCCESS:
      return {
        ...state,
        isFetchingBoard: false,
        board: action.board,
      };

    case boardConstants.CREATING_BOARD_STARTED:
      return { ...state, isFetchingBoard: true };
    case boardConstants.CREATING_BOARD_FAILURE:
      return { ...state, isFetchingBoard: false, error: action.message || '' };
    case boardConstants.CREATING_BOARD_SUCCESS:
      return {
        ...state,
        isFetchingBoard: false,
        board: action.board,
        boards: [action.board, ...state.boards],
      };

    case boardConstants.UPDATING_BOARD_STARTED:
      return { ...state, isFetchingBoard: true };
    case boardConstants.UPDATING_BOARD_FAILURE:
      return { ...state, isFetchingBoard: false, error: action.message || '' };
    case boardConstants.UPDATING_BOARD_SUCCESS:
      return {
        ...state,
        isEdit: false,
        isFetchingBoard: false,
        isFetchingTodo: false,
        board: action.board,
        boards: [...state.boards].map((board) => {
          if (action.board.id === board.id) {
            return action.board;
          }
          return board;
        }),
      };

    // Lane Actions
    case todoMainConstants.HANDLE_SET_LANE:
      return { ...state, lane: action.value };

    case laneConstants.LANE_ACTION_STARTED:
      return { ...state, isFetchingLane: true };
    case laneConstants.LANE_ACTION_FAILED:
      return { ...state, isFetchingLane: false, error: action.message || '' };

    case laneConstants.CREATE_LANE_SUCCESS:
      return {
        ...state,
        isFetchingLane: false,
        board: action.board,
        boards: [...state.boards].map((board) => {
          if (action.board.id === board.id) {
            return action.board;
          }
          return board;
        }),
      };

    case laneConstants.UPDATING_LANE_SUCCESS:
      return {
        ...state,
        isEdit: false,
        isFetchingLane: false,
        board: {
          ...state.board,
          lanes: [...state.board.lanes].map((lane) => {
            return lane.id === action.id ? action : lane;
          }),
        },
      };

    case laneConstants.DELETE_LANE_STARTED:
      return {
        ...state,
      };
    case laneConstants.DELETE_LANE_SUCCESS:
      return {
        ...state,
        board: {
          ...state.board,
          lanes: [...state.board.lanes].filter((lane) => lane.id !== action.id),
        },
      };
    case laneConstants.DELETE_LANE_FAILURE:
      return {
        ...state,
        error: action.message,
      };

    // ToDo Actions
    case todoConstants.TODO_ACTION_STARTED:
      return { ...state, isFetchingTodo: true };
    case todoConstants.TODO_ACTION_FAILED:
      return { ...state, isFetchingTodo: false, error: action.message || '' };
    case todoConstants.CREATE_TODO_SUCCESS:
      return {
        ...state,
        isFetchingTodo: false,
        board: {
          ...state.board,
          lanes: state.board?.lanes?.map((lane) => {
            return lane.id === action.id ? action : lane;
          }),
        },
        entityTodos: [...state.entityTodos, action.todo],
      };

    case todoConstants.GET_TODO_STARTED:
      return { ...state, isFetchingTodo: true };
    case todoConstants.GET_TODO_FAILURE:
      return { ...state, isFetchingTodo: false, error: action.message || '' };
    case todoConstants.GET_TODO_SUCCESS:
      return {
        ...state,
        todo: action.toDo,
        isFetchingTodo: false,
      };

    case todoConstants.UPDATING_TODO_SUCCESS:
      return action.previousLane
        ? {
            ...state,
            isFetchingTodo: false,
            board: {
              ...state.board,
              lanes: [
                ...state.board.lanes.map((lane) => {
                  if (lane.id === action.previousLane?.id) {
                    const newLane = { ...lane };
                    const newTodos = lane.toDos.filter((todo) => todo.id !== action.toDo.id);
                    newLane.toDos = newTodos;
                    return newLane;
                  }
                  if (lane.id === action.toDo?.lane?.id) {
                    const newLane = { ...lane };
                    const newTodos = lane.toDos.filter((todo) => todo.id !== action.toDo.id);
                    newTodos.push(action.toDo);
                    newLane.toDos = newTodos;
                    return newLane;
                  } else {
                    return lane;
                  }
                }),
              ],
            },
            todo: action.toDo,
            todos: [...state.todos].map((todo) =>
              todo.id === action.toDo.id ? action.toDo : todo
            ),
            entityTodos: [...state.entityTodos].map((todo) =>
              todo.id === action.toDo.id ? action.toDo : todo
            ),
          }
        : {
            ...state,
            todo: action.toDo,
            isFetchingTodo: false,
            todos: [...state.todos].map((todo) =>
              todo.id === action.toDo.id ? action.toDo : todo
            ),
            entityTodos: [...state.entityTodos].map((todo) =>
              todo.id === action.toDo.id ? action.toDo : todo
            ),
          };

    case todoConstants.DELETE_TODO_STARTED:
      return { ...state, isFetchingTodo: true };
    case todoConstants.DELETE_TODO_SUCCESS:
      return {
        ...state,
        isFetchingTodo: false,
      };
    case todoConstants.DELETE_TODO_FAILURE:
      return { ...state, isFetchingTodo: false, error: action.message };

    case todoConstants.GET_TODOS_STARTED:
      return {
        ...state,
        isFetchingTodos: true,
        isFetchingTodosFrom: action.from || 0,
      };
    case todoConstants.GET_TODOS_SUCCESS:
      return {
        ...state,
        isFetchingTodos: false,
        todos: action.from > 0 ? [...state.todos, ...action.toDos] : action.toDos,
        todosLoaded:
          action.from > 0 ? state.todos.length + action.toDos.length : action.toDos.length,
        todosTotalResults: action.totalResults || 0,
      };

    case todoConstants.GET_ENTITY_TODOS_STARTED:
      return {
        ...state,
        isFetchingEntityTodos: true,
        isFetchingEntityTodosFrom: action.from || 0,
      };
    case todoConstants.GET_ENTITY_TODOS_SUCCESS:
      return {
        ...state,
        isFetchingEntityTodos: false,
        entityTodos: action.from > 0 ? [...state.entityTodos, ...action.toDos] : action.toDos,
        entityTodosLoaded:
          action.from > 0 ? state.entityTodos.length + action.toDos.length : action.toDos.length,
        entityTodosTotalResults: action.totalResults || 0,
      };

    case todoConstants.GET_TODO_STATISTICS_SUCCESS:
      return {
        ...state,
        isFetchingTodo: false,
        todoStatistics: action.toDoStatistics,
      };

    // Comment Actions
    case commentConstants.GETTING_COMMENTS_STARTED:
      return {
        ...state,
        isFetchingComments: true,
        isFetchingCommentsFrom: action.from || 0,
      };
    case commentConstants.GETTING_COMMENTS_FAILURE:
      return { ...state, isFetchingComments: false, error: action.message || '' };
    case commentConstants.GETTING_COMMENTS_SUCCESS:
      return {
        ...state,
        isFetchingComments: false,
        comments:
          action.from > 0 ? [...state.comments, ...action.toDoComments] : action.toDoComments,
        commentsLoaded:
          action.from > 0
            ? state.comments.length + action.toDoComments.length
            : action.toDoComments.length,
        commentsTotalResults: action.totalResults || 0,
      };

    case commentConstants.CREATING_COMMENT_STARTED:
      return { ...state, isFetchingComments: true };
    case commentConstants.CREATING_COMMENT_FAILURE:
      return { ...state, isFetchingComments: false, error: action.message || '' };
    case commentConstants.CREATING_COMMENT_SUCCESS:
      return {
        ...state,
        isFetchingComments: false,
        comments: [action.toDoComment, ...state.comments],
      };

    case commentConstants.UPDATING_COMMENT_STARTED:
      return { ...state, isFetchingComments: true };
    case commentConstants.UPDATING_COMMENT_FAILURE:
      return { ...state, isFetchingComments: false, error: action.message || '' };
    case commentConstants.UPDATING_COMMENT_SUCCESS:
      return {
        ...state,
        isFetchingComments: false,
        comments: [...state.comments].map((comment) => {
          if (action.toDoComment.id === comment.id) {
            return action.toDoComment;
          }
          return comment;
        }),
      };

    case commentConstants.DELETE_COMMENT_STARTED:
      return { ...state, isFetchingComments: true };
    case commentConstants.DELETE_COMMENT_SUCCESS:
      return {
        ...state,
        isFetchingComments: false,
        comments: [...state.comments].filter((comment) => action.toDoComment.id !== comment.id),
      };
    case commentConstants.DELETE_COMMENT_FAILURE:
      return { ...state, isFetchingComments: false, error: action.message };

    default:
      return state;
  }
}
