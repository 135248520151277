import React, { Component } from 'react';

export default class NoData extends Component {
  render() {
    const { className, children } = this.props;

    return (
      <p className={(className || '') + ' no-data'}>
        {children || 'Geen data gevonden'}
      </p>
    );
  }
}
