import { store } from '@/index';

export const isExternal = (user) => {
  return user?.accountType === 'customer' || user?.accountType === 'supplier';
};

export const hasPermission = (permission) => {
  return store.getState().auth?.session?.permissions?.some((per) => per.name == permission);
};

export const containsPermissionPart = (permission) => {
  return store.getState().auth?.session?.permissions?.some((per) => per.name?.includes(permission));
};
