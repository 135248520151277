import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

class NoteView extends Component {
  render() {
    const { note } = this.props;
    return (
      <div className="note">
        <div className="title">
          {note.name}{' '}
          <span className="date">
            {moment(note.createdAt).format('DD/MM/YYYY HH:mm')}
          </span>
        </div>
        <div className="meta">
          <div className="author">
            {note.user ? `${note.user.firstName} ${note.user.lastName}` : ''}{' '}
          </div>
        </div>
        <div className="text">{note.text}</div>
      </div>
    );
  }
}
export default withTranslation('translation')(NoteView);
