import React, { Component } from 'react';

import ReactSVG from 'react-svg';

export default class CloseButton extends Component {
  render() {
    const { onClick } = this.props;

    return (
      <ReactSVG
        onClick={(e) => onClick && onClick(e)}
        className="close-button"
        src="/icons/cross.svg"
      />
    );
  }
}
