import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateSupportTicket from '@components/support/crud/CreateSupportTicket.js';

import * as navigationActions from '@actions/navigationActions';
import * as supportActions from '@actions/supportActions';

class CreateSupportTicketContainer extends PureComponent {
  render() {
    const { changeValue, createTicket, onNavigateTo, ...props } = this.props;

    return (
      <CreateSupportTicket
        {...props}
        onChange={changeValue}
        createTicket={(ticket) => {
          return createTicket(ticket).then((response) => {
            onNavigateTo(
              `/support/ticket/${response.supportTicket.id}/${response.supportTicket.token}`
            );
          });
        }}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    profile: state.auth.profile,
    ...state.support,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTicket: (ticket) => dispatch(supportActions.createTicket(ticket)),
    changeValue: (name, value) => dispatch(supportActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateSupportTicketContainer));
