
import { tireConstants } from "@constants/tire.constants";

const initialState = {
    isFetchingTire: false,
    formTire: null,
    tire: null,

    isFetchingTires: false,
    isFetchingTiresFrom: 0,
    tiresTotalResults: 0,
    tiresLoaded: 0,
    tires: [],
};

export default function tire(state = initialState, action) {
    switch (action.type) {
        case tireConstants.TIRE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case tireConstants.GET_TIRE_STARTED:
            return { ...state, isFetchingTire: true };
        case tireConstants.GET_TIRE_FAILURE:
            return { ...state, isFetchingTire: false, tire: null, error: action.message || "" };
        case tireConstants.GET_TIRE_SUCCESS:
            return {
                ...state,
                isFetchingTire: false,
                tire: action.tire,
            };
            
        case tireConstants.GET_TIRES_STARTED:
            return { ...state, isFetchingTires: true,  isFetchingTiresFrom: action.from || 0 };
        case tireConstants.GET_TIRES_FAILURE:
            return { ...state, isFetchingTires: false, tires: [], error: action.message || "" };
        case tireConstants.GET_TIRES_SUCCESS:
            return {
                ...state,
                isFetchingTires: false,
                tires: action.from > 0 ? [...state.tires, ...action.tires] : action.tires,
                tiresLoaded:
                    action.from > 0
                        ? state.tires.length + action.tires.length
                        : action.tires.length,
                tiresTotalResults: action.totalResults || 0,
            };

        case tireConstants.SEARCH_TIRES_STARTED:
            return { ...state, isFetchingTires: true, isFetchingTiresFrom: action.from || 0  };
        case tireConstants.SEARCH_TIRES_FAILURE:
            return { ...state, isFetchingTires: false, tires: [], error: action.message || "" };
        case tireConstants.SEARCH_TIRES_SUCCESS:
            return {
                ...state,
                isFetchingTires: false,
                tires: action.from > 0 ? [...state.tires, ...action.tires] : action.tires,
                tiresLoaded:
                    action.from > 0
                        ? state.tires.length + action.tires.length
                        : action.tires.length,
                tiresTotalResults: action.totalResults || 0,
            };

        case tireConstants.CREATING_TIRE_STARTED:
            return { ...state, isFetchingTire: true };
        case tireConstants.CREATING_TIRE_FAILURE:
            return { ...state, isFetchingTire: false, tire: null, error: action.message || "" };
        case tireConstants.CREATING_TIRE_SUCCESS:
            return {
                ...state,
                isFetchingTire: false,
                tire: action.tire,
                tires: [action.tire, ...state.tires],
            };

        case tireConstants.UPDATING_TIRE_STARTED:
            return { ...state, isFetchingTire: true };
        case tireConstants.UPDATING_TIRE_FAILURE:
            return { ...state, isFetchingTire: false, tire: null, error: action.message || "" };
        case tireConstants.UPDATING_TIRE_SUCCESS:
            return {
                ...state,
                isFetchingTire: false,
                tire: action.tire,
                tires: [...state.tires].map((tire) => {
                    if (action.tire.id === tire.id) {
                        return action.tire;
                    }
                    return tire;
                }),
            };

        case tireConstants.DELETE_TIRE_STARTED:
            return { ...state, isFetchingTire: true };
        case tireConstants.DELETE_TIRE_FAILURE:
            return { ...state, isFetchingTire: false, tire: null, message: action.message || "" };
        case tireConstants.DELETE_TIRE_SUCCESS:
            return {
                ...state,
                isFetchingTire: false,
                tire: action.tire,
                tires: [...state.tires].filter((tire) => action.tire.id !== tire.id),
            };
        default:
            return state;
    }
}    
    