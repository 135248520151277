
import { certificateConstants } from "@constants/certificate.constants";

const initialState = {
    isFetchingCertificate: false,
    formCertificate: null,
    certificate: null,

    isFetchingCertificates: false,
    isFetchingCertificatesFrom: 0,
    certificatesTotalResults: 0,
    certificatesLoaded: 0,
    certificates: [],
};

export default function certificate(state = initialState, action) {
    switch (action.type) {
        case certificateConstants.CERTIFICATE_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case certificateConstants.GET_CERTIFICATE_STARTED:
            return { ...state, isFetchingCertificate: true };
        case certificateConstants.GET_CERTIFICATE_FAILURE:
            return { ...state, isFetchingCertificate: false, certificate: null, error: action.message || "" };
        case certificateConstants.GET_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isFetchingCertificate: false,
                certificate: action.certificate,
            };
            
        case certificateConstants.GET_CERTIFICATES_STARTED:
            return { ...state, isFetchingCertificates: true,  isFetchingCertificatesFrom: action.from || 0 };
        case certificateConstants.GET_CERTIFICATES_FAILURE:
            return { ...state, isFetchingCertificates: false, certificates: [], error: action.message || "" };
        case certificateConstants.GET_CERTIFICATES_SUCCESS:
            return {
                ...state,
                isFetchingCertificates: false,
                certificates: action.from > 0 ? [...state.certificates, ...action.certificates] : action.certificates,
                certificatesLoaded:
                    action.from > 0
                        ? state.certificates.length + action.certificates.length
                        : action.certificates.length,
                certificatesTotalResults: action.totalResults || 0,
            };

        case certificateConstants.SEARCH_CERTIFICATES_STARTED:
            return { ...state, isFetchingCertificates: true, isFetchingCertificatesFrom: action.from || 0  };
        case certificateConstants.SEARCH_CERTIFICATES_FAILURE:
            return { ...state, isFetchingCertificates: false, certificates: [], error: action.message || "" };
        case certificateConstants.SEARCH_CERTIFICATES_SUCCESS:
            return {
                ...state,
                isFetchingCertificates: false,
                certificates: action.from > 0 ? [...state.certificates, ...action.certificates] : action.certificates,
                certificatesLoaded:
                    action.from > 0
                        ? state.certificates.length + action.certificates.length
                        : action.certificates.length,
                certificatesTotalResults: action.totalResults || 0,
            };

        case certificateConstants.CREATING_CERTIFICATE_STARTED:
            return { ...state, isFetchingCertificate: true };
        case certificateConstants.CREATING_CERTIFICATE_FAILURE:
            return { ...state, isFetchingCertificate: false, certificate: null, error: action.message || "" };
        case certificateConstants.CREATING_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isFetchingCertificate: false,
                certificate: action.certificate,
                certificates: [action.certificate, ...state.certificates],
            };

        case certificateConstants.UPDATING_CERTIFICATE_STARTED:
            return { ...state, isFetchingCertificate: true };
        case certificateConstants.UPDATING_CERTIFICATE_FAILURE:
            return { ...state, isFetchingCertificate: false, certificate: null, error: action.message || "" };
        case certificateConstants.UPDATING_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isFetchingCertificate: false,
                certificate: action.certificate,
                certificates: [...state.certificates].map((certificate) => {
                    if (action.certificate.id === certificate.id) {
                        return action.certificate;
                    }
                    return certificate;
                }),
            };

        case certificateConstants.DELETE_CERTIFICATE_STARTED:
            return { ...state, isFetchingCertificate: true };
        case certificateConstants.DELETE_CERTIFICATE_FAILURE:
            return { ...state, isFetchingCertificate: false, certificate: null, message: action.message || "" };
        case certificateConstants.DELETE_CERTIFICATE_SUCCESS:
            return {
                ...state,
                isFetchingCertificate: false,
                certificate: action.certificate,
                certificates: [...state.certificates].filter((certificate) => action.certificate.id !== certificate.id),
            };
        default:
            return state;
    }
}    
    