import { actorConstants } from '../constants/actor.constants';

const initialState = {
  isFetchingActor: false,
  formActor: null,
  actor: null,

  isFetchingActors: false,
  isFetchingActorsFrom: 0,
  actorsTotalResults: 0,
  actorsLoaded: 0,
  actors: [],
};

export default function actor(state = initialState, action) {
  switch (action.type) {
    case actorConstants.ACTOR_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case actorConstants.GET_ACTOR_STARTED:
      return { ...state, isFetchingActor: true };
    case actorConstants.GET_ACTOR_FAILURE:
      return {
        ...state,
        isFetchingActor: false,
        actor: null,
        error: action.message || '',
      };
    case actorConstants.GET_ACTOR_SUCCESS:
      return {
        ...state,
        isFetchingActor: false,
        actor: action.actor,
      };

    case actorConstants.GET_ACTORS_STARTED:
      return { ...state, isFetchingActors: true };
    case actorConstants.GET_ACTORS_FAILURE:
      return {
        ...state,
        isFetchingActors: false,
        actors: [],
        error: action.message || '',
      };
    case actorConstants.GET_ACTORS_SUCCESS:
      return {
        ...state,
        isFetchingActors: false,
        actors:
          action.from > 0 ? [...state.actors, ...action.actors] : action.actors,
        actorsLoaded:
          action.from > 0
            ? state.actors.length + action.actors.length
            : action.actors.length,
        actorsTotalResults: action.totalResults || 0,
      };

    case actorConstants.SEARCH_ACTORS_STARTED:
      return { ...state, isFetchingActors: true };
    case actorConstants.SEARCH_ACTORS_FAILURE:
      return {
        ...state,
        isFetchingActors: false,
        actors: [],
        error: action.message || '',
      };
    case actorConstants.SEARCH_ACTORS_SUCCESS:
      return {
        ...state,
        isFetchingActors: false,
        actors:
          action.from > 0 ? [...state.actors, ...action.actors] : action.actors,
        actorsLoaded:
          action.from > 0
            ? state.actors.length + action.actors.length
            : action.actors.length,
        actorsTotalResults: action.totalResults || 0,
      };

    case actorConstants.CREATING_ACTOR_STARTED:
      return { ...state, isFetchingActor: true };
    case actorConstants.CREATING_ACTOR_FAILURE:
      return {
        ...state,
        isFetchingActor: false,
        actor: null,
        error: action.message || '',
      };
    case actorConstants.CREATING_ACTOR_SUCCESS:
      return {
        ...state,
        isFetchingActor: false,
        actor: action.actor,
        actors: [action.actor, ...state.actors],
      };

    case actorConstants.UPDATING_ACTOR_STARTED:
      return { ...state, isFetchingActor: true };
    case actorConstants.UPDATING_ACTOR_FAILURE:
      return {
        ...state,
        isFetchingActor: false,
        actor: null,
        error: action.message || '',
      };
    case actorConstants.UPDATING_ACTOR_SUCCESS:
      return {
        ...state,
        isFetchingActor: false,
        actor: action.actor,
        actors: [...state.actors].map((actor) => {
          if (action.actor.id === actor.id) {
            return action.actor;
          }
          return actor;
        }),
      };

    case actorConstants.DELETE_ACTOR_STARTED:
      return { ...state, isFetchingActor: true };
    case actorConstants.DELETE_ACTOR_FAILURE:
      return {
        ...state,
        isFetchingActor: false,
        actor: null,
        message: action.message || '',
      };
    case actorConstants.DELETE_ACTOR_SUCCESS:
      return {
        ...state,
        isFetchingActor: false,
        actor: action.actor,
        actors: [...state.actors].filter(
          (actor) => action.actor.id !== actor.id
        ),
      };
    default:
      return state;
  }
}
