import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Loader from '@components/ui/components/Loader';
import NoData from '@components/ui/components/NoData';

import * as insightApi from '@api/insightApi';

class WelcomeStatsPage extends PureComponent {
  _isMounted = true;

  constructor(props) {
    super(props);

    this.state = {
      resultIsFetching: false,
      response: null,
    };
  }

  componentDidMount() {
    this.setState({
      resultIsFetching: true,
    });

    insightApi
      .getWelcomeStats()
      .then((response) => {
        if (this._isMounted)
          this.setState({
            resultIsFetching: false,
            response: response,
          });
      })
      .catch((response) => {
        if (this._isMounted)
          this.setState({
            error: response?.data?.message || '',
            resultIsFetching: false,
          });
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    const { resultIsFetching, response, error } = this.state;

    if (resultIsFetching) {
      return (
        <div className="dashboard__information">
          <Loader />
        </div>
      );
    }

    if (error) {
      return (
        <div className="dashboard__information center">
          <p>{error}</p>
        </div>
      );
    }

    if (response) {
      return (
        <div className="dashboard__information">
          <div className="dashboard__information__block dashboard__information__block--bottom-left-corner">
            <div className="dashboard__information__block__value">
              <ReactSVG
                className="dashboard__information__block__icon"
                src="/icons/waypoint.svg"
              />
              {response.data.actionsToDo}
            </div>
            <div className="dashboard__information__block__name">
              {t('dashboard.welcome.openActions')}
            </div>
          </div>
          <div className="dashboard__information__block">
            <div className="dashboard__information__block__value">
              <ReactSVG
                className="dashboard__information__block__icon"
                src="/icons/route.svg"
              />
              {response.data.consignmentInTransit}
            </div>
            <div className="dashboard__information__block__name">
              {t('dashboard.welcome.openTrips')}
            </div>
          </div>
          <div className="dashboard__information__block dashboard__information__block--right-corner">
            <div className="dashboard__information__block__value">
              <ReactSVG
                className="dashboard__information__block__icon"
                src="/icons/chat.svg"
              />
              {response.data.unreadMessages}
            </div>
            <div className="dashboard__information__block__name">
              {t('dashboard.welcome.unreadMessages')}
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="dashboard__information center">
        <div className="dashboard__information__block dashboard__information__block--right-corner dashboard__information__block--bottom-left-corner">
          <NoData className="center" />
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(WelcomeStatsPage);
