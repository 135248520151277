import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateActionTimesPage from '@components/action/crud/UpdateActionTimesPage.js';

import * as actionActions from '@actions/actionActions';
import * as navigationActions from '@actions/navigationActions';

class UpdateActionTimesContainer extends PureComponent {
  render() {
    return <UpdateActionTimesPage {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.action,
    ...ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || "DD/MM/YYYY",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateActionTimes: (actionId, startTime, endTime) =>
      dispatch(actionActions.updateActionTimes(actionId, startTime, endTime)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateActionTimesContainer));
