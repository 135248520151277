import L, { divIcon } from 'leaflet';

export default divIcon({
  html: `<svg
   version="1.1"
   id="Capa_1"
   x="0px"
   y="0px"
   viewBox="0 0 95.301 95.301"
   style="enable-background:new 0 0 95.301 95.301;"
   xml:space="preserve"
   sodipodi:docname="orientation.svg"
   inkscape:version="1.0.2 (e86c8708, 2021-01-15)">

<g
   id="g12"
   transform="rotate(135,51.658295,37.974021)">
	<path
   d="M 94.695,0.605 C 94.112,0.022 93.239,-0.158 92.471,0.147 L 1.297,36.581 c -0.858,0.343 -1.383,1.214 -1.286,2.133 0.097,0.919 0.793,1.661 1.704,1.816 l 45.313,7.742 7.743,45.312 c 0.156,0.911 0.898,1.606 1.816,1.704 0.072,0.008 0.146,0.012 0.218,0.012 0.836,0 1.599,-0.508 1.915,-1.297 L 95.153,2.828 C 95.46,2.062 95.279,1.188 94.695,0.605 Z"
   id="path10" />
</g>
<g
   id="g14">
</g>
<g
   id="g16">
</g>
<g
   id="g18">
</g>
<g
   id="g20">
</g>
<g
   id="g22">
</g>
<g
   id="g24">
</g>
<g
   id="g26">
</g>
<g
   id="g28">
</g>
<g
   id="g30">
</g>
<g
   id="g32">
</g>
<g
   id="g34">
</g>
<g
   id="g36">
</g>
<g
   id="g38">
</g>
<g
   id="g40">
</g>
<g
   id="g42">
</g>
</svg>`,
  iconSize: new L.Point(30, 30),
  iconAnchor: [15, 15],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  className: 'default-marker',
});
