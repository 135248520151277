import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import TransportEquipment from '@models/goods/TransportEquipment';

import TransportEquipmentForm from './TransportEquipmentForm';

export default class CreateTransportEquipmentPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formTransportEquipment', new TransportEquipment());
  }

  render() {
    const {
      t,
      onChange,
      formTransportEquipment,
      isFetchingTransportEquipment,
      createTransportEquipment,
    } = this.props;

    if (!formTransportEquipment || isFetchingTransportEquipment) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('transportEquipment.new')}</h1>
        <div
          className="step-form"
          key={`${formTransportEquipment.id}-${formTransportEquipment.versionNumber}-${formTransportEquipment.nonce}`}
        >
          <form className="form active no-padding-top">
            <div className="input-container">
              <TransportEquipmentForm
                {...this.props}
                key={formTransportEquipment.id}
                notInTransportOrderContext={true}
                goods={formTransportEquipment}
                onChange={(transportEquipment) =>
                  onChange('formTransportEquipment', transportEquipment)
                }
                onSubmit={(transportEquipment) => createTransportEquipment(transportEquipment)}
              />
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
