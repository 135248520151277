import api from '../api';

export const uploadFile = (file, purpose = '', progress = (e) => {}) => {
  let formData = new FormData();

  formData.append('file', file);
  formData.append('purpose', purpose);

  return api.post(`/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progress,
  });
};

export const fileToStructuredMatrix = (file, progress = (e) => {}) => {
  let formData = new FormData();

  formData.append('file', file);

  return api.post(`/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progress,
  });
};

export const fileToHtml = (file, progress = (e) => {}) => {
  let formData = new FormData();

  formData.append('file', file);

  return api.post(`/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progress,
  });
};
