import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import AlertStack from '@uicomponents/AlertStack';

import * as navigationActions from '@actions/navigationActions';

class AlertStackContainer extends PureComponent {
  render() {
    const { stack, popStack, ...props } = this.props;

    return <AlertStack stack={stack} popStack={popStack} {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.navigation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popAlert: () => dispatch(navigationActions.popAlert()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertStackContainer);
