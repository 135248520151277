import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import NoData from '@uicomponents/NoData';

import ContactPersonView from './ContactPersonView';

class ContactPersonsView extends Component {
  render() {
    const { t, contactPersons } = this.props;

    if (contactPersons.length > 0) {
      return (
        <div className="contact-details">
          {contactPersons
            .map((association) => association.entity)
            .sort((a, b) => moment.utc(b.updatedAt).diff(moment.utc(a.updatedAt)))
            .map((contactPerson) => (
              <ContactPersonView key={contactPerson.id} contactPerson={contactPerson} />
            ))}
        </div>
      );
    }

    return <NoData>{t('contactPersons.notFound')}</NoData>;
  }
}
export default withTranslation('translation')(ContactPersonsView);
