import React, { Component } from 'react';

import Attachments from '@uicomponents/Attachments';

import Association from '@models/general/Association';

import RichTextEditorWithMentions from '../../ui/inputs/MentionsRichTextEditor';

export default class CommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      commentText: null,
    };
  }

  createComment(e) {
    e.preventDefault();
    const { todo, user, createComment, commentForm, closeForm } = this.props;
    const newCommentForm = { ...commentForm };
    newCommentForm.creator = user;
    newCommentForm.comment = this.state.commentText;
    newCommentForm.toDo = todo;
    createComment && createComment(newCommentForm);
    closeForm();
  }

  updateComment(e) {
    e.preventDefault();
    const { todo, updateComment, commentForm, closeForm } = this.props;
    const newCommentForm = { ...commentForm };
    newCommentForm.comment = this.state.commentText;
    newCommentForm.toDo = todo;
    updateComment && updateComment(newCommentForm);
    closeForm();
  }

  componentWillUnmount() {
    const { changeValue } = this.props;
    changeValue('commentForm', null);
  }

  render() {
    const { t, commentForm, closeForm, ownProps } = this.props;
    const { isEdit } = ownProps;

    return (
      <div ref={this.divRef} className="relative">
        <div className="comment-form">
          <form className="form active no-padding-top">
            <RichTextEditorWithMentions
              comment={commentForm?.comment}
              onTextChange={(commentText) => this.setState({ commentText })}
            />

            <div className="input-group more d-flex align-items-center gap-10 justify-content-end">
              <Attachments
                {...this.props}
                isMinimal
                key={commentForm?.documents}
                files={commentForm?.documents?.map((document) => {
                  return document.entity.file;
                })}
                onChange={(files) => {
                  const { changeValue, commentForm } = this.props;
                  const newCommentForm = { ...commentForm };

                  newCommentForm.documents = files.map((file) => {
                    const newFile = { ...file };
                    return new Association('inline', {
                      name: newFile.originalName,
                      mimeType: newFile.mimeType,
                      content: {
                        contentType: 'uri',
                        uri: newFile.url,
                      },
                      file: newFile,
                    });
                  });
                  changeValue('commentForm', newCommentForm);
                }}
              />
              <button
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  closeForm();
                }}
              >
                {t('form.cancel')}
              </button>
              <button onClick={(e) => (isEdit ? this.updateComment(e) : this.createComment(e))}>
                {isEdit ? t('form.update') : t('form.create')}
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
