export const todoMainConstants = {
  LANES_VIEW: 'LANES_VIEW',
  BOARDS_VIEW: 'BOARDS_VIEW',
  OVERVIEW_VIEW: 'OVERVIEW_VIEW',
  TODO_VIEW: 'TODO_VIEW',

  ALL_BOARDS_VIEW: 'ALL_BOARDS',
  MY_BOARDS_VIEW: 'MY_BOARDS',

  MY_STATS_VIEW: 'MY_STATS_VIEW',
  ALL_STATS_VIEW: 'ALL_STATS_VIEW',

  WEEKLY_VIEW: 'WEEKLY_VIEW',
  MONTHLY_VIEW: 'MONTHLY_VIEW',

  HANDLE_SET_VIEW: 'HANDLE_SET_VIEW',
  HANDLE_SET_EDIT: 'HANDLE_SET_EDIT',

  HANDLE_LANE_FORM: 'HANDLE_LANE_FORM',
  HANDLE_TODO_FORM: 'HANDLE_TODO_FORM',
  HANDLE_BOARD_FORM: 'HANDLE_BOARD_FORM',
  HANDLE_INPUT_CHANGE: 'HANDLE_INPUT_CHANGE',

  HANDLE_SET_LANE: 'HANDLE_SET_LANE',
  TODO_CHANGE_VALUE: 'TODO_CHANGE_VALUE',
};
