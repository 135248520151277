import { feedbackConstants } from '@constants/feedback.constants';

const initialState = {
  isFetchingFeedback: false,
  formFeedback: null,
  feedback: null,

  isFetchingFeedbacks: false,
  isFetchingFeedbacksFrom: 0,
  feedbacksTotalResults: 0,
  feedbacksLoaded: 0,
  feedbacks: [],
};

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case feedbackConstants.FEEDBACK_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case feedbackConstants.GET_FEEDBACK_STARTED:
      return { ...state, isFetchingFeedback: true };
    case feedbackConstants.GET_FEEDBACK_FAILURE:
      return {
        ...state,
        isFetchingFeedback: false,
        error: action.message || '',
      };
    case feedbackConstants.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        isFetchingFeedback: false,
        feedback: action.feedback,
      };

    case feedbackConstants.GET_FEEDBACKS_STARTED:
      return {
        ...state,
        isFetchingFeedbacks: true,
        isFetchingFeedbacksFrom: action.from || 0,
      };
    case feedbackConstants.GET_FEEDBACKS_FAILURE:
      return {
        ...state,
        isFetchingFeedbacks: false,
        error: action.message || '',
      };
    case feedbackConstants.GET_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isFetchingFeedbacks: false,
        feedbacks:
          action.from > 0
            ? [...state.feedbacks, ...action.feedbacks]
            : action.feedbacks,
        feedbacksLoaded:
          action.from > 0
            ? state.feedbacks.length + action.feedbacks.length
            : action.feedbacks.length,
        feedbacksTotalResults: action.totalResults || 0,
      };

    case feedbackConstants.SEARCH_FEEDBACKS_STARTED:
      return {
        ...state,
        isFetchingFeedbacks: true,
        isFetchingFeedbacksFrom: action.from || 0,
      };
    case feedbackConstants.SEARCH_FEEDBACKS_FAILURE:
      return {
        ...state,
        isFetchingFeedbacks: false,
        error: action.message || '',
      };
    case feedbackConstants.SEARCH_FEEDBACKS_SUCCESS:
      return {
        ...state,
        isFetchingFeedbacks: false,
        feedbacks:
          action.from > 0
            ? [...state.feedbacks, ...action.feedbacks]
            : action.feedbacks,
        feedbacksLoaded:
          action.from > 0
            ? state.feedbacks.length + action.feedbacks.length
            : action.feedbacks.length,
        feedbacksTotalResults: action.totalResults || 0,
      };

    case feedbackConstants.CREATING_FEEDBACK_STARTED:
      return { ...state, isFetchingFeedback: true };
    case feedbackConstants.CREATING_FEEDBACK_FAILURE:
      return {
        ...state,
        isFetchingFeedback: false,
        error: action.message || '',
      };
    case feedbackConstants.CREATING_FEEDBACK_SUCCESS:
      return {
        ...state,
        isFetchingFeedback: false,
        feedback: action.feedback,
        feedbacks: [action.feedback, ...state.feedbacks],
      };

    case feedbackConstants.UPDATING_FEEDBACK_STARTED:
      return { ...state, isFetchingFeedback: true };
    case feedbackConstants.UPDATING_FEEDBACK_FAILURE:
      return {
        ...state,
        isFetchingFeedback: false,
        error: action.message || '',
      };
    case feedbackConstants.UPDATING_FEEDBACK_SUCCESS:
      return {
        ...state,
        isFetchingFeedback: false,
        feedback: action.feedback,
        feedbacks: [...state.feedbacks].map((feedback) => {
          if (action.feedback.id === feedback.id) {
            return action.feedback;
          }
          return feedback;
        }),
      };

    case feedbackConstants.DELETE_FEEDBACK_STARTED:
      return { ...state, isFetchingFeedback: true };
    case feedbackConstants.DELETE_FEEDBACK_FAILURE:
      return {
        ...state,
        isFetchingFeedback: false,
        message: action.message || '',
      };
    case feedbackConstants.DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        isFetchingFeedback: false,
        feedback: action.feedback,
        feedbacks: [...state.feedbacks].filter(
          (feedback) => action.feedback.id !== feedback.id
        ),
      };
    default:
      return state;
  }
}
