import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateChatPage from '@components/chat/crud/UpdateChatPage';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import * as fileApi from '@api/fileApi';

import * as chatActions from '@actions/chatActions';
import * as userActions from '@actions/userActions';

class UpdateChatContainer extends PureComponent {
  componentDidMount() {
    const { activeChat, changeValue } = this.props;
    activeChat ? changeValue('formChat', activeChat) : null;
  }

  render() {
    const {
      t,
      changeValue,
      getChat,
      isFetching,
      createChat,
      createMessage,
      onChatCreated,
      chatPopup,
      chatIsFetching,
      ...props
    } = this.props;

    if (isFetching || chatIsFetching) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('update.chat')}</h1>
        <UpdateChatPage
          {...props}
          t={t}
          onChange={changeValue}
          chatPopup={chatPopup}
          getChat={(id) => getChat(id)}
          uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
          createChat={(chat, message) =>
            createChat(chat, message).then((response) => {
              createMessage(response.chat.id, message).then(() => {
                onChatCreated && onChatCreated(response.chat);
              });
            })
          }
        />
      </AsideView>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    users: state.user.users,
    usersIsFetching: state.user.usersIsFetching,

    languages: state.platform.languages,
    ...state.chat,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(chatActions.changeChatValue(name, value)),

    getChat: (id) => dispatch(chatActions.getChat(id)),
    updateChat: (chat) => dispatch(chatActions.updateChat(chat)),
    createMessage: (id, message) =>
      dispatch(chatActions.createMessage(id, message)),

    searchUsers: (searchParameters, from, to) =>
      dispatch(userActions.searchUsers(searchParameters, from, to)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateChatContainer));
