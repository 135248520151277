import api from '../api';

export const createLane = (boardId, lane) => {
  return api.post(`/board/${boardId}/lane`, lane);
};

export const updateLane = (lane) => {
  return api.put(`/board/lane/${lane.id}`, lane);
};

export const updateLanePosition = (id, position) => {
  return api.put(`/board/lane/${id}/change-position/${position}`);
};

export const deleteLane = (lane) => {
  return api.delete(`/board/lane/${lane.id}`);
};

export const getLane = (lane) => {
  return api.get(`/board/lane/${lane.id}`);
};
