import React, { Component } from 'react';

export default class Switch extends Component {
  render() {
    const { checked, disabled, onChange, ...props } = this.props;
    return (
      <div
        {...props}
        className={`switch${disabled ? ' disabled' : ''}`}
        onClick={(e) => (onChange ? onChange(e, !checked) : null)}
      >
        <input type="checkbox" checked={checked} readOnly />
        <span className="slider round"></span>
      </div>
    );
  }
}
