
import { complaintConstants } from "@constants/complaint.constants";

const initialState = {
    isFetchingComplaint: false,
    formComplaint: null,
    complaint: null,

    isFetchingComplaints: false,
    isFetchingComplaintsFrom: 0,
    complaintsTotalResults: 0,
    complaintsLoaded: 0,
    complaints: [],
};

export default function complaint(state = initialState, action) {
    switch (action.type) {
        case complaintConstants.COMPLAINT_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case complaintConstants.GET_COMPLAINT_STARTED:
            return { ...state, isFetchingComplaint: true };
        case complaintConstants.GET_COMPLAINT_FAILURE:
            return { ...state, isFetchingComplaint: false, complaint: null, error: action.message || "" };
        case complaintConstants.GET_COMPLAINT_SUCCESS:
            return {
                ...state,
                isFetchingComplaint: false,
                complaint: action.complaint,
            };
            
        case complaintConstants.GET_COMPLAINTS_STARTED:
            return { ...state, isFetchingComplaints: true,  isFetchingComplaintsFrom: action.from || 0 };
        case complaintConstants.GET_COMPLAINTS_FAILURE:
            return { ...state, isFetchingComplaints: false, complaints: [], error: action.message || "" };
        case complaintConstants.GET_COMPLAINTS_SUCCESS:
            return {
                ...state,
                isFetchingComplaints: false,
                complaints: action.from > 0 ? [...state.complaints, ...action.complaints] : action.complaints,
                complaintsLoaded:
                    action.from > 0
                        ? state.complaints.length + action.complaints.length
                        : action.complaints.length,
                complaintsTotalResults: action.totalResults || 0,
            };

        case complaintConstants.SEARCH_COMPLAINTS_STARTED:
            return { ...state, isFetchingComplaints: true, isFetchingComplaintsFrom: action.from || 0  };
        case complaintConstants.SEARCH_COMPLAINTS_FAILURE:
            return { ...state, isFetchingComplaints: false, complaints: [], error: action.message || "" };
        case complaintConstants.SEARCH_COMPLAINTS_SUCCESS:
            return {
                ...state,
                isFetchingComplaints: false,
                complaints: action.from > 0 ? [...state.complaints, ...action.complaints] : action.complaints,
                complaintsLoaded:
                    action.from > 0
                        ? state.complaints.length + action.complaints.length
                        : action.complaints.length,
                complaintsTotalResults: action.totalResults || 0,
            };

        case complaintConstants.CREATING_COMPLAINT_STARTED:
            return { ...state, isFetchingComplaint: true };
        case complaintConstants.CREATING_COMPLAINT_FAILURE:
            return { ...state, isFetchingComplaint: false, complaint: null, error: action.message || "" };
        case complaintConstants.CREATING_COMPLAINT_SUCCESS:
            return {
                ...state,
                isFetchingComplaint: false,
                complaint: action.complaint,
                complaints: [action.complaint, ...state.complaints],
            };

        case complaintConstants.UPDATING_COMPLAINT_STARTED:
            return { ...state, isFetchingComplaint: true };
        case complaintConstants.UPDATING_COMPLAINT_FAILURE:
            return { ...state, isFetchingComplaint: false, complaint: null, error: action.message || "" };
        case complaintConstants.UPDATING_COMPLAINT_SUCCESS:
            return {
                ...state,
                isFetchingComplaint: false,
                complaint: action.complaint,
                complaints: [...state.complaints].map((complaint) => {
                    if (action.complaint.id === complaint.id) {
                        return action.complaint;
                    }
                    return complaint;
                }),
            };

        case complaintConstants.DELETE_COMPLAINT_STARTED:
            return { ...state, isFetchingComplaint: true };
        case complaintConstants.DELETE_COMPLAINT_FAILURE:
            return { ...state, isFetchingComplaint: false, complaint: null, message: action.message || "" };
        case complaintConstants.DELETE_COMPLAINT_SUCCESS:
            return {
                ...state,
                isFetchingComplaint: false,
                complaint: action.complaint,
                complaints: [...state.complaints].filter((complaint) => action.complaint.id !== complaint.id),
            };
        default:
            return state;
    }
}    
    