import { renderRoutes } from '@routes';
import { lazy } from 'react';

const PlanBoardContainer = lazy(() => import('@containers/planning/planBoard/PlanBoardContainer'));

const routes = [
  {
    paths: ['', '/:subtab'],
    component: PlanBoardContainer,
    type: 'portal',
    props: { standalone: true, noHeader: true, noFooter: true },
  },
];

const PlanboardRoutes = (props) => renderRoutes(props, routes);
export default PlanboardRoutes;
