import {
  BoldButton,
  ItalicButton,
  OrderedListButton,
  UnderlineButton,
  UnorderedListButton,
} from '@draft-js-plugins/buttons';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createStaticToolbarPlugin from '@draft-js-plugins/static-toolbar';
import createTextAlignmentPlugin from '@draft-js-plugins/text-alignment';
import React, { Component } from 'react';

import {
  CompositeDecorator,
  EditorState,
  Entity,
  Modifier,
  RichUtils,
  convertFromRaw,
  convertToRaw,
} from 'draft-js';

import { search } from '@api/userApi';

import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import 'draft-js/dist/Draft.css';

const mentionPlugin = createMentionPlugin({
  mentionComponent: (mentionProps) => {
    return <span class="font-medium font-sm text-primary">@{mentionProps?.mention?.name}</span>;
  },
});

class RichTextEditorWithMentions extends Component {
  constructor(props) {
    super(props);

    // this.mentionPlugin = createMentionPlugin();
    this.staticToolbarPlugin = createStaticToolbarPlugin();
    this.textAlignmentPlugin = createTextAlignmentPlugin();
    this.mentionPlugin = mentionPlugin;

    this.plugins = [this.mentionPlugin, this.staticToolbarPlugin, this.textAlignmentPlugin];

    this.state = {
      open: false,
      suggestions: [],
      editorState: this.props.comment
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.comment)))
        : EditorState.createEmpty(),
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.comment !== this.props?.comment) {
      this.setState({
        editorState: this.props.comment
          ? EditorState.createWithContent(convertFromRaw(JSON.parse(this.props.comment)))
          : EditorState.createEmpty(),
      });
    }
  }

  onChange = (editorState) => {
    this.setState({ editorState }, () => this.props.onTextChange(this.getDraftContent()));
  };

  onSearchChange = ({ value }) => {
    search({ query: value }, 0, 20).then((response) => {
      if (response.success) {
        this.setState({
          suggestions: response.users
            .filter(
              (mention) =>
                mention.firstName &&
                mention.firstName.trim() !== '' &&
                mention.lastName.trim() !== ''
            )
            .map((mention) => ({
              id: mention.id,
              entity: 'user',
              name: `${mention.firstName} ${mention.lastName}`, // Format the name
            })),
        });
      }
    });
  };

  handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  onAddMention = (mention) => {
    const { editorState } = this.state;
    const contentState = editorState.getCurrentContent();

    // Create a new entity for the mention
    const contentStateWithEntity = contentState.createEntity('MENTION', 'IMMUTABLE', { mention });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    // Replace the selected text with the mention entity
    const selectionState = editorState.getSelection();
    const contentStateWithMention = Modifier.replaceText(
      contentState,
      selectionState,
      mention.name,
      null,
      entityKey
    );

    const newEditorState = EditorState.push(
      editorState,
      contentStateWithMention,
      'insert-characters'
    );

    this.setState({ editorState: newEditorState });
  };

  focus = () => {
    this.editor.focus();
  };

  onOpenChange = (_open) => {
    this.setState({ open: _open });
  };

  toggleBlockType = (blockType) => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };

  toggleInlineStyle = (inlineStyle) => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
  };

  getDraftContent = () => {
    const contentState = this.state.editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const jsonContentState = JSON.stringify(rawContentState);
    return jsonContentState;
  };

  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const { Toolbar } = this.staticToolbarPlugin;
    const { TextAlignment } = this.textAlignmentPlugin;

    // const htmlContent = draftToHtml(JSON.parse(initialContent), {}, customStyleMap);

    return (
      <div onClick={this.focus} className="editor">
        <Toolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <TextAlignment {...externalProps} />
              <OrderedListButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
            </>
          )}
        </Toolbar>
        <Editor
          plugins={this.plugins}
          onChange={this.onChange}
          editorState={this.state.editorState}
          handleKeyCommand={this.handleKeyCommand}
          ref={(element) => {
            this.editor = element;
          }}
        />
        <MentionSuggestions
          open={this.state.open}
          onOpenChange={this.onOpenChange}
          onAddMention={this.onAddMention}
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
      </div>
    );
  }
}

export default RichTextEditorWithMentions;
