export const capacityContractConstants = {
  CAPACITYCONTRACT_CHANGE_VALUE: 'CAPACITYCONTRACT_CHANGE_VALUE',

  GET_CAPACITYCONTRACT_STARTED: 'GET_CAPACITYCONTRACT_STARTED',
  GET_CAPACITYCONTRACT_SUCCESS: 'GET_CAPACITYCONTRACT_SUCCESS',
  GET_CAPACITYCONTRACT_FAILURE: 'GET_CAPACITYCONTRACT_FAILURE',

  GET_CAPACITYCONTRACTS_STARTED: 'GET_CAPACITYCONTRACTS_STARTED',
  GET_CAPACITYCONTRACTS_SUCCESS: 'GET_CAPACITYCONTRACTS_SUCCESS',
  GET_CAPACITYCONTRACTS_FAILURE: 'GET_CAPACITYCONTRACTS_FAILURE',

  SEARCH_CAPACITYCONTRACTS_STARTED: 'SEARCH_CAPACITYCONTRACTS_STARTED',
  SEARCH_CAPACITYCONTRACTS_SUCCESS: 'SEARCH_CAPACITYCONTRACTS_SUCCESS',
  SEARCH_CAPACITYCONTRACTS_FAILURE: 'SEARCH_CAPACITYCONTRACTS_FAILURE',

  CREATING_CAPACITYCONTRACT_STARTED: 'CREATING_CAPACITYCONTRACT_STARTED',
  CREATING_CAPACITYCONTRACT_SUCCESS: 'CREATING_CAPACITYCONTRACT_SUCCESS',
  CREATING_CAPACITYCONTRACT_FAILURE: 'CREATING_CAPACITYCONTRACT_FAILURE',

  UPDATING_CAPACITYCONTRACT_STARTED: 'UPDATING_CAPACITYCONTRACT_STARTED',
  UPDATING_CAPACITYCONTRACT_SUCCESS: 'UPDATING_CAPACITYCONTRACT_SUCCESS',
  UPDATING_CAPACITYCONTRACT_FAILURE: 'UPDATING_CAPACITYCONTRACT_FAILURE',

  DELETE_CAPACITYCONTRACT_STARTED: 'DELETE_CAPACITYCONTRACT_STARTED',
  DELETE_CAPACITYCONTRACT_SUCCESS: 'DELETE_CAPACITYCONTRACT_SUCCESS',
  DELETE_CAPACITYCONTRACT_FAILURE: 'DELETE_CAPACITYCONTRACT_FAILURE',
};
