import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ConsignmentFormPage from '@components/consignment/crud/ConsignmentFormPage.js';

import * as fileApi from '@api/fileApi';

import * as businessActions from '@actions/businessActions';
import * as consignmentActions from '@actions/consignmentActions';
import * as navigationActions from '@actions/navigationActions';

class UpdateConsignmentContainer extends PureComponent {
  componentDidMount() {
    const { changeValue, ownProps } = this.props;
    const { consignment } = ownProps;

    changeValue('formConsignment', consignment);
  }
  render() {
    const {
      t,
      changeValue,
      updateConsignment,
      searchBusinesses,
      callback,
      ...props
    } = this.props;
    const { popStack } = this.props;

    return (
      <ConsignmentFormPage
        {...props}
        headerName={t('consignment.update')}
        onChange={(consignment) =>
          updateConsignment(consignment).then((response) => {
            callback?.(response);
          })
        }
        searchBusinesses={(searchParameters, from, amount) =>
          searchBusinesses(searchParameters, from, amount)
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,
    currencies: state.platform.currencies,
    isFetchingCurrencies: state.platform.isFetchingCurrencies,
    ...state.business,
    ...state.consignment,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateConsignment: (consignment) =>
      dispatch(consignmentActions.updateConsignment(consignment)),
    changeValue: (name, value) =>
      dispatch(consignmentActions.changeValue(name, value)),

    searchBusinesses: (searchParameters, from, amount) =>
      dispatch(
        businessActions.searchBusinesses(searchParameters, from, amount)
      ),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateConsignmentContainer));
