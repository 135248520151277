export const messageTemplateConstants = {
  MESSAGE_TEMPLATE_CHANGE_VALUE: 'MESSAGE_TEMPLATE_CHANGE_VALUE',

  GET_MESSAGE_TEMPLATE_STARTED: 'GET_MESSAGE_TEMPLATE_STARTED',
  GET_MESSAGE_TEMPLATE_SUCCESS: 'GET_MESSAGE_TEMPLATE_SUCCESS',
  GET_MESSAGE_TEMPLATE_FAILURE: 'GET_MESSAGE_TEMPLATE_FAILURE',

  GET_MESSAGE_TEMPLATES_STARTED: 'GET_MESSAGE_TEMPLATES_STARTED',
  GET_MESSAGE_TEMPLATES_SUCCESS: 'GET_MESSAGE_TEMPLATES_SUCCESS',
  GET_MESSAGE_TEMPLATES_FAILURE: 'GET_MESSAGE_TEMPLATES_FAILURE',

  SEARCH_MESSAGE_TEMPLATES_STARTED: 'SEARCH_MESSAGE_TEMPLATES_STARTED',
  SEARCH_MESSAGE_TEMPLATES_SUCCESS: 'SEARCH_MESSAGE_TEMPLATES_SUCCESS',
  SEARCH_MESSAGE_TEMPLATES_FAILURE: 'SEARCH_MESSAGE_TEMPLATES_FAILURE',

  CREATING_MESSAGE_TEMPLATE_STARTED: 'CREATING_MESSAGE_TEMPLATE_STARTED',
  CREATING_MESSAGE_TEMPLATE_SUCCESS: 'CREATING_MESSAGE_TEMPLATE_SUCCESS',
  CREATING_MESSAGE_TEMPLATE_FAILURE: 'CREATING_MESSAGE_TEMPLATE_FAILURE',

  UPDATING_MESSAGE_TEMPLATE_STARTED: 'UPDATING_MESSAGE_TEMPLATE_STARTED',
  UPDATING_MESSAGE_TEMPLATE_SUCCESS: 'UPDATING_MESSAGE_TEMPLATE_SUCCESS',
  UPDATING_MESSAGE_TEMPLATE_FAILURE: 'UPDATING_MESSAGE_TEMPLATE_FAILURE',

  DELETE_MESSAGE_TEMPLATE_STARTED: 'DELETE_MESSAGE_TEMPLATE_STARTED',
  DELETE_MESSAGE_TEMPLATE_SUCCESS: 'DELETE_MESSAGE_TEMPLATE_SUCCESS',
  DELETE_MESSAGE_TEMPLATE_FAILURE: 'DELETE_MESSAGE_TEMPLATE_FAILURE',
};
