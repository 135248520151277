import { fieldConstants } from '@constants';

import { searchFields } from '@api/tqlApi';
import { getFields } from '@api/tqlApi';

/**
 * Fetching fields
 */
export const startGetFields = () => ({
  type: fieldConstants.GET_FIELD_STARTED,
});

export const getFieldsSuccess = (result) => ({
  type: fieldConstants.GET_FIELD_SUCCESS,
  ...result,
});

export const getFieldsFailure = (error) => ({
  type: fieldConstants.GET_FIELD_FAILURE,
  ...error,
});

export const getFieldsRequest = (entityType) => {
  return (dispatch) => {
    dispatch(startGetFields());

    const promise = getFields(entityType);

    promise
      .then((result) => {
        dispatch(getFieldsSuccess(result));
      })
      .catch((error) => {
        dispatch(getFieldsFailure(error));
      });

    return promise;
  };
};

/**
 * Searching fields
 */

export const searchFieldsRequest = (entityType, keyword, limit) => {
  return (dispatch) => {
    dispatch(startGetFields());

    const promise = searchFields(entityType, keyword, limit);

    promise
      .then((result) => {
        dispatch(getFieldsSuccess(result));
      })
      .catch((error) => {
        dispatch(getFieldsFailure(error));
      });

    return promise;
  };
};
