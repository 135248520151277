import { renderRoutes } from '@routes';
import { lazy } from 'react';

const DashboardContainer = lazy(() => import('@containers/dashboard/DashboardContainer'));

const routes = [
  {
    paths: ['', '/:tab', '/:tab/:subtab'],
    component: DashboardContainer,
    type: 'portal',
  },
];

const DashboardRoutes = (props) => renderRoutes(props, routes);
export default DashboardRoutes;
