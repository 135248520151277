export const documentConstants = {
  DOCUMENT_CHANGE_VALUE: 'DOCUMENT_CHANGE_VALUE',

  GET_DOCUMENT_STARTED: 'GET_DOCUMENT_STARTED',
  GET_DOCUMENT_SUCCESS: 'GET_DOCUMENT_SUCCESS',
  GET_DOCUMENT_FAILURE: 'GET_DOCUMENT_FAILURE',

  GET_DOCUMENTS_STARTED: 'GET_DOCUMENTS_STARTED',
  GET_DOCUMENTS_SUCCESS: 'GET_DOCUMENTS_SUCCESS',
  GET_DOCUMENTS_FAILURE: 'GET_DOCUMENTS_FAILURE',

  SEARCH_DOCUMENTS_STARTED: 'SEARCH_DOCUMENTS_STARTED',
  SEARCH_DOCUMENTS_SUCCESS: 'SEARCH_DOCUMENTS_SUCCESS',
  SEARCH_DOCUMENTS_FAILURE: 'SEARCH_DOCUMENTS_FAILURE',

  CREATING_DOCUMENT_STARTED: 'CREATING_DOCUMENT_STARTED',
  CREATING_DOCUMENT_SUCCESS: 'CREATING_DOCUMENT_SUCCESS',
  CREATING_DOCUMENT_FAILURE: 'CREATING_DOCUMENT_FAILURE',

  UPDATING_DOCUMENT_STARTED: 'UPDATING_DOCUMENT_STARTED',
  UPDATING_DOCUMENT_SUCCESS: 'UPDATING_DOCUMENT_SUCCESS',
  UPDATING_DOCUMENT_FAILURE: 'UPDATING_DOCUMENT_FAILURE',

  DELETE_DOCUMENT_STARTED: 'DELETE_DOCUMENT_STARTED',
  DELETE_DOCUMENT_SUCCESS: 'DELETE_DOCUMENT_SUCCESS',
  DELETE_DOCUMENT_FAILURE: 'DELETE_DOCUMENT_FAILURE',
};
