import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

class DeveloperDashboardPage extends Component {
  render() {
    const { t, user } = this.props;
    return (
      <div className="wrapper top">
        <div className="container">
          <h1>
            {t('welcome.header')} {user.firstName}
          </h1>
          <h3></h3>
        </div>
      </div>
    );
  }
}
export default withTranslation('translation')(DeveloperDashboardPage);
