import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import VehicleDetailView from '@components/vehicle/VehicleDetailView';

import Loader from '@uicomponents/Loader';

import * as chatActions from '@actions/chatActions';
import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class VehicleDetailContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getExtendedVehicle, getChat } = this.props;
    if (ownProps.vehicle) {
      changeValue('vehicle', ownProps.vehicle);
      if (ownProps.vehicle.chat) getChat(ownProps.vehicle.chat.id);
    } else if (ownProps.vehicleId) {
      getExtendedVehicle(ownProps.vehicleId).then((data) => {
        changeValue('vehicle', data.extendedVehicle);
        if (data.extendedVehicle.chat) getChat(data.extendedVehicle.chat.id);
      });
    }
  }

  render() {
    const { vehicle, ownProps } = this.props;

    if (
      vehicle?.id != ownProps.vehicle?.id &&
      vehicle?.id != ownProps.vehicleId
    ) {
      return <Loader />;
    }
    return <VehicleDetailView {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    ...state.vehicle,
    chatIsFetching: state.chat.chatIsFetching,
    chat: state.chat.chat,
    standalone: ownProps.standalone || false,
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(vehicleActions.changeValue(name, value)),

    getExtendedVehicle: (id) => dispatch(vehicleActions.getExtendedVehicle(id)),
    deleteVehicle: (vehicle) => dispatch(vehicleActions.deleteVehicle(vehicle)),

    getChat: (id) => dispatch(chatActions.getChat(id)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleDetailContainer));
