import React, { Component } from 'react';

export default class PopOver extends Component {
  render() {
    const { isActive, children, onClose, className } = this.props;

    return (
      <div className={`popover ${isActive && 'active'} ${className}`}>
        <div
          className="background"
          onClick={(e) => {
            e.stopPropagation();
            onClose && onClose();
          }}
        ></div>
        <div className="children">{children}</div>
      </div>
    );
  }
}
