import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';

import jsonBeautify from 'json-beautify';

import Switch from '@uicomponents/Switch';

import FieldMappingInput from './FieldMappingInput';
import FormInput from '@uiinputs/FormInput';

class FieldMappingsInput extends PureComponent {
  static defaultProps = {
    value: [],
    disabledIncoming: false,
    disabledOutgoing: false,
    topLevel: false,
  };

  state = {
    jsonMode: false,
    json: {},
  };

  addFieldSelector = () => {
    const { value } = this.props;

    this.onChange({
      fields: [
        ...value,
        {
          none: Math.random().toString(36).substring(7),
          sequenceNr: value.length + 1,
        },
      ],
    });
  };

  onChange = (valueObject) => {
    const { onChange } = this.props;

    onChange && onChange(valueObject);
  };

  render() {
    const { t, key, value, topLevel, disabledIncoming, disabledOutgoing } = this.props;
    const { jsonMode, json } = this.state;
    const fields = value;

    return (
      <>
        {!jsonMode && (
          <div className="scrollable scrollable__x">
            {fields
              .sort((a, b) => a.sequenceNr - b.sequenceNr)
              .map((field, index) => (
                <FieldMappingInput
                  key={`${key}-field-${field.sequenceNr}-${index}-`}
                  value={field}
                  disabledIncoming={disabledIncoming}
                  disabledOutgoing={disabledOutgoing}
                  onChange={(newFieldSelector) => {
                    const newFieldSelectors = [...fields];
                    newFieldSelectors[index] = newFieldSelector;

                    this.onChange({
                      fields: newFieldSelectors,
                    });
                  }}
                  onDelete={(e) => {
                    const newFieldSelectors = [...fields];
                    newFieldSelectors.splice(index, 1);

                    this.onChange({
                      fields: newFieldSelectors,
                    });
                  }}
                />
              ))}
          </div>
        )}

        {!disabledIncoming && (
          <div className="input-group flex-container">
            <div className={`input-group one left`}>
              <button type="button" onClick={(e) => this.addFieldSelector(e)}>
                {t('form.label.addField')}
              </button>
            </div>
            {topLevel && (
              <div className="input-group one right">
                <div>{t('form.label.json')}</div>
                <Switch
                  checked={jsonMode}
                  onChange={(e) => {
                    this.setState({
                      jsonMode: !jsonMode,
                      json: JSON.stringify(fields),
                    });

                    this.onChange({
                      fields: jsonMode ? JSON.parse(json) : fields,
                    });
                  }}
                />
              </div>
            )}
          </div>
        )}
        {topLevel && jsonMode ? (
          <div className="input-container">
            <FormInput
              type="textarea"
              value={jsonBeautify(JSON.parse(json), null, 2, 100)}
              onChange={(e) => {
                e.preventDefault();
                this.setState({
                  json: e.target.value,
                });
              }}
            />
          </div>
        ) : null}
      </>
    );
  }
}

export default withTranslation('translation')(FieldMappingsInput);
