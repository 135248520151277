/* Dependencies */
import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import React, { Component } from 'react';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import 'babel-polyfill';
import _ from 'lodash';

import IndexContainer from './IndexContainer';
import SocketWrapper from './SocketWrapper';
import configureI18n from './i18n';
// all CSS being loaded
import './sass/index.sass';
import configureStore from './state/store/configureStore.js';

import '@webscopeio/react-textarea-autocomplete/style.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'shepherd.js/dist/css/shepherd.css';

/**
 *    The base instance of TransportialOTMS
 **/
export const store = configureStore();
export const i18n = configureI18n;

const siteId = 3799191;
const hotjarVersion = 6;

export default class TransportialOTMS extends Component {
  componentDidMount() {
    //hotjar & sentry
    if (process.env.REACT_APP_MODE === 'production') {
      Hotjar.init(siteId, hotjarVersion);

      Sentry.init({
        dsn: 'https://ce26fd3220cbf5d54bc2c00640a513b3@o1173807.ingest.us.sentry.io/4507605047705600',
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/test.api.otms.transportial\.com\/api/,
          /^https:\/\/api.otms.transportial\.com\/api/,
        ],
        // Session Replay
        replaysSessionSampleRate: 0.1, 
        replaysOnErrorSampleRate: 1.0,
        environment: process.env.REACT_APP_MODE,
      });
    }

    // window identifier
    if (!window.name) window.name = Math.random().toString(36);
  }

  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <SocketWrapper Page={() => <IndexContainer {...this.props} />} />
        </BrowserRouter>
      </Provider>
    );
  }
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .then((registration) => {})
    .catch((err) => {
      console.log('Service worker registration failed, error:', err);
    });
}

const root = createRoot(document.getElementById('transportial'));
root.render(<TransportialOTMS />);
