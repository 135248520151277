import { pricingVariableIndexConstants } from '@constants/pricingVariableIndex.constants';

const initialState = {
  isFetchingPricingVariableIndex: false,
  formPricingVariableIndex: null,
  pricingVariableIndex: null,

  isFetchingPricingVariableIndexes: false,
  isFetchingPricingVariableIndexesFrom: 0,
  pricingVariableIndexesTotalResults: 0,
  pricingVariableIndexesLoaded: 0,
  pricingVariableIndexes: [],
};

export default function pricingVariableIndex(state = initialState, action) {
  switch (action.type) {
    case pricingVariableIndexConstants.PRICINGVARIABLEINDEX_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_STARTED:
      return { ...state, isFetchingPricingVariableIndex: true };
    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: null,
        error: action.message || '',
      };
    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: action.pricingVariableIndex,
      };

    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_STARTED:
      return {
        ...state,
        isFetchingPricingVariableIndexes: true,
        isFetchingPricingVariableIndexesFrom: action.from || 0,
      };
    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndexes: false,
        pricingVariableIndexes: [],
        error: action.message || '',
      };
    case pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndexes: false,
        pricingVariableIndexes:
          action.from > 0
            ? [...state.pricingVariableIndexes, ...action.pricingVariableIndexes]
            : action.pricingVariableIndexes,
        pricingVariableIndexesLoaded:
          action.from > 0
            ? state.pricingVariableIndexes.length + action.pricingVariableIndexes.length
            : action.pricingVariableIndexes.length,
        pricingVariableIndexesTotalResults: action.totalResults || 0,
      };

    case pricingVariableIndexConstants.SEARCH_PRICINGVARIABLEINDEXES_STARTED:
      return {
        ...state,
        isFetchingPricingVariableIndexes: true,
        isFetchingPricingVariableIndexesFrom: action.from || 0,
      };
    case pricingVariableIndexConstants.SEARCH_PRICINGVARIABLEINDEXES_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndexes: false,
        pricingVariableIndexes: [],
        error: action.message || '',
      };
    case pricingVariableIndexConstants.SEARCH_PRICINGVARIABLEINDEXES_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndexes: false,
        pricingVariableIndexes:
          action.from > 0
            ? [...state.pricingVariableIndexes, ...action.pricingVariableIndexes]
            : action.pricingVariableIndexes,
        pricingVariableIndexesLoaded:
          action.from > 0
            ? state.pricingVariableIndexes.length + action.pricingVariableIndexes.length
            : action.pricingVariableIndexes.length,
        pricingVariableIndexesTotalResults: action.totalResults || 0,
      };

    case pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_STARTED:
      return { ...state, isFetchingPricingVariableIndex: true };
    case pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: null,
        error: action.message || '',
      };
    case pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: action.pricingVariableIndex,
        pricingVariableIndexes: [action.pricingVariableIndex, ...state.pricingVariableIndexes],
      };

    case pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_STARTED:
      return { ...state, isFetchingPricingVariableIndex: true };
    case pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: null,
        error: action.message || '',
      };
    case pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: action.pricingVariableIndex,
        pricingVariableIndexes: [...state.pricingVariableIndexes].map((pricingVariableIndex) => {
          if (action.pricingVariableIndex.id === pricingVariableIndex.id) {
            return action.pricingVariableIndex;
          }
          return pricingVariableIndex;
        }),
      };

    case pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_STARTED:
      return { ...state, isFetchingPricingVariableIndex: true };
    case pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_FAILURE:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: null,
        message: action.message || '',
      };
    case pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_SUCCESS:
      return {
        ...state,
        isFetchingPricingVariableIndex: false,
        pricingVariableIndex: action.pricingVariableIndex,
        pricingVariableIndexes: [...state.pricingVariableIndexes].filter(
          (pricingVariableIndex) => action.pricingVariableIndex.id !== pricingVariableIndex.id
        ),
      };
    default:
      return state;
  }
}
