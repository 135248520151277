import { boardComputerConstants } from '@constants/boardComputer.constants';

const initialState = {
  isFetchingBoardComputer: false,
  formBoardComputer: null,
  boardComputer: null,

  isFetchingBoardComputers: false,
  boardComputersTotalResults: 0,
  boardComputersLoaded: 0,
  boardComputersFrom: 0,
  boardComputers: [],
};

export default function boardComputer(state = initialState, action) {
  switch (action.type) {
    case boardComputerConstants.BOARD_COMPUTER_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case boardComputerConstants.GET_BOARD_COMPUTER_STARTED:
      return { ...state, isFetchingBoardComputer: true };
    case boardComputerConstants.GET_BOARD_COMPUTER_FAILURE:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: null,
        error: action.message || '',
      };
    case boardComputerConstants.GET_BOARD_COMPUTER_SUCCESS:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: action.boardComputer,
      };

    case boardComputerConstants.SEARCH_BOARD_COMPUTERS_STARTED:
    case boardComputerConstants.GET_BOARD_COMPUTERS_STARTED:
      return {
        ...state,
        isFetchingBoardComputers: true,
        boardComputersFrom: action.from || 0,
      };
    case boardComputerConstants.SEARCH_BOARD_COMPUTERS_FAILURE:
    case boardComputerConstants.GET_BOARD_COMPUTERS_FAILURE:
      return {
        ...state,
        isFetchingBoardComputers: false,
        boardComputers: [],
        error: action.message || '',
      };
    case boardComputerConstants.SEARCH_BOARD_COMPUTERS_SUCCESS:
    case boardComputerConstants.GET_BOARD_COMPUTERS_SUCCESS:
      return {
        ...state,
        isFetchingBoardComputers: false,
        boardComputers:
          action.from > 0
            ? [...state.boardComputers, ...action.boardComputers]
            : action.boardComputers,
        boardComputersLoaded:
          action.from > 0
            ? state.boardComputers.length + action.boardComputers.length
            : action.boardComputers.length,
        boardComputersTotalResults: action.totalResults || 0,
      };

    case boardComputerConstants.CREATING_BOARD_COMPUTER_STARTED:
      return { ...state, isFetchingBoardComputer: true };
    case boardComputerConstants.CREATING_BOARD_COMPUTER_FAILURE:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: null,
        error: action.message || '',
      };
    case boardComputerConstants.CREATING_BOARD_COMPUTER_SUCCESS:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: action.boardComputer,
        boardComputers: [action.boardComputer, ...state.boardComputers],
      };

    case boardComputerConstants.UPDATING_BOARD_COMPUTER_STARTED:
      return { ...state, isFetchingBoardComputer: true };
    case boardComputerConstants.UPDATING_BOARD_COMPUTER_FAILURE:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: null,
        error: action.message || '',
      };
    case boardComputerConstants.UPDATING_BOARD_COMPUTER_SUCCESS:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: action.boardComputer,
        boardComputers: [...state.boardComputers].map((boardComputer) => {
          if (action.boardComputer.id === boardComputer.id) {
            return action.boardComputer;
          }
          return boardComputer;
        }),
      };

    case boardComputerConstants.DELETE_BOARD_COMPUTER_STARTED:
      return { ...state, isFetchingBoardComputer: true };
    case boardComputerConstants.DELETE_BOARD_COMPUTER_FAILURE:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: null,
        message: action.message || '',
      };
    case boardComputerConstants.DELETE_BOARD_COMPUTER_SUCCESS:
      return {
        ...state,
        isFetchingBoardComputer: false,
        boardComputer: action.boardComputer,
        boardComputers: [...state.boardComputers].filter(
          (boardComputer) => action.boardComputer.id !== boardComputer.id
        ),
      };
    default:
      return state;
  }
}
