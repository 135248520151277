import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import BoardComputer from '@models/boardComputer/BoardComputer';

import BoardComputerForm from './BoardComputerForm';

export default class CreateBoardComputerPage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newBoardComputer = new BoardComputer('latLonPointGeoReference');
    newBoardComputer.name = targetName || '';
    onChange('formBoardComputer', newBoardComputer);
  }

  render() {
    const {
      t,
      formBoardComputer,
      isFetchingBoardComputer,
      createBoardComputer,
    } = this.props;

    if (!formBoardComputer || isFetchingBoardComputer) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('boardComputer.new')}</h1>
        <BoardComputerForm
          {...this.props}
          key={formBoardComputer.id}
          onSubmit={(boardComputer) => createBoardComputer(boardComputer)}
        />
      </AsideView>
    );
  }
}
