import { administrationConstants, platformConstants } from '../constants';

const initialState = {
  isFetchingAdministrations: false,
  isFetchingAdministrationsFrom: 0,
  administrationsLoaded: 0,
  administrationsTotalResults: 0,
  administrations: [],

  isFetchingAdministration: false,
  administration: null,

  isFetchingTransactions: false,
  isFetchingTransactionsByDate: false,
  transactionsLoaded: 0,
  transactionsLoadedFrom: 0,
  transactionsTotalResults: 0,
  transactions: [],
  transactionsByDate: [],

  isFetchingTransaction: false,
  transaction: null,

  isFetchingInvoices: false,
  invoicesLoaded: 0,
  invoicesLoadedFrom: 0,
  invoicesTotalResults: 0,
  invoices: [],
  invoiceForm: null,

  isFetchingInvoice: false,
  invoice: null,

  isFetchingQuotes: false,
  quotesLoaded: 0,
  quotesLoadedFrom: 0,
  quotesTotalResults: 0,
  quotes: [],

  isFetchingQuote: false,
  quote: null,

  isFetchingPayouts: false,
  payoutsLoaded: 0,
  payoutsLoadedFrom: 0,
  payoutsTotalResults: 0,
  payouts: [],

  isFetchingPayout: false,
  payout: null,

  isFetchingCurrencies: false,
  currencies: [],
};

export default function administration(state = initialState, action) {
  switch (action.type) {
    case administrationConstants.ADMINISTRATION_CHANGE_VALUE:
      state = {
        ...state,
        error: '',
        [action.name]: action.value,
      };
      return state;

    case administrationConstants.GET_ADMINISTRATION_STARTED:
      return { ...state, isFetchingAdministration: true };
    case administrationConstants.GET_ADMINISTRATION_FAILURE:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: null,
      };
    case administrationConstants.GET_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: action.administration,
      };

    case administrationConstants.SEARCH_ADMINISTRATIONS_STARTED:
    case administrationConstants.GET_ADMINISTRATIONS_STARTED:
      return {
        ...state,
        isFetchingAdministrations: true,
        isFetchingAdministrationsFrom: action.from || 0,
      };
    case administrationConstants.SEARCH_ADMINISTRATIONS_FAILURE:
    case administrationConstants.GET_ADMINISTRATIONS_FAILURE:
      return {
        ...state,
        isFetchingAdministrations: false,
        administrations: [],
        error: action.message || '',
      };
    case administrationConstants.SEARCH_ADMINISTRATIONS_SUCCESS:
    case administrationConstants.GET_ADMINISTRATIONS_SUCCESS:
      return {
        ...state,
        isFetchingAdministrations: false,
        administrations:
          action.from > 0
            ? [...state.administrations, ...action.administrations]
            : action.administrations,
        administrationsLoaded:
          action.from > 0
            ? state.administrations.length + action.administrations.length
            : action.administrations.length,
        administrationsTotalResults: action.totalResults || 0,
      };

    case administrationConstants.CREATING_ADMINISTRATION_STARTED:
      return { ...state, isFetchingAdministration: true };
    case administrationConstants.CREATING_ADMINISTRATION_FAILURE:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: null,
        error: action.message || '',
      };
    case administrationConstants.CREATING_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: action.administration,
        administrations: [action.administration, ...state.administrations],
      };

    case administrationConstants.UPDATING_ADMINISTRATION_STARTED:
      return { ...state, isFetchingAdministration: true };
    case administrationConstants.UPDATING_ADMINISTRATION_FAILURE:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: null,
        error: action.message || '',
      };
    case administrationConstants.UPDATING_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: action.administration,
        administrations: [...state.administrations].map((administration) => {
          if (action.administration.id === administration.id) {
            return action.administration;
          }
          return administration;
        }),
      };

    case administrationConstants.DELETE_ADMINISTRATION_STARTED:
      return { ...state, isFetchingAdministration: true };
    case administrationConstants.DELETE_ADMINISTRATION_FAILURE:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: null,
        message: action.message || '',
      };
    case administrationConstants.DELETE_ADMINISTRATION_SUCCESS:
      return {
        ...state,
        isFetchingAdministration: false,
        administration: action.administration,
        administrations: [...state.administrations].filter(
          (administration) => action.administration.id !== administration.id
        ),
      };

    case administrationConstants.GET_TRANSACTIONS_STARTED:
      return { ...state, isFetchingTransactions: true };
    case administrationConstants.GET_TRANSACTIONS_FAILURE:
      return { ...state, isFetchingTransactions: false, transactions: [] };
    case administrationConstants.GET_TRANSACTIONS_SUCCESS:
      state = {
        ...state,
        isFetchingTransactions: false,
        transactions:
          action.from > 0 ? [...state.transactions, ...action.transactions] : action.transactions,
        transactionsLoaded:
          action.from > 0
            ? state.transactions.length + action.transactions.length
            : action.transactions.length,
        transactionsTotalResults: action.totalResults || 0,
      };
      return state;

    case administrationConstants.GET_TRANSACTIONS_BY_DATE_STARTED:
      return { ...state, isFetchingTransactionsByDate: true };
    case administrationConstants.GET_TRANSACTIONS_BY_DATE_FAILURE:
      return {
        ...state,
        isFetchingTransactionsByDate: false,
        transactionsByDate: [],
        message: action.message || '',
      };
    case administrationConstants.GET_TRANSACTIONS_BY_DATE_SUCCESS:
      state = {
        ...state,
        isFetchingTransactionsByDate: false,
        transactionsByDate: action.transactions,
      };
      return state;

    case administrationConstants.CREATING_TRANSACTION_STARTED:
    case administrationConstants.UPDATING_TRANSACTION_STARTED:
      return { ...state, isFetching: true };
    case administrationConstants.CREATING_TRANSACTION_FAILURE:
    case administrationConstants.UPDATING_TRANSACTION_FAILURE:
      return { ...state, isFetching: false, transaction: null };
    case administrationConstants.CREATING_TRANSACTION_SUCCESS:
    case administrationConstants.UPDATING_TRANSACTION_SUCCESS:
      state = {
        ...state,
        isFetching: false,
        transaction: action.transaction,
      };
      return state;

    case administrationConstants.CREATING_CHARGE_STARTED:
    case administrationConstants.UPDATING_CHARGE_STARTED:
      return { ...state, isFetching: true };
    case administrationConstants.CREATING_CHARGE_FAILURE:
    case administrationConstants.UPDATING_CHARGE_FAILURE:
      return { ...state, isFetching: false, charge: null };
    case administrationConstants.CREATING_CHARGE_SUCCESS:
    case administrationConstants.UPDATING_CHARGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        charge: action.charge,
        source: action.source,
      };

    case administrationConstants.EXECUTE_CHARGE_STARTED:
      return { ...state, isFetching: true };
    case administrationConstants.EXECUTE_CHARGE_FAILURE:
      return { ...state, isFetching: false, charge: null };
    case administrationConstants.EXECUTE_CHARGE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        charge: action.charge,
      };

    case administrationConstants.GET_INVOICE_STARTED:
      return { ...state, isFetchingInvoice: true };
    case administrationConstants.GET_INVOICE_FAILURE:
      return { ...state, isFetchingInvoice: false, invoice: null };
    case administrationConstants.GET_INVOICE_SUCCESS:
      state = {
        ...state,
        isFetchingInvoice: false,
        invoice: action.invoice,
        invoiceForm: action.invoice,
      };
      return state;

    case administrationConstants.GET_INVOICES_STARTED:
      return {
        ...state,
        isFetchingInvoices: true,
        invoicesLoadedFrom: action.from || 0,
      };
    case administrationConstants.GET_INVOICES_FAILURE:
      return { ...state, isFetchingInvoices: false, invoices: [] };
    case administrationConstants.GET_INVOICES_SUCCESS:
      state = {
        ...state,
        isFetchingInvoices: false,
        invoices: action.from > 0 ? [...state.invoices, ...action.invoices] : action.invoices,
        invoicesLoaded:
          action.from > 0 ? state.invoices.length + action.invoices.length : action.invoices.length,
        invoicesTotalResults: action.totalResults || 0,
      };
      return state;

    case administrationConstants.CREATE_INVOICE_STARTED:
    case administrationConstants.UPDATE_INVOICE_STARTED:
      return { ...state, isFetchingInvoice: true };
    case administrationConstants.CREATE_INVOICE_FAILURE:
    case administrationConstants.UPDATE_INVOICE_FAILURE:
      return { ...state, isFetchingInvoice: false, invoice: null };

    case administrationConstants.CREATE_INVOICE_SUCCESS:
      return {
        ...state,
        isFetchingInvoice: false,
        invoices: [action.invoice, ...state.invoices],
        invoice: action.invoice,
      };

    case administrationConstants.UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        isFetchingInvoice: false,
        invoices: [...state.invoices].map((invoice) =>
          invoice.id === action.invoice.id ? action.invoice : invoice
        ),
        invoice: action.invoice,
      };

    case administrationConstants.GET_QUOTE_STARTED:
      return { ...state, isFetchingQuote: true };
    case administrationConstants.GET_QUOTE_FAILURE:
      return { ...state, isFetchingQuote: false, quote: null };
    case administrationConstants.GET_QUOTE_SUCCESS:
      state = {
        ...state,
        isFetchingQuote: false,
        quote: action.quote,
      };
      return state;

    case administrationConstants.GET_QUOTES_STARTED:
      return {
        ...state,
        isFetchingQuotes: true,
        quotesLoadedFrom: action.from || 0,
      };
    case administrationConstants.GET_QUOTES_FAILURE:
      return { ...state, isFetchingQuotes: false, quotes: [] };
    case administrationConstants.GET_QUOTES_SUCCESS:
      state = {
        ...state,
        isFetchingQuotes: false,
        quotes: action.from > 0 ? [...state.quotes, ...action.quotes] : action.quotes,
        quotesLoaded:
          action.from > 0 ? state.quotes.length + action.quotes.length : action.quotes.length,
        quotesTotalResults: action.totalResults || 0,
      };
      return state;

    case administrationConstants.CREATE_QUOTE_STARTED:
    case administrationConstants.UPDATE_QUOTE_STARTED:
      return { ...state, isFetchingQuote: true };
    case administrationConstants.CREATE_QUOTE_FAILURE:
    case administrationConstants.UPDATE_QUOTE_FAILURE:
      return { ...state, isFetchingQuote: false, quote: null };
    case administrationConstants.CREATE_QUOTE_SUCCESS:
      return {
        ...state,
        isFetchingQuote: false,
        quote: action.quote,
        quotes: [action.quote, ...state.quotes],
      };
    case administrationConstants.UPDATE_QUOTE_SUCCESS:
      return {
        ...state,
        isFetchingQuote: false,
        quote: action.quote,
        quotes: [...state.quotes].map((quote) =>
          quote.id === action.quote.id ? action.quote : quote
        ),
      };

    case administrationConstants.CREATING_PAYOUT_STARTED:
    case administrationConstants.UPDATING_PAYOUT_STARTED:
      return { ...state, isFetchingPayout: true };
    case administrationConstants.CREATING_PAYOUT_FAILURE:
    case administrationConstants.UPDATING_PAYOUT_FAILURE:
      return { ...state, isFetchingPayout: false, payout: null };
    case administrationConstants.CREATING_PAYOUT_SUCCESS:
    case administrationConstants.UPDATING_PAYOUT_SUCCESS:
      return {
        ...state,
        isFetchingPayout: false,
        payout: action.payout,
      };

    case administrationConstants.GET_PAYOUT_STARTED:
      return { ...state, isFetchingPayout: true };
    case administrationConstants.GET_PAYOUT_FAILURE:
      return { ...state, isFetchingPayout: false, payout: null };
    case administrationConstants.GET_PAYOUT_SUCCESS:
      state = {
        ...state,
        isFetchingPayout: false,
        payout: action.payout,
      };
      return state;

    case administrationConstants.GET_PAYOUTS_STARTED:
      return { ...state, isFetchingPayouts: true };
    case administrationConstants.GET_PAYOUTS_FAILURE:
      return { ...state, isFetchingPayouts: false, payouts: [] };
    case administrationConstants.GET_PAYOUTS_SUCCESS:
      state = {
        ...state,
        isFetchingPayouts: false,
        payouts: action.from > 0 ? [...state.payouts, ...action.payouts] : action.payouts,
        payoutsLoaded:
          action.from > 0 ? state.payouts.length + action.payouts.length : action.payouts.length,
        payoutsTotalResults: action.totalResults || 0,
      };
      return state;

    case administrationConstants.GET_CURRENCIES_STARTED:
      return { ...state, isFetchingCurrencies: true };
    case administrationConstants.GET_CURRENCIES_FAILURE:
      return { ...state, isFetchingCurrencies: false, currencies: [] };

    case administrationConstants.GET_CURRENCIES_SUCCESS:
      state = {
        ...state,
        isFetchingCurrencies: false,
        currencies: action.currencies || [],
      };
      return state;
    case platformConstants.GET_PLATFORM_SUCCESS:
      state = {
        ...state,
        isFetchingCurrencies: false,
        currencies: action.currencies || [],
        administrations: action.administrations || [],
        administration: action.administrations
          ? action.administrations.find((option) => option.primary === true)?.id ||
            action.administrations[0]?.id
          : null,
      };

      return state;
    default:
      return state;
  }
}
