import React, { Component } from 'react';

import SimpleReactValidator from 'simple-react-validator';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';
import FormInput from '@uiinputs/FormInput';

import Business from '@models/business/Business';

export default class CreateBusinessPage extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();

    this.state = {
      contactPerson: {},
    };
  }

  componentDidMount() {
    const { onChange, targetBusiness } = this.props;
    onChange('formBusiness', targetBusiness || new Business());
  }

  render() {
    const { contactPerson } = this.state;
    const { t, formBusiness, businessIsFetching, updateBusiness, callback, popStack } = this.props;

    if (!formBusiness || businessIsFetching) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1 className="no-margin-bottom">{t('contactPerson.new')}</h1>
        <div className="input-group no-margin-top">
          <div className="input-group half no-margin-top">
            <FormInput
              className="small"
              type="text"
              label="form.label.firstName"
              value={contactPerson?.firstName}
              onChange={(event) => {
                const newContactPerson = { ...contactPerson };
                newContactPerson.firstName = event.target.value;

                this.setState({ contactPerson: newContactPerson });
              }}
            />
          </div>
          <div className="input-group half no-margin-top">
            <FormInput
              className="small"
              type="text"
              label="form.label.lastName"
              value={contactPerson?.lastName}
              onChange={(event) => {
                const newContactPerson = { ...contactPerson };
                newContactPerson.lastName = event.target.value;

                this.setState({ contactPerson: newContactPerson });
              }}
            />
          </div>
        </div>
        <div>
          <FormInput
            className="small"
            type="email"
            required={true}
            label="form.label.email"
            value={contactPerson?.email}
            onChange={(event) => {
              const newContactPerson = { ...contactPerson };
              newContactPerson.email = event.target.value;

              this.setState({ contactPerson: newContactPerson });
            }}
          />
          {this.validator.message(t('form.label.email'), contactPerson.email, 'required')}
        </div>
        <div className="input-group no-margin-top">
          <div className="input-group half no-margin-top">
            <FormInput
              className="small"
              type="text"
              label="form.label.position"
              value={contactPerson?.position}
              onChange={(event) => {
                const newContactPerson = { ...contactPerson };
                newContactPerson.position = event.target.value;

                this.setState({ contactPerson: newContactPerson });
              }}
            />
          </div>
          <div className="input-group half no-margin-top">
            <FormInput
              className="small"
              type="number"
              label="form.label.phoneNumber"
              value={contactPerson?.phone}
              onChange={(event) => {
                const newContactPerson = { ...contactPerson };
                newContactPerson.phone = event.target.value;

                this.setState({ contactPerson: newContactPerson });
              }}
            />
          </div>
        </div>
        <div className="input-group right">
          <button
            type="button"
            disabled={!this.validator.allValid()}
            onClick={() => {
              const newBusiness = { ...formBusiness.business };
              const newContactPersons = [...(formBusiness.business.contactPersons || [])];
              newContactPersons.push(this.state.contactPerson);
              newBusiness.contactPersons = newContactPersons;
              updateBusiness(newBusiness).then((data) => {
                popStack?.();
                callback?.(
                  data?.business?.contactPersons
                    .map((association) => association.entity)
                    .find((person) => person.email === contactPerson.email),
                  data.business
                );
              });
            }}
          >
            {t('form.save')}
          </button>
        </div>
      </AsideView>
    );
  }
}
