import React, { PureComponent } from 'react';

import ReactSVG from 'react-svg';

import Loader from '@uicomponents/Loader';

export default class AsideAccount extends PureComponent {
  render() {
    const { user, accountBox, updateState } = this.props;
    if (user) {
      return (
        <div className="aside-account">
          <div className="d-flex gap-15">
            <div className="account-logo">
              <img
                src={user && user.avatar ? user.avatar.url : '/images/user.svg'}
                alt={`avatar ${user ? user.firstName : ''}`}
              />
            </div>
            <div>
              <div className="account-name">{`${user.firstName} ${user.lastName}`}</div>
              <div className="account-email">{user.email}</div>
            </div>
          </div>
          <div
            className="close-button"
            onClick={(e) => {
              e.preventDefault();
              updateState({
                accountBox: !accountBox,
                notificationBox: false,
                messagesBox: false,
              });
            }}
          >
            <ReactSVG src="/icons/cross.svg" />
          </div>
        </div>
      );
    }

    return <Loader />;
  }
}
