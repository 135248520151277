import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import { administrativeReferenceToString } from '@utils/locationUtils';
import { defaultDateTimeFormat } from '@utils/dateUtils';

class TripBreakdown extends Component {
  render() {
    const { t, trip, dateFormat } = this.props;
    if (!trip) return null;

    const { route } = trip;

    return (
      <div className="trip-breakdown" key={`trip-${trip.id}`}>
        <div className="trip-breakdown__header-row">
          <div className="trip-breakdown__column">
            {t('trip.breakdown.from')}
          </div>
          <div className="trip-breakdown__column">{t('trip.breakdown.to')}</div>
          <div className="trip-breakdown__column">
            {t('trip.breakdown.timeWindow')}
          </div>
          <div className="trip-breakdown__column">
            {t('trip.breakdown.deliverTime')}
          </div>
        </div>
        {route.actions
          ?.map((association) => association.entity)
          ?.filter((entity) => entity.type === 'move')
          ?.sort((a, b) => a.sequenceNr - b.sequenceNr)
          ?.map((action) => {
            const tripAction = trip.actions
              ?.map((ta) => ta.entity)
              .filter(
                (ta) =>
                  action &&
                  ta &&
                  ta.location &&
                  ta.location.entity &&
                  action.to &&
                  action.to.entity &&
                  ta.location.entity.id === action.to.entity.id
              )[0];

            let timeWindowStartTime = null;
            let timeWindowEndTime = null;
            if (tripAction) {
              timeWindowStartTime = tripAction.constraints
                .map((constraint) => constraint.entity)
                .find(
                  (constraint) =>
                    constraint?.value?.type === 'startDateTimeConstraint'
                );
              timeWindowEndTime = tripAction.constraints
                .map((constraint) => constraint.entity)
                .find(
                  (constraint) =>
                    constraint?.value?.type === 'endDateTimeConstraint'
                );
            }

            return (
              <div
                className={`trip-breakdown__row trip-breakdown__row--${action.lifeCycle.toLowerCase()}`}
                key={action.id}
              >
                <div className="trip-breakdown__column trip-breakdown__from">
                  {action.from
                    ? administrativeReferenceToString(
                        action.from.entity.administrativeReference,
                        true
                      )
                    : null}
                </div>
                <div className="trip-breakdown__column trip-breakdown__to">
                  {action.to
                    ? administrativeReferenceToString(
                        action.to.entity.administrativeReference,
                        true
                      )
                    : null}
                </div>
                <div className="trip-breakdown__column trip-breakdown__timewindow">
                  {timeWindowStartTime &&
                    `${defaultDateTimeFormat(
                      timeWindowStartTime?.value?.startDateTime, dateFormat
                    )}${
                      timeWindowEndTime ? ' - ' : ''
                    }`}
                  {timeWindowEndTime &&
                    defaultDateTimeFormat(timeWindowEndTime?.value?.endDateTime, dateFormat)}
                </div>
                <div className="trip-breakdown__column trip-breakdown__time">
                  {action.endTime ? (
                    defaultDateTimeFormat(action.endTime, dateFormat)
                  ) : action.eta ? (
                    <>
                      <b>{t("trip.eta")}</b>
                      {` - ${defaultDateTimeFormat(action.eta, dateFormat)}`}
                    </>
                  ) : (
                    t('trip.breakdown.eta.notYetCalculated')
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  }
}

export default withTranslation('translation')(TripBreakdown);
