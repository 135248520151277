import * as locationApi from '@api/locationApi';

import { locationConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: locationConstants.LOCATION_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching location
 */
export const startGetLocation = () => ({
  type: locationConstants.GET_LOCATION_STARTED,
});

export const getLocationSuccess = (result) => ({
  type: locationConstants.GET_LOCATION_SUCCESS,
  ...result,
});

export const getLocationFailure = (error) => ({
  type: locationConstants.GET_LOCATION_FAILURE,
  ...error,
});

export const getLocation = (id) => {
  return (dispatch) => {
    dispatch(startGetLocation());

    const promise = locationApi.get(id);

    promise
      .then((result) => {
        dispatch(getLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(getLocationFailure(error));
      });

    return promise;
  };
};

/**
 * Creating location
 */
export const changeCreateLocation = (name, value) => ({
  type: locationConstants.CREATING_LOCATION_CHANGE_VALUE,
  name,
  value,
});
export const startCreateLocation = () => ({
  type: locationConstants.CREATING_LOCATION_STARTED,
});

export const createLocationSuccess = (result) => ({
  type: locationConstants.CREATING_LOCATION_SUCCESS,
  ...result,
});

export const createLocationFailure = (error) => ({
  type: locationConstants.CREATING_LOCATION_FAILURE,
  ...error,
});

export const createLocation = (location) => {
  return (dispatch) => {
    dispatch(startCreateLocation());

    const promise = locationApi.create(location);

    promise
      .then((result) => {
        dispatch(createLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(createLocationFailure(error));
      });

    return promise;
  };
};

/**
 * Updating location
 */
export const changeUpdateLocation = (name, value) => ({
  type: locationConstants.UPDATING_LOCATION_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateLocation = () => ({
  type: locationConstants.UPDATING_LOCATION_STARTED,
});

export const updateLocationSuccess = (result) => ({
  type: locationConstants.UPDATING_LOCATION_SUCCESS,
  ...result,
});

export const updateLocationFailure = (error) => ({
  type: locationConstants.UPDATING_LOCATION_FAILURE,
  ...error,
});

export const updateLocation = (location) => {
  return (dispatch) => {
    dispatch(startUpdateLocation());

    const promise = locationApi.update(location);

    promise
      .then((result) => {
        dispatch(updateLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(updateLocationFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting location
 */
export const startDeleteLocation = () => ({
  type: locationConstants.DELETE_LOCATION_STARTED,
});

export const deleteLocationSuccess = (result) => ({
  type: locationConstants.DELETE_LOCATION_SUCCESS,
  ...result,
});

export const deleteLocationFailure = (error) => ({
  type: locationConstants.DELETE_LOCATION_FAILURE,
  ...error,
});
export const deleteLocation = (location) => {
  return (dispatch) => {
    dispatch(startDeleteLocation());

    const promise = locationApi.remove(location);

    promise
      .then((result) => {
        dispatch(deleteLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteLocationFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching locations
 */
export const startGetLocations = (from) => ({
  type: locationConstants.GET_LOCATIONS_STARTED,
  from: from,
});

export const getLocationsSuccess = (result, from) => ({
  type: locationConstants.GET_LOCATIONS_SUCCESS,
  from: from,
  ...result,
});

export const getLocationsFailure = (error) => ({
  type: locationConstants.GET_LOCATIONS_FAILURE,
  ...error,
});

export const getLocations = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetLocations(from));

    const promise = locationApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getLocationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getLocationsFailure(error));
      });

    return promise;
  };
};

export const searchLocations = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetLocations(from));

    const promise = locationApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getLocationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getLocationsFailure(error));
      });

    return promise;
  };
};
