import React, { PureComponent } from 'react';

import { connect } from 'react-redux';

import PopOverStack from '@uicomponents/PopOverStack';

import * as navigationActions from '@actions/navigationActions';

class PopOverStackContainer extends PureComponent {
  render() {
    return <PopOverStack {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    ...state.navigation,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    popPopOver: () => dispatch(navigationActions.popPopOver()),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopOverStackContainer);
