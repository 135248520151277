import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import moment from 'moment';

import Loader from '@uicomponents/Loader';
import MapPeek from '@uicomponents/MapPeek';
import NoData from '@uicomponents/NoData';
import { defaultDateTimeFormat } from '@utils/dateUtils';

class VehicleEventsView extends Component {
  handleScroll = (e) => {
    const { isFetchingEvents, eventsLoaded, eventsTotalResults } = this.props;
    const isAtBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.offsetHeight + 50;

    if (isAtBottom && !isFetchingEvents) {
      // fetch more
      if (eventsTotalResults > eventsLoaded) {
        this.load(eventsLoaded, 20);
      }
    }
  };

  load = (offset, amount) => {
    const { vehicle, getVehicleEvents } = this.props;
    getVehicleEvents(vehicle.id, offset, amount);
  };

  componentDidMount() {
    this.load(0, 30);
  }

  render() {
    const { t, isFetchingEvents, isFetchingEventsFrom, events, dateFormat } = this.props;

    if (isFetchingEvents && isFetchingEventsFrom === 0) return <Loader />;

    return (
      <div
        className="vehicle-events scrollable"
        onScroll={(e) => this.handleScroll(e)}
      >
        {events.length > 0 ? (
          <>
            {events.map((event) => (
              <div
                className="vehicle-events__event"
                key={`vehicle.event-${event.id}`}
              >
                <div className="vehicle-events__name">
                  {t(`eventName.${event.type}`)}{' '}
                  {event.name && `- ${event.name}`}
                  <span className="vehicle-events__type">
                    {event.lifeCycle}
                  </span>
                </div>
                {event.geoReference ? (
                  <div className="vehicle-events__location">
                    <span>
                      {event.geoReference.lat}, {event.geoReference.lon}
                    </span>
                    <MapPeek
                      direction="bottom"
                      latLonPointGeoReference={event.geoReference}
                    />
                  </div>
                ) : null}
                <div className="vehicle-events__date">
                  {defaultDateTimeFormat(event.createdAt, dateFormat)}
                </div>
              </div>
            ))}
            {isFetchingEvents && <Loader />}
          </>
        ) : (
          <div className="not-found-action-box">
            <div className="icon">
              <ReactSVG src="/icons/space.svg" />
            </div>
            <NoData>{t('vehicle.events.notFound')}</NoData>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleEventsView);
