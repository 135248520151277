export const transportOrderConstants = {
  TRANSPORT_ORDER_CHANGE_VALUE: 'TRANSPORT_ORDER_CHANGE_VALUE',

  GET_TRANSPORT_ORDER_STARTED: 'GET_TRANSPORT_ORDER_STARTED',
  GET_TRANSPORT_ORDER_SUCCESS: 'GET_TRANSPORT_ORDER_SUCCESS',
  GET_TRANSPORT_ORDER_FAILURE: 'GET_TRANSPORT_ORDER_FAILURE',

  GET_TRANSPORT_ORDERS_STARTED: 'GET_TRANSPORT_ORDERS_STARTED',
  GET_TRANSPORT_ORDERS_SUCCESS: 'GET_TRANSPORT_ORDERS_SUCCESS',
  GET_TRANSPORT_ORDERS_FAILURE: 'GET_TRANSPORT_ORDERS_FAILURE',

  GET_TRANSPORT_ORDERS_WATCHTOWER_STARTED:
    'GET_TRANSPORT_ORDERS_WATCHTOWER_STARTED',
  GET_TRANSPORT_ORDERS_WATCHTOWER_SUCCESS:
    'GET_TRANSPORT_ORDERS_WATCHTOWER_SUCCESS',
  GET_TRANSPORT_ORDERS_WATCHTOWER_FAILURE:
    'GET_TRANSPORT_ORDERS_WATCHTOWER_FAILURE',

  CREATING_TRANSPORT_ORDER_STARTED: 'CREATING_TRANSPORT_ORDER_STARTED',
  CREATING_TRANSPORT_ORDER_SUCCESS: 'CREATING_TRANSPORT_ORDER_SUCCESS',
  CREATING_TRANSPORT_ORDER_FAILURE: 'CREATING_TRANSPORT_ORDER_FAILURE',

  UPDATING_TRANSPORT_ORDER_STARTED: 'UPDATING_TRANSPORT_ORDER_STARTED',
  UPDATING_TRANSPORT_ORDER_SUCCESS: 'UPDATING_TRANSPORT_ORDER_SUCCESS',
  UPDATING_TRANSPORT_ORDER_FAILURE: 'UPDATING_TRANSPORT_ORDER_FAILURE',

  DELETE_TRANSPORT_ORDER_STARTED: 'DELETE_TRANSPORT_ORDER_STARTED',
  DELETE_TRANSPORT_ORDER_SUCCESS: 'DELETE_TRANSPORT_ORDER_SUCCESS',
  DELETE_TRANSPORT_ORDER_FAILURE: 'DELETE_TRANSPORT_ORDER_FAILURE',
};
