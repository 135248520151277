import * as teamApi from '@api/teamApi';

import { teamConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: teamConstants.TEAM_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching team
 */
export const startGetTeam = () => ({
  type: teamConstants.GET_TEAM_STARTED,
});

export const getTeamSuccess = (result) => ({
  type: teamConstants.GET_TEAM_SUCCESS,
  ...result,
});

export const getTeamFailure = (error) => ({
  type: teamConstants.GET_TEAM_FAILURE,
  ...error,
});

export const getTeam = (id) => {
  return (dispatch) => {
    dispatch(startGetTeam());

    const promise = teamApi.get(id);

    promise
      .then((result) => {
        dispatch(getTeamSuccess(result));
      })
      .catch((error) => {
        dispatch(getTeamFailure(error));
      });

    return promise;
  };
};

/**
 * Creating team
 */
export const startCreateTeam = () => ({
  type: teamConstants.CREATING_TEAM_STARTED,
});

export const createTeamSuccess = (result) => ({
  type: teamConstants.CREATING_TEAM_SUCCESS,
  ...result,
});

export const createTeamFailure = (error) => ({
  type: teamConstants.CREATING_TEAM_FAILURE,
  ...error,
});

export const createTeam = (team) => {
  return (dispatch) => {
    dispatch(startCreateTeam());

    const promise = teamApi.create(team);

    promise
      .then((result) => {
        dispatch(createTeamSuccess(result));
      })
      .catch((error) => {
        dispatch(createTeamFailure(error));
      });

    return promise;
  };
};

/**
 * Updating team
 */
export const startUpdateTeam = () => ({
  type: teamConstants.UPDATING_TEAM_STARTED,
});

export const updateTeamSuccess = (result) => ({
  type: teamConstants.UPDATING_TEAM_SUCCESS,
  ...result,
});

export const updateTeamFailure = (error) => ({
  type: teamConstants.UPDATING_TEAM_FAILURE,
  ...error,
});

export const updateTeam = (team) => {
  return (dispatch) => {
    dispatch(startUpdateTeam());

    const promise = teamApi.update(team);

    promise
      .then((result) => {
        dispatch(updateTeamSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTeamFailure(error));
      });

    return promise;
  };
};

/**
 * Delete team
 */
export const startDeleteTeam = () => ({
  type: teamConstants.DELETE_TEAM_STARTED,
});

export const deleteTeamSuccess = (result) => ({
  type: teamConstants.DELETE_TEAM_SUCCESS,
  ...result,
});

export const deleteTeamFailure = (error) => ({
  type: teamConstants.DELETE_TEAM_FAILURE,
  ...error,
});

export const deleteTeam = (team) => {
  return (dispatch) => {
    dispatch(startDeleteTeam());

    const promise = teamApi.remove(team);

    promise
      .then((result) => {
        dispatch(deleteTeamSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTeamFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching teams
 */
export const startGetTeams = (from) => ({
  type: teamConstants.GET_TEAMS_STARTED,
  from: from,
});

export const getTeamsSuccess = (result, from) => ({
  type: teamConstants.GET_TEAMS_SUCCESS,
  from: from,
  ...result,
});

export const getTeamsFailure = (error) => ({
  type: teamConstants.GET_TEAMS_FAILURE,
  ...error,
});

export const getTeams = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTeams(from));

    const promise = teamApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getTeamsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTeamsFailure(error));
      });

    return promise;
  };
};

export const searchTeams = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTeams(from));

    const promise = teamApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getTeamsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTeamsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching light teams
 */
export const startGetVehicleLightGroups = () => ({
  type: teamConstants.GET_VEHICLE_LIGHT_GROUPS_STARTED,
});

export const getVehicleLightGroupsSuccess = (result) => ({
  type: teamConstants.GET_VEHICLE_LIGHT_GROUPS_SUCCESS,
  ...result,
});

export const getVehicleLightGroupsFailure = (error) => ({
  type: teamConstants.GET_VEHICLE_LIGHT_GROUPS_FAILURE,
  ...error,
});

export const getVehicleLightGroups = () => {
  return (dispatch) => {
    dispatch(startGetVehicleLightGroups());

    const promise = teamApi.light();

    promise
      .then((result) => {
        dispatch(getVehicleLightGroupsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleLightGroupsFailure(error));
      });

    return promise;
  };
};
