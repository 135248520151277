import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Contract from '@models/contract/Contract';

import ContractForm from './ContractForm';

export default class CreateContractPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formContract', new Contract());
  }

  render() {
    const { t, formContract, isFetchingContract, createContract } = this.props;

    if (!formContract || isFetchingContract) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('contract.new')}</h1>
        <ContractForm
          {...this.props}
          key={formContract.id}
          onSubmit={(contract) => createContract(contract)}
        />
      </AsideView>
    );
  }
}
