import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/vehicleGroup/${id}`);
};

export const create = (vehicleGroup) => {
  return api.post(`/vehicleGroup`, vehicleGroup);
};

export const update = (vehicleGroup) => {
  return api.put(`/vehicleGroup`, vehicleGroup);
};

export const remove = (vehicleGroup) => {
  return api.delete(`/vehicleGroup/${vehicleGroup.id}`);
};

// Get and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/vehicleGroups/${from}/${amount}`);
};

export const light = () => {
  return api.get(`/vehicleGroups/light`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/vehicleGroups/${from}/${amount}`, searchParameters);
};
