export const fleetConstants = {
  FLEET_CHANGE_VALUE: 'FLEET_CHANGE_VALUE',

  GET_FLEET_STARTED: 'GET_FLEET_STARTED',
  GET_FLEET_SUCCESS: 'GET_FLEET_SUCCESS',
  GET_FLEET_FAILURE: 'GET_FLEET_FAILURE',

  GET_FLEETS_STARTED: 'GET_FLEETS_STARTED',
  GET_FLEETS_SUCCESS: 'GET_FLEETS_SUCCESS',
  GET_FLEETS_FAILURE: 'GET_FLEETS_FAILURE',

  GET_FLEETS_LIGHT_STARTED: 'GET_FLEETS_LIGHT_STARTED',
  GET_FLEETS_LIGHT_FAILURE: 'GET_FLEETS_LIGHT_FAILURE',
  GET_FLEETS_LIGHT_SUCCESS: 'GET_FLEETS_LIGHT_SUCCESS',

  SEARCH_FLEETS_STARTED: 'SEARCH_FLEETS_STARTED',
  SEARCH_FLEETS_SUCCESS: 'SEARCH_FLEETS_SUCCESS',
  SEARCH_FLEETS_FAILURE: 'SEARCH_FLEETS_FAILURE',

  CREATING_FLEET_STARTED: 'CREATING_FLEET_STARTED',
  CREATING_FLEET_SUCCESS: 'CREATING_FLEET_SUCCESS',
  CREATING_FLEET_FAILURE: 'CREATING_FLEET_FAILURE',

  UPDATING_FLEET_STARTED: 'UPDATING_FLEET_STARTED',
  UPDATING_FLEET_SUCCESS: 'UPDATING_FLEET_SUCCESS',
  UPDATING_FLEET_FAILURE: 'UPDATING_FLEET_FAILURE',

  DELETE_FLEET_STARTED: 'DELETE_FLEET_STARTED',
  DELETE_FLEET_SUCCESS: 'DELETE_FLEET_SUCCESS',
  DELETE_FLEET_FAILURE: 'DELETE_FLEET_FAILURE',
};
