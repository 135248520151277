import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import Switch from '@uicomponents/Switch';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class OpeningTimesInput extends Component {
  state = this.props.value ? this.props.value : {};

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleCopyPreviousDay = (currentDayIndex) => {
    if (currentDayIndex > 0) {
      const previousDay = days[currentDayIndex - 1];
      const currentDay = days[currentDayIndex];

      this.setState(
        {
          [currentDay]: {
            ...this.state[currentDay],
            ...this.state[previousDay],
          },
        },
        () => {
          this.props.onChange && this.props.onChange(this.state);
        }
      );
    }
  };

  render() {
    const { t, onChange } = this.props;
    return (
      <>
        {days.map((day, index) => {
          return (
            <div className="input-group mt-10 flex-contianer">
              <div className="input-group no-margin-top fifth">{t(`day.${day}`)}</div>
              <div className="input-group no-margin-top tenth">
                <Switch
                  checked={this.state[day]?.open ? true : false}
                  onChange={(e, newValue) => {
                    e.preventDefault();
                    this.setState(
                      {
                        [day]: { ...this.state[day], open: newValue },
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
              </div>
              <div className="input-group no-margin-top thirty">
                <input
                  type="time"
                  value={this.state[day]?.start}
                  disabled={!this.state[day]?.open}
                  onChange={(e) => {
                    this.setState(
                      {
                        [day]: { ...this.state[day], start: e.target.value },
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
                <label>{t(`form.label.openingTime`)}</label>
              </div>
              <div className="input-group no-margin-top thirty">
                <input
                  type="time"
                  value={this.state[day]?.end}
                  disabled={!this.state[day]?.open}
                  onChange={(e) => {
                    this.setState(
                      {
                        [day]: { ...this.state[day], end: e.target.value },
                      },
                      () => {
                        onChange && onChange(this.state);
                      }
                    );
                  }}
                />
                <label>{t(`form.label.closingTime`)}</label>
              </div>
              {index !== 0 && (
                <div
                  className="input-group no-margin-top tenth cursor-pointer d-flex justify-content-center my-10"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.handleCopyPreviousDay(index);
                  }}
                  title={t('form.copy')}
                >
                  <ReactSVG src="/icons/duplicate.svg" />
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  }
}

export default withTranslation('translation')(OpeningTimesInput);
