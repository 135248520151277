import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class LocationTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;

    const options = [
      { value: 'warehouse', label: t('locationType.warehouse') },
      { value: 'store', label: t('locationType.store') },
      {
        value: 'environmentalZone',
        label: t('locationType.environmentalZone'),
      },
      { value: 'restrictedArea', label: t('locationType.restrictedArea') },
      { value: 'customer', label: t('locationType.customer') },
      { value: 'parkingSpot', label: t('locationType.parkingLot') },
      { value: 'operationalBase', label: t('locationType.operationalBase') },
      { value: 'fuelStation', label: t('locationType.fuelStation') },
    ];

    return (
      <Select
        value={options.find((option) => option.value === value)}
        noOptionsMessage={() => t('noOptions')}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(LocationTypeSelector);
