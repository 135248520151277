import React, { Component } from 'react';

import CreateTransportOrderContainer from '@containers/transportOrder/crud/CreateTransportOrderContainer';

import AsideView from '@uicomponents/AsideView';
import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';

import * as transportOrderApi from '@api/transportOrderApi';
import Association from '@models/general/Association';
import TransportOrder from '@models/transportOrder/TransportOrder';

export default class CreateTransportOrderFromFilePage extends Component {
  state = {
    isConvertingTransportOrder: false,
    documents: [],
    transportOrders: [],
  };

  openTransportOrder = (index) => {
    const { addToStack, popStack, platform, t } = this.props;
    const { documents, transportOrders } = this.state;
    if (index < transportOrders.length) {
      const newTransportOrder = { ...transportOrders[index] };
      if (!newTransportOrder.deliveryTerms) {
        newTransportOrder.deliveryTerms = platform.planningSettings?.defaultDeliveryTerms;
      }
      newTransportOrder.documents = documents;

      this.setState({
        isConvertingTransportOrder: false,
      });
      addToStack({
        name: t('transportOrder.new'),
        component: (
          <CreateTransportOrderContainer
            targetTransportOrder={newTransportOrder}
            callback={() => {
              setTimeout(() => {
                this.openTransportOrder(index + 1);
              }, 1000);
            }}
            onNavigateTo={(url) => {
              popStack();
              history.push(url);
            }}
          />
        ),
      });
    } else {
      popStack();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { t, platform, addToStack } = this.props;
    const { documents } = this.state;
    this.setState({
      isConvertingTransportOrder: true,
    });

    transportOrderApi
      .fromDocuments({
        documents: documents.map((document) => document.entity.file.id),
      })
      .then((response) => {
        this.setState(
          {
            transportOrders: response.transportOrders,
          },
          () => {
            this.openTransportOrder(0);
          }
        );
      })
      .catch((exception) => {
        const newTransportOrder = new TransportOrder();
        newTransportOrder.deliveryTerms = platform.planningSettings?.defaultDeliveryTerms;
        newTransportOrder.documents = documents;

        this.setState({
          isConvertingTransportOrder: false,
        });
        addToStack({
          name: t('transportOrder.new'),
          component: (
            <CreateTransportOrderContainer
              targetTransportOrder={newTransportOrder}
              onNavigateTo={(url) => {
                popStack();
                history.push(url);
              }}
            />
          ),
        });
      });
  };

  render() {
    const { platform, t } = this.props;
    const { documents, isConvertingTransportOrder } = this.state;

    return (
      <AsideView>
        <h1>{t('transportOrder.new')}</h1>
        <p>{t('transportOrder.fromDocuments')}</p>
        {isConvertingTransportOrder ? (
          <Loader />
        ) : (
          <div
            className="step-form"
            key={documents.map((d) => `${d.entity.id}-${d.entity.nonce}`).join('-')}
          >
            <form className="form active no-padding-top" onSubmit={(e) => this.handleSubmit(e)}>
              <div className="input-container">
                <div className="input-group">
                  <Attachments
                    {...this.props}
                    key={documents
                      .map(
                        (d) =>
                          `${d.entity.id}-${d.entity.nonce}-${d.entity.file?.id}-${d.entity.file?.name}`
                      )
                      .join('-')}
                    files={documents.map((document) => {
                      return document.entity.file;
                    })}
                    onChange={(files) => {
                      const newDocuments = files.map((file) => {
                        const newFile = { ...file };
                        return new Association('inline', {
                          id: Math.random().toString(36).substring(7),
                          name: newFile.originalName || newFile.name,
                          mimeType: newFile.mimeType,
                          content: {
                            contentType: 'uri',
                            uri: newFile.url,
                          },
                          file: newFile,
                        });
                      });

                      this.setState({
                        documents: newDocuments,
                      });
                    }}
                  />
                </div>
                <div className="input-group more right">
                  <input
                    type="submit"
                    onClick={(e) => this.handleSubmit(e)}
                    value={t('form.create')}
                  />
                </div>
              </div>
            </form>
            <p>
              {t('transportOrder.fromDocumentsEmail')}:{' '}
              {`${platform.id}@${
                process.env.REACT_APP_API_URL.includes('test')
                  ? 'test.transportorder.otms.transportial.com'
                  : 'transportorder.otms.transportial.com'
              }`}
            </p>
          </div>
        )}
      </AsideView>
    );
  }
}
