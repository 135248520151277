import api from '@/api';

export const get = (id = '') => {
  return api.get(`/administration/${id}`);
};

export const create = (administration) => {
  return api.post(`/administration`, administration);
};
export const update = (administration) => {
  return api.put(`/administration`, administration);
};

export const remove = (administration) => {
  return api.delete(`/administration/${administration.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/administrations/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/administrations/${from}/${amount}`, searchParameters);
};

// Funds
export const hasFunds = (id = '', amount) => {
  return api.post(`/administration/${id}/hasFunds`, { amount });
};

/**
 * Charges
 */
export const createCharge = (charge) => {
  return api.post(`/charge`, charge);
};

export const updateCharge = (charge) => {
  return api.put(`/charge`, charge);
};

export const executeCharge = (charge) => {
  return api.post(`/charge/execute`, charge);
};

/**
 * Transactions
 */
export const getTransaction = (id) => {
  return api.get(`/transaction/${id}`);
};

export const getTransactions = (from, amount) => {
  return api.get(`/transactions/${from}/${amount}`);
};
export const getTransactionsByDate = (startDate, toDate) => {
  return api.get(
    `/transactions/date/${startDate.format('YYYY-MM-DD')}/${toDate.format('YYYY-MM-DD')}`
  );
};

export const getAdministrationTransactions = (administration, from, amount) => {
  return api.get(`/transactions/${administration.id}/${from}/${amount}`);
};

/**
 * Invoice CRUD
 */
export const getInvoice = (id) => {
  return api.get(`/invoice/${id}`);
};

export const createInvoice = (invoice) => {
  return api.post(`/invoice`, invoice);
};

export const updateInvoice = (invoice) => {
  return api.put(`/invoice`, invoice);
};

export const getInvoices = (administrationId, from, amount) => {
  return api.get(`/invoices/${administrationId}/${from}/${amount}`);
};

export const searchInvoices = (administrationId, searchParameters = {}, from, amount) => {
  return api.post(`/invoices/${administrationId}/${from}/${amount}`, searchParameters);
};
export const sendInvoice = (invoice) => {
  return api.post(`/invoice/${invoice.id}/send`);
};

export const deleteInvoice = (invoice) => {
  return api.delete(`/invoice/${invoice.id}`);
};

export const sendInvoiceReminder = (invoice) => {
  return api.post(`/invoice/${invoice.id}/send:reminder`);
};

/**
 * Quotes CRUD
 */
export const getQuote = (id) => {
  return api.get(`/quote/${id}`);
};
export const getPublicQuote = (id, shareCode) => {
  return api.get(`/quote:public/${id}/${shareCode}`);
};

export const createQuote = (quote) => {
  return api.post(`/quote`, quote);
};

export const updateQuote = (quote) => {
  return api.put(`/quote`, quote);
};

export const deleteQuote = (quote) => {
  return api.delete(`/quote/${quote.id}`);
};

export const getQuotes = (administrationId, from, amount = 30) => {
  return api.get(`/quotes/${administrationId}/${from}/${amount}`);
};

export const searchQuotes = (administrationId, searchParameters = {}, from, amount) => {
  return api.post(`/quotes/${administrationId}/${from}/${amount}`, searchParameters);
};

export const sendQuote = (quote) => {
  return api.post(`/quote/${quote.id}/send`);
};
export const acceptQuote = (quote) => {
  return api.post(`/quote/${quote.id}/accept`);
};
export const declineQuote = (quote, reason) => {
  return api.post(`/quote/${quote.id}/decline`, { reason });
};

export const publicAcceptQuote = (quote, shareCode) => {
  return api.post(`/quote:public/${quote.id}/accept/${shareCode}`);
};
export const publicDeclineQuote = (quote, shareCode, reason) => {
  return api.post(`/quote:public/${quote.id}/decline/${shareCode}`, { reason });
};

export const createInvoiceFromQuote = (quote) => {
  return api.post(`/quote/${quote.id}/create:invoice`);
};

/**
 * Payouts
 */
export const createPayout = (payout) => {
  return api.post(`/payout`, payout);
};

export const updatePayout = (payout) => {
  return api.put(`/payout`, payout);
};

export const getPayout = (id) => {
  return api.get(`/payout/${id}`);
};

export const getPayouts = (from, amount) => {
  return api.get(`/payouts/${from}/${amount}`);
};

// Rest
export const getCurrencies = () => {
  return api.get(`/currencies`);
};
