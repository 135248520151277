export default class ContactPerson {
  id;
  nonce;

  lastName;
  firstName;
  position;
  email;
  phone;

  updatedAt;
  createdAt;
  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.updatedAt = new Date();
  }
}
