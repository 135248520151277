import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import DashboardPage from '@components/dashboard/DashboardPage';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class DashboardContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <DashboardPage {...props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,

    vehiclesLocation: state.vehicle.vehiclesLocation,

    isFetchingAdministrations: state.administration.isFetchingAdministrations,
    administrations: state.administration.administrations,
    administration: state.administration.administration,

    filter: state.filter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehiclesLocation: () => dispatch(vehicleActions.getVehiclesLocation()),

    getAdministrations: (offset, amount) =>
      dispatch(administrationActions.getAdministrations(offset, amount)),
    changeAdministrationValue: (name, value) =>
      dispatch(administrationActions.changeValue(name, value)),
    getInvoices: (administrationId, from, to) =>
      dispatch(administrationActions.getInvoices(administrationId, from, to)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(DashboardContainer));
