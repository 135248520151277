import { scheduleRouteConstants } from '@constants/scheduleRoute.constants';

const initialState = {
  isFetchingScheduleRoute: false,
  formScheduleRoute: null,
  scheduleRoute: null,

  isFetchingScheduleRoutes: false,
  isFetchingScheduleRoutesFrom: 0,
  scheduleRoutesTotalResults: 0,
  scheduleRoutesLoaded: 0,
  scheduleRoutes: [],
};

export default function scheduleRoute(state = initialState, action) {
  switch (action.type) {
    case scheduleRouteConstants.SCHEDULEROUTE_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case scheduleRouteConstants.GET_SCHEDULEROUTE_STARTED:
      return { ...state, isFetchingScheduleRoute: true };
    case scheduleRouteConstants.GET_SCHEDULEROUTE_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        error: action.message || '',
      };
    case scheduleRouteConstants.GET_SCHEDULEROUTE_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        scheduleRoute: action.scheduleRoute,
      };

    case scheduleRouteConstants.GET_SCHEDULEROUTES_STARTED:
      return {
        ...state,
        isFetchingScheduleRoutes: true,
        isFetchingScheduleRoutesFrom: action.from || 0,
      };
    case scheduleRouteConstants.GET_SCHEDULEROUTES_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoutes: false,
        error: action.message || '',
      };
    case scheduleRouteConstants.GET_SCHEDULEROUTES_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoutes: false,
        scheduleRoutes:
          action.from > 0
            ? [...state.scheduleRoutes, ...action.scheduleRoutes]
            : action.scheduleRoutes,
        scheduleRoutesLoaded:
          action.from > 0
            ? state.scheduleRoutes.length + action.scheduleRoutes.length
            : action.scheduleRoutes.length,
        scheduleRoutesTotalResults: action.totalResults || 0,
      };

    case scheduleRouteConstants.SEARCH_SCHEDULEROUTES_STARTED:
      return {
        ...state,
        isFetchingScheduleRoutes: true,
        isFetchingScheduleRoutesFrom: action.from || 0,
      };
    case scheduleRouteConstants.SEARCH_SCHEDULEROUTES_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoutes: false,
        error: action.message || '',
      };
    case scheduleRouteConstants.SEARCH_SCHEDULEROUTES_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoutes: false,
        scheduleRoutes:
          action.from > 0
            ? [...state.scheduleRoutes, ...action.scheduleRoutes]
            : action.scheduleRoutes,
        scheduleRoutesLoaded:
          action.from > 0
            ? state.scheduleRoutes.length + action.scheduleRoutes.length
            : action.scheduleRoutes.length,
        scheduleRoutesTotalResults: action.totalResults || 0,
      };

    case scheduleRouteConstants.CREATING_SCHEDULEROUTE_STARTED:
      return { ...state, isFetchingScheduleRoute: true };
    case scheduleRouteConstants.CREATING_SCHEDULEROUTE_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        error: action.message || '',
      };
    case scheduleRouteConstants.CREATING_SCHEDULEROUTE_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        scheduleRoute: action.scheduleRoute,
        scheduleRoutes: [action.scheduleRoute, ...state.scheduleRoutes],
      };

    case scheduleRouteConstants.UPDATING_SCHEDULEROUTE_STARTED:
      return { ...state, isFetchingScheduleRoute: true };
    case scheduleRouteConstants.UPDATING_SCHEDULEROUTE_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        error: action.message || '',
      };
    case scheduleRouteConstants.UPDATING_SCHEDULEROUTE_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        scheduleRoute: action.scheduleRoute,
        scheduleRoutes: [...state.scheduleRoutes].map((scheduleRoute) => {
          if (action.scheduleRoute.id === scheduleRoute.id) {
            return action.scheduleRoute;
          }
          return scheduleRoute;
        }),
      };

    case scheduleRouteConstants.DELETE_SCHEDULEROUTE_STARTED:
      return { ...state, isFetchingScheduleRoute: true };
    case scheduleRouteConstants.DELETE_SCHEDULEROUTE_FAILURE:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        message: action.message || '',
      };
    case scheduleRouteConstants.DELETE_SCHEDULEROUTE_SUCCESS:
      return {
        ...state,
        isFetchingScheduleRoute: false,
        scheduleRoute: action.scheduleRoute,
        scheduleRoutes: [...state.scheduleRoutes].filter(
          (scheduleRoute) => action.scheduleRoute.id !== scheduleRoute.id
        ),
      };
    default:
      return state;
  }
}
