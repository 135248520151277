import * as tenderApi from '@api/tenderApi';

import { tenderConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: tenderConstants.TENDER_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching tender
 */
export const startGetTender = () => ({
  type: tenderConstants.GET_TENDER_STARTED,
});

export const getTenderSuccess = (result) => ({
  type: tenderConstants.GET_TENDER_SUCCESS,
  ...result,
});

export const getTenderFailure = (error) => ({
  type: tenderConstants.GET_TENDER_FAILURE,
  ...error,
});

export const getTender = (id) => {
  return (dispatch) => {
    dispatch(startGetTender());

    const promise = tenderApi.get(id);

    promise
      .then((result) => {
        dispatch(getTenderSuccess(result));
      })
      .catch((error) => {
        dispatch(getTenderFailure(error));
      });

    return promise;
  };
};

/**
 * Creating tender
 */
export const startCreateTender = () => ({
  type: tenderConstants.CREATING_TENDER_STARTED,
});

export const createTenderSuccess = (result) => ({
  type: tenderConstants.CREATING_TENDER_SUCCESS,
  ...result,
});

export const createTenderFailure = (error) => ({
  type: tenderConstants.CREATING_TENDER_FAILURE,
  ...error,
});

export const createTender = (tender) => {
  return (dispatch) => {
    dispatch(startCreateTender());

    const promise = tenderApi.create(tender);

    promise
      .then((result) => {
        dispatch(createTenderSuccess(result));
      })
      .catch((error) => {
        dispatch(createTenderFailure(error));
      });

    return promise;
  };
};

/**
 * Updating tender
 */
export const startUpdateTender = () => ({
  type: tenderConstants.UPDATING_TENDER_STARTED,
});

export const updateTenderSuccess = (result) => ({
  type: tenderConstants.UPDATING_TENDER_SUCCESS,
  ...result,
});

export const updateTenderFailure = (error) => ({
  type: tenderConstants.UPDATING_TENDER_FAILURE,
  ...error,
});

export const updateTender = (tender) => {
  return (dispatch) => {
    dispatch(startUpdateTender());

    const promise = tenderApi.update(tender);

    promise
      .then((result) => {
        dispatch(updateTenderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTenderFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting tender
 */
export const startDeleteTender = () => ({
  type: tenderConstants.DELETE_TENDER_STARTED,
});

export const deleteTenderSuccess = (result) => ({
  type: tenderConstants.DELETE_TENDER_SUCCESS,
  ...result,
});

export const deleteTenderFailure = (error) => ({
  type: tenderConstants.DELETE_TENDER_FAILURE,
  ...error,
});
export const deleteTender = (tender) => {
  return (dispatch) => {
    dispatch(startDeleteTender());

    const promise = tenderApi.remove(tender);

    promise
      .then((result) => {
        dispatch(deleteTenderSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTenderFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching tenders
 */
export const startGetTenders = (from) => ({
  type: tenderConstants.GET_TENDERS_STARTED,
  from: from,
});

export const getTendersSuccess = (result, from) => ({
  type: tenderConstants.GET_TENDERS_SUCCESS,
  from: from,
  ...result,
});

export const getTendersFailure = (error) => ({
  type: tenderConstants.GET_TENDERS_FAILURE,
  ...error,
});

export const getTenders = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTenders(from));

    const promise = tenderApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getTendersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTendersFailure(error));
      });

    return promise;
  };
};

export const searchTenders = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTenders(from));

    const promise = tenderApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getTendersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTendersFailure(error));
      });

    return promise;
  };
};
