export const terminalConstants = {
  TERMINAL_CHANGE_VALUE: 'TERMINAL_CHANGE_VALUE',

  GET_TERMINAL_STARTED: 'GET_TERMINAL_STARTED',
  GET_TERMINAL_SUCCESS: 'GET_TERMINAL_SUCCESS',
  GET_TERMINAL_FAILURE: 'GET_TERMINAL_FAILURE',

  GET_TERMINALS_STARTED: 'GET_TERMINALS_STARTED',
  GET_TERMINALS_SUCCESS: 'GET_TERMINALS_SUCCESS',
  GET_TERMINALS_FAILURE: 'GET_TERMINALS_FAILURE',

  SEARCH_TERMINALS_STARTED: 'SEARCH_TERMINALS_STARTED',
  SEARCH_TERMINALS_SUCCESS: 'SEARCH_TERMINALS_SUCCESS',
  SEARCH_TERMINALS_FAILURE: 'SEARCH_TERMINALS_FAILURE',

  CREATING_TERMINAL_STARTED: 'CREATING_TERMINAL_STARTED',
  CREATING_TERMINAL_SUCCESS: 'CREATING_TERMINAL_SUCCESS',
  CREATING_TERMINAL_FAILURE: 'CREATING_TERMINAL_FAILURE',

  UPDATING_TERMINAL_STARTED: 'UPDATING_TERMINAL_STARTED',
  UPDATING_TERMINAL_SUCCESS: 'UPDATING_TERMINAL_SUCCESS',
  UPDATING_TERMINAL_FAILURE: 'UPDATING_TERMINAL_FAILURE',

  DELETE_TERMINAL_STARTED: 'DELETE_TERMINAL_STARTED',
  DELETE_TERMINAL_SUCCESS: 'DELETE_TERMINAL_SUCCESS',
  DELETE_TERMINAL_FAILURE: 'DELETE_TERMINAL_FAILURE',
};
