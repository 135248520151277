import { actionConstants } from '@constants';

import * as actionApi from '@api/actionApi';

export const changeValue = (name, value) => ({
  type: actionConstants.ACTION_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching action
 */
export const startGetAction = () => ({
  type: actionConstants.GET_ACTION_STARTED,
});

export const getActionSuccess = (result) => ({
  type: actionConstants.GET_ACTION_SUCCESS,
  ...result,
});

export const getActionFailure = (error) => ({
  type: actionConstants.GET_ACTION_FAILURE,
  ...error,
});

export const getAction = (id) => {
  return (dispatch) => {
    dispatch(startGetAction());

    const promise = actionApi.get(id);

    promise
      .then((result) => {
        dispatch(getActionSuccess(result));
      })
      .catch((error) => {
        dispatch(getActionFailure(error));
      });

    return promise;
  };
};

/**
 * Creating action
 */
export const changeCreateAction = (name, value) => ({
  type: actionConstants.CREATING_ACTION_CHANGE_VALUE,
  name,
  value,
});
export const startCreateAction = () => ({
  type: actionConstants.CREATING_ACTION_STARTED,
});

export const createActionSuccess = (result) => ({
  type: actionConstants.CREATING_ACTION_SUCCESS,
  ...result,
});

export const createActionFailure = (error) => ({
  type: actionConstants.CREATING_ACTION_FAILURE,
  ...error,
});

export const createAction = (action) => {
  return (dispatch) => {
    dispatch(startCreateAction());

    const promise = actionApi.create(action);

    promise
      .then((result) => {
        dispatch(createActionSuccess(result));
      })
      .catch((error) => {
        dispatch(createActionFailure(error));
      });

    return promise;
  };
};

/**
 * Updating action
 */
export const changeUpdateAction = (name, value) => ({
  type: actionConstants.UPDATING_ACTION_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateAction = () => ({
  type: actionConstants.UPDATING_ACTION_STARTED,
});

export const updateActionSuccess = (result) => ({
  type: actionConstants.UPDATING_ACTION_SUCCESS,
  ...result,
});

export const updateActionFailure = (error) => ({
  type: actionConstants.UPDATING_ACTION_FAILURE,
  ...error,
});

export const updateAction = (action) => {
  return (dispatch) => {
    dispatch(startUpdateAction());

    const promise = actionApi.update(action);

    promise
      .then((result) => {
        dispatch(updateActionSuccess(result));
      })
      .catch((error) => {
        dispatch(updateActionFailure(error));
      });

    return promise;
  };
};

export const updateActionTimes = (actionId, startTime, endTime) => {
  return (dispatch) => {
    dispatch(startUpdateAction());

    const promise = actionApi.updateTimes(actionId, startTime, endTime);

    promise
      .then((result) => {
        dispatch(updateActionSuccess(result));
      })
      .catch((error) => {
        dispatch(updateActionFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting action
 */
export const startDeleteAction = () => ({
  type: actionConstants.DELETE_ACTION_STARTED,
});

export const deleteActionSuccess = (result) => ({
  type: actionConstants.DELETE_ACTION_SUCCESS,
  ...result,
});

export const deleteActionFailure = (error) => ({
  type: actionConstants.DELETE_ACTION_FAILURE,
  ...error,
});
export const deleteAction = (action) => {
  return (dispatch) => {
    dispatch(startDeleteAction());

    const promise = actionApi.remove(action);

    promise
      .then((result) => {
        dispatch(deleteActionSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteActionFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching actions
 */
export const startGetActions = () => ({
  type: actionConstants.GET_ACTIONS_STARTED,
});

export const getActionsSuccess = (result, from) => ({
  type: actionConstants.GET_ACTIONS_SUCCESS,
  from: from,
  ...result,
});

export const getActionsFailure = (error) => ({
  type: actionConstants.GET_ACTIONS_FAILURE,
  ...error,
});

export const getActions = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetActions());

    const promise = actionApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getActionsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getActionsFailure(error));
      });

    return promise;
  };
};

export const searchActions = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetActions());

    const promise = actionApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getActionsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getActionsFailure(error));
      });

    return promise;
  };
};
