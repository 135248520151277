import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import VehicleTransportEquipmentsView from '@components/vehicle/VehicleTransportEquipmentsView';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class VehicleTransportEquipmentsContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <VehicleTransportEquipmentsView {...props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.vehicle,
    ...ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || "DD/MM/YYYY",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicle: (id) => dispatch(vehicleActions.getVehicle(id)),
    getVehicleTransportEquipments: (id, from, to) =>
      dispatch(vehicleActions.getVehicleTransportEquipments(id, from, to)),

    vehicleDetachTransportEquipment: (id, action) =>
      dispatch(vehicleActions.vehicleDetachTransportEquipment(id, action)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleTransportEquipmentsContainer));
