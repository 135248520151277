import * as capacityApi from '@api/capacityApi';

import { capacityConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: capacityConstants.CAPACITY_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching capacity
 */
export const startGetCapacity = () => ({
  type: capacityConstants.GET_CAPACITY_STARTED,
});

export const getCapacitySuccess = (result) => ({
  type: capacityConstants.GET_CAPACITY_SUCCESS,
  ...result,
});

export const getCapacityFailure = (error) => ({
  type: capacityConstants.GET_CAPACITY_FAILURE,
  ...error,
});

export const getCapacity = (id) => {
  return (dispatch) => {
    dispatch(startGetCapacity());

    const promise = capacityApi.get(id);

    promise
      .then((result) => {
        dispatch(getCapacitySuccess(result));
      })
      .catch((error) => {
        dispatch(getCapacityFailure(error));
      });

    return promise;
  };
};

/**
 * Creating capacity
 */
export const changeCreateCapacity = (name, value) => ({
  type: capacityConstants.CREATING_CAPACITY_CHANGE_VALUE,
  name,
  value,
});
export const startCreateCapacity = () => ({
  type: capacityConstants.CREATING_CAPACITY_STARTED,
});

export const createCapacitySuccess = (result) => ({
  type: capacityConstants.CREATING_CAPACITY_SUCCESS,
  ...result,
});

export const createCapacityFailure = (error) => ({
  type: capacityConstants.CREATING_CAPACITY_FAILURE,
  ...error,
});

export const createCapacity = (capacity) => {
  return (dispatch) => {
    dispatch(startCreateCapacity());

    const promise = capacityApi.create(capacity);

    promise
      .then((result) => {
        dispatch(createCapacitySuccess(result));
      })
      .catch((error) => {
        dispatch(createCapacityFailure(error));
      });

    return promise;
  };
};

/**
 * Updating capacity
 */
export const changeUpdateCapacity = (name, value) => ({
  type: capacityConstants.UPDATING_CAPACITY_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateCapacity = () => ({
  type: capacityConstants.UPDATING_CAPACITY_STARTED,
});

export const updateCapacitySuccess = (result) => ({
  type: capacityConstants.UPDATING_CAPACITY_SUCCESS,
  ...result,
});

export const updateCapacityFailure = (error) => ({
  type: capacityConstants.UPDATING_CAPACITY_FAILURE,
  ...error,
});

export const updateCapacity = (capacity) => {
  return (dispatch) => {
    dispatch(startUpdateCapacity());

    const promise = capacityApi.update(capacity);

    promise
      .then((result) => {
        dispatch(updateCapacitySuccess(result));
      })
      .catch((error) => {
        dispatch(updateCapacityFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting capacity
 */
export const startDeleteCapacity = () => ({
  type: capacityConstants.DELETE_CAPACITY_STARTED,
});

export const deleteCapacitySuccess = (result) => ({
  type: capacityConstants.DELETE_CAPACITY_SUCCESS,
  ...result,
});

export const deleteCapacityFailure = (error) => ({
  type: capacityConstants.DELETE_CAPACITY_FAILURE,
  ...error,
});
export const deleteCapacity = (capacity) => {
  return (dispatch) => {
    dispatch(startDeleteCapacity());

    const promise = capacityApi.remove(capacity);

    promise
      .then((result) => {
        dispatch(deleteCapacitySuccess(result));
      })
      .catch((error) => {
        dispatch(deleteCapacityFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching capacities
 */
export const startGetCapacities = (from) => ({
  type: capacityConstants.GET_CAPACITIES_STARTED,
  from: from,
});

export const getCapacitiesSuccess = (result, from) => ({
  type: capacityConstants.GET_CAPACITIES_SUCCESS,
  from: from,
  ...result,
});

export const getCapacitiesFailure = (error) => ({
  type: capacityConstants.GET_CAPACITIES_FAILURE,
  ...error,
});

export const getCapacities = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetCapacities(from));

    const promise = capacityApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getCapacitiesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getCapacitiesFailure(error));
      });

    return promise;
  };
};

export const searchCapacities = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetCapacities(from));

    const promise = capacityApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getCapacitiesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getCapacitiesFailure(error));
      });

    return promise;
  };
};
