import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

class ContactDetailView extends Component {
  render() {
    const { t, contactDetail } = this.props;
    return (
      <div className="contact-detail">
        <div className="flex-container">
          <div className="name">{t(`form.label.${contactDetail.type}`)}: </div>
          <div className="value">{contactDetail.value}</div>
        </div>

        {contactDetail.remarks.length > 0 && (
          <div className="remarks">{contactDetail.remarks}</div>
        )}
      </div>
    );
  }
}
export default withTranslation('translation')(ContactDetailView);
