import { chauffeurTimeslotConstants } from '@constants/chauffeurTimeslot.constants';

const initialState = {
  isFetchingChauffeurTimeslot: false,
  formChauffeurTimeslot: null,
  chauffeurTimeslot: null,

  isFetchingChauffeurTimeslots: false,
  isFetchingChauffeurTimeslotsFrom: 0,
  chauffeurTimeslotsTotalResults: 0,
  chauffeurTimeslotsLoaded: 0,
  chauffeurTimeslots: [],
};

export default function chauffeurTimeslot(state = initialState, action) {
  switch (action.type) {
    case chauffeurTimeslotConstants.CHAUFFEURTIMESLOT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOT_STARTED:
      return { ...state, isFetchingChauffeurTimeslot: true };
    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOT_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: null,
        error: action.message || '',
      };
    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOT_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: action.chauffeurTimeslot,
      };

    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOTS_STARTED:
      return {
        ...state,
        isFetchingChauffeurTimeslots: true,
        isFetchingChauffeurTimeslotsFrom: action.from || 0,
      };
    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOTS_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslots: false,
        chauffeurTimeslots: [],
        error: action.message || '',
      };
    case chauffeurTimeslotConstants.GET_CHAUFFEURTIMESLOTS_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslots: false,
        chauffeurTimeslots:
          state.chauffeurTimeslots.length > 0
            ? [...state.chauffeurTimeslots, ...action.chauffeurTimeslots]
            : action.chauffeurTimeslots,
        chauffeurTimeslotsLoaded:
          action.from > 0
            ? state.chauffeurTimeslots.length + action.chauffeurTimeslots.length
            : action.chauffeurTimeslots.length,
        chauffeurTimeslotsTotalResults: action.totalResults || 0,
      };

    case chauffeurTimeslotConstants.SEARCH_CHAUFFEURTIMESLOTS_STARTED:
      return {
        ...state,
        isFetchingChauffeurTimeslots: true,
        isFetchingChauffeurTimeslotsFrom: action.from || 0,
      };
    case chauffeurTimeslotConstants.SEARCH_CHAUFFEURTIMESLOTS_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslots: false,
        chauffeurTimeslots: [],
        error: action.message || '',
      };
    case chauffeurTimeslotConstants.SEARCH_CHAUFFEURTIMESLOTS_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslots: false,
        chauffeurTimeslots:
          action.from > 0
            ? [...state.chauffeurTimeslots, ...action.chauffeurTimeslots]
            : action.chauffeurTimeslots,
        chauffeurTimeslotsLoaded:
          action.from > 0
            ? state.chauffeurTimeslots.length + action.chauffeurTimeslots.length
            : action.chauffeurTimeslots.length,
        chauffeurTimeslotsTotalResults: action.totalResults || 0,
      };

    case chauffeurTimeslotConstants.CREATING_CHAUFFEURTIMESLOT_STARTED:
      return { ...state, isFetchingChauffeurTimeslot: true };
    case chauffeurTimeslotConstants.CREATING_CHAUFFEURTIMESLOT_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: null,
        error: action.message || '',
      };
    case chauffeurTimeslotConstants.CREATING_CHAUFFEURTIMESLOT_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: action.chauffeurTimeslot,
        chauffeurTimeslots: [
          action.chauffeurTimeslot,
          ...state.chauffeurTimeslots,
        ],
      };

    case chauffeurTimeslotConstants.UPDATING_CHAUFFEURTIMESLOT_STARTED:
      return { ...state, isFetchingChauffeurTimeslot: true };
    case chauffeurTimeslotConstants.UPDATING_CHAUFFEURTIMESLOT_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: null,
        error: action.message || '',
      };
    case chauffeurTimeslotConstants.UPDATING_CHAUFFEURTIMESLOT_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: action.chauffeurTimeslot,
        chauffeurTimeslots: [...state.chauffeurTimeslots].map(
          (chauffeurTimeslot) => {
            if (action.chauffeurTimeslot.id === chauffeurTimeslot.id) {
              return action.chauffeurTimeslot;
            }
            return chauffeurTimeslot;
          }
        ),
      };

    case chauffeurTimeslotConstants.DELETE_CHAUFFEURTIMESLOT_STARTED:
      return { ...state, isFetchingChauffeurTimeslot: true };
    case chauffeurTimeslotConstants.DELETE_CHAUFFEURTIMESLOT_FAILURE:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: null,
        message: action.message || '',
      };
    case chauffeurTimeslotConstants.DELETE_CHAUFFEURTIMESLOT_SUCCESS:
      return {
        ...state,
        isFetchingChauffeurTimeslot: false,
        chauffeurTimeslot: action.chauffeurTimeslot,
        chauffeurTimeslots: [...state.chauffeurTimeslots].filter(
          (chauffeurTimeslot) =>
            action.chauffeurTimeslot.id !== chauffeurTimeslot.id
        ),
      };
    default:
      return state;
  }
}
