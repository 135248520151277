import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class SelectorTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = [
      { value: 'html', label: t('selectorType.html') },
      { value: 'matrix', label: t('selectorType.matrix') },
      {
        value: 'text_variable_position',
        label: t('selectorType.text_variable_position'),
      },
      {
        value: 'constant',
        label: t('selectorType.constant'),
      },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(SelectorTypeSelector);
