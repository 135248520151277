import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import TimeRow from './TimeRow';

class DrivingTimes extends Component {
  render() {
    const { t, chauffeur } = this.props;

    return (
      <div className="chauffeur-times">
        {chauffeur.remainingDrivingTimes && (
          <div className="driving">
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes')}
              timeBreakDown={{
                total: t('timeBreakDown.total'),
                minimum: null,
                maximum: t('timeBreakDown.maximum'),
                remaining: t('timeBreakDown.remaining'),
              }}
              header={true}
            />
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes.daysOfReducedRest')}
              timeBreakDown={chauffeur.remainingDrivingTimes.daysOfReducedRest}
              format={'day'}
            />
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes.dailyAmplitude')}
              timeBreakDown={chauffeur.remainingDrivingTimes.dailyAmplitude}
              format={'second'}
            />
            <TimeRow
              name={t(
                'chauffeur.remainingDrivingTimes.drivingTimeBelowMaximum'
              )}
              timeBreakDown={
                chauffeur.remainingDrivingTimes.drivingTimeBelowMaximum
              }
              format={'second'}
            />
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes.dailyDrivingTime')}
              timeBreakDown={chauffeur.remainingDrivingTimes.dailyDrivingTime}
              format={'second'}
            />
            <TimeRow
              name={t(
                'chauffeur.remainingDrivingTimes.daysOfDrivingLongerThan9h'
              )}
              timeBreakDown={
                chauffeur.remainingDrivingTimes.daysOfDrivingLongerThan9h
              }
              format={'day'}
            />
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes.weeklyDrivingTime')}
              timeBreakDown={chauffeur.remainingDrivingTimes.weeklyDrivingTime}
              format={'second'}
            />
            <TimeRow
              name={t('chauffeur.remainingDrivingTimes.BIWeeklyDrivingTime')}
              timeBreakDown={
                chauffeur.remainingDrivingTimes.BIWeeklyDrivingTime
              }
              format={'second'}
            />
            <TimeRow
              name={t(
                'chauffeur.remainingDrivingTimes.daysOfConsecutiveDriving'
              )}
              timeBreakDown={
                chauffeur.remainingDrivingTimes.daysOfConsecutiveDriving
              }
              format={'day'}
            />
          </div>
        )}
        {chauffeur.remainingRestingTimes && (
          <div className="resting">
            <TimeRow
              name={t('chauffeur.remainingRestingTimes')}
              timeBreakDown={{
                total: t('timeBreakDown.total'),
                minimum: t('timeBreakDown.minimum'),
                maximum: null,
                remaining: t('timeBreakDown.remaining'),
              }}
              header={true}
            />
            <TimeRow
              name={t('chauffeur.remainingRestingTimes.breakBelowMinimum')}
              timeBreakDown={chauffeur.remainingRestingTimes.breakBelowMinimum}
              format={'second'}
            />
            <TimeRow
              name={t('chauffeur.remainingRestingTimes.dailyRest')}
              timeBreakDown={chauffeur.remainingRestingTimes.dailyRest}
              format={'second'}
            />
            <TimeRow
              name={t('chauffeur.remainingRestingTimes.previousDailyRest')}
              timeBreakDown={chauffeur.remainingRestingTimes.previousDailyRest}
              format={'second'}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(DrivingTimes);
