import * as transportOrderApi from '@api/transportOrderApi';



import { transportOrderConstants } from '../constants';


export const changeValue = (name, value) => ({
  type: transportOrderConstants.TRANSPORT_ORDER_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching transportOrder
 */
export const startGetTransportOrder = () => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDER_STARTED,
});

export const getTransportOrderSuccess = (result) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDER_SUCCESS,
  ...result,
});

export const getTransportOrderFailure = (error) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDER_FAILURE,
  ...error,
});

export const getTransportOrder = (id) => {
  return (dispatch) => {
    dispatch(startGetTransportOrder());

    const promise = transportOrderApi.get(id);

    promise
      .then((result) => {
        dispatch(getTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(getTransportOrderFailure(error));
      });

    return promise;
  };
};

export const getPublicTransportOrder = (id, shareCode) => {
  return (dispatch) => {
    dispatch(startGetTransportOrder());

    const promise = transportOrderApi.publicGet(id, shareCode);

    promise
      .then((result) => {
        dispatch(getTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(getTransportOrderFailure(error));
      });

    return promise;
  };
};

/**
 * Creating transportOrder
 */
export const startCreateTransportOrder = () => ({
  type: transportOrderConstants.CREATING_TRANSPORT_ORDER_STARTED,
});

export const createTransportOrderSuccess = (result) => ({
  type: transportOrderConstants.CREATING_TRANSPORT_ORDER_SUCCESS,
  ...result,
});

export const createTransportOrderFailure = (error) => ({
  type: transportOrderConstants.CREATING_TRANSPORT_ORDER_FAILURE,
  ...error,
});

export const createTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startCreateTransportOrder());

    const promise = transportOrderApi.create(transportOrder);

    promise
      .then((result) => {
        dispatch(createTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(createTransportOrderFailure(error));
      });

    return promise;
  };
};

/**
 * Updating transportOrder
 */
export const startUpdateTransportOrder = () => ({
  type: transportOrderConstants.UPDATING_TRANSPORT_ORDER_STARTED,
});

export const updateTransportOrderSuccess = (result) => ({
  type: transportOrderConstants.UPDATING_TRANSPORT_ORDER_SUCCESS,
  ...result,
});

export const updateTransportOrderFailure = (error) => ({
  type: transportOrderConstants.UPDATING_TRANSPORT_ORDER_FAILURE,
  ...error,
});

export const updateTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startUpdateTransportOrder());

    const promise = transportOrderApi.update(transportOrder);

    promise
      .then((result) => {
        dispatch(updateTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTransportOrderFailure(error));
      });

    return promise;
  };
};

export const acceptTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startUpdateTransportOrder());

    const promise = transportOrderApi.accept(transportOrder.id);

    promise
      .then((result) => {
        dispatch(updateTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTransportOrderFailure(error));
      });

    return promise;
  };
};

export const declineTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startUpdateTransportOrder());

    const promise = transportOrderApi.decline(transportOrder.id);

    promise
      .then((result) => {
        dispatch(updateTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTransportOrderFailure(error));
      });

    return promise;
  };
};

export const cancelTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startUpdateTransportOrder());

    const promise = transportOrderApi.cancel(transportOrder.id);

    promise
      .then((result) => {
        dispatch(updateTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTransportOrderFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting transportOrder
 */
export const startDeleteTransportOrder = () => ({
  type: transportOrderConstants.DELETE_TRANSPORT_ORDER_STARTED,
});

export const deleteTransportOrderSuccess = (result) => ({
  type: transportOrderConstants.DELETE_TRANSPORT_ORDER_SUCCESS,
  ...result,
});

export const deleteTransportOrderFailure = (error) => ({
  type: transportOrderConstants.DELETE_TRANSPORT_ORDER_FAILURE,
  ...error,
});
export const deleteTransportOrder = (transportOrder) => {
  return (dispatch) => {
    dispatch(startDeleteTransportOrder());

    const promise = transportOrderApi.remove(transportOrder);

    promise
      .then((result) => {
        dispatch(deleteTransportOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTransportOrderFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transportOrders
 */
export const startGetTransportOrders = (from) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_STARTED,
  from: from,
});

export const getTransportOrdersSuccess = (result, from) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_SUCCESS,
  from: from,
  ...result,
});

export const getTransportOrdersFailure = (error) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_FAILURE,
  ...error,
});

export const getTransportOrders = (
  from = 0,
  amount = 20,
  cleanState = false
) => {
  return (dispatch) => {
    dispatch(startGetTransportOrders(cleanState ? 0 : from));

    const promise = transportOrderApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getTransportOrdersSuccess(result, cleanState ? 0 : from));
      })
      .catch((error) => {
        dispatch(getTransportOrdersFailure(error));
      });

    return promise;
  };
};

export const getTransportOrdersWithStatus = (
  status = 'requested',
  from = 0,
  amount = 20,
  cleanState = false
) => {
  return (dispatch) => {
    dispatch(startGetTransportOrders(cleanState ? 0 : from));

    const promise = transportOrderApi.latestWithStatus(status, from, amount);

    promise
      .then((result) => {
        dispatch(getTransportOrdersSuccess(result, cleanState ? 0 : from));
      })
      .catch((error) => {
        dispatch(getTransportOrdersFailure(error));
      });

    return promise;
  };
};

export const searchTransportOrders = (
  searchParameters = [],
  from = 0,
  amount = 20,
  cleanState = false
) => {
  return (dispatch) => {
    dispatch(startGetTransportOrders(cleanState ? 0 : from));

    const promise = transportOrderApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getTransportOrdersSuccess(result, cleanState ? 0 : from));
      })
      .catch((error) => {
        dispatch(getTransportOrdersFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transportOrders for the watchtower
 */
export const startGetWatchTowerTransportOrders = (from) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_STARTED,
  from: from,
});

export const getWatchTowerTransportOrdersSuccess = (result, from) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_SUCCESS,
  from: from,
  ...result,
});

export const getWatchTowerTransportOrdersFailure = (error) => ({
  type: transportOrderConstants.GET_TRANSPORT_ORDERS_WATCHTOWER_FAILURE,
  ...error,
});

export const getWatchTowerTransportOrders = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetWatchTowerTransportOrders(from));

    const promise = transportOrderApi.searchExtended(
      {
        statuses: [
          'accepted',
          'actual',
          'calculated_trip',
          'partially_planned',
          'planned',
        ],
      },
      from,
      amount
    );

    promise
      .then((result) => {
        dispatch(getWatchTowerTransportOrdersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getWatchTowerTransportOrdersFailure(error));
      });

    return promise;
  };
};

export const searchWatchTowerTransportOrders = (
  searchParameters = {},
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetWatchTowerTransportOrders(from));

    const promise = transportOrderApi.searchExtended(
      {
        ...searchParameters,
      },
      from,
      amount
    );

    promise
      .then((result) => {
        dispatch(getWatchTowerTransportOrdersSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getWatchTowerTransportOrdersFailure(error));
      });

    return promise;
  };
};