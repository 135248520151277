import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTransportOrderFromFilePage from '@components/transportOrder/crud/CreateTransportOrderFromFilePage.js';

import * as fileApi from '@api/fileApi';

import * as navigationActions from '@actions/navigationActions';

class CreateTransportOrderFromFileContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return (
      <CreateTransportOrderFromFilePage
        {...props}
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    simpleForms: state.settings?.settings?.userInterface?.simpleForms,
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTransportOrderFromFileContainer));
