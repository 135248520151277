import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import QuoteView from '@components/administration/quote/QuoteView';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';

class QuoteContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getQuote } = this.props;
    if (ownProps.quote) {
      changeValue('quote', ownProps.quote);
    } else if (ownProps.quoteId) {
      getQuote(ownProps.quoteId);
    }
  }

  render() {
    const { getQuote, ...props } = this.props;

    return <QuoteView {...props} getQuotes={(id) => getQuote(id)} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    ...state.administration,
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),
    getQuote: (id) => dispatch(administrationActions.getQuote(id)),
    sendQuote: (quote) => dispatch(administrationActions.sendQuote(quote)),
    deleteQuote: (quote) => dispatch(administrationActions.deleteQuote(quote)),
    updateQuote: (quote) => dispatch(administrationActions.updateQuote(quote)),
    getQuotes: (administrationId, from, to) =>
      dispatch(administrationActions.getQuotes(administrationId, from, to)),

    acceptQuote: (quote) => dispatch(administrationActions.acceptQuote(quote)),
    declineQuote: (quote) => dispatch(administrationActions.declineQuote(quote)),
    createAlert: (alert) => dispatch(navigationActions.createAlert(alert)),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(QuoteContainer));
