import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateBusinessPage from '@components/business/crud/CreateBusinessPage';

import * as fileApi from '@api/fileApi';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';

class CreateBusinessContainer extends PureComponent {
  render() {
    const { changeValue, createBusiness, popStack, callback, ...props } =
      this.props;

    return (
      <CreateBusinessPage
        {...props}
        popStack={popStack}
        onChange={changeValue}
        createBusiness={(business) =>
          createBusiness(business).then((data) => {
            popStack?.();
            callback?.(data);
          })
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    industries: state.platform.industries,
    ...state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(businessActions.changeCreateBusiness(name, value)),
    createBusiness: (business) =>
      dispatch(businessActions.createBusiness(business)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateBusinessContainer));
