export const laneConstants = {
  HANDLE_SET_LANE: 'HANDLE_SET_LANE',
  HANDLE_LANE_FORM: 'HANDLE_LANE_FORM',

  GETTING_LANES_STARTED: 'GETTING_LANES_STARTED',
  GETTING_LANES_SUCCESS: 'GETTING_LANES_SUCCESS',
  GETTING_LANES_FAILURE: 'GETTING_LANES_FAILURE',

  GET_LANE_STARTED: 'GET_LANE_STARTED',
  GET_LANE_SUCCESS: 'GET_LANE_SUCCESS',
  GET_LANE_FAILURE: 'GET_LANE_FAILURE',

  DELETE_LANE_STARTED: 'DELETE_LANE_STARTED',
  DELETE_LANE_SUCCESS: 'DELETE_LANE_SUCCESS',
  DELETE_LANE_FAILURE: 'DELETE_LANE_FAILURE',

  LANE_ACTION_FAILED: 'LANE_ACTION_FAILED',
  LANE_ACTION_STARTED: 'LANE_ACTION_STARTED',
  CREATE_LANE_SUCCESS: 'CREATE_LANE_SUCCESS',
  UPDATING_LANE_SUCCESS: 'UPDATING_LANE_SUCCESS',
};
