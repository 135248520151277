export const integrationConstants = {
  INTEGRATION_CHANGE_VALUE: 'INTEGRATION_CHANGE_VALUE',

  GET_INTEGRATION_STARTED: 'GET_INTEGRATION_STARTED',
  GET_INTEGRATION_SUCCESS: 'GET_INTEGRATION_SUCCESS',
  GET_INTEGRATION_FAILURE: 'GET_INTEGRATION_FAILURE',

  GET_INTEGRATIONS_STARTED: 'GET_INTEGRATIONS_STARTED',
  GET_INTEGRATIONS_SUCCESS: 'GET_INTEGRATIONS_SUCCESS',
  GET_INTEGRATIONS_FAILURE: 'GET_INTEGRATIONS_FAILURE',

  SEARCH_INTEGRATIONS_STARTED: 'SEARCH_INTEGRATIONS_STARTED',
  SEARCH_INTEGRATIONS_SUCCESS: 'SEARCH_INTEGRATIONS_SUCCESS',
  SEARCH_INTEGRATIONS_FAILURE: 'SEARCH_INTEGRATIONS_FAILURE',

  CREATING_INTEGRATION_STARTED: 'CREATING_INTEGRATION_STARTED',
  CREATING_INTEGRATION_SUCCESS: 'CREATING_INTEGRATION_SUCCESS',
  CREATING_INTEGRATION_FAILURE: 'CREATING_INTEGRATION_FAILURE',

  UPDATING_INTEGRATION_STARTED: 'UPDATING_INTEGRATION_STARTED',
  UPDATING_INTEGRATION_SUCCESS: 'UPDATING_INTEGRATION_SUCCESS',
  UPDATING_INTEGRATION_FAILURE: 'UPDATING_INTEGRATION_FAILURE',

  DELETE_INTEGRATION_STARTED: 'DELETE_INTEGRATION_STARTED',
  DELETE_INTEGRATION_SUCCESS: 'DELETE_INTEGRATION_SUCCESS',
  DELETE_INTEGRATION_FAILURE: 'DELETE_INTEGRATION_FAILURE',

  GET_INTEGRATION_LOGS_STARTED: 'GET_INTEGRATION_LOGS_STARTED',
  GET_INTEGRATION_LOGS_SUCCESS: 'GET_INTEGRATION_LOGS_SUCCESS',
  GET_INTEGRATION_LOGS_FAILURE: 'GET_INTEGRATION_LOGS_FAILURE',

  GET_INTEGRATION_OBJECTS_STARTED: 'GET_INTEGRATION_OBJECTS_STARTED',
  GET_INTEGRATION_OBJECTS_SUCCESS: 'GET_INTEGRATION_OBJECTS_SUCCESS',
  GET_INTEGRATION_OBJECTS_FAILURE: 'GET_INTEGRATION_OBJECTS_FAILURE',

  GET_INTEGRATION_TASKS_STARTED: 'GET_INTEGRATION_TASKS_STARTED',
  GET_INTEGRATION_TASKS_SUCCESS: 'GET_INTEGRATION_TASKS_SUCCESS',
  GET_INTEGRATION_TASKS_FAILURE: 'GET_INTEGRATION_TASKS_FAILURE',

  GET_INTEGRATION_REQUEST_LOGS_STARTED: 'GET_INTEGRATION_REQUEST_LOGS_STARTED',
  GET_INTEGRATION_REQUEST_LOGS_SUCCESS: 'GET_INTEGRATION_REQUEST_LOGS_SUCCESS',
  GET_INTEGRATION_REQUEST_LOGS_FAILURE: 'GET_INTEGRATION_REQUEST_LOGS_FAILURE',
};
