import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import LocationForm from './LocationForm';

export default class UpdateLocationPage extends Component {
  componentDidMount() {
    const { onChange, ownProps } = this.props;
    onChange('formLocation', ownProps.location);
  }

  render() {
    const { t, formLocation, isFetchingLocation, updateLocation } = this.props;

    if (!formLocation || isFetchingLocation) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('location.update')}</h1>
        <LocationForm
          {...this.props}
          key={formLocation.id}
          onSubmit={(location) => updateLocation(location)}
        />
      </AsideView>
    );
  }
}
