export const feedbackConstants = {
  FEEDBACK_CHANGE_VALUE: 'FEEDBACK_CHANGE_VALUE',

  GET_FEEDBACK_STARTED: 'GET_FEEDBACK_STARTED',
  GET_FEEDBACK_SUCCESS: 'GET_FEEDBACK_SUCCESS',
  GET_FEEDBACK_FAILURE: 'GET_FEEDBACK_FAILURE',

  GET_FEEDBACKS_STARTED: 'GET_FEEDBACKS_STARTED',
  GET_FEEDBACKS_SUCCESS: 'GET_FEEDBACKS_SUCCESS',
  GET_FEEDBACKS_FAILURE: 'GET_FEEDBACKS_FAILURE',

  SEARCH_FEEDBACKS_STARTED: 'SEARCH_FEEDBACKS_STARTED',
  SEARCH_FEEDBACKS_SUCCESS: 'SEARCH_FEEDBACKS_SUCCESS',
  SEARCH_FEEDBACKS_FAILURE: 'SEARCH_FEEDBACKS_FAILURE',

  CREATING_FEEDBACK_STARTED: 'CREATING_FEEDBACK_STARTED',
  CREATING_FEEDBACK_SUCCESS: 'CREATING_FEEDBACK_SUCCESS',
  CREATING_FEEDBACK_FAILURE: 'CREATING_FEEDBACK_FAILURE',

  UPDATING_FEEDBACK_STARTED: 'UPDATING_FEEDBACK_STARTED',
  UPDATING_FEEDBACK_SUCCESS: 'UPDATING_FEEDBACK_SUCCESS',
  UPDATING_FEEDBACK_FAILURE: 'UPDATING_FEEDBACK_FAILURE',

  DELETE_FEEDBACK_STARTED: 'DELETE_FEEDBACK_STARTED',
  DELETE_FEEDBACK_SUCCESS: 'DELETE_FEEDBACK_SUCCESS',
  DELETE_FEEDBACK_FAILURE: 'DELETE_FEEDBACK_FAILURE',
};
