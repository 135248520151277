import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateContactPersonPage from '@components/business/crud/CreateContactPersonPage';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';

class CreateContactPersonContainer extends PureComponent {
  render() {
    const { changeValue, updateBusiness, popStack, callback, ...props } = this.props;

    return (
      <CreateContactPersonPage
        {...props}
        callback={callback}
        popStack={popStack}
        onChange={changeValue}
        updateBusiness={(business) => updateBusiness(business)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.business,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(businessActions.changeCreateBusiness(name, value)),
    updateBusiness: (business) => dispatch(businessActions.updateBusiness(business)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateContactPersonContainer));
