import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import VehicleView from '@components/vehicle/VehicleView';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class VehicleContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getVehicle } = this.props;
    if (ownProps.vehicle) {
      changeValue('vehicle', ownProps.vehicle);
    } else if (ownProps.vehicleId) {
      getVehicle(ownProps.vehicleId);
    }
  }

  render() {
    const { getVehicle, deleteVehicle, ...props } = this.props;
    return (
      <VehicleView
        {...props}
        getVehicle={(id = '') => getVehicle(id)}
        deleteVehicle={(vehicle) => deleteVehicle(vehicle)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.vehicle,
    ownProps: ownProps,
    platform: state.platform.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(vehicleActions.changeValue(name, value)),

    getVehicle: (id) => dispatch(vehicleActions.getVehicle(id)),
    deleteVehicle: (vehicle) => dispatch(vehicleActions.deleteVehicle(vehicle)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleContainer));
