export default class VehicleGroup {
  id;
  nonce;

  name;
  users;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.users = [];
  }
}
