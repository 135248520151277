import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

export default class NotFound extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="absolute-center center error-page">
        <ReactSVG src="/icons/lost.svg" className="error-page__icon" />
        <h1>404 error occurred</h1>
        <p>Can't find this location, please try again</p>
        <Link to="/">Back to main page</Link>
      </div>
    );
  }
}
