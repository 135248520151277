import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TripView from '@components/trip/TripView';

import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';
import * as vehicleActions from '@actions/vehicleActions';

class TripContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getTrip } = this.props;
    if (ownProps.trip) {
      changeValue('trip', ownProps.trip);
    } else if (ownProps.tripId) {
      getTrip(ownProps.tripId);
    }
  }

  render() {
    const { getTrip, searchTrips, deleteTrip, ...props } = this.props;
    return (
      <TripView
        {...props}
        getTrip={(id = '') => getTrip(id)}
        deleteTrip={(trip) => deleteTrip(trip)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,
    ...state.trip,
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(tripActions.changeValue(name, value)),

    getExtendedVehicle: (id) => dispatch(vehicleActions.getExtendedVehicle(id)),

    getTrip: (id) => dispatch(tripActions.getTrip(id)),
    deleteTrip: (trip) => dispatch(tripActions.deleteTrip(trip)),
    cancelTrip: (trip) => dispatch(tripActions.cancelTrip(trip)),

    createAlert: (alert) => dispatch(navigationActions.createAlert(alert)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TripContainer));
