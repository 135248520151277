export const samlConstants = {
  SAML_CHANGE_VALUE: 'SAML_CHANGE_VALUE',

  GET_SAML_STARTED: 'GET_SAML_STARTED',
  GET_SAML_SUCCESS: 'GET_SAML_SUCCESS',
  GET_SAML_FAILURE: 'GET_SAML_FAILURE',

  GET_SAMLS_STARTED: 'GET_SAMLS_STARTED',
  GET_SAMLS_SUCCESS: 'GET_SAMLS_SUCCESS',
  GET_SAMLS_FAILURE: 'GET_SAMLS_FAILURE',

  SEARCH_SAMLS_STARTED: 'SEARCH_SAMLS_STARTED',
  SEARCH_SAMLS_SUCCESS: 'SEARCH_SAMLS_SUCCESS',
  SEARCH_SAMLS_FAILURE: 'SEARCH_SAMLS_FAILURE',

  CREATING_SAML_STARTED: 'CREATING_SAML_STARTED',
  CREATING_SAML_SUCCESS: 'CREATING_SAML_SUCCESS',
  CREATING_SAML_FAILURE: 'CREATING_SAML_FAILURE',

  UPDATING_SAML_STARTED: 'UPDATING_SAML_STARTED',
  UPDATING_SAML_SUCCESS: 'UPDATING_SAML_SUCCESS',
  UPDATING_SAML_FAILURE: 'UPDATING_SAML_FAILURE',

  DELETE_SAML_STARTED: 'DELETE_SAML_STARTED',
  DELETE_SAML_SUCCESS: 'DELETE_SAML_SUCCESS',
  DELETE_SAML_FAILURE: 'DELETE_SAML_FAILURE',

  GET_PLATFORM_SAML_METADATA_STARTED: 'GET_PLATFORM_SAML_METADATA_STARTED',
  GET_PLATFORM_SAML_METADATA_SUCCESS: 'GET_PLATFORM_SAML_METADATA_SUCCESS',
  GET_PLATFORM_SAML_METADATA_FAILURE: 'GET_PLATFORM_SAML_METADATA_FAILURE',
};
