import React, { Component } from 'react';

import moment from 'moment';

export default class Footer extends Component {
  render() {
    const { isAuthenticated, noFooter } = this.props;

    if (noFooter) return null;

    return (
      <footer className="mini">
        <div className="container">
          <a href="https://transportial.com" target="_blank">
            <img src="/images/Transportial.png" />
          </a>
          <span>
            @copyright {moment().year()} - All rights reserved -{' '}
            <a href="https://transportial.com" target="_blank">
              Transportial B.V.
            </a>
          </span>
        </div>
      </footer>
    );
  }
}
