import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import {
  interpretMatrixSelectionStrings,
  interpretTextVariablePositionSelectionString,
  numberToLetter,
} from '@utils/matrixUtils';
import { prefixZeroFormat } from '@utils/numberUtils';

class IntegrationMatrixView extends Component {
  render() {
    const { matrix, fields } = this.props;
    let selectedMatrixCells = interpretMatrixSelectionStrings(
      fields.filter((field) => field.selectorType === 'matrix').map((field) => field.selector)
    );

    // Adding text variable position
    fields
      .filter((field) => field.selectorType === 'text_variable_position')
      .forEach((field) => {
        const cells = interpretTextVariablePositionSelectionString(
          matrix,
          field.selector,
          parseInt(field.top),
          parseInt(field.right),
          parseInt(field.bottom),
          parseInt(field.left)
        );
        cells.forEach((cell) => selectedMatrixCells.push(cell));
      });

    if (!matrix) return null;

    return (
      <div className="matrix-view scrollable matrix-table">
        {matrix.map((x, indexX) => {
          return (
            <>
              {indexX === 0 && (
                <div className={`column`} style={{ position: 'sticky', top: 0 }}>
                  <div className="cell--header"></div>
                  {x.map((y, indexY) => {
                    const emptyX = matrix.map((x) => x[indexY]).join('') === '';

                    return (
                      <>
                        <div className={`cell column ${emptyX ? ' empty' : ''}`}>
                          {numberToLetter(indexY + 1)}
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
              <div className={`column `}>
                <div className={`cell--header row`} style={{ position: 'sticky', left: 0 }}>
                  {prefixZeroFormat(indexX + 1)}
                </div>

                {x.map((y, indexY) => {
                  const emptyX = matrix.map((x) => x[indexY]).join('') === '';

                  return (
                    <>
                      <div
                        className={`cell ${
                          selectedMatrixCells.find((cell) => cell.x === indexY && cell.y === indexX)
                            ? 'selected'
                            : ''
                        } ${emptyX ? ' empty' : ''}`}
                      >
                        {y}
                      </div>
                    </>
                  );
                })}
              </div>
            </>
          );
        })}
      </div>
    );
  }
}

export default withTranslation('translation')(IntegrationMatrixView);
