export const capacityConstants = {
  CAPACITY_CHANGE_VALUE: 'CAPACITY_CHANGE_VALUE',

  GET_CAPACITY_STARTED: 'GET_CAPACITY_STARTED',
  GET_CAPACITY_SUCCESS: 'GET_CAPACITY_SUCCESS',
  GET_CAPACITY_FAILURE: 'GET_CAPACITY_FAILURE',

  GET_CAPACITIES_STARTED: 'GET_CAPACITIES_STARTED',
  GET_CAPACITIES_SUCCESS: 'GET_CAPACITIES_SUCCESS',
  GET_CAPACITIES_FAILURE: 'GET_CAPACITIES_FAILURE',

  SEARCH_CAPACITIES_STARTED: 'SEARCH_CAPACITIES_STARTED',
  SEARCH_CAPACITIES_SUCCESS: 'SEARCH_CAPACITIES_SUCCESS',
  SEARCH_CAPACITIES_FAILURE: 'SEARCH_CAPACITIES_FAILURE',

  CREATING_CAPACITY_STARTED: 'CREATING_CAPACITY_STARTED',
  CREATING_CAPACITY_SUCCESS: 'CREATING_CAPACITY_SUCCESS',
  CREATING_CAPACITY_FAILURE: 'CREATING_CAPACITY_FAILURE',

  UPDATING_CAPACITY_STARTED: 'UPDATING_CAPACITY_STARTED',
  UPDATING_CAPACITY_SUCCESS: 'UPDATING_CAPACITY_SUCCESS',
  UPDATING_CAPACITY_FAILURE: 'UPDATING_CAPACITY_FAILURE',

  DELETE_CAPACITY_STARTED: 'DELETE_CAPACITY_STARTED',
  DELETE_CAPACITY_SUCCESS: 'DELETE_CAPACITY_SUCCESS',
  DELETE_CAPACITY_FAILURE: 'DELETE_CAPACITY_FAILURE',
};
