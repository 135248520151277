function onChangeAddValueTag(e) {
  if (e.target.value === '') {
    e.target.classList.remove('has-value');
  } else {
    e.target.classList.add('has-value');
  }
}
function onFocusAddActive(e) {
  e.target.parentElement.classList.add('active');
}
function onFocusRemoveActive(e) {
  e.target.parentElement.classList.remove('active');
}

const animationInputSelector = `
.input-group input[type="text"], 
.input-group input[type="number"], 
.input-group input[type="password"], 
.input-group input[type="email"], 
.input-group input[type="time"], 
.input-group textarea`;
const inputSelector = '.input-group input, .input-group textarea';

export const activateInputs = () => {
  document.querySelectorAll(inputSelector).forEach((element) => {
    element.addEventListener('input blur change', onChangeAddValueTag);
    if (element.value) element.classList.add('has-value');
  });

  document.querySelectorAll(animationInputSelector).forEach((element) => {
    element.addEventListener('focus', onFocusAddActive);
    element.addEventListener('focusout', onFocusRemoveActive);
  });
};

export const deactivateInputs = () => {
  document.querySelectorAll(inputSelector).forEach((element) => {
    element.removeEventListener('input blur change', onChangeAddValueTag);
  });
  document.querySelectorAll(animationInputSelector).forEach((element) => {
    element.removeEventListener('focus', onFocusAddActive);
    element.removeEventListener('focusout', onFocusRemoveActive);
  });
};

export const enableSwitchCheckboxes = () => {
  document.querySelectorAll("input[type='checkbox']").forEach((checkBox) => {
    checkBox.addEventListener('click', function (e) {
      document
        .querySelectorAll(
          "input[type='checkbox'][name='" + e.target.getAttribute('name') + "']"
        )
        .forEach((element) => {
          element.checked = false;
        });
      e.target.checked = true;
    });
  });
};

export const pasteIntoInput = (el, text) => {
  el.focus();
  if (
    typeof el.selectionStart == 'number' &&
    typeof el.selectionEnd == 'number'
  ) {
    var val = el.value;
    var selStart = el.selectionStart;
    el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd);
    el.selectionEnd = el.selectionStart = selStart + text.length;
  } else if (typeof document.selection != 'undefined') {
    var textRange = document.selection.createRange();
    textRange.text = text;
    textRange.collapse(false);
    textRange.select();
  }
};

export const validateStep = (validator, fields) => {
  let valid = true;
  fields.foreach((step) => {});

  return valid;
};
