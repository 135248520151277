import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateBusinessContainer from '@containers/business/crud/CreateBusinessContainer';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';
import { search as searchBusinesses } from '@api/businessApi';

const transformBusinessToValue = (business) => ({
  id: business.id,
  value: business.id,
  label: `${business.name}`,
  business: { ...business },
});

class BusinessInput extends Component {
  state = {
    business: this.props.value
      ? transformBusinessToValue(this.props.value)
      : null,
  };

  onChange = (newBusiness) => {
    this.setState({
      business: newBusiness,
    });
    const { onChange } = this.props;
    onChange && onChange(newBusiness ? newBusiness.business : null);
  };

  render() {
    const { t, addToStack, ...props } = this.props;
    const { business } = this.state;
    return (
      <div className="input-group no-margin-top">
        <div className="input-group no-margin-top">
          <AsyncCreatableSelect
            {...props}
            isClearable
            noOptionsMessage={() => t('businesses.notFound')}
            placeholder={t('form.label.selectBusiness')}
            loadingMessage={() => t('loading')}
            loadOptions={(inputValue, callback) => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchBusinesses({ query: inputValue }, 0, 40).then(
                  (response) => {
                    callback(
                      response.businesses.map((business) =>
                        transformBusinessToValue(business)
                      )
                    );
                  }
                );
              }, 400);
            }}
            value={business}
            onChange={(e) => this.onChange(e)}
            onCreateOption={(inputValue) => {
              addToStack({
                name: t('business.new'),
                component: (
                  <CreateBusinessContainer
                    targetName={inputValue}
                    callback={(data) => {
                      const newBusiness = {
                        id: data.business.id,
                        value: data.business.id,
                        label: data.business.name,
                        business: { ...data.business },
                      };
                      this.setState({
                        business: newBusiness,
                      });

                      this.onChange(newBusiness);
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(businessActions.changeCreateBusiness(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(BusinessInput));
