import * as laneApi from '../../api/laneApi';
import { laneConstants } from '../constants';

/**
 * Lane Actions
 */

export const laneActionStarted = () => ({
  type: laneConstants.LANE_ACTION_STARTED,
});

export const laneActionFailed = (error) => ({
  type: laneConstants.LANE_ACTION_FAILED,
  ...error,
});

/**
 * Creating Lane
 */

export const createLaneSuccess = (result) => ({
  type: laneConstants.CREATE_LANE_SUCCESS,
  ...result,
});

export const createLane = (boardId, lane) => {
  return (dispatch) => {
    dispatch(laneActionStarted());

    const promise = laneApi.createLane(boardId, lane);
    promise
      .then((result) => {
        dispatch(createLaneSuccess(result));
      })
      .catch((error) => {
        dispatch(laneActionFailed(error));
      });
    return promise;
  };
};

/**
 * Updating Lane
 */

export const updateLaneSuccess = (result) => ({
  type: laneConstants.UPDATING_LANE_SUCCESS,
  ...result,
});

export const updateLane = (lane) => {
  return (dispatch) => {
    dispatch(laneActionStarted());

    const promise = laneApi.updateLane(lane);
    promise
      .then((result) => {
        dispatch(updateLaneSuccess(result.lane));
      })
      .catch((error) => {
        dispatch(laneActionFailed(error));
      });
    return promise;
  };
};

export const setLane = (value) => ({
  type: laneConstants.HANDLE_SET_LANE,
  value,
});

export const updateLanePosition = (id, position) => {
  return (dispatch) => {
    const promise = laneApi.updateLanePosition(id, position);
    promise.then((result) => {}).catch((error) => {});
    return promise;
  };
};

/* Deleting a Lane */

export const deleteLaneStarted = (result) => ({
  type: laneConstants.DELETE_LANE_STARTED,
  ...result,
});

export const deleteLaneSuccess = (result) => ({
  type: laneConstants.DELETE_LANE_SUCCESS,
  ...result,
});

export const deleteLaneFailure = (result) => ({
  type: laneConstants.DELETE_LANE_FAILURE,
  ...result,
});

export const deleteLane = (lane) => {
  return (dispatch) => {
    dispatch(deleteLaneStarted());

    const promise = laneApi.deleteLane(lane);
    promise
      .then((result) => {
        dispatch(deleteLaneSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteLaneFailure(error));
      });
    return promise;
  };
};

/**
 * Get a Lane
 */
export const getLaneStarted = () => ({
  type: boardConstants.GET_LANE_STARTED,
});

export const getLaneSuccess = (result) => ({
  type: boardConstants.GET_LANE_SUCCESS,
  ...result,
});

export const getLaneFailure = (error) => ({
  type: boardConstants.GET_LANE_FAILURE,
  ...error,
});

export const getLane = (lane) => {
  return (dispatch) => {
    dispatch(getLaneStarted());

    const promise = laneApi.getLane(lane);
    promise
      .then((result) => {
        dispatch(getLaneSuccess(result));
      })
      .catch((error) => {
        dispatch(getLaneFailure(error));
      });

    return promise;
  };
};
