import { mailConstants } from '../constants/mail.constants';
import { socketConstants } from '../constants/socket.constants';

const initialState = {
  isFetchingMail: false,
  formMail: null,
  mail: null,

  isFetchingMails: false,
  mailsTotalResults: 0,
  mailsLoaded: 0,
  mails: [],
};

export default function mail(state = initialState, action) {
  switch (action.type) {
    case mailConstants.MAIL_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case mailConstants.GET_MAILS_STARTED:
      return { ...state, isFetchingMails: true };
    case mailConstants.GET_MAILS_FAILURE:
      return { ...state, isFetchingMails: false, error: action.message || '' };
    case mailConstants.GET_MAILS_SUCCESS:
      return {
        ...state,
        isFetchingMails: false,
        mails:
          action.from > 0 ? [...state.mails, ...action.mails] : action.mails,
        mailsLoaded:
          action.from > 0
            ? state.mails.length + action.mails.length
            : action.mails.length,
        mailsTotalResults: action.totalResults || 0,
      };
    case socketConstants.NEW_MAIL_MESSAGES:
      return {
        ...state,
        isFetchingMails: false,
        mails: [action.value, ...state.mails].slice(0, 10),
        mailsLoaded: [action.value, ...state.mails].slice(0, 10).length,
      };

    case mailConstants.SEARCH_MAILS_STARTED:
      return { ...state, isFetchingMails: true };
    case mailConstants.SEARCH_MAILS_FAILURE:
      return { ...state, isFetchingMails: false, error: action.message || '' };
    case mailConstants.SEARCH_MAILS_SUCCESS:
      return {
        ...state,
        isFetchingMails: false,
        mails:
          action.from > 0 ? [...state.mails, ...action.mails] : action.mails,
        mailsLoaded:
          action.from > 0
            ? state.mails.length + action.mails.length
            : action.mails.length,
        mailsTotalResults: action.totalResults || 0,
      };

    case mailConstants.CREATING_MAIL_STARTED:
      return { ...state, isFetchingMail: true };
    case mailConstants.CREATING_MAIL_FAILURE:
      return { ...state, isFetchingMail: false, error: action.message || '' };
    case mailConstants.CREATING_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingMail: false,
        mail: action.mail,
        mails: [action.mail, ...state.mails],
      };

    case mailConstants.UPDATING_MAIL_STARTED:
      return { ...state, isFetchingMail: true };
    case mailConstants.UPDATING_MAIL_FAILURE:
      return { ...state, isFetchingMail: false, error: action.message || '' };
    case mailConstants.UPDATING_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingMail: false,
        mail: action.mail,
        mails: [...state.mails].map((mail) => {
          if (action.mail.id === mail.id) {
            return action.mail;
          }
          return mail;
        }),
      };

    case mailConstants.ARCHIVE_MAIL_STARTED:
      return { ...state, isFetchingMail: true };
    case mailConstants.ARCHIVE_MAIL_FAILURE:
      return { ...state, isFetchingMail: false, message: action.message || '' };
    case mailConstants.ARCHIVE_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingMail: false,
        mail: action.mail,
        mails: [...state.mails].filter((mail) => action.mail.id !== mail.id),
        mailsTotalResults: state.mailsTotalResults - 1,
      };

    case mailConstants.UNARCHIVE_MAIL_STARTED:
      return { ...state, isFetchingMail: true };
    case mailConstants.UNARCHIVE_MAIL_FAILURE:
      return { ...state, isFetchingMail: false, message: action.message || '' };
    case mailConstants.UNARCHIVE_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingMail: false,
        mail: action.mail,
        mails: [action.mail, ...state.mails],
        mailsTotalResults: state.mailsTotalResults - 1,
      };

    case mailConstants.DELETE_MAIL_STARTED:
      return { ...state, isFetchingMail: true };
    case mailConstants.DELETE_MAIL_FAILURE:
      return { ...state, isFetchingMail: false, message: action.message || '' };
    case mailConstants.DELETE_MAIL_SUCCESS:
      return {
        ...state,
        isFetchingMail: false,
        mail: action.mail,
        mails: [...state.mails].filter((mail) => action.mail.id !== mail.id),
        mailsTotalResults: state.mailsTotalResults - 1,
      };

    default:
      return state;
  }
}
