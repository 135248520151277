import { typeConstants } from '@constants/type.constants';

const initialState = {
  isFetchingType: false,
  formType: null,
  type: null,

  isFetchingTypes: false,
  isFetchingTypesFrom: 0,
  typesTotalResults: 0,
  typesLoaded: 0,
  types: [],
};

export default function type(state = initialState, action) {
  switch (action.type) {
    case typeConstants.TYPE_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case typeConstants.GET_TYPE_STARTED:
      return { ...state, isFetchingType: true };
    case typeConstants.GET_TYPE_FAILURE:
      return { ...state, isFetchingType: false, type: null, error: action.message || '' };
    case typeConstants.GET_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingType: false,
        type: action.typeObject,
      };

    case typeConstants.GET_TYPES_STARTED:
      return { ...state, isFetchingTypes: true, isFetchingTypesFrom: action.from || 0 };
    case typeConstants.GET_TYPES_FAILURE:
      return { ...state, isFetchingTypes: false, types: [], error: action.message || '' };
    case typeConstants.GET_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingTypes: false,
        types: action.from > 0 ? [...state.types, ...action.types] : action.types,
        typesLoaded:
          action.from > 0 ? state.types.length + action.types.length : action.types.length,
        typesTotalResults: action.totalResults || 0,
      };

    case typeConstants.SEARCH_TYPES_STARTED:
      return { ...state, isFetchingTypes: true, isFetchingTypesFrom: action.from || 0 };
    case typeConstants.SEARCH_TYPES_FAILURE:
      return { ...state, isFetchingTypes: false, types: [], error: action.message || '' };
    case typeConstants.SEARCH_TYPES_SUCCESS:
      return {
        ...state,
        isFetchingTypes: false,
        types: action.from > 0 ? [...state.types, ...action.types] : action.types,
        typesLoaded:
          action.from > 0 ? state.types.length + action.types.length : action.types.length,
        typesTotalResults: action.totalResults || 0,
      };

    case typeConstants.CREATING_TYPE_STARTED:
      return { ...state, isFetchingType: true };
    case typeConstants.CREATING_TYPE_FAILURE:
      return { ...state, isFetchingType: false, type: null, error: action.message || '' };
    case typeConstants.CREATING_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingType: false,
        type: action.typeObject,
        types: [action.typeObject, ...state.types],
      };

    case typeConstants.UPDATING_TYPE_STARTED:
      return { ...state, isFetchingType: true };
    case typeConstants.UPDATING_TYPE_FAILURE:
      return { ...state, isFetchingType: false, type: null, error: action.message || '' };
    case typeConstants.UPDATING_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingType: false,
        type: action.typeObject,
        types: [...state.types].map((type) => {
          if (action.typeObject.id === type.id) {
            return action.typeObject;
          }
          return type;
        }),
      };

    case typeConstants.DELETE_TYPE_STARTED:
      return { ...state, isFetchingType: true };
    case typeConstants.DELETE_TYPE_FAILURE:
      return { ...state, isFetchingType: false, type: null, message: action.message || '' };
    case typeConstants.DELETE_TYPE_SUCCESS:
      return {
        ...state,
        isFetchingType: false,
        type: action.typeObject,
        types: [...state.types].filter((type) => action.typeObject.id !== type.id),
      };
    default:
      return state;
  }
}
