import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateIntegrationPage from '@components/integration/crud/CreateIntegrationPage.js';

import * as integrationActions from '@actions/integrationActions';
import * as navigationActions from '@actions/navigationActions';
import * as platformActions from '@actions/platformActions';

class CreateIntegrationContainer extends PureComponent {
  componentDidMount() {
    const { getPermissions } = this.props;
    getPermissions();
  }

  render() {
    const { changeValue, createIntegration, popStack, callback, ...props } =
      this.props;
    return (
      <CreateIntegrationPage
        {...props}
        onChange={changeValue}
        createIntegration={(integration) =>
          createIntegration(integration).then((response) => {
            popStack?.();
            callback?.(response.integration);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    integrationManualTypes: state.platform.integrationManualTypes,
    triggerEvents: state.platform.triggerEvents,
    entityTypes: state.platform.entityTypes,
    triggerTypes: state.platform.triggerTypes,
    requestMethods: state.platform.requestMethods,
    dataTypes: state.platform.dataTypes,
    permissions: state.platform.permissions,

    ...state.integration,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createIntegration: (integration) =>
      dispatch(integrationActions.createIntegration(integration)),
    changeValue: (name, value) =>
      dispatch(integrationActions.changeValue(name, value)),

    getPermissions: () => dispatch(platformActions.getPermissions()),

    updateTopStack: (args) => dispatch(navigationActions.updateTopStack(args)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateIntegrationContainer));
