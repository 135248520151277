import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';

import SupportTicket from '@models/support/SupportTicket';

import SupportTicketForm from './SupportTicketForm';

export default class CreateSupportTicket extends Component {
  componentDidMount() {
    const { user, onChange } = this.props;
    const supportTicket = new SupportTicket();

    if (user) {
      supportTicket.firstName = user.firstName;
      supportTicket.lastName = user.lastName;
      supportTicket.email = user.email;
    }
    onChange('formSupportTicket', supportTicket);
  }

  render() {
    const { t, createTicket, formSupportTicket } = this.props;

    return (
      <AsideView>
        <h1>{t('support.ticket.new.header')}</h1>
        <SupportTicketForm
          {...this.props}
          onSubmit={(e) => createTicket(formSupportTicket)}
        />
      </AsideView>
    );
  }
}
