import { fleetConstants } from '@constants';

import * as fleetApi from '@api/fleetApi';

export const changeValue = (name, value) => ({
  type: fleetConstants.FLEET_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching fleet
 */
export const startGetFleet = () => ({
  type: fleetConstants.GET_FLEET_STARTED,
});

export const getFleetSuccess = (result) => ({
  type: fleetConstants.GET_FLEET_SUCCESS,
  ...result,
});

export const getFleetFailure = (error) => ({
  type: fleetConstants.GET_FLEET_FAILURE,
  ...error,
});

export const getFleet = (id) => {
  return (dispatch) => {
    dispatch(startGetFleet());

    const promise = fleetApi.get(id);

    promise
      .then((result) => {
        dispatch(getFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(getFleetFailure(error));
      });

    return promise;
  };
};

/**
 * Creating fleet
 */
export const startCreateFleet = () => ({
  type: fleetConstants.CREATING_FLEET_STARTED,
});

export const createFleetSuccess = (result) => ({
  type: fleetConstants.CREATING_FLEET_SUCCESS,
  ...result,
});

export const createFleetFailure = (error) => ({
  type: fleetConstants.CREATING_FLEET_FAILURE,
  ...error,
});

export const createFleet = (fleet) => {
  return (dispatch) => {
    dispatch(startCreateFleet());

    const promise = fleetApi.create(fleet);

    promise
      .then((result) => {
        dispatch(createFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(createFleetFailure(error));
      });

    return promise;
  };
};

/**
 * Updating fleet
 */
export const startUpdateFleet = () => ({
  type: fleetConstants.UPDATING_FLEET_STARTED,
});

export const updateFleetSuccess = (result) => ({
  type: fleetConstants.UPDATING_FLEET_SUCCESS,
  ...result,
});

export const updateFleetFailure = (error) => ({
  type: fleetConstants.UPDATING_FLEET_FAILURE,
  ...error,
});

export const updateFleet = (fleet) => {
  return (dispatch) => {
    dispatch(startUpdateFleet());

    const promise = fleetApi.update(fleet);

    promise
      .then((result) => {
        dispatch(updateFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(updateFleetFailure(error));
      });

    return promise;
  };
};
/**
 * Deleting fleet
 */
export const startDeleteFleet = () => ({
  type: fleetConstants.DELETE_FLEET_STARTED,
});

export const deleteFleetSuccess = (result) => ({
  type: fleetConstants.DELETE_FLEET_SUCCESS,
  ...result,
});

export const deleteFleetFailure = (error) => ({
  type: fleetConstants.DELETE_FLEET_FAILURE,
  ...error,
});

export const deleteFleet = (fleet) => {
  return (dispatch) => {
    dispatch(startDeleteFleet());

    const promise = fleetApi.remove(fleet);

    promise
      .then((result) => {
        dispatch(deleteFleetSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteFleetFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching fleets
 */
export const startGetFleets = (from) => ({
  type: fleetConstants.GET_FLEETS_STARTED,
  from: from,
});

export const getFleetsSuccess = (result, from) => ({
  type: fleetConstants.GET_FLEETS_SUCCESS,
  from: from,
  ...result,
});

export const getFleetsFailure = (error) => ({
  type: fleetConstants.GET_FLEETS_FAILURE,
  ...error,
});

export const getFleets = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetFleets(from));

    const promise = fleetApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getFleetsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getFleetsFailure(error));
      });

    return promise;
  };
};

export const searchFleets = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetFleets(from));

    const promise = fleetApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getFleetsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getFleetsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching light fleets
 */
export const startGetFleetsLight = () => ({
  type: fleetConstants.GET_FLEETS_LIGHT_STARTED,
});

export const getFleetsLightSuccess = (result) => ({
  type: fleetConstants.GET_FLEETS_LIGHT_SUCCESS,
  ...result,
});

export const getFleetsLightFailure = (error) => ({
  type: fleetConstants.GET_FLEETS_LIGHT_FAILURE,
  ...error,
});

export const getFleetsLight = () => {
  return (dispatch) => {
    dispatch(startGetFleetsLight());

    const promise = fleetApi.light();

    promise
      .then((result) => {
        dispatch(getFleetsLightSuccess(result));
      })
      .catch((error) => {
        dispatch(getFleetsLightFailure(error));
      });

    return promise;
  };
};
