import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/location/${id}`);
};

export const create = (location) => {
  return api.post(`/location`, location);
};

export const update = (location) => {
  return api.put(`/location`, location);
};

export const remove = (location) => {
  return api.delete(`/location/${location.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/locations/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/locations/${from}/${amount}`, searchParameters);
};

// Business logic
export const geocodeAddressGeoReference = (addressGeoReference) => {
  return api.post(`/location/geocode/addressGeoReference`, addressGeoReference);
};

export const getDuplicateLocations = (location) => {
  return api.post(`/location/duplicates`, location);
};

export const getLocationActionAverageDuration = (location, type) => {
  return api.get(`/location/${location.id}/action/${type}/duration:average`);
};
