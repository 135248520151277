export const eventConstants = {
  EVENT_CHANGE_VALUE: 'EVENT_CHANGE_VALUE',

  GET_EVENT_STARTED: 'GET_EVENT_STARTED',
  GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
  GET_EVENT_FAILURE: 'GET_EVENT_FAILURE',

  GET_EVENTS_STARTED: 'GET_EVENTS_STARTED',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_FAILURE: 'GET_EVENTS_FAILURE',

  SEARCH_EVENTS_STARTED: 'SEARCH_EVENTS_STARTED',
  SEARCH_EVENTS_SUCCESS: 'SEARCH_EVENTS_SUCCESS',
  SEARCH_EVENTS_FAILURE: 'SEARCH_EVENTS_FAILURE',

  CREATING_EVENT_STARTED: 'CREATING_EVENT_STARTED',
  CREATING_EVENT_SUCCESS: 'CREATING_EVENT_SUCCESS',
  CREATING_EVENT_FAILURE: 'CREATING_EVENT_FAILURE',

  UPDATING_EVENT_STARTED: 'UPDATING_EVENT_STARTED',
  UPDATING_EVENT_SUCCESS: 'UPDATING_EVENT_SUCCESS',
  UPDATING_EVENT_FAILURE: 'UPDATING_EVENT_FAILURE',

  DELETE_EVENT_STARTED: 'DELETE_EVENT_STARTED',
  DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
  DELETE_EVENT_FAILURE: 'DELETE_EVENT_FAILURE',
};
