import api from '../api';

export const generateMessage = (id = '', chat = '') => {
  return api.get(`/messageTemplate/${id}/message/${chat}`);
};

// CRUD
export const get = (id = '') => {
  return api.get(`/messageTemplate/${id}`);
};

export const create = (messageTemplate) => {
  return api.post(`/messageTemplate`, messageTemplate);
};

export const update = (messageTemplate) => {
  return api.put(`/messageTemplate`, messageTemplate);
};

export const remove = (messageTemplate) => {
  return api.delete(`/messageTemplate/${messageTemplate.id}`);
};

// Finding
export const latest = (from = 0, amount = 20) => {
  return api.get(`/messageTemplates/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/messageTemplates/${from}/${amount}`, searchParameters);
};
