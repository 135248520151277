import { actionConstants } from '../constants/action.constants';

const initialState = {
  isFetchingAction: false,
  formAction: null,
  action: null,

  isFetchingActions: false,
  isFetchingActionsFrom: 0,
  actionsTotalResults: 0,
  actionsLoaded: 0,
  actions: [],
};

export default function action(state = initialState, action) {
  switch (action.type) {
    case actionConstants.ACTION_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case actionConstants.GET_ACTION_STARTED:
      return { ...state, isFetchingAction: true };
    case actionConstants.GET_ACTION_FAILURE:
      return {
        ...state,
        isFetchingAction: false,
        action: null,
        error: action.message || '',
      };
    case actionConstants.GET_ACTION_SUCCESS:
      return {
        ...state,
        isFetchingAction: false,
        action: action.action,
      };

    case actionConstants.GET_ACTIONS_STARTED:
      return { ...state, isFetchingActions: true };
    case actionConstants.GET_ACTIONS_FAILURE:
      return {
        ...state,
        isFetchingActions: false,
        actions: [],
        error: action.message || '',
      };
    case actionConstants.GET_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingActions: false,
        actions: action.from > 0 ? [...state.actions, ...action.actions] : action.actions,
        actionsLoaded:
          action.from > 0 ? state.actions.length + action.actions.length : action.actions.length,
        actionsTotalResults: action.totalResults || 0,
      };

    case actionConstants.SEARCH_ACTIONS_STARTED:
      return { ...state, isFetchingActions: true };
    case actionConstants.SEARCH_ACTIONS_FAILURE:
      return {
        ...state,
        isFetchingActions: false,
        actions: [],
        error: action.message || '',
      };
    case actionConstants.SEARCH_ACTIONS_SUCCESS:
      return {
        ...state,
        isFetchingActions: false,
        actions: action.from > 0 ? [...state.actions, ...action.actions] : action.actions,
        actionsLoaded:
          action.from > 0 ? state.actions.length + action.actions.length : action.actions.length,
        actionsTotalResults: action.totalResults || 0,
      };

    case actionConstants.CREATING_ACTION_STARTED:
      return { ...state, isFetchingAction: true };
    case actionConstants.CREATING_ACTION_FAILURE:
      return { ...state, isFetchingAction: false, error: action.message || '' };
    case actionConstants.CREATING_ACTION_SUCCESS:
      return {
        ...state,
        isFetchingAction: false,
        action: action.action,
        actions: [action.action, ...state.actions],
      };

    case actionConstants.UPDATING_ACTION_STARTED:
      return { ...state, isFetchingAction: true };
    case actionConstants.UPDATING_ACTION_FAILURE:
      return {
        ...state,
        isFetchingAction: false,
        action: null,
        error: action.message || '',
      };
    case actionConstants.UPDATING_ACTION_SUCCESS:
      return {
        ...state,
        isFetchingAction: false,
        action: action.action,
        actions: [...state.actions].map((action) => {
          if (action.action.id === action.id) {
            return action.action;
          }
          return action;
        }),
      };

    case actionConstants.DELETE_ACTION_STARTED:
      return { ...state, isFetchingAction: true };
    case actionConstants.DELETE_ACTION_FAILURE:
      return {
        ...state,
        isFetchingAction: false,
        action: null,
        message: action.message || '',
      };
    case actionConstants.DELETE_ACTION_SUCCESS:
      return {
        ...state,
        isFetchingAction: false,
        action: action.action,
        actions: [...state.actions].filter((action) => action.action.id !== action.id),
      };
    default:
      return state;
  }
}
