export const tenderConstants = {
  TENDER_CHANGE_VALUE: 'TENDER_CHANGE_VALUE',

  GET_TENDER_STARTED: 'GET_TENDER_STARTED',
  GET_TENDER_SUCCESS: 'GET_TENDER_SUCCESS',
  GET_TENDER_FAILURE: 'GET_TENDER_FAILURE',

  GET_TENDERS_STARTED: 'GET_TENDERS_STARTED',
  GET_TENDERS_SUCCESS: 'GET_TENDERS_SUCCESS',
  GET_TENDERS_FAILURE: 'GET_TENDERS_FAILURE',

  SEARCH_TENDERS_STARTED: 'SEARCH_TENDERS_STARTED',
  SEARCH_TENDERS_SUCCESS: 'SEARCH_TENDERS_SUCCESS',
  SEARCH_TENDERS_FAILURE: 'SEARCH_TENDERS_FAILURE',

  CREATING_TENDER_STARTED: 'CREATING_TENDER_STARTED',
  CREATING_TENDER_SUCCESS: 'CREATING_TENDER_SUCCESS',
  CREATING_TENDER_FAILURE: 'CREATING_TENDER_FAILURE',

  UPDATING_TENDER_STARTED: 'UPDATING_TENDER_STARTED',
  UPDATING_TENDER_SUCCESS: 'UPDATING_TENDER_SUCCESS',
  UPDATING_TENDER_FAILURE: 'UPDATING_TENDER_FAILURE',

  DELETE_TENDER_STARTED: 'DELETE_TENDER_STARTED',
  DELETE_TENDER_SUCCESS: 'DELETE_TENDER_SUCCESS',
  DELETE_TENDER_FAILURE: 'DELETE_TENDER_FAILURE',
};
