export default class Action {
  type;
  actions;

  constructor(type, actions = []) {
    this.nonce = Math.random().toString(36).substring(7);

    this.lifeCycle = 'requested';
    this.type = type;
    this.actions = actions;

    this.constraints = [];
  }
}
