import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import CloseButton from '@uicomponents/CloseButton';

import TaxRate from '@models/general/TaxRate';

import { formatAmount } from '@utils/moneyUtils';

import PricingTaxRateFormPage from './PricingTaxRateFormPage';

class PricingTaxRatesInput extends Component {
  state = {
    taxRates: this.props.taxRates || [{}],
  };

  addNewTaxRate = () => {
    const { taxRates } = this.state;
    this.setState({
      taxRates: [...taxRates, new TaxRate()],
    });
  };

  updateTaxRate = (newTaxRate, index) => {
    const { onChange } = this.props;

    const { taxRates } = this.state;
    const newTaxRates = [...taxRates];
    newTaxRates[index] = newTaxRate;

    this.setState({
      taxRates: newTaxRates,
    });

    onChange?.(newTaxRates);
  };

  render() {
    const {
      t,
      user,
      addToStack,
      popStack,
      onChange,
      platform,
      dataTypes,
      currencies,
      pricingTaxRateSubUnits,
      entityType,
      taxRateOptions,
      pricingCategories,
      pricingEntityTypes,
      pricingTaxRateTypes,
    } = this.props;

    const { taxRates } = this.state;

    return (
      <>
        {taxRates.map((taxRate, index) => (
          <div
            className="list-sector pricing-form-element"
            key={`input-pricing-${index}-${taxRate.id}-${taxRate.nonce}`}
            onClick={(e) => {
              e.preventDefault();
              addToStack({
                name: t('pricing.taxRate.header', { count: index + 1 }),
                component: (
                  <PricingTaxRateFormPage
                    platform={platform}
                    popStack={popStack}
                    addToStack={addToStack}
                    currencies={currencies}
                    pricingTaxRateSubUnits={pricingTaxRateSubUnits}
                    entityType={entityType}
                    user={user}
                    taxRates={taxRateOptions}
                    headerName={t('pricing.taxRate.header', {
                      count: index + 1,
                    })}
                    taxRate={{ ...taxRate, sequenceNr: index + 1 }}
                    dataTypes={dataTypes}
                    pricingCategories={pricingCategories}
                    onChange={(newTaxRate) => {
                      this.updateTaxRate(newTaxRate, index);
                    }}
                  />
                ),
              });
            }}
          >
            <div className="flex-container justify-between">
              <h2>
                {t('pricing.taxRate.header', { count: index + 1 })}{' '}
                {taxRate.name ? ` - ${taxRate.name}` : null}
              </h2>

              <div className="list-actions">
                <div></div>
                <CloseButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const newTaxRates = [...taxRates];
                    newTaxRates.splice(index, 1);

                    this.setState({ taxRates: newTaxRates });
                    onChange?.(newTaxRates);
                  }}
                />
                <div className="go">
                  <ReactSVG src="/icons/back.svg" />
                </div>
              </div>
            </div>
            <div>
              <div className="flex-container value-list">
                {taxRate.name && (
                  <p className="no-margin-bottom value-list-item">
                    <b>{t('pricing.taxRate.name')}:</b> {taxRate.name}
                  </p>
                )}
                {taxRate.taxRate && (
                  <p className="no-margin-bottom value-list-item">
                    <b>{t('pricing.taxRate.name')}:</b> {taxRate.taxRate?.name}
                  </p>
                )}
              </div>
              {taxRate.description && (
                <p className="no-margin">
                  <b>{t('pricing.taxRate.description')}:</b> {taxRate.description}
                </p>
              )}
            </div>
          </div>
        ))}
        <div className="input-group left">
          <button type="button" onClick={(e) => this.addNewTaxRate(e)}>
            {t('form.label.addPricingTaxRate')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(PricingTaxRatesInput);
