import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateChatPage from '@components/chat/crud/CreateChatPage';

import * as chatActions from '@actions/chatActions';
import * as userActions from '@actions/userActions';

class CreateChatContainer extends PureComponent {
  render() {
    const {
      changeValue,
      getChat,
      createChat,
      createMessage,
      onChatCreated,
      chatPopup,
      ...props
    } = this.props;

    return (
      <CreateChatPage
        {...props}
        onChange={changeValue}
        chatPopup={chatPopup}
        getChat={(id) => getChat(id)}
        createChat={(chat, message) =>
          createChat(chat, message).then((response) => {
            createMessage(response.chat.id, message).then(() => {
              onChatCreated && onChatCreated(response.chat);
            });
          })
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    users: state.user.users,
    usersIsFetching: state.user.usersIsFetching,

    languages: state.platform.languages,
    ...state.chat,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(chatActions.changeChatValue(name, value)),

    getChat: (id) => dispatch(chatActions.getChat(id)),
    createChat: (chat, message) =>
      dispatch(chatActions.createChat(chat, message)),
    createMessage: (id, message) =>
      dispatch(chatActions.createMessage(id, message)),

    searchUsers: (searchParameters, from, to) =>
      dispatch(userActions.searchUsers(searchParameters, from, to)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateChatContainer));
