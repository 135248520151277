import { generalConstants } from '@constants';
import { settingsConstants } from '@constants';

import * as generalApi from '@api/generalApi';

/**
 * Fetching settings
 */
export const startGetSettings = () => ({
  type: settingsConstants.GET_SETTINGS_STARTED,
});

export const updateSettingsFailure = (error) => ({
  type: settingsConstants.UPDATE_SETTINGS_FAILURE,
  ...error,
});

/**
 * Change variable values
 */

export const taxRatesSuccess = (result) => ({
  type: generalConstants.UPDATE_TAX_RATES_SUCCESS,
  ...result,
});

export const getTaxRates = () => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.getTaxRates();

    promise
      .then((result) => {
        dispatch(taxRatesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const updateTaxRates = (taxRates) => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.updateTaxRates(taxRates);

    promise
      .then((result) => {
        dispatch(taxRatesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const attributesSuccess = (result) => ({
  type: generalConstants.UPDATE_ATTRIBUTES_SUCCESS,
  ...result,
});

export const getAttributes = () => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.getAttributes();

    promise
      .then((result) => {
        dispatch(attributesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const updateAttributes = (attributes) => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.updateAttributes(attributes);

    promise
      .then((result) => {
        dispatch(attributesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const pricingCategoriesSuccess = (result) => ({
  type: generalConstants.UPDATE_PRICING_CATEGORIES_SUCCESS,
  ...result,
});

export const getPricingCategories = (includeDisabled = false) => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.getPricingCategories(includeDisabled);

    promise
      .then((result) => {
        dispatch(pricingCategoriesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const updatePricingCategories = (pricingCategories) => {
  return (dispatch) => {
    dispatch(startGetSettings());
    const promise = generalApi.updatePricingCategories(pricingCategories);

    promise
      .then((result) => {
        dispatch(pricingCategoriesSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
      });
    return promise;
  };
};

export const startGetEntityStructure = () => ({
  type: generalConstants.GET_ENTITY_STRUCTURE_STARTED,
});

export const getEntityStructureSuccess = (result) => ({
  type: generalConstants.GET_ENTITY_STRUCTURE_SUCCESS,
  ...result,
});

export const getEntityStructureFailure = (result) => ({
  type: generalConstants.GET_ENTITY_STRUCTURE_FAILURE,
  ...result,
});

export const getEntityStructure = (entityType) => {
  return (dispatch) => {
    dispatch(startGetEntityStructure());
    const promise = generalApi.getEntityStructure(entityType);

    promise
      .then((result) => {
        dispatch(getEntityStructureSuccess(result));
      })
      .catch((error) => {
        dispatch(getEntityStructureFailure(error));
      });
    return promise;
  };
};
