import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class TransportModesSelector extends Component {
  static deafultProps = {
    value: [],
  };

  render() {
    const { t, value, onChange, placeholder } = this.props;
    const options = [
      { label: t('transportMode.maritime'), value: 'maritime' },
      { label: t('transportMode.road'), value: 'road' },
      { label: t('transportMode.rail'), value: 'rail' },
      { label: t('transportMode.air'), value: 'air' },
      { label: t('transportMode.inlandWaterway'), value: 'inlandWaterway' },
    ];
    return (
      <Select
        isMulti={true}
        value={options.filter((option) =>
          value ? value.includes(option.value) : false
        )}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(TransportModesSelector);
