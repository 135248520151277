import * as tripApi from '@api/tripApi';



import { tripConstants } from '../constants';


export const changeValue = (name, value) => ({
  type: tripConstants.TRIP_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching trip
 */
export const startGetTrip = () => ({
  type: tripConstants.GET_TRIP_STARTED,
});

export const getTripSuccess = (result) => ({
  type: tripConstants.GET_TRIP_SUCCESS,
  ...result,
});

export const getTripFailure = (error) => ({
  type: tripConstants.GET_TRIP_FAILURE,
  ...error,
});

export const getTrip = (id) => {
  return (dispatch) => {
    dispatch(startGetTrip());

    const promise = tripApi.get(id);

    promise
      .then((result) => {
        dispatch(getTripSuccess(result));
      })
      .catch((error) => {
        dispatch(getTripFailure(error));
      });

    return promise;
  };
};

export const getPublicTrip = (id, shareCode) => {
  return (dispatch) => {
    dispatch(startGetTrip());

    const promise = tripApi.publicGet(id, shareCode);

    promise
      .then((result) => {
        dispatch(getTripSuccess(result));
      })
      .catch((error) => {
        dispatch(getTripFailure(error));
      });

    return promise;
  };
};

/**
 * Creating trip
 */
export const changeCreateTrip = (name, value) => ({
  type: tripConstants.CREATING_TRIP_CHANGE_VALUE,
  name,
  value,
});
export const startCreateTrip = () => ({
  type: tripConstants.CREATING_TRIP_STARTED,
});

export const createTripSuccess = (result) => ({
  type: tripConstants.CREATING_TRIP_SUCCESS,
  ...result,
});

export const createTripFailure = (error) => ({
  type: tripConstants.CREATING_TRIP_FAILURE,
  ...error,
});

export const createTrip = (trip) => {
  return (dispatch) => {
    dispatch(startCreateTrip());

    const promise = tripApi.create(trip);

    promise
      .then((result) => {
        dispatch(createTripSuccess(result));
      })
      .catch((error) => {
        dispatch(createTripFailure(error));
      });

    return promise;
  };
};
export const createTripWithRoute = (trip) => {
  return (dispatch) => {
    dispatch(startCreateTrip());

    const promise = tripApi.createWithRoute(trip);

    promise
      .then((result) => {
        dispatch(createTripSuccess(result));
      })
      .catch((error) => {
        dispatch(createTripFailure(error));
      });

    return promise;
  };
};

export const startCreateTrips = () => ({
  type: tripConstants.CREATING_TRIPS_STARTED,
});

export const createTripsSuccess = (result) => ({
  type: tripConstants.CREATING_TRIS_SUCCESS,
  ...result,
});

export const createTripsFailure = (error) => ({
  type: tripConstants.CREATING_TRIPS_FAILURE,
  ...error,
});

export const createTrips = (trips) => {
  return (dispatch) => {
    dispatch(startCreateTrips());

    const promise = tripApi.createTrips(trips);

    promise
      .then((result) => {
        dispatch(createTripsSuccess(result));
      })
      .catch((error) => {
        dispatch(createTripsFailure(error));
      });

    return promise;
  };
};

/**
 * Updating trip
 */
export const changeUpdateTrip = (name, value) => ({
  type: tripConstants.UPDATING_TRIP_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateTrip = () => ({
  type: tripConstants.UPDATING_TRIP_STARTED,
});

export const updateTripSuccess = (result) => ({
  type: tripConstants.UPDATING_TRIP_SUCCESS,
  ...result,
});

export const updateTripFailure = (error) => ({
  type: tripConstants.UPDATING_TRIP_FAILURE,
  ...error,
});

export const updateTrip = (trip) => {
  return (dispatch) => {
    dispatch(startUpdateTrip());

    const promise = tripApi.update(trip);

    promise
      .then((result) => {
        dispatch(updateTripSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTripFailure(error));
      });

    return promise;
  };
};

export const updateTripAddDocuments = (trip, documents) => {
  return (dispatch) => {
    dispatch(startUpdateTrip());

    const promise = tripApi.updateTripAddDocuments(trip, documents);

    promise
      .then((result) => {
        dispatch(updateTripSuccess(result));
      })
      .catch((error) => {
        dispatch(updateTripFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting trip
 */
export const startDeleteTrip = () => ({
  type: tripConstants.DELETE_TRIP_STARTED,
});

export const deleteTripSuccess = (result) => ({
  type: tripConstants.DELETE_TRIP_SUCCESS,
  ...result,
});

export const deleteTripFailure = (error) => ({
  type: tripConstants.DELETE_TRIP_FAILURE,
  ...error,
});
export const deleteTrip = (trip) => {
  return (dispatch) => {
    dispatch(startDeleteTrip());

    const promise = tripApi.remove(trip);

    promise
      .then((result) => {
        dispatch(deleteTripSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTripFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching trips
 */
export const startGetTrips = (from) => ({
  type: tripConstants.GET_TRIPS_STARTED,
  from,
});

export const getTripsSuccess = (result, from) => ({
  type: tripConstants.GET_TRIPS_SUCCESS,
  from: from,
  ...result,
});

export const getTripsFailure = (error) => ({
  type: tripConstants.GET_TRIPS_FAILURE,
  ...error,
});

export const getTrips = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTrips(from));

    const promise = tripApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTripsFailure(error));
      });

    return promise;
  };
};

export const searchTrips = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetTrips(from));

    const promise = tripApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTripsFailure(error));
      });

    return promise;
  };
};

/**
 * Confirm a trip - confirming a trip will send
 */
export const startConfirmTrip = (trip) => ({
  type: tripConstants.CONFIRM_TRIP_STARTED,
  trip: trip,
});

export const startConfirmSuccess = (result, trip) => ({
  type: tripConstants.CONFIRM_TRIP_SUCCESS,

  trip: trip,
  ...result,
});

export const startConfirmFailure = (error, trip) => ({
  type: tripConstants.CONFIRM_TRIP_FAILURE,
  ...error,
  trip: trip,
});
export const confirmTrip = (trip) => {
  return (dispatch) => {
    dispatch(startConfirmTrip(trip));

    const promise = tripApi.confirm(trip);

    promise
      .then((result) => {
        dispatch(startConfirmSuccess(result));
      })
      .catch((error) => {
        dispatch(startConfirmFailure(error, trip));
      });

    return promise;
  };
};

/**
 * Starting a trip - attaching it to the vehicle and making it the "active" trip
 */
export const startStartTrip = (trip) => ({
  type: tripConstants.START_TRIP_STARTED,
  trip: trip,
});

export const startTripSuccess = (result, trip) => ({
  type: tripConstants.START_TRIP_SUCCESS,

  trip: trip,
  ...result,
});

export const startTripFailure = (error, trip) => ({
  type: tripConstants.START_TRIP_FAILURE,
  ...error,
  trip: trip,
});
export const startTrip = (trip) => {
  return (dispatch) => {
    dispatch(startStartTrip(trip));

    const promise = tripApi.start(trip);

    promise
      .then((result) => {
        dispatch(startTripSuccess(result));
      })
      .catch((error) => {
        dispatch(startTripFailure(error, trip));
      });

    return promise;
  };
};

/**
 * Finishing a trip - detaching it from the vecicle
 */
export const startFinishTrip = (trip) => ({
  type: tripConstants.FINISH_TRIP_STARTED,
  trip: trip,
});

export const finishTripSuccess = (result, trip) => ({
  type: tripConstants.FINISH_TRIP_SUCCESS,
  trip: trip,

  ...result,
});

export const finishTripFailure = (error, trip) => ({
  type: tripConstants.FINISH_TRIP_FAILURE,
  ...error,
  trip: trip,
});

export const finishTrip = (trip) => {
  return (dispatch) => {
    dispatch(startFinishTrip(trip));

    const promise = tripApi.finish(trip);

    promise
      .then((result) => {
        dispatch(finishTripSuccess(result, trip));
      })
      .catch((error) => {
        dispatch(finishTripFailure(error, trip));
      });

    return promise;
  };
};

/**
 * Finishing a Trip and starting a new Trip.
 */
export const startFinishAndStartNewTrip = () => ({
  type: tripConstants.FINISH_TRIP_AND_START_NEW_STARTED,
});

export const finishAndStartNewTripSuccess = (result) => ({
  type: tripConstants.FINISH_TRIP_AND_START_NEW_SUCCESS,
  ...result,
});

export const finishAndStartNewTripFailure = (error) => ({
  type: tripConstants.FINISH_TRIP_AND_START_NEW_FAILURE,
  ...error,
});

export const finishAndStartNewTrip = (trip, newTrip) => {
  return (dispatch) => {
    dispatch(startFinishAndStartNewTrip());
    dispatch(startFinishTrip(trip));
    dispatch(startStartTrip(newTrip));

    const promise = tripApi.finishAndStart(trip, newTrip);

    promise
      .then((result) => {
        dispatch(finishAndStartNewTripSuccess(result));
        dispatch(finishTripSuccess({}, trip));
        dispatch(startTripSuccess({}, newTrip));
      })
      .catch((error) => {
        dispatch(finishAndStartNewTripFailure(error));
        dispatch(finishTripFailure({}, trip));
        dispatch(startTripFailure({}, newTrip));
      });

    return promise;
  };
};

/**
 * Cancelling a trip - detaching it from the vehicle
 */
export const startCancelTrip = (trip) => ({
  type: tripConstants.CANCEL_TRIP_STARTED,
  trip: trip,
});

export const cancelTripSuccess = (result) => ({
  type: tripConstants.CANCEL_TRIP_SUCCESS,
  ...result,
});
export const cancelTripFailure = (error, trip) => ({
  type: tripConstants.CANCEL_TRIP_FAILURE,
  ...error,
  trip: trip,
});

export const cancelTrip = (trip) => {
  return (dispatch) => {
    dispatch(startCancelTrip(trip));

    const promise = tripApi.cancel(trip);

    promise
      .then((result) => {
        dispatch(cancelTripSuccess(result));
      })
      .catch((error) => {
        dispatch(cancelTripFailure(error, trip));
      });

    return promise;
  };
};

/**
 * Finishing a trip - detaching it from the vehicle
 */
export const startUpdateTripAction = (trip, action) => ({
  type: tripConstants.UPDATE_TRIP_ACTION_STARTED,
  trip: trip,
  action: action,
});

export const updateTripActionSuccess = (result, trip, action) => ({
  type: tripConstants.UPDATE_TRIP_ACTION_SUCCESS,
  ...result,
});

export const updateTripActionFailure = (error, trip, action) => ({
  type: tripConstants.UPDATE_TRIP_ACTION_FAILURE,
  ...error,
});

export const updateTripAction = (trip, action) => {
  return (dispatch) => {
    dispatch(startUpdateTripAction(trip, action));

    const promise = tripApi.updateTripAction(trip, action);

    promise
      .then((result) => {
        dispatch(updateTripActionSuccess(result, trip, action));
      })
      .catch((error) => {
        dispatch(updateTripActionFailure(error, trip, action));
      });

    return promise;
  };
};

export const updateTripActionLifeCycle = (trip, action, lifeCycle) => {
  return (dispatch) => {
    dispatch(startUpdateTripAction(trip, action));

    const promise = tripApi.updateTripActionLifeCycle(trip, action, lifeCycle);

    promise
      .then((result) => {
        dispatch(updateTripActionSuccess(result, trip, action));
      })
      .catch((error) => {
        dispatch(updateTripActionFailure(error, trip, action));
      });

    return promise;
  };
};

export const updateTripActionTimes = (trip, action, startTime, endTime) => {
  return (dispatch) => {
    dispatch(startUpdateTripAction(trip, action));

    const promise = tripApi.updateTripActionTimes(
      trip,
      action,
      startTime,
      endTime
    );

    promise
      .then((result) => {
        dispatch(updateTripActionSuccess(result, trip, action));
      })
      .catch((error) => {
        dispatch(updateTripActionFailure(error, trip, action));
      });

    return promise;
  };
};