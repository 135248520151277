import React, { Component } from 'react';

import Select from 'react-select';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import FinancialLineInput from '@components/administration/quote/crud/FinancialLineInput';

import Attachments from '@uicomponents/Attachments';
import Loader from '@uicomponents/Loader';
import Switch from '@uicomponents/Switch';
import FormInput from '@uiinputs/FormInput';

import Association from '@models/general/Association';

import { uuidv4 } from '@utils/commonUtils';
import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { isExternal } from '@utils/userUtils';

import AdministrationInput from '../../administration/crud/AdministrationInput';
import ConsignmentsInput from '../../consignment/crud/ConsignmentsInput';
import CustomerInput from '../../customer/crud/CustomerInput';
import ContactDetailsInput from '../../general/crud/ContactDetailsInput';
import NotesInput from '../../note/crud/NotesInput';
import TeamInput from '../../team/TeamInput';
import DeliveryTermsSelector from '../DeliveryTermsSelector';

export default class TransportOrderForm extends Component {
  constructor(props) {
    super(props);

    this.validator = new SimpleReactValidator();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit(e) {
    e.preventDefault();
    const { onSubmit, quoteForm, formTransportOrder } = this.props;

    const newTransportOrder = { ...formTransportOrder };
    newTransportOrder.quote = formTransportOrder?.spotQuote ? { ...quoteForm } : null;
    onSubmit && onSubmit(newTransportOrder);
  }

  onChange = (value, property) => {
    const { onChange, formTransportOrder } = this.props;
    const newTransportOrder = { ...formTransportOrder };
    newTransportOrder[property] = value;

    onChange('formTransportOrder', newTransportOrder, event);
  };

  quoteUpdate = (field) => {
    const { changeValue, quoteForm, formTransportOrder } = this.props;

    const newQuoteForm = { ...quoteForm };

    switch (field) {
      case 'customer':
        newQuoteForm.targetBusiness = formTransportOrder?.customer?.business;

      case 'administration':
        newQuoteForm.dueAt = moment().add(
          formTransportOrder?.administration?.dueDays || 14,
          'days'
        );
        newQuoteForm.administration = formTransportOrder?.administration;
      case 'value':
        newQuoteForm.currency = formTransportOrder?.value?.currency;
    }

    changeValue('quoteForm', newQuoteForm);
  };

  render() {
    const {
      t,
      user,
      onChange,
      formTransportOrder,
      currencies,
      isFetchingCurrencies,
      platform,
      simpleForms,
      changeValue,
    } = this.props;

    if (currencies.length < 1 && isFetchingCurrencies) {
      return <Loader />;
    }

    return (
      <div
        className="step-form transport-order-form"
        key={`${formTransportOrder.id}-${formTransportOrder.versionNumber}-${formTransportOrder.nonce}`}
      >
        <form className="form active no-padding-top">
          <div className="input-container">
            <div className="text-inputs">
              {!simpleForms && (
                <>
                  <FormInput
                    type="text"
                    wrapperClass="no-margin-top"
                    label="form.label.name"
                    required={true}
                    value={formTransportOrder.name}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.name = event.target.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                  {this.validator.message(
                    t('form.label.name'),
                    formTransportOrder.name,
                    'required'
                  )}
                </>
              )}

              <div className="input-group">
                <CustomerInput
                  {...this.props}
                  key={`${
                    formTransportOrder.customer && formTransportOrder.customer.business
                      ? formTransportOrder.customer.business.id
                      : ''
                  }`}
                  placeholder={`${t('form.label.selectCustomer')}*`}
                  simpleForms={simpleForms}
                  customer={formTransportOrder.customer ? { ...formTransportOrder.customer } : null}
                  onChange={(customer) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.customer = customer;

                    onChange('formTransportOrder', newTransportOrder);
                    if (formTransportOrder.spotQuote) {
                      this.quoteUpdate('customer');
                    }
                  }}
                />
                {this.validator.message(
                  t('form.label.customer'),
                  formTransportOrder.customer,
                  'required'
                )}
              </div>
              <FormInput
                type="text"
                label="form.label.referenceDescription"
                value={formTransportOrder.referenceDescription}
                onChange={(event) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.referenceDescription = event.target.value;

                  onChange('formTransportOrder', newTransportOrder, event);
                }}
              />
              <div className="input-group">
                <div className="input-group no-margin-top">
                  <DeliveryTermsSelector
                    key={formTransportOrder.deliveryTerms}
                    value={formTransportOrder.deliveryTerms}
                    defaultValue={platform.planningSettings?.defaultDeliveryTerms}
                    placeholder={`${t('form.label.selectDeliveryTerms')}*`}
                    onChange={(event) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.deliveryTerms = event.value;

                      onChange('formTransportOrder', newTransportOrder, event);
                    }}
                  />
                </div>
                {this.validator.message(
                  t('form.label.deliveryTerms'),
                  formTransportOrder.deliveryTerms,
                  'required'
                )}
              </div>
              {!isExternal(user) && (
                <>
                  <div className="input-group">
                    <div className="input-group no-margin-top">
                      <TeamInput
                        key={formTransportOrder?.team}
                        value={formTransportOrder.team}
                        placeholder={t('form.label.selectTeam')}
                        onChange={(newTeam) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.team = { ...newTeam };

                          onChange('formTransportOrder', newTransportOrder);
                        }}
                      />
                    </div>
                  </div>
                  {platform?.features?.map((feature) => feature.name)?.includes('financial') && (
                    <div className="input-group">
                      <AdministrationInput
                        key={formTransportOrder.administration?.id || ''}
                        administration={formTransportOrder.administration}
                        onChange={(newAdministration) => {
                          const newTransportOrder = { ...formTransportOrder };
                          newTransportOrder.administration = newAdministration;

                          onChange('formTransportOrder', newTransportOrder, event);
                          if (formTransportOrder.spotQuote) {
                            this.quoteUpdate('administration');
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
            {!simpleForms && (
              <>
                <FormInput
                  type="textarea"
                  label="form.label.description"
                  value={formTransportOrder.description}
                  onChange={(event) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.description = event.target.value;

                    onChange('formTransportOrder', newTransportOrder, event);
                  }}
                />
              </>
            )}
            <div className="input-group">
              <div>{t('form.label.spotquote')}</div>
              <Switch
                checked={formTransportOrder.spotQuote}
                onChange={(e, newState) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.spotQuote = newState;

                  if (newState) {
                    if (formTransportOrder.quote) {
                      changeValue('quoteForm', formTransportOrder.quote);
                    } else {
                      const initialQuoteForm = {
                        name: '',
                        description: '',
                        direction: 'in',
                        dueAt: moment().add(
                          formTransportOrder?.administration?.dueDays || 14,
                          'days'
                        ),
                        targetBusiness: formTransportOrder?.customer?.business,
                        administration: formTransportOrder?.administration,
                        currency: formTransportOrder?.value?.currency,
                        lines: [
                          {
                            ...{
                              name: '',
                              value: {
                                amount: null,
                                taxRate: this.props?.variables?.taxRates?.[0],
                              },
                              sequenceNr: 0,
                              quantity: 1,
                              description: '',
                              nonce: uuidv4(),
                              pricingCategory: null,
                              endDateTime: null,
                              startDateTime: null,
                            },
                          },
                        ],
                        subTotal: {
                          amount: 0,
                        },
                        total: {
                          amount: 0,
                        },
                        tax: {
                          amount: 0,
                          inclusiveOfTax: false,
                        },
                      };
                      changeValue('quoteForm', initialQuoteForm);
                    }
                  } else {
                    changeValue('quoteForm', null);
                  }

                  onChange('formTransportOrder', newTransportOrder);
                }}
              />
            </div>
            {formTransportOrder?.spotQuote && (
              <FinancialLineInput {...this.props} formType="quoteForm" form={quoteForm} />
            )}
            {!simpleForms && (
              <div className="input-group upload-files">
                <h3 className="no-margin-top no-margin-bottom">{t('form.label.documents')}</h3>
                <Attachments
                  {...this.props}
                  key={formTransportOrder?.documents}
                  files={formTransportOrder?.documents?.map((document) => {
                    return document.entity.file;
                  })}
                  onChange={(files) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.documents = files.map((file) => {
                      const newFile = { ...file };
                      return new Association('inline', {
                        name: newFile.originalName,
                        mimeType: newFile.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: newFile.url,
                        },
                        file: newFile,
                      });
                    });
                    onChange('formTransportOrder', newTransportOrder);
                  }}
                />
              </div>
            )}
            <div className="input-group consignments-input">
              <h3>{t('form.label.consignments')}</h3>
              <ConsignmentsInput
                {...this.props}
                key={formTransportOrder.consignments}
                consignments={
                  formTransportOrder.consignments ? [...formTransportOrder.consignments] : []
                }
                onChange={(newConsignments) => {
                  const newTransportOrder = { ...formTransportOrder };
                  newTransportOrder.consignments = newConsignments;

                  onChange('formTransportOrder', newTransportOrder);
                }}
              />
            </div>
            {!simpleForms && (
              <>
                <div className="input-group notes">
                  <h3>{t('form.label.notes')}</h3>
                  <NotesInput
                    key={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    notes={formTransportOrder.notes ? [...formTransportOrder.notes] : []}
                    onChange={(notes) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.notes = notes;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
                <div className="input-group">
                  <h3>{t('form.label.contactDetails')}</h3>
                  <ContactDetailsInput
                    key={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    contactDetails={
                      formTransportOrder.contactDetails
                        ? [...formTransportOrder.contactDetails]
                        : []
                    }
                    onChange={(contactDetails) => {
                      const newTransportOrder = { ...formTransportOrder };
                      newTransportOrder.contactDetails = contactDetails;

                      onChange('formTransportOrder', newTransportOrder);
                    }}
                  />
                </div>
              </>
            )}
            <div className="input-group more right buttons">
              {!formTransportOrder.updatedAt && (
                <button
                  disabled={!this.validator.allValid()}
                  onClick={(e) => {
                    const newTransportOrder = { ...formTransportOrder };
                    newTransportOrder.status = 'requested';

                    onChange('formTransportOrder', newTransportOrder);
                  }}
                >
                  {t('form.saveAsRequested')}
                </button>
              )}
              <input
                type="submit"
                disabled={!this.validator.allValid()}
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.save')}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
