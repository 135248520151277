import * as boardApi from '../../api/boardApi';
import { boardConstants, todoMainConstants } from '../constants';
import * as todoMainActions from './todoMainActions';

/**
 * Creating boards
 */
export const createBoardStarted = () => ({
  type: boardConstants.CREATING_BOARD_STARTED,
});

export const createBoardSuccess = (result) => ({
  type: boardConstants.CREATING_BOARD_SUCCESS,
  ...result,
});

export const createBoardFailure = (error) => ({
  type: boardConstants.CREATING_BOARD_FAILURE,
  ...error,
});

export const createBoard = (board) => {
  return (dispatch) => {
    dispatch(createBoardStarted());

    const promise = boardApi.createBoard(board);
    promise
      .then((result) => {
        dispatch(createBoardSuccess(result));
      })
      .catch((error) => {
        dispatch(createBoardFailure(error));
      });
    return promise;
  };
};

/**
 * Updating board
 */
export const updateBoardStarted = () => ({
  type: boardConstants.UPDATING_BOARD_STARTED,
});

export const updateBoardSuccess = (result) => ({
  type: boardConstants.UPDATING_BOARD_SUCCESS,
  ...result,
});

export const updateBoardFailure = (error) => ({
  type: boardConstants.UPDATING_BOARD_FAILURE,
  ...error,
});

export const updateBoard = (board) => {
  return (dispatch) => {
    dispatch(updateBoardStarted());

    const promise = boardApi.updateBoard(board);

    promise
      .then((result) => {
        dispatch(updateBoardSuccess(result));
      })
      .catch((error) => {
        dispatch(updateBoardFailure(error));
      });

    return promise;
  };
};

/**
 * Delete a Board
 */
export const deleteBoardStarted = () => ({
  type: boardConstants.DELETE_BOARD_STARTED,
});

export const deleteBoardSuccess = (result) => ({
  type: boardConstants.DELETE_BOARD_SUCCESS,
  ...result,
});

export const deleteBoardFailure = (error) => ({
  type: boardConstants.DELETE_BOARD_FAILURE,
  ...error,
});

export const deleteBoard = (board) => {
  return (dispatch) => {
    dispatch(deleteBoardStarted());

    const promise = boardApi.deleteBoard(board);
    promise
      .then((result) => {
        dispatch(deleteBoardSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteBoardFailure(error));
      });
    return promise;
  };
};

/**
 * getting paginated boards
 */
export const gettingBoardsStarted = (from) => ({
  type: boardConstants.GETTING_BOARDS_STARTED,
  from: from,
});

export const gettingBoardsSuccess = (result, from) => ({
  type: boardConstants.GETTING_BOARDS_SUCCESS,
  from: from,
  ...result,
});

export const gettingBoardsFailure = (error) => ({
  type: boardConstants.GETTING_BOARDS_FAILURE,
  ...error,
});

export const getBoards = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(gettingBoardsStarted(from));

    const promise = boardApi.getPaginatedBoards(from, amount);
    promise
      .then((result) => {
        dispatch(gettingBoardsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(gettingBoardsFailure(error));
      });

    return promise;
  };
};

export const searchBoards = (searchParameters, from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(gettingBoardsStarted(from));

    const promise = boardApi.searchBoards(searchParameters, from, amount);
    promise
      .then((result) => {
        dispatch(gettingBoardsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(gettingBoardsFailure(error));
      });

    return promise;
  };
};

/**
 * get single board
 */
export const getBoardStarted = () => ({
  type: boardConstants.GET_BOARD_STARTED,
});

export const getBoardSuccess = (result) => ({
  type: boardConstants.GET_BOARD_SUCCESS,
  ...result,
});

export const getBoardFailure = (error) => ({
  type: boardConstants.GET_BOARD_FAILURE,
  ...error,
});

export const getBoardById = (id) => {
  return (dispatch) => {
    dispatch(getBoardStarted());
    dispatch(todoMainActions.setView(todoMainConstants.LANES_VIEW));

    const promise = boardApi.getBoardById(id);
    promise
      .then((result) => {
        dispatch(getBoardSuccess(result));
      })
      .catch((error) => {
        dispatch(getBoardFailure(error));
      });

    return promise;
  };
};
