import { integrationConstants } from '@constants';

import * as integrationApi from '@api/integrationApi';

export const changeValue = (name, value) => ({
  type: integrationConstants.INTEGRATION_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching integration
 */
export const startGetIntegration = () => ({
  type: integrationConstants.GET_INTEGRATION_STARTED,
});

export const getIntegrationSuccess = (result) => ({
  type: integrationConstants.GET_INTEGRATION_SUCCESS,
  ...result,
});

export const getIntegrationFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATION_FAILURE,
  ...error,
});

export const getIntegration = (id) => {
  return (dispatch) => {
    dispatch(startGetIntegration());

    const promise = integrationApi.get(id);

    promise
      .then((result) => {
        dispatch(getIntegrationSuccess(result));
      })
      .catch((error) => {
        dispatch(getIntegrationFailure(error));
      });

    return promise;
  };
};

/**
 * Creating integration
 */
export const changeCreateIntegration = (name, value) => ({
  type: integrationConstants.CREATING_INTEGRATION_CHANGE_VALUE,
  name,
  value,
});
export const startCreateIntegration = () => ({
  type: integrationConstants.CREATING_INTEGRATION_STARTED,
});

export const createIntegrationSuccess = (result) => ({
  type: integrationConstants.CREATING_INTEGRATION_SUCCESS,
  ...result,
});

export const createIntegrationFailure = (error) => ({
  type: integrationConstants.CREATING_INTEGRATION_FAILURE,
  ...error,
});

export const createIntegration = (integration) => {
  return (dispatch) => {
    dispatch(startCreateIntegration());

    const promise = integrationApi.create(integration);

    promise
      .then((result) => {
        dispatch(createIntegrationSuccess(result));
      })
      .catch((error) => {
        dispatch(createIntegrationFailure(error));
      });

    return promise;
  };
};

/**
 * Updating integration
 */
export const changeUpdateIntegration = (name, value) => ({
  type: integrationConstants.UPDATING_INTEGRATION_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateIntegration = () => ({
  type: integrationConstants.UPDATING_INTEGRATION_STARTED,
});

export const updateIntegrationSuccess = (result) => ({
  type: integrationConstants.UPDATING_INTEGRATION_SUCCESS,
  ...result,
});

export const updateIntegrationFailure = (error) => ({
  type: integrationConstants.UPDATING_INTEGRATION_FAILURE,
  ...error,
});

export const updateIntegration = (integration) => {
  return (dispatch) => {
    dispatch(startUpdateIntegration());

    const promise = integrationApi.update(integration);

    promise
      .then((result) => {
        dispatch(updateIntegrationSuccess(result));
      })
      .catch((error) => {
        dispatch(updateIntegrationFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting integration
 */
export const startDeleteIntegration = () => ({
  type: integrationConstants.DELETE_INTEGRATION_STARTED,
});

export const deleteIntegrationSuccess = (result) => ({
  type: integrationConstants.DELETE_INTEGRATION_SUCCESS,
  ...result,
});

export const deleteIntegrationFailure = (error) => ({
  type: integrationConstants.DELETE_INTEGRATION_FAILURE,
  ...error,
});
export const deleteIntegration = (integration) => {
  return (dispatch) => {
    dispatch(startDeleteIntegration());

    const promise = integrationApi.remove(integration);

    promise
      .then((result) => {
        dispatch(deleteIntegrationSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteIntegrationFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching integrations
 */
export const startGetIntegrations = (from) => ({
  type: integrationConstants.GET_INTEGRATIONS_STARTED,
  from: from,
});

export const getIntegrationsSuccess = (result, from) => ({
  type: integrationConstants.GET_INTEGRATIONS_SUCCESS,
  from: from,
  ...result,
});

export const getIntegrationsFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATIONS_FAILURE,
  ...error,
});

export const getIntegrations = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetIntegrations(from));

    const promise = integrationApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationsFailure(error));
      });

    return promise;
  };
};

export const searchIntegrations = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetIntegrations(from));

    const promise = integrationApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching integration logs
 */
export const startGetIntegrationLogs = (from) => ({
  type: integrationConstants.GET_INTEGRATION_LOGS_STARTED,
  from: from,
});

export const getIntegrationLogsSuccess = (result, from) => ({
  type: integrationConstants.GET_INTEGRATION_LOGS_SUCCESS,
  from: from,
  ...result,
});

export const getIntegrationLogsFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATION_LOGS_FAILURE,
  ...error,
});

export const getIntegrationLogs = (id = '', from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetIntegrationLogs(from));

    const promise = integrationApi.logs(id, from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationLogsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationLogsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching integration objects
 */
export const startGetIntegrationObjects = (from) => ({
  type: integrationConstants.GET_INTEGRATION_OBJECTS_STARTED,
  from: from,
});

export const getIntegrationObjectsSuccess = (result, from) => ({
  type: integrationConstants.GET_INTEGRATION_OBJECTS_SUCCESS,
  from: from,
  ...result,
});

export const getIntegrationObjectsFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATION_OBJECTS_FAILURE,
  ...error,
});

export const getIntegrationObjects = (id = '', from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetIntegrationObjects(from));

    const promise = integrationApi.objects(id, from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationObjectsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationObjectsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching integration tasks
 */
export const startGetIntegrationTasks = (from) => ({
  type: integrationConstants.GET_INTEGRATION_TASKS_STARTED,
  from: from,
});

export const getIntegrationTasksSuccess = (result, from) => ({
  type: integrationConstants.GET_INTEGRATION_TASKS_SUCCESS,
  from: from,
  ...result,
});

export const getIntegrationTasksFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATION_TASKS_FAILURE,
  ...error,
});

export const getIntegrationTasks = (id = '', from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetIntegrationTasks(from));

    const promise = integrationApi.tasks(id, from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationTasksSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationTasksFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching integration request logs
 */
export const startGetIntegrationRequestLogs = (from) => ({
  type: integrationConstants.GET_INTEGRATION_REQUEST_LOGS_STARTED,
  from: from,
});

export const getIntegrationRequestLogsSuccess = (result, from) => ({
  type: integrationConstants.GET_INTEGRATION_REQUEST_LOGS_SUCCESS,
  from: from,
  ...result,
});

export const getIntegrationRequestLogsFailure = (error) => ({
  type: integrationConstants.GET_INTEGRATION_REQUEST_LOGS_FAILURE,
  ...error,
});

export const getIntegrationRequestLogs = (id = '', from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetIntegrationRequestLogs(from));

    const promise = integrationApi.requestLogs(id, from, amount);

    promise
      .then((result) => {
        dispatch(getIntegrationRequestLogsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getIntegrationRequestLogsFailure(error));
      });

    return promise;
  };
};