export default class DataSource {
  id;
  uuid;

  directionType;
  fields;

  constructor() {
    this.directionType = 'in';
    this.fields = [];
    this.allowedIps = [];
  }
}
