import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Location from '@models/location/Location';

import LocationForm from './LocationForm';

export default class CreateLocationPage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newLocation = new Location('latLonPointGeoReference');
    newLocation.name = targetName || '';
    onChange('formLocation', newLocation);
  }

  render() {
    const { t, formLocation, isFetchingLocation, createLocation } = this.props;

    if (!formLocation || isFetchingLocation) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('location.new')}</h1>
        <LocationForm
          {...this.props}
          key={formLocation.id}
          onSubmit={(location) => createLocation(location)}
        />
      </AsideView>
    );
  }
}
