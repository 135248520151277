import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreatePricingVariableIndexPage from '@components/pricingVariableIndex/crud/CreatePricingVariableIndexPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as pricingVariableIndexActions from '@actions/pricingVariableIndexActions';

class CreatePricingVariableIndexContainer extends PureComponent {
  render() {
    const {
      changeValue,
      createPricingVariableIndex,
      popStack,
      callback,
      ...props
    } = this.props;
    return (
      <CreatePricingVariableIndexPage
        {...props}
        onChange={changeValue}
        createPricingVariableIndex={(pricingVariableIndex) =>
          createPricingVariableIndex(pricingVariableIndex).then((response) => {
            popStack?.();
            callback?.(response.pricingVariableIndex);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.pricingVariableIndex,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createPricingVariableIndex: (pricingVariableIndex) =>
      dispatch(
        pricingVariableIndexActions.createPricingVariableIndex(
          pricingVariableIndex
        )
      ),
    changeValue: (name, value) =>
      dispatch(pricingVariableIndexActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreatePricingVariableIndexContainer));
