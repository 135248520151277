import api from '../api';

export const createComment = (comment) => {
  return api.post(`/toDo/comment`, comment);
};

export const updateComment = (comment) => {
  return api.put(`/toDo/comment`, comment);
};

export const deleteComment = (comment) => {
  return api.delete(`/toDo/comment/${comment.id}`);
};

export const getComments = (todo, from, amount) => {
  return api.get(`/toDo/${todo.id}/comments/${from}/${amount}`);
};
