import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import PricingVariableIndex from '@models/pricingVariableIndex/PricingVariableIndex';

import PricingVariableIndexForm from './PricingVariableIndexForm';

export default class CreatePricingVariableIndexPage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newPricingVariableIndex = new PricingVariableIndex(
      'latLonPointGeoReference'
    );
    newPricingVariableIndex.name = targetName || '';
    onChange('formPricingVariableIndex', newPricingVariableIndex);
  }

  render() {
    const {
      t,
      formPricingVariableIndex,
      isFetchingPricingVariableIndex,
      createPricingVariableIndex,
    } = this.props;

    if (!formPricingVariableIndex || isFetchingPricingVariableIndex) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('pricingVariableIndex.new')}</h1>
        <PricingVariableIndexForm
          {...this.props}
          key={formPricingVariableIndex.id}
          onSubmit={(pricingVariableIndex) =>
            createPricingVariableIndex(pricingVariableIndex)
          }
        />
      </AsideView>
    );
  }
}
