import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

class ADRView extends Component {
  render() {
    const { t, adr } = this.props;
    return (
      <div className="adr-view">
        {adr.UNNumber && (
          <div className="adr-view__UNNumber">
            <b>{t('adr.UNNumber')}</b>: {adr.UNNumber}
          </div>
        )}
        {adr.language && (
          <div className="adr-view__language">
            <b>{t('adr.language')}</b>: {adr.language}
          </div>
        )}
        {adr.description && (
          <div className="adr-view__description">
            <b>{t('adr.description')}</b>: {adr.description}
          </div>
        )}
        {adr.technicalName && (
          <div className="adr-view__technicalName">
            <b>{t('adr.technicalName')}</b>: {adr.technicalName}
          </div>
        )}
        {adr.waste && (
          <div className="adr-view__waste">
            <b>{t('adr.waste')}</b>: {adr.waste ? t('yes') : t('no')}
          </div>
        )}
        {adr.environmentallyHazardous && (
          <div className="adr-view__environmentallyHazardous">
            <b>{t('adr.environmentallyHazardous')}</b>:{' '}
            {adr.environmentallyHazardous ? t('yes') : t('no')}
          </div>
        )}
        {adr.class && (
          <div className="adr-view__class">
            <b>{t('adr.class')}</b>: {adr.class}
          </div>
        )}
        {adr.classificationCode && (
          <div className="adr-view__classificationCode">
            <b>{t('adr.classificationCode')}</b>: {adr.classificationCode}
          </div>
        )}
        {adr.packagingGroup && (
          <div className="adr-view__packagingGroup">
            <b>{t('adr.packagingGroup')}</b>: {adr.packagingGroup}
          </div>
        )}
        {adr.dangerNumber && (
          <div className="adr-view__dangerNumber">
            <b>{t('adr.dangerNumber')}</b>: {adr.dangerNumber}
          </div>
        )}
        {adr.tunnelCode && (
          <div className="adr-view__tunnelCode">
            <b>{t('adr.tunnelCode')}</b>: {adr.tunnelCode}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(ADRView);
