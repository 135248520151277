
export const complaintConstants = {
    COMPLAINT_CHANGE_VALUE: "COMPLAINT_CHANGE_VALUE",

    GET_COMPLAINT_STARTED: "GET_COMPLAINT_STARTED",
    GET_COMPLAINT_SUCCESS: "GET_COMPLAINT_SUCCESS",
    GET_COMPLAINT_FAILURE: "GET_COMPLAINT_FAILURE",

    GET_COMPLAINTS_STARTED: "GET_COMPLAINTS_STARTED",
    GET_COMPLAINTS_SUCCESS: "GET_COMPLAINTS_SUCCESS",
    GET_COMPLAINTS_FAILURE: "GET_COMPLAINTS_FAILURE",

    SEARCH_COMPLAINTS_STARTED: "SEARCH_COMPLAINTS_STARTED",
    SEARCH_COMPLAINTS_SUCCESS: "SEARCH_COMPLAINTS_SUCCESS",
    SEARCH_COMPLAINTS_FAILURE: "SEARCH_COMPLAINTS_FAILURE",

    CREATING_COMPLAINT_STARTED: "CREATING_COMPLAINT_STARTED",
    CREATING_COMPLAINT_SUCCESS: "CREATING_COMPLAINT_SUCCESS",
    CREATING_COMPLAINT_FAILURE: "CREATING_COMPLAINT_FAILURE",

    UPDATING_COMPLAINT_STARTED: "UPDATING_COMPLAINT_STARTED",
    UPDATING_COMPLAINT_SUCCESS: "UPDATING_COMPLAINT_SUCCESS",
    UPDATING_COMPLAINT_FAILURE: "UPDATING_COMPLAINT_FAILURE",

    DELETE_COMPLAINT_STARTED: "DELETE_COMPLAINT_STARTED",
    DELETE_COMPLAINT_SUCCESS: "DELETE_COMPLAINT_SUCCESS",
    DELETE_COMPLAINT_FAILURE: "DELETE_COMPLAINT_FAILURE",
};
