
export const planConstants = {
    PLAN_CHANGE_VALUE: "PLAN_CHANGE_VALUE",

    GET_PLAN_STARTED: "GET_PLAN_STARTED",
    GET_PLAN_SUCCESS: "GET_PLAN_SUCCESS",
    GET_PLAN_FAILURE: "GET_PLAN_FAILURE",

    GET_PLANS_STARTED: "GET_PLANS_STARTED",
    GET_PLANS_SUCCESS: "GET_PLANS_SUCCESS",
    GET_PLANS_FAILURE: "GET_PLANS_FAILURE",

    SEARCH_PLANS_STARTED: "SEARCH_PLANS_STARTED",
    SEARCH_PLANS_SUCCESS: "SEARCH_PLANS_SUCCESS",
    SEARCH_PLANS_FAILURE: "SEARCH_PLANS_FAILURE",

    CREATING_PLAN_STARTED: "CREATING_PLAN_STARTED",
    CREATING_PLAN_SUCCESS: "CREATING_PLAN_SUCCESS",
    CREATING_PLAN_FAILURE: "CREATING_PLAN_FAILURE",

    UPDATING_PLAN_STARTED: "UPDATING_PLAN_STARTED",
    UPDATING_PLAN_SUCCESS: "UPDATING_PLAN_SUCCESS",
    UPDATING_PLAN_FAILURE: "UPDATING_PLAN_FAILURE",

    DELETE_PLAN_STARTED: "DELETE_PLAN_STARTED",
    DELETE_PLAN_SUCCESS: "DELETE_PLAN_SUCCESS",
    DELETE_PLAN_FAILURE: "DELETE_PLAN_FAILURE",
};
