export const settingsConstants = {
  GET_SETTINGS_STARTED: 'GET_SETTINGS_STARTED',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_FAILURE: 'GET_SETTINGS_FAILURE',

  UPDATE_SETTINGS_STARTED: 'UPDATE_SETTINGS_STARTED',
  UPDATE_SETTINGS_SUCCESS: 'UPDATE_SETTINGS_SUCCESS',
  UPDATE_SETTINGS_FAILURE: 'UPDATE_SETTINGS_FAILURE',
  SETTINGS_ON_CHANGE: 'SETTINGS_ON_CHANGE',

  UPDATE_TUTORIAL: 'UPDATE_TUTORIAL',
  UPDATE_TUTORIAL_ERROR_MESSAGE: 'UPDATE_TUTORIAL_ERROR_MESSAGE',
  SET_PAYMENT_RETRY_CONFIG: 'SET_PAYMENT_RETRY_CONFIG',
};
