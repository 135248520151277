import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Attribute from '@models/attribute/Attribute';

import AttributeForm from './AttributeForm';

export default class CreateAttributePage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newAttribute = new Attribute();
    newAttribute.name = targetName || '';
    onChange('formAttribute', newAttribute);
  }

  render() {
    const { t, formAttribute, isFetchingAttribute, createAttribute } =
      this.props;

    if (!formAttribute || isFetchingAttribute) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('attribute.new')}</h1>
        <AttributeForm
          {...this.props}
          key={formAttribute.id}
          onSubmit={(attribute) => createAttribute(attribute)}
        />
      </AsideView>
    );
  }
}
