import React, { Component } from 'react';

import ItemView from './ItemView';
import TransportEquipmentView from './TransportEquipmentView';

export default class GoodsView extends Component {
  render() {
    const { goods } = this.props;

    return goods.type === 'transportEquipment' ? (
      <TransportEquipmentView {...this.props} />
    ) : (
      <ItemView {...this.props} />
    );
  }
}
