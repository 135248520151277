import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChatPage from '@components/chat/ChatPage';

import * as chatActions from '@actions/chatActions';
import * as navigationActions from '@actions/navigationActions';

class ChatContainer extends PureComponent {
  render() {
    const { getChat, chatPopup, ...props } = this.props;
    return (
      <ChatPage
        {...props}
        chatPopup={chatPopup}
        getChat={(id) => getChat(id)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    chatIsFetching: state.chat.chatIsFetching,
    chat: state.chat.chat,
    activeChats: state.chat.activeChats,
    maximizeChatList: state.chat.maximizeChatList,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChat: (id) => dispatch(chatActions.getChat(id)),
    changeValue: (name, value) =>
      dispatch(chatActions.changeChatValue(name, value)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ChatContainer));
