import { capacityConstants } from '@constants/capacity.constants';

const initialState = {
  isFetchingCapacity: false,
  formCapacity: null,
  capacity: null,

  isFetchingCapacities: false,
  isFetchingCapacitiesFrom: 0,
  capacitiesTotalResults: 0,
  capacitiesLoaded: 0,
  capacities: [],
};

export default function capacity(state = initialState, action) {
  switch (action.type) {
    case capacityConstants.CAPACITY_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case capacityConstants.GET_CAPACITY_STARTED:
      return { ...state, isFetchingCapacity: true };
    case capacityConstants.GET_CAPACITY_FAILURE:
      return {
        ...state,
        isFetchingCapacity: false,
        error: action.message || '',
      };
    case capacityConstants.GET_CAPACITY_SUCCESS:
      return {
        ...state,
        isFetchingCapacity: false,
        capacity: action.capacity,
      };

    case capacityConstants.GET_CAPACITIES_STARTED:
      return {
        ...state,
        isFetchingCapacities: true,
        isFetchingCapacitiesFrom: action.from || 0,
      };
    case capacityConstants.GET_CAPACITIES_FAILURE:
      return {
        ...state,
        isFetchingCapacities: false,
        capacities: [],
        error: action.message || '',
      };
    case capacityConstants.GET_CAPACITIES_SUCCESS:
      return {
        ...state,
        isFetchingCapacities: false,
        capacities:
          action.from > 0
            ? [...state.capacities, ...action.capacities]
            : action.capacities,
        capacitiesLoaded:
          action.from > 0
            ? state.capacities.length + action.capacities.length
            : action.capacities.length,
        capacitiesTotalResults: action.totalResults || 0,
      };

    case capacityConstants.SEARCH_CAPACITIES_STARTED:
      return {
        ...state,
        isFetchingCapacities: true,
        isFetchingCapacitiesFrom: action.from || 0,
      };
    case capacityConstants.SEARCH_CAPACITIES_FAILURE:
      return {
        ...state,
        isFetchingCapacities: false,
        capacities: [],
        error: action.message || '',
      };
    case capacityConstants.SEARCH_CAPACITIES_SUCCESS:
      return {
        ...state,
        isFetchingCapacities: false,
        capacities:
          action.from > 0
            ? [...state.capacities, ...action.capacities]
            : action.capacities,
        capacitiesLoaded:
          action.from > 0
            ? state.capacities.length + action.capacities.length
            : action.capacities.length,
        capacitiesTotalResults: action.totalResults || 0,
      };

    case capacityConstants.CREATING_CAPACITY_STARTED:
      return { ...state, isFetchingCapacity: true };
    case capacityConstants.CREATING_CAPACITY_FAILURE:
      return {
        ...state,
        isFetchingCapacity: false,
        error: action.message || '',
      };
    case capacityConstants.CREATING_CAPACITY_SUCCESS:
      return {
        ...state,
        isFetchingCapacity: false,
        capacity: action.capacity,
        capacities: [action.capacity, ...state.capacities],
      };

    case capacityConstants.UPDATING_CAPACITY_STARTED:
      return { ...state, isFetchingCapacity: true };
    case capacityConstants.UPDATING_CAPACITY_FAILURE:
      return {
        ...state,
        isFetchingCapacity: false,
        error: action.message || '',
      };
    case capacityConstants.UPDATING_CAPACITY_SUCCESS:
      return {
        ...state,
        isFetchingCapacity: false,
        capacity: action.capacity,
        capacities: [...state.capacities].map((capacity) => {
          if (action.capacity.id === capacity.id) {
            return action.capacity;
          }
          return capacity;
        }),
      };

    case capacityConstants.DELETE_CAPACITY_STARTED:
      return { ...state, isFetchingCapacity: true };
    case capacityConstants.DELETE_CAPACITY_FAILURE:
      return {
        ...state,
        isFetchingCapacity: false,
        message: action.message || '',
      };
    case capacityConstants.DELETE_CAPACITY_SUCCESS:
      return {
        ...state,
        isFetchingCapacity: false,
        capacity: action.capacity,
        capacities: [...state.capacities].filter(
          (capacity) => action.capacity.id !== capacity.id
        ),
      };
    default:
      return state;
  }
}
