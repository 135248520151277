import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator';

import moment from 'moment';

import ConsignmentSelector from '@components/consignment/crud/ConsignmentSelector';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class SelectConsignmentsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consignments: this.props.consignments || [],
    };
  }

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { consignments } = this.state;
    const { onSubmit } = this.props;
    onSubmit && onSubmit(consignments);
  };

  render() {
    const { t } = this.props;
    const { consignments } = this.state;

    return (
      <form
        className="form active no-padding-top"
        onSubmit={(e) => this.handleSubmit(e)}
      >
        <div className="input-container">
          <div className="input-group more">
            <ConsignmentSelector
              {...this.props}
              selectedConsignments={consignments}
              onChange={(newConsignments) => {
                this.setState({
                  consignments: newConsignments,
                });
              }}
            />
          </div>
          <div className="input-group more right">
            <input
              type="submit"
              onClick={(e) => this.handleSubmit(e)}
              value={t('form.save')}
            />
          </div>
        </div>
      </form>
    );
  }
}
export default withTranslation('translation')(SelectConsignmentsView);
