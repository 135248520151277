import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateAttributePage from '@components/attribute/CreateAttributePage';

import * as attributeActions from '@actions/attributeActions';
import * as navigationActions from '@actions/navigationActions';

class CreateAttributeContainer extends PureComponent {
  render() {
    const { changeValue, createAttribute, popStack, callback, ...props } =
      this.props;

    return (
      <CreateAttributePage
        {...props}
        popStack={popStack}
        onChange={changeValue}
        createAttribute={(attribute) =>
          createAttribute(attribute).then((data) => {
            popStack?.();
            callback?.(data);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    industries: state.platform.industries,
    ...state.attribute,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(attributeActions.changeCreateAttribute(name, value)),
    createAttribute: (attribute) =>
      dispatch(attributeActions.createAttribute(attribute)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateAttributeContainer));
