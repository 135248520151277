import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/mail/${id}`);
};

export const create = (mail) => {
  return api.post(`/mail`, mail);
};

export const update = (mail) => {
  return api.put(`/mail`, mail);
};

export const remove = (mail) => {
  return api.delete(`/mail/${mail.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/mails/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/mails/${from}/${amount}`, searchParameters);
};

// Business logic
export const archive = (id = '') => {
  return api.get(`/mail/${id}/archive`);
};

export const convertToTransportOrder = (id = '') => {
  return api.get(`/mail/${id}/toTransportOrder`);
};
