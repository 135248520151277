
export const cardConstants = {
    CARD_CHANGE_VALUE: "CARD_CHANGE_VALUE",

    GET_CARD_STARTED: "GET_CARD_STARTED",
    GET_CARD_SUCCESS: "GET_CARD_SUCCESS",
    GET_CARD_FAILURE: "GET_CARD_FAILURE",

    GET_CARDS_STARTED: "GET_CARDS_STARTED",
    GET_CARDS_SUCCESS: "GET_CARDS_SUCCESS",
    GET_CARDS_FAILURE: "GET_CARDS_FAILURE",

    SEARCH_CARDS_STARTED: "SEARCH_CARDS_STARTED",
    SEARCH_CARDS_SUCCESS: "SEARCH_CARDS_SUCCESS",
    SEARCH_CARDS_FAILURE: "SEARCH_CARDS_FAILURE",

    CREATING_CARD_STARTED: "CREATING_CARD_STARTED",
    CREATING_CARD_SUCCESS: "CREATING_CARD_SUCCESS",
    CREATING_CARD_FAILURE: "CREATING_CARD_FAILURE",

    UPDATING_CARD_STARTED: "UPDATING_CARD_STARTED",
    UPDATING_CARD_SUCCESS: "UPDATING_CARD_SUCCESS",
    UPDATING_CARD_FAILURE: "UPDATING_CARD_FAILURE",

    DELETE_CARD_STARTED: "DELETE_CARD_STARTED",
    DELETE_CARD_SUCCESS: "DELETE_CARD_SUCCESS",
    DELETE_CARD_FAILURE: "DELETE_CARD_FAILURE",
};
