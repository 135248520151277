import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import Loader from '@uicomponents/Loader';

import * as locationApi from '@api/locationApi';

class LocationActionAverageDurationView extends Component {
  state = {
    isFetching: false,
    hasFetched: false,
    value: null,
  };

  static defaultProps = {
    type: 'stop',
    location: null,
    label: '',
    transformValue: (value) => {
      if (value.duration || value.duration === 0)
        return moment.utc(value.duration).format('HH:mm:ss');
      if (value.message) return value.message;
    },
  };

  fetch = (e) => {
    const { location, type } = this.props;
    this.setState({
      isFetching: true,
    });
    locationApi
      .getLocationActionAverageDuration(location, type)
      .then((response) => {
        this.setState({
          value: response,
          hasFetched: true,
          isFetching: false,
        });
      });
  };

  render() {
    const { isFetching, hasFetched, value } = this.state;
    const { t, label, transformValue } = this.props;

    return (
      <div className="location-info-row">
        <div className="location-info-row__name">{label}</div>
        <div className="location-info-row__value">
          {isFetching ? (
            <Loader />
          ) : hasFetched ? (
            transformValue(value)
          ) : (
            <button type="button" onClick={(e) => this.fetch(e)}>
              {t('fetch')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(
  LocationActionAverageDurationView
);
