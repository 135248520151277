import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateContractContainer from '@containers/contract/crud/CreateContractContainer';

import * as contractActions from '@actions/contractActions';
import * as navigationActions from '@actions/navigationActions';
import { search as searchContracts } from '@api/contractApi';

const transformContractToValue = (contract) => ({
  id: contract.id,
  value: contract.id,
  label: `${contract.title}`,
  contract: { ...contract },
});

class ContractInput extends Component {
  state = {
    contract: this.props.value
      ? transformContractToValue(this.props.value)
      : null,
  };

  onChange = (newContract) => {
    this.setState({
      contract: newContract,
    });
    const { onChange } = this.props;
    onChange && onChange(newContract ? newContract.contract : null);
  };

  render() {
    const { t, addToStack } = this.props;
    const { contract } = this.state;
    return (
      <div className="input-group no-margin-top">
        <div className="input-group no-margin-top">
          <AsyncCreatableSelect
            isClearable
            noOptionsMessage={() => t('contracts.notFound')}
            placeholder={t('form.label.selectContract')}
            loadingMessage={() => t('loading')}
            loadOptions={(inputValue, callback) => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchContracts({ query: inputValue }, 0, 40).then(
                  (response) => {
                    callback(
                      response.contracts.map((contract) =>
                        transformContractToValue(contract)
                      )
                    );
                  }
                );
              }, 400);
            }}
            value={contract}
            onChange={(e) => this.onChange(e)}
            onCreateOption={(inputValue) => {
              addToStack({
                name: t('contract.new'),
                component: (
                  <CreateContractContainer
                    targetName={inputValue}
                    callback={(data) => {
                      const newContract = {
                        id: data.contract.id,
                        value: data.contract.id,
                        label: data.contract.title,
                        contract: { ...data.contract },
                      };
                      this.setState({
                        contract: newContract,
                      });

                      this.onChange(newContract);
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.contract,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(contractActions.changeCreateContract(name, value)),
    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ContractInput));
