import { store } from '@/index';

export default class Consignment {
  id;
  nonce;

  combined;
  goods;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);
    this.combined =
      store.getState().platform.platform?.planningSettings?.alwaysCombinedTransport || false;

    this.documents = [];

    this.goods = [];
    this.actions = [];
  }
}
