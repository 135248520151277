
export const publisherConstants = {
    PUBLISHER_CHANGE_VALUE: "PUBLISHER_CHANGE_VALUE",

    GET_PUBLISHER_STARTED: "GET_PUBLISHER_STARTED",
    GET_PUBLISHER_SUCCESS: "GET_PUBLISHER_SUCCESS",
    GET_PUBLISHER_FAILURE: "GET_PUBLISHER_FAILURE",

    GET_PUBLISHERS_STARTED: "GET_PUBLISHERS_STARTED",
    GET_PUBLISHERS_SUCCESS: "GET_PUBLISHERS_SUCCESS",
    GET_PUBLISHERS_FAILURE: "GET_PUBLISHERS_FAILURE",

    SEARCH_PUBLISHERS_STARTED: "SEARCH_PUBLISHERS_STARTED",
    SEARCH_PUBLISHERS_SUCCESS: "SEARCH_PUBLISHERS_SUCCESS",
    SEARCH_PUBLISHERS_FAILURE: "SEARCH_PUBLISHERS_FAILURE",

    CREATING_PUBLISHER_STARTED: "CREATING_PUBLISHER_STARTED",
    CREATING_PUBLISHER_SUCCESS: "CREATING_PUBLISHER_SUCCESS",
    CREATING_PUBLISHER_FAILURE: "CREATING_PUBLISHER_FAILURE",

    UPDATING_PUBLISHER_STARTED: "UPDATING_PUBLISHER_STARTED",
    UPDATING_PUBLISHER_SUCCESS: "UPDATING_PUBLISHER_SUCCESS",
    UPDATING_PUBLISHER_FAILURE: "UPDATING_PUBLISHER_FAILURE",

    DELETE_PUBLISHER_STARTED: "DELETE_PUBLISHER_STARTED",
    DELETE_PUBLISHER_SUCCESS: "DELETE_PUBLISHER_SUCCESS",
    DELETE_PUBLISHER_FAILURE: "DELETE_PUBLISHER_FAILURE",
};
