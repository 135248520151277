import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class TransportModeSelector extends Component {
  render() {
    const { t, value, onChange, placeholder } = this.props;
    const options = [
      { label: t('transportMode.maritime'), value: 'maritime' },
      { label: t('transportMode.road'), value: 'road' },
      { label: t('transportMode.rail'), value: 'rail' },
      { label: t('transportMode.air'), value: 'air' },
      { label: t('transportMode.inlandWaterway'), value: 'inlandWaterway' },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(TransportModeSelector);
