import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateContractPage from '@components/contract/crud/CreateContractPage.js';

import * as contractActions from '@actions/contractActions';
import * as navigationActions from '@actions/navigationActions';

class CreateContractContainer extends PureComponent {
  render() {
    const {
      changeValue,
      createContract,
      searchVehicles,
      popStack,
      callback,
      ...props
    } = this.props;
    return (
      <CreateContractPage
        {...props}
        onChange={changeValue}
        createContract={(contract) =>
          createContract(contract).then((response) => {
            popStack?.();
            callback?.(response);
          })
        }
        searchVehicles={(searchParameters, from, amount) =>
          searchVehicles(searchParameters, from, amount)
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    languages: state.platform.languages,
    ...state.contract,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createContract: (contract) =>
      dispatch(contractActions.createContract(contract)),
    changeValue: (name, value) =>
      dispatch(contractActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateContractContainer));
