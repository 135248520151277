import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import { administrativeReferenceToString } from '@utils/locationUtils';

class InformationComponent extends Component {
  renderField = (field, entity) => {
    const value = entity[field?.name];
    const { t } = this.props;

    switch (field?.type) {
      case 'text':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.${field.name}`)}</b>: {value}
          </div>
        ) : null;

      case 'boolean':
        return value !== undefined ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.${field.name}`)}</b>: {value ? t('yes') : t('no')}
          </div>
        ) : null;

      case 'link':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.${field.name}`)}</b>:{' '}
            <a href={value} target="__blank" rel="noopener noreferrer">
              {value}
            </a>
          </div>
        ) : null;

      case 'type':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.type`)}</b>: {t(`${field.entityType}Type.${value}`)}
          </div>
        ) : null;

      case 'administrativeReference':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.address`)}</b>: {administrativeReferenceToString(value)}
          </div>
        ) : null;

      case 'latLon':
        return entity.geoReference && entity.geoReference.lat && entity.geoReference.lon ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.latLon`)}</b>:{' '}
            {`${entity.geoReference.lat}, ${entity.geoReference.lon}`}
          </div>
        ) : null;

      case 'remark':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.remark`)}</b>: {value}
          </div>
        ) : null;

      case 'boardComputerId':
        return value ? (
          <div key={field.name}>
            <b>{t('boardComputer.boardComputerId')}</b>: {value}
          </div>
        ) : null;

      case 'businessLocation':
        return value && value.administrativeReference ? (
          <div key={field.name}>
            <b>
              {t('business.location')} #{field.index + 1}
            </b>
            :{' '}
            <div
              className="as-link"
              onClick={(e) => {
                e.preventDefault();
                field.onClick(value);
              }}
            >
              {administrativeReferenceToString(value.administrativeReference)}
            </div>
          </div>
        ) : null;

      case 'equipmentType':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.equipmentType`)}</b>: {t(`${value}`)}
          </div>
        ) : null;

      case 'dimensions':
        const { length, height, width } = entity;
        return length && height && width ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.dimensions`)}</b>:{' '}
            {`${length.value} x ${width.value} ${width.unit} x ${height.value}`}
          </div>
        ) : null;

      case 'vehicleType':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.vehicleType`)}</b>: {t(`${value}`)}
          </div>
        ) : null;

      case 'fuelType':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.fuelType`)}</b>: {t(`${value}`)}
          </div>
        ) : null;

      case 'loadCapacities':
        return value && value.length > 0
          ? value.map((loadCapacity, index) => (
              <div key={`${field.name}-${index}`}>
                <b>
                  {t(`${field.entityType}.loadCapacity`)} #{index + 1}
                </b>
                : {`${loadCapacity.value} ${loadCapacity.unit}`}
              </div>
            ))
          : null;

      case 'hostNames':
        return value && value.length > 0 ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.hostNames`)}</b>: {value.map((h) => h.hostName).join(', ')}
          </div>
        ) : null;

      case 'code':
        return value ? (
          <div key={field.name}>
            <code>{value}</code>
          </div>
        ) : null;

      case 'weight':
      case 'grossWeight':
      case 'loadMeters':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.${field.name}`)}</b>: {`${value.value} ${value.unit}`}
          </div>
        ) : null;

      case 'id':
        return value ? (
          <div key={field.name}>
            <h3 className="no-margin-top">#{value}</h3>
          </div>
        ) : null;

      case 'name':
        return value ? (
          <div key={field.name}>
            <h1 className="no-margin-bottom with-flair">{value}</h1>
          </div>
        ) : null;

      case 'role':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.role`)}</b>: {t(`${value.name}`)}
          </div>
        ) : null;

      case 'language':
        return value ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.language`)}</b>: {t(`${value.localName}`)}
          </div>
        ) : null;

      case 'platforms':
        return value && value.length > 0 ? (
          <div key={field.name}>
            <b>{t(`${field.entityType}.platforms`)}</b>: {value.map((p) => p.name).join(', ')}
          </div>
        ) : null;

      default:
        return null;
    }
  };

  render() {
    const { entity, fields } = this.props;

    return (
      <div className="field-renderer">{fields.map((field) => this.renderField(field, entity))}</div>
    );
  }
}

export default withTranslation('translation')(InformationComponent);
