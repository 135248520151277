import { attributeConstants } from '@constants/attribute.constants';

const initialState = {
  isFetchingAttribute: false,
  formAttribute: null,
  attribute: null,

  isFetchingAttributes: false,
  attributesTotalResults: 0,
  attributesLoaded: 0,
  attributes: [],
};

export default function attribute(state = initialState, action) {
  switch (action.type) {
    case attributeConstants.CREATING_ATTRIBUTE_CHANGE_VALUE:
    case attributeConstants.UPDATING_ATTRIBUTE_CHANGE_VALUE:
    case attributeConstants.ATTRIBUTE_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case attributeConstants.GET_ATTRIBUTE_STARTED:
      return { ...state, isFetchingAttribute: true };
    case attributeConstants.GET_ATTRIBUTE_FAILURE:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: null,
        error: action.message || '',
      };
    case attributeConstants.GET_ATTRIBUTE_SUCCESS:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: action.attribute,
      };
    case attributeConstants.SEARCH_ATTRIBUTES_STARTED:
    case attributeConstants.GET_ATTRIBUTES_STARTED:
      return { ...state, isFetchingAttributes: true };
    case attributeConstants.SEARCH_ATTRIBUTES_FAILURE:
    case attributeConstants.GET_ATTRIBUTES_FAILURE:
      return {
        ...state,
        isFetchingAttributes: false,
        attributes: [],
        error: action.message || '',
      };
    case attributeConstants.SEARCH_ATTRIBUTES_SUCCESS:
    case attributeConstants.GET_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        isFetchingAttributes: false,
        attributes:
          action.from > 0
            ? [...state.attributes, ...action.attributes]
            : action.attributes,
        attributesLoaded:
          action.from > 0
            ? state.attributes.length + action.attributes.length
            : action.attributes.length,
        attributesTotalResults: action.totalResults || 0,
      };

    case attributeConstants.CREATING_BUSINESS_STARTED:
      return { ...state, isFetchingAttribute: true };
    case attributeConstants.CREATING_BUSINESS_FAILURE:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: null,
        error: action.message || '',
      };
    case attributeConstants.CREATING_BUSINESS_SUCCESS:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: action.attribute,
        attributes: [action.attributes, ...state.attributes],
      };

    case attributeConstants.UPDATING_BUSINESS_STARTED:
      return { ...state, isFetchingAttribute: true };
    case attributeConstants.UPDATING_BUSINESS_FAILURE:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: null,
        error: action.message || '',
      };
    case attributeConstants.UPDATING_BUSINESS_SUCCESS:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: action.attribute,
        attributes: [...state.attributes].map((attribute) => {
          if (action.attribute.id === attribute.id) {
            return action.attribute;
          }
          return attribute;
        }),
      };

    case attributeConstants.DELETE_BUSINESS_STARTED:
      return { ...state, isFetchingAttribute: true };
    case attributeConstants.DELETE_BUSINESS_FAILURE:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: null,
        message: action.message || '',
      };
    case attributeConstants.DELETE_BUSINESS_SUCCESS:
      return {
        ...state,
        isFetchingAttribute: false,
        attribute: action.attribute,
        attributes: [...state.attributes].filter(
          (attribute) => action.attribute.id !== attribute.id
        ),
      };
    default:
      return state;
  }
}
