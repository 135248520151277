export const vehicleGroupConstants = {
  VEHICLE_GROUP_CHANGE_VALUE: 'VEHICLE_GROUP_CHANGE_VALUE',

  GET_VEHICLE_GROUP_STARTED: 'GET_VEHICLE_GROUP_STARTED',
  GET_VEHICLE_GROUP_SUCCESS: 'GET_VEHICLE_GROUP_SUCCESS',
  GET_VEHICLE_GROUP_FAILURE: 'GET_VEHICLE_GROUP_FAILURE',

  GET_VEHICLE_GROUPS_STARTED: 'GET_VEHICLE_GROUPS_STARTED',
  GET_VEHICLE_GROUPS_SUCCESS: 'GET_VEHICLE_GROUPS_SUCCESS',
  GET_VEHICLE_GROUPS_FAILURE: 'GET_VEHICLE_GROUPS_FAILURE',

  GET_VEHICLE_LIGHT_GROUPS_STARTED: 'GET_VEHICLE_LIGHT_GROUPS_STARTED',
  GET_VEHICLE_LIGHT_GROUPS_FAILURE: 'GET_VEHICLE_LIGHT_GROUPS_FAILURE',
  GET_VEHICLE_LIGHT_GROUPS_SUCCESS: 'GET_VEHICLE_LIGHT_GROUPS_SUCCESS',

  SEARCH_VEHICLE_GROUPS_STARTED: 'SEARCH_VEHICLE_GROUPS_STARTED',
  SEARCH_VEHICLE_GROUPS_SUCCESS: 'SEARCH_VEHICLE_GROUPS_SUCCESS',
  SEARCH_VEHICLE_GROUPS_FAILURE: 'SEARCH_VEHICLE_GROUPS_FAILURE',

  CREATING_VEHICLE_GROUP_STARTED: 'CREATING_VEHICLE_GROUP_STARTED',
  CREATING_VEHICLE_GROUP_SUCCESS: 'CREATING_VEHICLE_GROUP_SUCCESS',
  CREATING_VEHICLE_GROUP_FAILURE: 'CREATING_VEHICLE_GROUP_FAILURE',

  UPDATING_VEHICLE_GROUP_STARTED: 'UPDATING_VEHICLE_GROUP_STARTED',
  UPDATING_VEHICLE_GROUP_SUCCESS: 'UPDATING_VEHICLE_GROUP_SUCCESS',
  UPDATING_VEHICLE_GROUP_FAILURE: 'UPDATING_VEHICLE_GROUP_FAILURE',

  DELETE_VEHICLE_GROUP_STARTED: 'DELETE_VEHICLE_GROUP_STARTED',
  DELETE_VEHICLE_GROUP_SUCCESS: 'DELETE_VEHICLE_GROUP_SUCCESS',
  DELETE_VEHICLE_GROUP_FAILURE: 'DELETE_VEHICLE_GROUP_FAILURE',
};
