import * as mailApi from '@api/mailApi';

import { mailConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: mailConstants.MAIL_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching mail
 */
export const startGetMail = () => ({
  type: mailConstants.GET_MAIL_STARTED,
});

export const getMailSuccess = (result) => ({
  type: mailConstants.GET_MAIL_SUCCESS,
  ...result,
});

export const getMailFailure = (error) => ({
  type: mailConstants.GET_MAIL_FAILURE,
  ...error,
});

export const getMail = (id) => {
  return (dispatch) => {
    dispatch(startGetMail());

    const promise = mailApi.get(id);

    promise
      .then((result) => {
        dispatch(getMailSuccess(result));
      })
      .catch((error) => {
        dispatch(getMailFailure(error));
      });

    return promise;
  };
};

/**
 * Creating mail
 */
export const changeCreateMail = (name, value) => ({
  type: mailConstants.CREATING_MAIL_CHANGE_VALUE,
  name,
  value,
});
export const startCreateMail = () => ({
  type: mailConstants.CREATING_MAIL_STARTED,
});

export const createMailSuccess = (result) => ({
  type: mailConstants.CREATING_MAIL_SUCCESS,
  ...result,
});

export const createMailFailure = (error) => ({
  type: mailConstants.CREATING_MAIL_FAILURE,
  ...error,
});

export const createMail = (mail) => {
  return (dispatch) => {
    dispatch(startCreateMail());

    const promise = mailApi.create(mail);

    promise
      .then((result) => {
        dispatch(createMailSuccess(result));
      })
      .catch((error) => {
        dispatch(createMailFailure(error));
      });

    return promise;
  };
};

/**
 * Updating mail
 */
export const changeUpdateMail = (name, value) => ({
  type: mailConstants.UPDATING_MAIL_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateMail = () => ({
  type: mailConstants.UPDATING_MAIL_STARTED,
});

export const updateMailSuccess = (result) => ({
  type: mailConstants.UPDATING_MAIL_SUCCESS,
  ...result,
});

export const updateMailFailure = (error) => ({
  type: mailConstants.UPDATING_MAIL_FAILURE,
  ...error,
});

export const updateMail = (mail) => {
  return (dispatch) => {
    dispatch(startUpdateMail());

    const promise = mailApi.update(mail);

    promise
      .then((result) => {
        dispatch(updateMailSuccess(result));
      })
      .catch((error) => {
        dispatch(updateMailFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting mail
 */
export const startDeleteMail = () => ({
  type: mailConstants.DELETE_MAIL_STARTED,
});

export const deleteMailSuccess = (result) => ({
  type: mailConstants.DELETE_MAIL_SUCCESS,
  ...result,
});

export const deleteMailFailure = (error) => ({
  type: mailConstants.DELETE_MAIL_FAILURE,
  ...error,
});
export const deleteMail = (mail) => {
  return (dispatch) => {
    dispatch(startDeleteMail());

    const promise = mailApi.remove(mail);

    promise
      .then((result) => {
        dispatch(deleteMailSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteMailFailure(error));
      });

    return promise;
  };
};
/**
 * Deleting mail
 */
export const startArchiveMail = () => ({
  type: mailConstants.ARCHIVE_MAIL_STARTED,
});

export const archiveMailSuccess = (result) => ({
  type: mailConstants.ARCHIVE_MAIL_SUCCESS,
  ...result,
});

export const archiveMailFailure = (error) => ({
  type: mailConstants.ARCHIVE_MAIL_FAILURE,
  ...error,
});
export const archiveMail = (mail) => {
  return (dispatch) => {
    dispatch(startArchiveMail());

    const promise = mailApi.archive(mail);

    promise
      .then((result) => {
        dispatch(archiveMailSuccess(result));
      })
      .catch((error) => {
        dispatch(archiveMailFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching mails
 */
export const startGetMails = (from) => ({
  type: mailConstants.GET_MAILS_STARTED,
  from: from,
});

export const getMailsSuccess = (result, from) => ({
  type: mailConstants.GET_MAILS_SUCCESS,
  from: from,
  ...result,
});

export const getMailsFailure = (error) => ({
  type: mailConstants.GET_MAILS_FAILURE,
  ...error,
});

export const getMails = (from = 0, amount = 20, cleanState = false) => {
  return (dispatch) => {
    dispatch(startGetMails(from));

    const promise = mailApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getMailsSuccess(result, cleanState ? 0 : from));
      })
      .catch((error) => {
        dispatch(getMailsFailure(error));
      });

    return promise;
  };
};

export const searchMails = (
  searchParameters = [],
  from = 0,
  amount = 20,
  cleanState = false
) => {
  return (dispatch) => {
    dispatch(startGetMails(from));

    const promise = mailApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getMailsSuccess(result, cleanState ? 0 : from));
      })
      .catch((error) => {
        dispatch(getMailsFailure(error));
      });

    return promise;
  };
};
