import { planConstants } from '@constants/plan.constants';

const initialState = {
  isFetchingPlan: false,
  formPlan: null,
  plan: null,

  isFetchingPlans: false,
  isFetchingPlansFrom: 0,
  plansTotalResults: 0,
  plansLoaded: 0,
  plans: [],
};

export default function plan(state = initialState, action) {
  switch (action.type) {
    case planConstants.PLAN_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case planConstants.GET_PLAN_STARTED:
      return { ...state, isFetchingPlan: true };
    case planConstants.GET_PLAN_FAILURE:
      return { ...state, isFetchingPlan: false, plan: null, error: action.message || '' };
    case planConstants.GET_PLAN_SUCCESS:
      return {
        ...state,
        isFetchingPlan: false,
        plan: action.plan,
      };

    case planConstants.GET_PLANS_STARTED:
      return { ...state, isFetchingPlans: true, isFetchingPlansFrom: action.from || 0 };
    case planConstants.GET_PLANS_FAILURE:
      return { ...state, isFetchingPlans: false, plans: [], error: action.message || '' };
    case planConstants.GET_PLANS_SUCCESS:
      return {
        ...state,
        isFetchingPlans: false,
        plans: action.from > 0 ? [...state.plans, ...action.plans] : action.plans,
        plansLoaded:
          action.from > 0 ? state.plans.length + action.plans.length : action.plans.length,
        plansTotalResults: action.totalResults || 0,
      };

    case planConstants.SEARCH_PLANS_STARTED:
      return { ...state, isFetchingPlans: true, isFetchingPlansFrom: action.from || 0 };
    case planConstants.SEARCH_PLANS_FAILURE:
      return { ...state, isFetchingPlans: false, plans: [], error: action.message || '' };
    case planConstants.SEARCH_PLANS_SUCCESS:
      return {
        ...state,
        isFetchingPlans: false,
        plans: action.from > 0 ? [...state.plans, ...action.plans] : action.plans,
        plansLoaded:
          action.from > 0 ? state.plans.length + action.plans.length : action.plans.length,
        plansTotalResults: action.totalResults || 0,
      };

    case planConstants.CREATING_PLAN_STARTED:
      return { ...state, isFetchingPlan: true };
    case planConstants.CREATING_PLAN_FAILURE:
      return { ...state, isFetchingPlan: false, plan: null, error: action.message || '' };
    case planConstants.CREATING_PLAN_SUCCESS:
      return {
        ...state,
        isFetchingPlan: false,
        plan: action.plan,
        plans: [action.plan, ...state.plans],
      };

    case planConstants.UPDATING_PLAN_STARTED:
      return { ...state, isFetchingPlan: true };
    case planConstants.UPDATING_PLAN_FAILURE:
      return { ...state, isFetchingPlan: false, plan: null, error: action.message || '' };
    case planConstants.UPDATING_PLAN_SUCCESS:
      return {
        ...state,
        isFetchingPlan: false,
        plan: action.plan,
        plans: [...state.plans].map((plan) => {
          if (action.plan.id === plan.id) {
            return action.plan;
          }
          return plan;
        }),
      };

    case planConstants.DELETE_PLAN_STARTED:
      return { ...state, isFetchingPlan: true };
    case planConstants.DELETE_PLAN_FAILURE:
      return { ...state, isFetchingPlan: false, plan: null, message: action.message || '' };
    case planConstants.DELETE_PLAN_SUCCESS:
      return {
        ...state,
        isFetchingPlan: false,
        plan: action.plan,
        plans: [...state.plans].filter((plan) => action.plan.id !== plan.id),
      };
    default:
      return state;
  }
}
