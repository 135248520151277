import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateBoardComputerContainer from '@containers/boardComputer/crud/CreateBoardComputerContainer';

import * as boardComputerActions from '@actions/boardComputerActions';
import * as navigationActions from '@actions/navigationActions';

let searchTimeout = null;

export const transformBoardComputerToValue = (boardComputer) => ({
  value: boardComputer.id,
  label: `${boardComputer.name} - ${boardComputer.type} - ${boardComputer.boardComputerId}`,
  boardComputer: boardComputer,
});

class BoardComputersInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boardComputers: this.props.boardComputers
        ? this.props.boardComputers.map((boardComputer) =>
            transformBoardComputerToValue(boardComputer)
          )
        : [],
    };
  }

  onChange = (newBoardComputers) => {
    this.setState({
      boardComputer: newBoardComputers,
    });

    const { onChange } = this.props;
    onChange &&
      onChange(
        newBoardComputers
          ? newBoardComputers.map(
              (newBoardComputer) => newBoardComputer.boardComputer
            ) || []
          : []
      );
  };

  render() {
    const { t, isFetchingBoardComputers, searchBoardComputers, addToStack } =
      this.props;
    const { boardComputers } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={true}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.selectBoardComputer')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingBoardComputers}
          isDisabled={isFetchingBoardComputers}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchBoardComputers({ query: inputValue }, 0, 40).then(
                (response) => {
                  callback(
                    response.boardComputers.map((boardComputer) =>
                      transformBoardComputerToValue(boardComputer)
                    )
                  );
                }
              );
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('boardComputer.new'),
              component: (
                <CreateBoardComputerContainer
                  targetName={inputValue}
                  callback={(newBoardComputer) => {
                    this.onChange([
                      ...boardComputers,
                      transformBoardComputerToValue(newBoardComputer),
                    ]);
                  }}
                />
              ),
            });
          }}
          value={boardComputers}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(boardComputerActions.changeValue(name, value)),
    searchBoardComputers: (searchParameters, from, amount) =>
      dispatch(
        boardComputerActions.searchBoardComputers(
          searchParameters,
          from,
          amount
        )
      ),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(BoardComputersInput));
