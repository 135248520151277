import devSettings from './configureStore.dev';
import prodSettings from './configureStore.prod';

let configureStore = null;
if (process.env.NODE_ENV === 'production') {
  configureStore = prodSettings;
} else {
  configureStore = devSettings;
}

export default configureStore;
