import { renderRoutes } from '@routes';
import { lazy } from 'react';

const TripsContainer = lazy(() =>
  import('@containers/trip/TripsContainer')
);
const PublicTripContainer = lazy(() =>
  import('@containers/trip/PublicTripContainer')
);

const routes = [
  {
    paths: ['/:id/share/:token'],
    component: PublicTripContainer,
    type: 'open',
  },
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: TripsContainer,
    type: 'portal',
  },
];

const TripRoutes = (props) => renderRoutes(props, routes);
export default TripRoutes;
