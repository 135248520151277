export const boardComputerConstants = {
  BOARD_COMPUTER_CHANGE_VALUE: 'BOARD_COMPUTER_CHANGE_VALUE',

  GET_BOARD_COMPUTER_STARTED: 'GET_BOARD_COMPUTER_STARTED',
  GET_BOARD_COMPUTER_SUCCESS: 'GET_BOARD_COMPUTER_SUCCESS',
  GET_BOARD_COMPUTER_FAILURE: 'GET_BOARD_COMPUTER_FAILURE',

  GET_BOARD_COMPUTERS_STARTED: 'GET_BOARD_COMPUTERS_STARTED',
  GET_BOARD_COMPUTERS_SUCCESS: 'GET_BOARD_COMPUTERS_SUCCESS',
  GET_BOARD_COMPUTERS_FAILURE: 'GET_BOARD_COMPUTERS_FAILURE',

  SEARCH_BOARD_COMPUTERS_STARTED: 'SEARCH_BOARD_COMPUTERS_STARTED',
  SEARCH_BOARD_COMPUTERS_SUCCESS: 'SEARCH_BOARD_COMPUTERS_SUCCESS',
  SEARCH_BOARD_COMPUTERS_FAILURE: 'SEARCH_BOARD_COMPUTERS_FAILURE',

  CREATING_BOARD_COMPUTER_STARTED: 'CREATING_BOARD_COMPUTER_STARTED',
  CREATING_BOARD_COMPUTER_SUCCESS: 'CREATING_BOARD_COMPUTER_SUCCESS',
  CREATING_BOARD_COMPUTER_FAILURE: 'CREATING_BOARD_COMPUTER_FAILURE',

  UPDATING_BOARD_COMPUTER_STARTED: 'UPDATING_BOARD_COMPUTER_STARTED',
  UPDATING_BOARD_COMPUTER_SUCCESS: 'UPDATING_BOARD_COMPUTER_SUCCESS',
  UPDATING_BOARD_COMPUTER_FAILURE: 'UPDATING_BOARD_COMPUTER_FAILURE',

  DELETE_BOARD_COMPUTER_STARTED: 'DELETE_BOARD_COMPUTER_STARTED',
  DELETE_BOARD_COMPUTER_SUCCESS: 'DELETE_BOARD_COMPUTER_SUCCESS',
  DELETE_BOARD_COMPUTER_FAILURE: 'DELETE_BOARD_COMPUTER_FAILURE',
};
