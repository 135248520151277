
export const banConstants = {
    BAN_CHANGE_VALUE: "BAN_CHANGE_VALUE",

    GET_BAN_STARTED: "GET_BAN_STARTED",
    GET_BAN_SUCCESS: "GET_BAN_SUCCESS",
    GET_BAN_FAILURE: "GET_BAN_FAILURE",

    GET_BANS_STARTED: "GET_BANS_STARTED",
    GET_BANS_SUCCESS: "GET_BANS_SUCCESS",
    GET_BANS_FAILURE: "GET_BANS_FAILURE",

    SEARCH_BANS_STARTED: "SEARCH_BANS_STARTED",
    SEARCH_BANS_SUCCESS: "SEARCH_BANS_SUCCESS",
    SEARCH_BANS_FAILURE: "SEARCH_BANS_FAILURE",

    CREATING_BAN_STARTED: "CREATING_BAN_STARTED",
    CREATING_BAN_SUCCESS: "CREATING_BAN_SUCCESS",
    CREATING_BAN_FAILURE: "CREATING_BAN_FAILURE",

    UPDATING_BAN_STARTED: "UPDATING_BAN_STARTED",
    UPDATING_BAN_SUCCESS: "UPDATING_BAN_SUCCESS",
    UPDATING_BAN_FAILURE: "UPDATING_BAN_FAILURE",

    DELETE_BAN_STARTED: "DELETE_BAN_STARTED",
    DELETE_BAN_SUCCESS: "DELETE_BAN_SUCCESS",
    DELETE_BAN_FAILURE: "DELETE_BAN_FAILURE",
};
