import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import AttachTransportEquipmentPage from '@components/vehicle/crud/AttachTransportEquipmentPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as vehicleActions from '@actions/vehicleActions';

class AttachTransportEquipmentContainer extends PureComponent {
  render() {
    const { vehicleAttachTransportEquipment, popStack, ...props } = this.props;
    return (
      <AttachTransportEquipmentPage
        {...props}
        vehicleAttachTransportEquipment={(
          vehicle,
          attachTransportEquipmentAction
        ) =>
          vehicleAttachTransportEquipment(
            vehicle,
            attachTransportEquipmentAction
          ).then((response) => {
            popStack();
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.vehicle,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    vehicleAttachTransportEquipment: (
      vehicle,
      attachTransportEquipmentAction
    ) =>
      dispatch(
        vehicleActions.vehicleAttachTransportEquipment(
          vehicle,
          attachTransportEquipmentAction
        )
      ),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(AttachTransportEquipmentContainer));
