import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/vehicle/${id}`);
};
export const getExtended = (id = '') => {
  return api.get(`/vehicle/extended/${id}`);
};

export const create = (vehicle) => {
  return api.post(`/vehicle`, vehicle);
};
export const update = (vehicle) => {
  return api.put(`/vehicle`, vehicle);
};

export const remove = (vehicle) => {
  return api.delete(`/vehicle/${vehicle.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/vehicles/${from}/${amount}`);
};

export const extendedLatest = (from = 0, amount = 20) => {
  return api.get(`/vehicles/extended/${from}/${amount}`);
};

export const extendedLatestWithVehicleGroup = (vehicleGroupId = '', from = 0, amount = 20) => {
  return api.get(`/vehicles/extended/${vehicleGroupId}/${from}/${amount}`);
};

export const search = (searchParameters = {}, from = 0, amount = 20) => {
  return api.post(`/vehicles/${from}/${amount}`, searchParameters);
};

export const searchExtendedLatest = (searchParameters = {}, from = 0, amount = 20) => {
  return api.post(`/vehicles/extended/${from}/${amount}`, searchParameters);
};

// locations
export const vehiclesLocation = () => {
  return api.get(`/vehicles/location`);
};
export const searchVehiclesLocation = (searchParams) => {
  return api.post(`/vehicles/location`, searchParams);
};

// chauffeurs
export const getChauffeurs = (id = '') => {
  return api.get(`/vehicle/${id}/chauffeurs`);
};

export const attachChauffeur = (id = '', action) => {
  return api.post(`/vehicle/${id}/attach/chauffeur`, action);
};
export const detachChauffeur = (id = '', action) => {
  return api.post(`/vehicle/${id}/detach/chauffeur`, action);
};

// transportEquipmet
export const getTransportEquipments = (id = '') => {
  return api.get(`/vehicle/${id}/transportEquipments`);
};

export const attachTransportEquipment = (id = '', action) => {
  return api.post(`/vehicle/${id}/attach/transportEquipment`, action);
};
export const detachTransportEquipment = (id = '', action) => {
  return api.post(`/vehicle/${id}/detach/transportEquipment`, action);
};

// events
export const getEvents = (id = '', from, amount) => {
  return api.get(`/vehicle/${id}/events/${from}/${amount}`);
};
export const getLocationUpdateEvents = (id = '', fromDate, toDate) => {
  return api.get(
    `/vehicle/${id}/locationUpdateEvents/${fromDate.startOf('day').toISOString()}/${toDate
      .endOf('day')
      .toISOString()}`
  );
};

// actions
export const getActions = (id = '', from, amount) => {
  return api.get(`/vehicle/${id}/actions/${from}/${amount}`);
};

// Trips
export const getTrips = (id, from, amount) => {
  return api.get(`/vehicle/${id}/trips/${from}/${amount}`);
};
export const getActiveTrips = (id, from, amount) => {
  return api.get(`/vehicle/${id}/trips:active/${from}/${amount}`);
};
export const getHistoryTrips = (id, from, amount) => {
  return api.get(`/vehicle/${id}/trips:history/${from}/${amount}`);
};
export const getTripsByDate = (id, fromDate, toDate, offset, amount) => {
  return api.get(
    `/vehicle/${id}/trips:byDate/${fromDate ? fromDate.toISOString() : null}/${
      toDate ? toDate.toISOString() : null
    }`
  );
};

export const getVehiclesNextTrips = (vehicles, offset, amount) => {
  return api.post(`/vehicles/trips:nextActive/${offset}/${amount}`, { vehicles });
};

export const updateVehicleTripsOrder = (id, trip1, trip2) => {
  return api.put(`/vehicle/${id}/trips:swap`, { trip1, trip2 });
};

export const getExtendedVehicleTrips = (vehicles, fromDate, toDate) => {
  return api.post(`/vehicles/trips:byDate/${fromDate}/${toDate}`, { vehicles });
};
