import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Team from '@models/team/Team';

import TeamForm from './TeamForm';

export default class CreateTeamPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formTeam', new Team());
  }

  render() {
    const { t, formTeam, isFetchingTeam, createTeam } = this.props;

    if (!formTeam || isFetchingTeam) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('team.new')}</h1>
        <TeamForm
          {...this.props}
          key={formTeam.id}
          onSubmit={(team) => createTeam(team)}
        />
      </AsideView>
    );
  }
}
