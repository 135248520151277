import React, { Component } from 'react';

export default class Progress extends Component {
  render() {
    const { progress, total, minimum, maximum } = this.props;

    return (
      <div className="progress-bar">
        <div
          className={`progress${minimum ? (total >= minimum ? ' green' : ' orange') : ''}${
            maximum ? (total > maximum ? ' red' : ' green') : ''
          }`}
          style={{ width: progress + '%' }}
        />
      </div>
    );
  }
}
