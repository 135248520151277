export default class Pricing {
  id;
  nonce;

  columns;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.columns = [
      {
        cells: [{}, {}, {}],
      },
      {
        cells: [{}, {}, {}],
      },
      {
        cells: [{}, {}, {}],
      },
    ];
  }
}
