import { consignmentConstants } from '@constants';

import * as consignmentApi from '@api/consignmentApi';

export const changeValue = (name, value) => ({
  type: consignmentConstants.CONSIGNMENT_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching consignment
 */
export const startGetConsignment = () => ({
  type: consignmentConstants.GET_CONSIGNMENT_STARTED,
});

export const getConsignmentSuccess = (result) => ({
  type: consignmentConstants.GET_CONSIGNMENT_SUCCESS,
  ...result,
});

export const getConsignmentFailure = (error) => ({
  type: consignmentConstants.GET_CONSIGNMENT_FAILURE,
  ...error,
});

export const getConsignment = (id) => {
  return (dispatch) => {
    dispatch(startGetConsignment());

    const promise = consignmentApi.get(id);

    promise
      .then((result) => {
        dispatch(getConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(getConsignmentFailure(error));
      });

    return promise;
  };
};

export const getExtendedConsignment = (id) => {
  return (dispatch) => {
    dispatch(startGetConsignment());

    const promise = consignmentApi.getExtended(id);

    promise
      .then((result) => {
        dispatch(getConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(getConsignmentFailure(error));
      });

    return promise;
  };
};

/**
 * Creating consignment
 */
export const startCreateConsignment = () => ({
  type: consignmentConstants.CREATING_CONSIGNMENT_STARTED,
});

export const createConsignmentSuccess = (result) => ({
  type: consignmentConstants.CREATING_CONSIGNMENT_SUCCESS,
  ...result,
});

export const createConsignmentFailure = (error) => ({
  type: consignmentConstants.CREATING_CONSIGNMENT_FAILURE,
  ...error,
});

export const createConsignment = (consignment) => {
  return (dispatch) => {
    dispatch(startCreateConsignment());

    const promise = consignmentApi.create(consignment);

    promise
      .then((result) => {
        dispatch(createConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(createConsignmentFailure(error));
      });

    return promise;
  };
};

/**
 * Updating consignment
 */
export const startUpdateConsignment = () => ({
  type: consignmentConstants.UPDATING_CONSIGNMENT_STARTED,
});

export const updateConsignmentSuccess = (result) => ({
  type: consignmentConstants.UPDATING_CONSIGNMENT_SUCCESS,
  ...result,
});

export const updateConsignmentFailure = (error) => ({
  type: consignmentConstants.UPDATING_CONSIGNMENT_FAILURE,
  ...error,
});

export const updateConsignment = (consignment) => {
  return (dispatch) => {
    dispatch(startUpdateConsignment());

    const promise = consignmentApi.update(consignment);

    promise
      .then((result) => {
        dispatch(updateConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(updateConsignmentFailure(error));
      });

    return promise;
  };
};

export const updateConsignmentAddDocuments = (consignment, documents) => {
  return (dispatch) => {
    dispatch(startUpdateConsignment());

    const promise = consignmentApi.updateConsignmentAddDocuments(
      consignment,
      documents
    );

    promise
      .then((result) => {
        dispatch(updateConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(updateConsignmentFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting consignment
 */
export const startDeleteConsignment = () => ({
  type: consignmentConstants.DELETE_CONSIGNMENT_STARTED,
});

export const deleteConsignmentSuccess = (result) => ({
  type: consignmentConstants.DELETE_CONSIGNMENT_SUCCESS,
  ...result,
});

export const deleteConsignmentFailure = (error) => ({
  type: consignmentConstants.DELETE_CONSIGNMENT_FAILURE,
  ...error,
});
export const deleteConsignment = (consignment) => {
  return (dispatch) => {
    dispatch(startDeleteConsignment());

    const promise = consignmentApi.remove(consignment);

    promise
      .then((result) => {
        dispatch(deleteConsignmentSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteConsignmentFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching consignments
 */
export const startGetConsignments = (from) => ({
  type: consignmentConstants.GET_CONSIGNMENTS_STARTED,
  from
});

export const getConsignmentsSuccess = (result, from) => ({
  type: consignmentConstants.GET_CONSIGNMENTS_SUCCESS,
  from: from,
  ...result,
});

export const getConsignmentsFailure = (error) => ({
  type: consignmentConstants.GET_CONSIGNMENTS_FAILURE,
  ...error,
});

export const getConsignments = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetConsignments(from));

    const promise = consignmentApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getConsignmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getConsignmentsFailure(error));
      });

    return promise;
  };
};

export const searchConsignments = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetConsignments(from));

    const promise = consignmentApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getConsignmentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getConsignmentsFailure(error));
      });

    return promise;
  };
};
