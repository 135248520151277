import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CommentForm from '@components/todo/todo/CommentForm';

import * as boardActions from '@actions/boardActions';
import * as commentActions from '@actions/commentActions';
import * as navigationActions from '@actions/navigationActions';
import * as todoMainActions from '@actions/todoMainActions';
import * as userActions from '@actions/userActions';

class CommentFormContainer extends Component {
  componentDidMount() {
    const { changeValue, ownProps } = this.props;

    if (ownProps && ownProps.isEdit) {
      changeValue('commentForm', ownProps?.comment);
    }
  }

  render() {
    return <CommentForm {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    fields: state.platform.fields,
    user: state.auth.user,
    ...state.todo,
    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    isEdit: (payload) => dispatch(todoMainActions.isEdit(payload)),
    setView: (payload) => dispatch(todoMainActions.setView(payload)),
    changeValue: (name, value) => dispatch(todoMainActions.changeValue(name, value)),

    getBoardById: (id) => dispatch(boardActions.getBoardById(id)),

    createComment: (comment) => dispatch(commentActions.createComment(comment)),
    updateComment: (comment) => dispatch(commentActions.updateComment(comment)),
    deleteComment: (comment) => dispatch(commentActions.deleteComment(comment)),
    getComments: (todo, from, amount) => dispatch(commentActions.getComments(todo, from, amount)),

    searchUsers: (searchParameters, from, to) =>
      dispatch(userActions.searchUsers(searchParameters, from, to)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CommentFormContainer));
