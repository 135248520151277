import { integrationConstants } from '../constants/integration.constants';


const initialState = {
  isFetchingIntegration: false,
  formIntegration: null,
  integration: null,

  isFetchingIntegrations: false,
  isFetchingIntegrationsFrom: 0,
  integrationsTotalResults: 0,
  integrationsLoaded: 0,
  integrations: [],

  isFetchingIntegrationLogs: false,
  isFetchingIntegrationLogsFrom: 0,
  integrationLogsTotalResults: 0,
  integrationLogsLoaded: 0,
  integrationLogs: [],

  isFetchingIntegrationObjects: false,
  isFetchingIntegrationObjectsFrom: 0,
  integrationObjectsTotalResults: 0,
  integrationObjectsLoaded: 0,
  integrationObjects: [],

  isFetchingIntegrationTasks: false,
  isFetchingIntegrationTasksFrom: 0,
  integrationTasksTotalResults: 0,
  integrationTasksLoaded: 0,
  integrationTasks: [],

  isFetchingIntegrationRequestLogs: false,
  isFetchingIntegrationRequestLogsFrom: 0,
  integrationRequestLogsTotalResults: 0,
  integrationRequestLogsLoaded: 0,
  integrationRequestLogs: [],
};

export default function integration(state = initialState, action) {
  switch (action.type) {
    case integrationConstants.INTEGRATION_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case integrationConstants.GET_INTEGRATIONS_STARTED:
      return {
        ...state,
        isFetchingIntegrations: true,
        isFetchingIntegrationsFrom: action.from || 0,
      };
    case integrationConstants.GET_INTEGRATIONS_FAILURE:
      return {
        ...state,
        isFetchingIntegrations: false,
        integrations: [],
        error: action.message || '',
      };
    case integrationConstants.GET_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrations: false,
        integrations:
          action.from > 0
            ? [...state.integrations, ...action.integrations]
            : action.integrations,
        integrationsLoaded:
          action.from > 0
            ? state.integrations.length + action.integrations.length
            : action.integrations.length,
        integrationsTotalResults: action.totalResults || 0,
      };

    case integrationConstants.SEARCH_INTEGRATIONS_STARTED:
      return {
        ...state,
        isFetchingIntegrations: true,
        isFetchingIntegrationsFrom: action.from || 0,
      };
    case integrationConstants.SEARCH_INTEGRATIONS_FAILURE:
      return {
        ...state,
        isFetchingIntegrations: false,
        integrations: [],
        error: action.message || '',
      };
    case integrationConstants.SEARCH_INTEGRATIONS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrations: false,
        integrations:
          action.from > 0
            ? [...state.integrations, ...action.integrations]
            : action.integrations,
        integrationsLoaded:
          action.from > 0
            ? state.integrations.length + action.integrations.length
            : action.integrations.length,
        integrationsTotalResults: action.totalResults || 0,
      };

    case integrationConstants.CREATING_INTEGRATION_STARTED:
      return { ...state, isFetchingIntegration: true };
    case integrationConstants.CREATING_INTEGRATION_FAILURE:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: null,
        integrations: [],
        error: action.message || '',
      };
    case integrationConstants.CREATING_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: action.integration,
        integrations: [action.integration, ...state.integrations],
      };

    case integrationConstants.UPDATING_INTEGRATION_STARTED:
      return { ...state, isFetchingIntegration: true };
    case integrationConstants.UPDATING_INTEGRATION_FAILURE:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: null,
        integrations: [],
        error: action.message || '',
      };
    case integrationConstants.UPDATING_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: action.integration,
        integrations: [...state.integrations].map((integration) => {
          if (action.integration.id === integration.id) {
            return action.integration;
          }
          return integration;
        }),
      };

    case integrationConstants.DELETE_INTEGRATION_STARTED:
      return { ...state, isFetchingIntegration: true };
    case integrationConstants.DELETE_INTEGRATION_FAILURE:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: null,
        integrations: [],
        message: action.message || '',
      };
    case integrationConstants.DELETE_INTEGRATION_SUCCESS:
      return {
        ...state,
        isFetchingIntegration: false,
        integration: action.integration,
        integrations: [...state.integrations].filter(
          (integration) => action.integration.id !== integration.id
        ),
      };

    case integrationConstants.GET_INTEGRATION_LOGS_STARTED:
      return {
        ...state,
        isFetchingIntegrationLogs: true,
        isFetchingIntegrationLogsFrom: action.from || 0,
      };
    case integrationConstants.GET_INTEGRATION_LOGS_FAILURE:
      return {
        ...state,
        isFetchingIntegrationLogs: false,
        integrationLogs: [],
        error: action.message || '',
      };
    case integrationConstants.GET_INTEGRATION_LOGS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrationLogs: false,
        integrationLogs:
          action.from > 0
            ? [...state.integrationLogs, ...action.integrationLogs]
            : action.integrationLogs,
        integrationLogsLoaded:
          action.from > 0
            ? state.integrationLogs.length + action.integrationLogs.length
            : action.integrationLogs.length,
        integrationLogsTotalResults: action.totalResults || 0,
      };

    case integrationConstants.GET_INTEGRATION_OBJECTS_STARTED:
      return {
        ...state,
        isFetchingIntegrationObjects: true,
        isFetchingIntegrationObjectsFrom: action.from || 0,
      };
    case integrationConstants.GET_INTEGRATION_OBJECTS_FAILURE:
      return {
        ...state,
        isFetchingIntegrationObjects: false,
        integrationObjects: [],
        error: action.message || '',
      };
    case integrationConstants.GET_INTEGRATION_OBJECTS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrationObjects: false,
        integrationObjects:
          action.from > 0
            ? [...state.integrationObjects, ...action.integrationObjects]
            : action.integrationObjects,
        integrationObjectsLoaded:
          action.from > 0
            ? state.integrationObjects.length + action.integrationObjects.length
            : action.integrationObjects.length,
        integrationObjectsTotalResults: action.totalResults || 0,
      };

    case integrationConstants.GET_INTEGRATION_TASKS_STARTED:
      return {
        ...state,
        isFetchingIntegrationTasks: true,
        isFetchingIntegrationTasksFrom: action.from || 0,
      };
    case integrationConstants.GET_INTEGRATION_TASKS_FAILURE:
      return {
        ...state,
        isFetchingIntegrationTasks: false,
        integrationsTasks: [],
        error: action.message || '',
      };
    case integrationConstants.GET_INTEGRATION_TASKS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrationTasks: false,
        integrationTasks:
          action.from > 0
            ? [...state.integrationTasks, ...action.integrationTasks]
            : action.integrationTasks,
        integrationTasksLoaded:
          action.from > 0
            ? state.integrationTasks.length + action.integrationTasks.length
            : action.integrationTasks.length,
        integrationTasksTotalResults: action.totalResults || 0,
      };


    case integrationConstants.GET_INTEGRATION_REQUEST_LOGS_STARTED:
      return {
        ...state,
        isFetchingIntegrationRequestLogs: true,
        isFetchingIntegrationRequestLogsFrom: action.from || 0,
      };
    case integrationConstants.GET_INTEGRATION_REQUEST_LOGS_FAILURE:
      return {
        ...state,
        isFetchingIntegrationRequestLogs: false,
        integrationsRequestLogs: [],
        error: action.message || '',
      };
    case integrationConstants.GET_INTEGRATION_REQUEST_LOGS_SUCCESS:
      return {
        ...state,
        isFetchingIntegrationRequestLogs: false,
        integrationRequestLogs:
          action.from > 0
            ? [...state.integrationRequestLogs, ...action.integrationRequestLogs]
            : action.integrationRequestLogs,
        integrationRequestLogsLoaded:
          action.from > 0
            ? state.integrationRequestLogs.length + action.integrationRequestLogs.length
            : action.integrationRequestLogs.length,
        integrationRequestLogsTotalResults: action.totalResults || 0,
      };
    default:
      return state;
  }
}