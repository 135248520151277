import { renderRoutes } from '@routes';
import { lazy } from 'react';

const CreateSupportTicket = lazy(() =>
  import('@containers/support/crud/CreateSupportTicketContainer')
);
const SupportTickets = lazy(() => import('@containers/support/SupportTicketsContainer'));
const SupportTicket = lazy(() => import('@containers/support/SupportTicketContainer'));

const routes = [
  {
    paths: ['/'],
    component: SupportTickets,
    type: 'portal',
  },
  {
    paths: ['/ticket/:id'],
    component: SupportTicket,
    type: 'portal',
  },
  {
    paths: ['/new'],
    component: CreateSupportTicket,
    type: 'open',
  },
  {
    paths: ['/ticket/:id/:token'],
    component: SupportTicket,
    type: 'open',
  },
];

const SupportRoutes = (props) => renderRoutes(props, routes);
export default SupportRoutes;
