import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import ChauffeurInput from '../../chauffeur/ChauffeurInput';

export default class AttachChauffeurPage extends Component {
  state = {
    action: {
      type: 'attachChauffeur',
    },
  };

  componentDidMount() {
    const { vehicle } = this.props;
    const { action } = this.state;
    this.setState({
      action: { ...action, vehicle: vehicle },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { vehicle, vehicleAttachChauffeur, popStack } = this.props;
    const { action } = this.state;
    vehicleAttachChauffeur(vehicle.id, action);
    popStack?.();
  };

  render() {
    const { t, isAttachingChauffeur, chauffeurs, searchChauffeurs } =
      this.props;
    const { action } = this.state;

    if (isAttachingChauffeur) {
      return <Loader />;
    }

    return (
      <AsideView>
        <h1>{t('vehicle.chauffeurs.attach')}</h1>
        <div className="step-form" key={action.id}>
          <form
            className="form active no-padding-top"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="input-container">
              <ChauffeurInput
                key={action.chauffeur ? action.chauffeur : null}
                chauffeur={action.chauffeur ? action.chauffeur : null}
                onChange={(newChauffeur) => {
                  const newAction = { ...action };
                  newAction.chauffeur = newChauffeur;

                  this.setState({
                    action: newAction,
                  });
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.attach')}
              />
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
