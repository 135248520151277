export const pricingVariableIndexConstants = {
  PRICINGVARIABLEINDEX_CHANGE_VALUE: 'PRICINGVARIABLEINDEX_CHANGE_VALUE',

  GET_PRICINGVARIABLEINDEX_STARTED: 'GET_PRICINGVARIABLEINDEX_STARTED',
  GET_PRICINGVARIABLEINDEX_SUCCESS: 'GET_PRICINGVARIABLEINDEX_SUCCESS',
  GET_PRICINGVARIABLEINDEX_FAILURE: 'GET_PRICINGVARIABLEINDEX_FAILURE',

  GET_PRICINGVARIABLEINDEXES_STARTED: 'GET_PRICINGVARIABLEINDEXES_STARTED',
  GET_PRICINGVARIABLEINDEXES_SUCCESS: 'GET_PRICINGVARIABLEINDEXES_SUCCESS',
  GET_PRICINGVARIABLEINDEXES_FAILURE: 'GET_PRICINGVARIABLEINDEXES_FAILURE',

  SEARCH_PRICINGVARIABLEINDEXES_STARTED:
    'SEARCH_PRICINGVARIABLEINDEXES_STARTED',
  SEARCH_PRICINGVARIABLEINDEXES_SUCCESS:
    'SEARCH_PRICINGVARIABLEINDEXES_SUCCESS',
  SEARCH_PRICINGVARIABLEINDEXES_FAILURE:
    'SEARCH_PRICINGVARIABLEINDEXES_FAILURE',

  CREATING_PRICINGVARIABLEINDEX_STARTED:
    'CREATING_PRICINGVARIABLEINDEX_STARTED',
  CREATING_PRICINGVARIABLEINDEX_SUCCESS:
    'CREATING_PRICINGVARIABLEINDEX_SUCCESS',
  CREATING_PRICINGVARIABLEINDEX_FAILURE:
    'CREATING_PRICINGVARIABLEINDEX_FAILURE',

  UPDATING_PRICINGVARIABLEINDEX_STARTED:
    'UPDATING_PRICINGVARIABLEINDEX_STARTED',
  UPDATING_PRICINGVARIABLEINDEX_SUCCESS:
    'UPDATING_PRICINGVARIABLEINDEX_SUCCESS',
  UPDATING_PRICINGVARIABLEINDEX_FAILURE:
    'UPDATING_PRICINGVARIABLEINDEX_FAILURE',

  DELETE_PRICINGVARIABLEINDEX_STARTED: 'DELETE_PRICINGVARIABLEINDEX_STARTED',
  DELETE_PRICINGVARIABLEINDEX_SUCCESS: 'DELETE_PRICINGVARIABLEINDEX_SUCCESS',
  DELETE_PRICINGVARIABLEINDEX_FAILURE: 'DELETE_PRICINGVARIABLEINDEX_FAILURE',
};
