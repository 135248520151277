import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTripPage from '@components/trip/crud/CreateTripPage.js';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';
import * as vehicleActions from '@actions/vehicleActions';

class CreateTripContainer extends PureComponent {
  render() {
    const { changeValue, changeQuoteValue, createTrip, callback, ...props } = this.props;
    const { popStack } = this.props;
    return (
      <CreateTripPage
        {...props}
        onChange={changeValue}
        changeValue={changeQuoteValue}
        createTrip={(trip) =>
          createTrip(trip).then(() => {
            popStack?.();
            callback?.();
          })
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    platform: state.platform.platform,
    simpleForms: state.settings?.settings?.userInterface?.simpleForms,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    variables: state.settings.variables,
    user: state.auth.user,
    ...state.administration,
    ...state.trip,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createQuote: (quote) => dispatch(administrationActions.createQuote(quote)),
    changeQuoteValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    createTrip: (trip) => dispatch(tripActions.createTrip(trip)),
    changeValue: (name, value) => dispatch(tripActions.changeValue(name, value)),

    searchVehicles: (searchParameters, from, amount) =>
      dispatch(vehicleActions.searchVehicles(searchParameters, from, amount)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTripContainer));
