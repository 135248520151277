export const flatten = (a) =>
  a.reduce((f, tF) => f.concat(Array.isArray(tF) ? flatten(tF) : tF), []);

export const flattenOne = (a) => a.reduce((f, tF) => f.concat(Array.isArray(tF) ? tF : tF), []);

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const distinct = (arr, property) =>
  arr.filter((v, i, a) => a.filter((t) => t).findIndex((t) => t[property] === v[property]) === i);

export const swapArrayLocs = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};
