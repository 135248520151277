export const teamConstants = {
  TEAM_CHANGE_VALUE: 'TEAM_CHANGE_VALUE',

  GET_TEAM_STARTED: 'GET_TEAM_STARTED',
  GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
  GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',

  GET_TEAMS_STARTED: 'GET_TEAMS_STARTED',
  GET_TEAMS_SUCCESS: 'GET_TEAMS_SUCCESS',
  GET_TEAMS_FAILURE: 'GET_TEAMS_FAILURE',

  GET_VEHICLE_LIGHT_GROUPS_STARTED: 'GET_VEHICLE_LIGHT_GROUPS_STARTED',
  GET_VEHICLE_LIGHT_GROUPS_FAILURE: 'GET_VEHICLE_LIGHT_GROUPS_FAILURE',
  GET_VEHICLE_LIGHT_GROUPS_SUCCESS: 'GET_VEHICLE_LIGHT_GROUPS_SUCCESS',

  SEARCH_TEAMS_STARTED: 'SEARCH_TEAMS_STARTED',
  SEARCH_TEAMS_SUCCESS: 'SEARCH_TEAMS_SUCCESS',
  SEARCH_TEAMS_FAILURE: 'SEARCH_TEAMS_FAILURE',

  CREATING_TEAM_STARTED: 'CREATING_TEAM_STARTED',
  CREATING_TEAM_SUCCESS: 'CREATING_TEAM_SUCCESS',
  CREATING_TEAM_FAILURE: 'CREATING_TEAM_FAILURE',

  UPDATING_TEAM_STARTED: 'UPDATING_TEAM_STARTED',
  UPDATING_TEAM_SUCCESS: 'UPDATING_TEAM_SUCCESS',
  UPDATING_TEAM_FAILURE: 'UPDATING_TEAM_FAILURE',

  DELETE_TEAM_STARTED: 'DELETE_TEAM_STARTED',
  DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
  DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
};
