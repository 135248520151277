export const filterConstants = {
  SET_ENTITY_TYPES: 'SET_ENTITY_TYPES',
  ADD_ENTITY_TYPE: 'ADD_ENTITY_TYPE',
  REMOVE_ENTITY_TYPE: 'REMOVE_ENTITY_TYPE',
  REPLACE_ENTITY_TYPE: 'REPLACE_ENTITY_TYPE',

  SET_FILTERS: 'SET_FILTERS',
  SET_SORT_OPTIONS: 'SET_SORT_OPTIONS',
  SET_FILTERS_STARTED: 'SET_FILTERS_STARTED',
};
