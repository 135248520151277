import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateBoardComputerPage from '@components/boardComputer/crud/CreateBoardComputerPage.js';

import * as boardComputerActions from '@actions/boardComputerActions';
import * as navigationActions from '@actions/navigationActions';

class CreateBoardComputerContainer extends PureComponent {
  render() {
    const { changeValue, createBoardComputer, popStack, callback, ...props } =
      this.props;
    return (
      <CreateBoardComputerPage
        {...props}
        onChange={changeValue}
        createBoardComputer={(boardComputer) =>
          createBoardComputer(boardComputer).then((response) => {
            popStack?.();
            callback?.(response.boardComputer);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,

    ...state.boardComputer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createBoardComputer: (boardComputer) =>
      dispatch(boardComputerActions.createBoardComputer(boardComputer)),
    changeValue: (name, value) =>
      dispatch(boardComputerActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateBoardComputerContainer));
