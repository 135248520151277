import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TransportEquipmentView from '@components/goods/TransportEquipmentView';

import Loader from '@uicomponents/Loader';

import * as navigationActions from '@actions/navigationActions';
import * as transportEquipmentActions from '@actions/transportEquipmentActions';

class TransportEquipmentContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getTransportEquipment } = this.props;
    if (ownProps.transportEquipment) {
      changeValue('transportEquipment', ownProps.transportEquipment);
    } else if (ownProps.transportEquipmentId) {
      getTransportEquipment(ownProps.transportEquipmentId);
    }
  }

  render() {
    const { transportEquipment, getTransportEquipment, deleteTransportEquipment, ...props } =
      this.props;

    if (!transportEquipment) return <Loader />;
    return (
      <TransportEquipmentView
        {...props}
        goods={transportEquipment}
        getTransportEquipment={(id = '') => getTransportEquipment(id)}
        deleteTransportEquipment={(transportEquipment) =>
          deleteTransportEquipment(transportEquipment)
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.transportEquipment,
    ownProps: ownProps,
    platform: state.platform.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(transportEquipmentActions.changeValue(name, value)),

    getTransportEquipment: (id) => dispatch(transportEquipmentActions.getTransportEquipment(id)),
    deleteTransportEquipment: (transportEquipment) =>
      dispatch(transportEquipmentActions.deleteTransportEquipment(transportEquipment)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TransportEquipmentContainer));
