import { cardConstants } from '@constants/card.constants';

const initialState = {
  isFetchingCard: false,
  formCard: null,
  card: null,

  isFetchingCards: false,
  isFetchingCardsFrom: 0,
  cardsTotalResults: 0,
  cardsLoaded: 0,
  cards: [],
};

export default function card(state = initialState, action) {
  switch (action.type) {
    case cardConstants.CARD_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case cardConstants.GET_CARD_STARTED:
      return { ...state, isFetchingCard: true };
    case cardConstants.GET_CARD_FAILURE:
      return { ...state, isFetchingCard: false, card: null, error: action.message || '' };
    case cardConstants.GET_CARD_SUCCESS:
      return {
        ...state,
        isFetchingCard: false,
        card: action.serviceCard,
      };

    case cardConstants.GET_CARDS_STARTED:
      return { ...state, isFetchingCards: true, isFetchingCardsFrom: action.from || 0 };
    case cardConstants.GET_CARDS_FAILURE:
      return { ...state, isFetchingCards: false, cards: [], error: action.message || '' };
    case cardConstants.GET_CARDS_SUCCESS:
      return {
        ...state,
        isFetchingCards: false,
        cards: action.from > 0 ? [...state.cards, ...action.serviceCards] : action.serviceCards,
        cardsLoaded:
          action.from > 0
            ? state.cards.length + action.serviceCards.length
            : action.serviceCards.length,
        cardsTotalResults: action.totalResults || 0,
      };

    case cardConstants.SEARCH_CARDS_STARTED:
      return { ...state, isFetchingCards: true, isFetchingCardsFrom: action.from || 0 };
    case cardConstants.SEARCH_CARDS_FAILURE:
      return { ...state, isFetchingCards: false, cards: [], error: action.message || '' };
    case cardConstants.SEARCH_CARDS_SUCCESS:
      return {
        ...state,
        isFetchingCards: false,
        cards: action.from > 0 ? [...state.cards, ...action.serviceCards] : action.serviceCards,
        cardsLoaded:
          action.from > 0
            ? state.cards.length + action.serviceCards.length
            : action.serviceCards.length,
        cardsTotalResults: action.totalResults || 0,
      };

    case cardConstants.CREATING_CARD_STARTED:
      return { ...state, isFetchingCard: true };
    case cardConstants.CREATING_CARD_FAILURE:
      return { ...state, isFetchingCard: false, card: null, error: action.message || '' };
    case cardConstants.CREATING_CARD_SUCCESS:
      return {
        ...state,
        isFetchingCard: false,
        card: action.serviceCard,
        cards: [action.serviceCard, ...state.cards],
      };

    case cardConstants.UPDATING_CARD_STARTED:
      return { ...state, isFetchingCard: true };
    case cardConstants.UPDATING_CARD_FAILURE:
      return { ...state, isFetchingCard: false, card: null, error: action.message || '' };
    case cardConstants.UPDATING_CARD_SUCCESS:
      return {
        ...state,
        isFetchingCard: false,
        card: action.serviceCard,
        cards: [...state.cards].map((card) => {
          if (action.serviceCard.id === card.id) {
            return action.serviceCard;
          }
          return card;
        }),
      };

    case cardConstants.DELETE_CARD_STARTED:
      return { ...state, isFetchingCard: true };
    case cardConstants.DELETE_CARD_FAILURE:
      return { ...state, isFetchingCard: false, card: null, message: action.message || '' };
    case cardConstants.DELETE_CARD_SUCCESS:
      return {
        ...state,
        isFetchingCard: false,
        card: action.serviceCard,
        cards: [...state.cards].filter((card) => action.serviceCard.id !== card.id),
      };
    default:
      return state;
  }
}
