import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Chauffeur from '@models/chauffeur/Chauffeur';

import ChauffeurForm from './ChauffeurForm';

export default class CreateChauffeurPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formChauffeur', new Chauffeur());
  }

  render() {
    const { t, formChauffeur, isFetchingChauffeur, createChauffeur } =
      this.props;

    if (!formChauffeur || isFetchingChauffeur) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('chauffeur.new')}</h1>
        <ChauffeurForm
          {...this.props}
          key={formChauffeur.id}
          onSubmit={(chauffeur) => createChauffeur(chauffeur)}
        />
      </AsideView>
    );
  }
}
