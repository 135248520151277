import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChauffeurActivitiesTimeline from '@components/chauffeur/ChauffeurActivitiesTimeline';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as navigationActions from '@actions/navigationActions';

class ChauffeurActivitiesTimelineContainer extends PureComponent {
  render() {
    return (
      <ChauffeurActivitiesTimeline {...this.props} {...this.props.ownProps} />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.chauffeur,
    ownProps,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || "DD/MM/YYYY",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChauffeurActivities: (chauffeur, fromDate, toDate) =>
      dispatch(
        chauffeurActions.getChauffeurActivities(chauffeur, fromDate, toDate)
      ),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ChauffeurActivitiesTimelineContainer));
