import { renderRoutes } from '@routes';
import { lazy } from 'react';

const DeveloperDashboardContainer = lazy(() =>
  import('@containers/developer/DeveloperDashboardContainer')
);
const DocumentationContainer = lazy(() => import('@containers/developer/DocumentationContainer'));
const PublishersContainer = lazy(() => import('@containers/publisher/PublishersContainer'));
const AppsContainer = lazy(() => import('@containers/app/AppsContainer'));
const AppContainer = lazy(() => import('@containers/app/AppContainer'));

const routes = [
  {
    paths: [''],
    component: DeveloperDashboardContainer,
    type: 'portal',
  },
  {
    paths: ['/documentation', '/documentation/:tab'],
    component: DocumentationContainer,
    type: 'portal',
  },
  {
    paths: ['/publishers'],
    component: PublishersContainer,
    type: 'portal',
  },
  {
    paths: ['/apps'],
    component: AppsContainer,
    type: 'portal',
  },
  {
    paths: ['/apps/:id'],
    component: AppContainer,
    type: 'portal',
  },
];

const DeveloperRoutes = (props) => renderRoutes(props, routes);
export default DeveloperRoutes;
