import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Business from '@models/business/Business';

import BusinessForm from './BusinessForm';

export default class UpdateBusinessPage extends Component {
  componentDidMount() {
    const { onChange, targetBusiness } = this.props;
    onChange('formBusiness', targetBusiness || new Business());
  }

  render() {
    const { t, formBusiness, businessIsFetching, updateBusiness, popStack } = this.props;

    if (!formBusiness || businessIsFetching) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1 className="no-margin-bottom">{t('business.update')}</h1>
        <BusinessForm
          {...this.props}
          onSubmit={(business) => updateBusiness(business).then(() => popStack())}
        />
      </AsideView>
    );
  }
}
