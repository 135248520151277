import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateLocationPage from '@components/location/crud/CreateLocationPage.js';

import * as locationActions from '@actions/locationActions';
import * as navigationActions from '@actions/navigationActions';

class CreateLocationContainer extends PureComponent {
  render() {
    const { changeValue, createLocation, popStack, callback, ...props } =
      this.props;
    return (
      <CreateLocationPage
        {...props}
        onChange={changeValue}
        createLocation={(location) =>
          createLocation(location).then((response) => {
            popStack?.();
            callback?.(response.location);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    ...state.location,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createLocation: (location) =>
      dispatch(locationActions.createLocation(location)),
    changeValue: (name, value) =>
      dispatch(locationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateLocationContainer));
