export const consignmentConstants = {
  CONSIGNMENT_CHANGE_VALUE: 'CONSIGNMENT_CHANGE_VALUE',

  GET_CONSIGNMENT_STARTED: 'GET_CONSIGNMENT_STARTED',
  GET_CONSIGNMENT_SUCCESS: 'GET_CONSIGNMENT_SUCCESS',
  GET_CONSIGNMENT_FAILURE: 'GET_CONSIGNMENT_FAILURE',

  GET_CONSIGNMENTS_STARTED: 'GET_CONSIGNMENTS_STARTED',
  GET_CONSIGNMENTS_SUCCESS: 'GET_CONSIGNMENTS_SUCCESS',
  GET_CONSIGNMENTS_FAILURE: 'GET_CONSIGNMENTS_FAILURE',

  SEARCH_CONSIGNMENTS_STARTED: 'SEARCH_CONSIGNMENTS_STARTED',
  SEARCH_CONSIGNMENTS_SUCCESS: 'SEARCH_CONSIGNMENTS_SUCCESS',
  SEARCH_CONSIGNMENTS_FAILURE: 'SEARCH_CONSIGNMENTS_FAILURE',

  CREATING_CONSIGNMENT_STARTED: 'CREATING_CONSIGNMENT_STARTED',
  CREATING_CONSIGNMENT_SUCCESS: 'CREATING_CONSIGNMENT_SUCCESS',
  CREATING_CONSIGNMENT_FAILURE: 'CREATING_CONSIGNMENT_FAILURE',

  UPDATING_CONSIGNMENT_STARTED: 'UPDATING_CONSIGNMENT_STARTED',
  UPDATING_CONSIGNMENT_SUCCESS: 'UPDATING_CONSIGNMENT_SUCCESS',
  UPDATING_CONSIGNMENT_FAILURE: 'UPDATING_CONSIGNMENT_FAILURE',

  DELETE_CONSIGNMENT_STARTED: 'DELETE_CONSIGNMENT_STARTED',
  DELETE_CONSIGNMENT_SUCCESS: 'DELETE_CONSIGNMENT_SUCCESS',
  DELETE_CONSIGNMENT_FAILURE: 'DELETE_CONSIGNMENT_FAILURE',
};
