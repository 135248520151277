import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class DirectionTypeSelector extends Component {
  static defaultProps = {
    disabled: false,
  };
  render() {
    const { t, onChange, placeholder, value, disabled } = this.props;
    const options = [
      { value: 'in', label: t('directionType.in') },
      { value: 'out', label: t('directionType.out') },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        isDisabled={disabled}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(DirectionTypeSelector);
