import React, { Component } from 'react';

export default class BreadCrumbs extends Component {
  render() {
    const { crumbs } = this.props;
    return (
      <div className="breadcrumbs">
        {crumbs &&
          crumbs.map((crumb) => (
            <div className="crumb" onClick={crumb.onClick}>
              {crumb.name}
            </div>
          ))}
      </div>
    );
  }
}
