export default class Message {
  id;
  nonce;

  message;
  type;
  files;

  read;
  received;

  isOffline;

  createdAt;
  updatedAt;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.files = [];
    this.read = [];
    this.received = [];

    this.isOffline = false;
  }
}
