import React, { Component } from 'react';

export default class Banner extends Component {
  updateAppVersion() {
    localStorage.removeItem('version-update-needed');
    window.location.reload();
  }
  render() {
    const { t } = this.props;
    return (
      <div className="announcement-banner">
        <div className="message">
          A newer version of this app is available, kindly update.
        </div>
        <button
          className="banner-button"
          onClick={(e) => {
            e.preventDefault();
            this.updateAppVersion();
          }}
        >
          {t('form.update')}
        </button>
      </div>
    );
  }
}
