import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateMessageForm from '@components/chat/crud/CreateMessageForm';

import * as chatActions from '@actions/chatActions';

class CreateMessageContainer extends PureComponent {
  render() {
    const { changeValue, createMessage, ...props } = this.props;

    return (
      <CreateMessageForm
        {...props}
        onChange={changeValue}
        createMessage={(chat, message) => createMessage(chat, message)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    languages: state.platform.languages,

    chat: state.chat.chat,
    message: state.chat.message,
    messageInConcept: state.chat.messagesInConcept[state?.chat?.chat?.id],

    darkMode: state.settings.darkMode,

    ...state.chat,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(chatActions.changeChatValue(name, value)),
    createMessage: (chat, message) =>
      dispatch(chatActions.createMessage(chat, message)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateMessageContainer));
