import L, { divIcon } from 'leaflet';

export default divIcon({
  html: `
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
        <g>
            <path d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035 c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z" />
        </g>
    </svg>
    `,
  iconSize: new L.Point(24, 24),
  iconAnchor: [12, 24],
  popupAnchor: [12, 24],
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  className: 'default-marker',
});
