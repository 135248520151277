import { vehicleGroupConstants } from '../constants/vehicleGroup.constants';

const initialState = {
  isFetchingVehicleGroup: false,
  formVehicleGroup: null,
  vehicleGroup: null,

  isFetchingVehicleGroups: false,
  isFetchingVehicleGroupsFrom: 0,
  vehicleGroupsTotalResults: 0,
  vehicleGroupsLoaded: 0,
  vehicleGroups: [],

  isFetchingVehicleLightGroups: false,
  vehicleLightGroups: [],
};

export default function vehicleGroup(state = initialState, action) {
  switch (action.type) {
    case vehicleGroupConstants.VEHICLE_GROUP_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case vehicleGroupConstants.GET_VEHICLE_GROUPS_STARTED:
      return {
        ...state,
        isFetchingVehicleGroups: true,
        isFetchingVehicleGroupsFrom: action.from || 0,
      };
    case vehicleGroupConstants.GET_VEHICLE_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingVehicleGroups: false,
        error: action.message || '',
      };
    case vehicleGroupConstants.GET_VEHICLE_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchingVehicleGroups: false,
        vehicleGroups:
          action.from > 0
            ? [...state.vehicleGroups, ...action.vehicleGroups]
            : action.vehicleGroups,
        vehicleGroupsLoaded:
          action.from > 0
            ? state.vehicleGroups.length + action.vehicleGroups.length
            : action.vehicleGroups.length,
        vehicleGroupsTotalResults: action.totalResults || 0,
      };

    case vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_STARTED:
      return { ...state, isFetchingVehicleLightGroups: true };
    case vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingVehicleLightGroups: false,
        error: action.message || '',
      };
    case vehicleGroupConstants.GET_VEHICLE_LIGHT_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchingVehicleLightGroups: false,
        vehicleLightGroups: action.vehicleGroups || [],
      };

    case vehicleGroupConstants.SEARCH_VEHICLE_GROUPS_STARTED:
      return {
        ...state,
        isFetchingVehicleGroups: true,
        isFetchingVehicleGroupsFrom: action.from || 0,
      };
    case vehicleGroupConstants.SEARCH_VEHICLE_GROUPS_FAILURE:
      return {
        ...state,
        isFetchingVehicleGroups: false,
        error: action.message || '',
      };
    case vehicleGroupConstants.SEARCH_VEHICLE_GROUPS_SUCCESS:
      return {
        ...state,
        isFetchingVehicleGroups: false,
        vehicleGroups:
          action.from > 0
            ? [...state.vehicleGroups, ...action.vehicleGroups]
            : action.vehicleGroups,
        vehicleGroupsLoaded:
          action.from > 0
            ? state.vehicleGroups.length + action.vehicleGroups.length
            : action.vehicleGroups.length,
        vehicleGroupsTotalResults: action.totalResults || 0,
      };

    case vehicleGroupConstants.CREATING_VEHICLE_GROUP_STARTED:
      return { ...state, isFetchingVehicleGroup: true };
    case vehicleGroupConstants.CREATING_VEHICLE_GROUP_FAILURE:
      return {
        ...state,
        isFetchingVehicleGroup: false,
        error: action.message || '',
      };
    case vehicleGroupConstants.CREATING_VEHICLE_GROUP_SUCCESS:
      return {
        ...state,
        isFetchingVehicleGroup: false,
        vehicleGroup: action.vehicleGroup,
        vehicleGroups: [action.vehicleGroup, ...state.vehicleGroups],
      };

    case vehicleGroupConstants.UPDATING_VEHICLE_GROUP_STARTED:
      return { ...state, isFetchingVehicleGroup: true };
    case vehicleGroupConstants.UPDATING_VEHICLE_GROUP_FAILURE:
      return {
        ...state,
        isFetchingVehicleGroup: false,
        error: action.message || '',
      };
    case vehicleGroupConstants.UPDATING_VEHICLE_GROUP_SUCCESS:
      return {
        ...state,
        isFetchingVehicleGroup: false,
        vehicleGroup: action.vehicleGroup,
        vehicleGroups: [...state.vehicleGroups].map((vehicleGroup) => {
          if (vehicleGroup.id === action.vehicleGroup.id) {
            return action.vehicleGroup;
          }
          return vehicleGroup;
        }),
      };

    case vehicleGroupConstants.DELETE_VEHICLE_GROUP_STARTED:
      return { ...state, isFetchingVehicle: true };
    case vehicleGroupConstants.DELETE_VEHICLE_GROUP_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        message: action.message || '',
      };
    case vehicleGroupConstants.DELETE_VEHICLE_GROUP_SUCCESS:
      return {
        ...state,
        isFetchingVehicleGroup: false,
        vehicleGroup: action.vehicleGroup,
        vehicleGroups: [...state.vehicleGroups].filter(
          (vehicleGroup) => action.vehicleGroup.id !== vehicleGroup.id
        ),
      };
    default:
      return state;
  }
}
