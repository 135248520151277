import api from '../api';


// CRUD
export const get = (id = '') => {
  return api.get(`/integration/${id}`);
};

export const create = (integration) => {
  return api.post(`/integration`, integration);
};

export const update = (integration) => {
  return api.put(`/integration`, integration);
};

export const remove = (integration) => {
  return api.delete(`/integration/${integration.id}`);
};

export const uploadFile = (file, progress = (e) => {}) => {
  let formData = new FormData();

  formData.append('file', file);

  return api.post(`/integration/file/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    onUploadProgress: progress,
  });
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/integrations/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/integrations/${offset}/${amount}`, searchParameters);
};

// Business logic
export const logs = (id = '', offset = 0, amount = 20) => {
  return api.get(`/integration/${id}/logs/${offset}/${amount}`);
};
export const objects = (id = '', offset = 0, amount = 20) => {
  return api.get(`/integration/${id}/objects/${offset}/${amount}`);
};
export const tasks = (id = '', offset = 0, amount = 20) => {
  return api.get(`/integration/${id}/tasks/${offset}/${amount}`);
};
export const requestLogs = (id = '', offset = 0, amount = 20) => {
  return api.get(`/integration/${id}/requestLogs/${offset}/${amount}`);
};
export const functions = (type = '') => {
  return api.get(`/integration/type/${type}/functions`);
};

export const generateCredentials = (credentials) => {
  return api.post(`/integration/generate/credentials`, credentials);
};

// mapping test
export const testDataSourceMapping = (dataSource = {}, data = '') => {
  return api.post(`/integration/dataSource/mapping:test`, { dataSource, data });
};
export const testFileTemplateMapping = (integration) => {
  return api.post(`/integration/fileTemplate/mapping:test`, integration);
};

// import data
export const importData = (data, entityType, fields) => {
  return api.post(`/integration/import/data`, { data, entityType, fields }, { timeout: 600000 });
};

// enable open id
export const setOpenId = (id, token) => {
  return api.post(`/integration/${id}/openId`, { token });
};