import { navigationConstants } from '../constants';

// Stack navigation
export const addToStack = (component) => ({
  type: navigationConstants.ADD_TO_STACK,
  component: component,
});

export const removeFromStack = (component) => ({
  type: navigationConstants.REMOVE_FROM_STACK,
  component: component,
});

export const updateStack = (component) => ({
  type: navigationConstants.UPDATE_STACK_ELEMENT,
  component,
});

export const updateTopStack = (args) => ({
  type: navigationConstants.UPDATE_LATEST_STACK_ELEMENT,
  arguments: args,
});

export const animateLastOut = () => ({
  type: navigationConstants.ANIMATE_LAST_OUT,
});

export const popStack = () => {
  return (dispatch) => {
    dispatch(animateLastOut());

    setTimeout(() => {
      dispatch({
        type: navigationConstants.POP_STACK,
      });
    }, 150);
  };
};

export const clearStack = () => ({
  type: navigationConstants.CLEAR_STACK,
});

// Alerts
export const removeAlert = (alert) => ({
  type: navigationConstants.REMOVE_ALERT,
  alert,
});
export const animateAlertOut = (alert) => ({
  type: navigationConstants.ANIMATE_ALERT_OUT,
  alert: { ...alert, animatingOut: true },
});

export const createAlert = (alert) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(animateAlertOut(alert));
    }, alert.timeout || 3500);
    setTimeout(() => {
      dispatch(removeAlert(alert));
    }, alert.timeout + 300 || 3700);
    dispatch({
      type: navigationConstants.CREATE_ALERT,
      alert: { ...alert, animatingOut: false },
    });
  };
};

export const popAlert = () => ({
  type: navigationConstants.POP_ALERT,
});

export const clearAllAlerts = () => ({
  type: navigationConstants.CLEAR_ALL_ALERTS,
});

// PopOvers
export const addPopOver = (popOver) => ({
  type: navigationConstants.ADD_POPOVER,
  popOver: popOver,
});

export const removePopOver = (popOver) => ({
  type: navigationConstants.REMOVE_POPOVER,
  popOver: popOver,
});

export const popPopOver = () => {
  return (dispatch) => {
    dispatch({
      type: navigationConstants.POP_POPOVER,
    });
  };
};

// planBoardType

export const setPlanBoardType = (type) => {
  return (dispatch) => {
    dispatch({
      type: navigationConstants.SET_PLAN_BOARD_TYPE,
      planBoardType: type,
    });
  };
};

export const setPlannerType = (type) => {
  return (dispatch) => {
    dispatch({
      type: navigationConstants.SET_PLANNER_TYPE,
      plannerType: type,
    });
  };
};
