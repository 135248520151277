import { authConstants, generalConstants, settingsConstants } from '@constants';

import moment from 'moment';

const initialState = {
  generalSettingsIsFetching: false,
  settingsIsFetching: false,
  settings: null,

  darkMode: (localStorage?.getItem('darkMode') || 'false') === 'true',
  theme: localStorage.getItem('theme') || 'light',
  variables: {
    taxRates: [],
    attributes: [],
    pricingCategories: [],
  },

  tutorial: null,
  retryPaymentConfig: null,
  tutorialErrorMessage: null,
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case settingsConstants.GET_SETTINGS_STARTED:
      return { ...state, settingsIsFetching: true };
    case settingsConstants.GET_SETTINGS_FAILURE:
      return {
        ...state,
        settingsIsFetching: false,
        message: action.message || '',
      };
    case settingsConstants.GET_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsIsFetching: false,
        ...extraditeSettings(action.settings),
      };

    case settingsConstants.UPDATE_SETTINGS_STARTED:
      return { ...state, settingsIsFetching: true };
    case settingsConstants.UPDATE_SETTINGS_FAILURE:
      return {
        ...state,
        settingsIsFetching: false,
        message: action.message || '',
      };
    case settingsConstants.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        settingsIsFetching: false,
        ...extraditeSettings(action.settings),
      };

    case authConstants.UPDATE_USER_STARTED:
      return { ...state, generalSettingsIsFetching: true };
    case authConstants.UPDATE_USER_FAILURE:
      return {
        ...state,
        generalSettingsIsFetching: false,
        message: action.message || '',
      };
    case authConstants.UPDATE_USER_SUCCESS:
      const newSettings = { ...state.settings };
      newSettings.user = action.user;
      return {
        ...state,
        generalSettingsIsFetching: false,
        settings: newSettings,
      };

    case settingsConstants.SETTINGS_ON_CHANGE:
      return {
        ...state,
        error: '',
        [action.name]: action.value,
      };

    case settingsConstants.UPDATE_TUTORIAL:
      return {
        ...state,
        tutorial: action.value,
      };

    case settingsConstants.UPDATE_TUTORIAL_ERROR_MESSAGE:
      return {
        ...state,
        tutorialErrorMessage: action.value,
      };

    case settingsConstants.SET_PAYMENT_RETRY_CONFIG:
      return {
        ...state,
        retryPaymentConfig: action.value,
      };

    case generalConstants.UPDATE_TAX_RATES_SUCCESS:
      return {
        ...state,
        settingsIsFetching: false,
        variables: { ...state.variables, taxRates: action.taxRates },
      };

    case generalConstants.UPDATE_ATTRIBUTES_SUCCESS:
      return {
        ...state,
        settingsIsFetching: false,
        variables: { ...state.variables, attributes: action.attributes },
      };

    case generalConstants.UPDATE_PRICING_CATEGORIES_SUCCESS:
      return {
        ...state,
        settingsIsFetching: false,
        variables: {
          ...state.variables,
          pricingCategories: action.pricingCategories,
        },
      };
    case authConstants.LOGOUT_SUCCESS:
    case authConstants.LOGOUT_FAILURE:
      return { ...initialState };
    default:
      return state;
  }
}

const extraditeSettings = (settings) => {
  let theme = 'light';
  switch (settings?.userInterface?.theme || localStorage.getItem('theme') || 'system') {
    case 'system':
    default:
      theme =
        window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
          ? 'dark'
          : 'light';
      break;
    case 'lightDayDarkNight':
      const currentHour = parseInt(moment().format('HH'));
      if ((currentHour >= 6 && currentHour < 12) || (currentHour >= 12 && currentHour < 18)) {
        theme = 'light';
      } else if ((currentHour >= 18 && currentHour < 24) || (currentHour >= 0 && currentHour < 6)) {
        theme = 'dark';
      }
      break;
    case 'dark':
      theme = 'dark';
      break;
    case 'light':
      theme = 'light';
      break;
  }
  const darkMode = theme === 'dark';

  if (localStorage) {
    localStorage.setItem('theme', theme);
    localStorage.setItem('darkMode', darkMode);
  }

  return { settings, darkMode, theme };
};
