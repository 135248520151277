import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import CapacityForm from '@components/capacity/crud/CapacityForm';

import AsideView from '@uicomponents/AsideView';

import * as capacityActions from '@actions/capacityActions';
import * as navigationActions from '@actions/navigationActions';

class CapacityModule extends Component {
  componentDidMount() {
    const { value, changeValue } = this.props;

    changeValue('formCapacity', value);
  }
  render() {
    const {
      t,
      onChange,
      changeValue,
      formCapacity,
      isFetchingCapacity,
      addToStack,
      popStack,
    } = this.props;

    if (!formCapacity || isFetchingCapacity) {
      return null;
    }

    return (
      <div
        className="list-sector pricing-form-element"
        onClick={(e) => {
          e.preventDefault();
          addToStack({
            name: formCapacity.name,
            component: (
              <AsideView>
                <h1>{t('capacity.create')}</h1>
                <CapacityForm
                  t={t}
                  formCapacity={formCapacity}
                  onSubmit={(capacity) => {
                    changeValue && changeValue('formCapacity', capacity);
                    onChange && onChange(capacity);
                    popStack?.();
                  }}
                />
              </AsideView>
            ),
          });
        }}
      >
        <div className="flex-container justify-between">
          <h2>
            {t('capacity.header')}
            {formCapacity.name ? ` - ${formCapacity.name}` : ''}
          </h2>
          <div className="list-actions">
            <div className="go">
              <ReactSVG src="/icons/back.svg" />
            </div>
          </div>
        </div>
        <div>
          <div className="flex-container value-list">
            {formCapacity.type && (
              <p className="no-margin-bottom value-list-item">
                <b>{t('capacity.type')}:</b> {formCapacity.module}
              </p>
            )}
          </div>
          {formCapacity.description && (
            <p className="no-margin">
              <b>{t('capacity.description')}:</b> {formCapacity.description}
            </p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.capacity,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(capacityActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CapacityModule));
