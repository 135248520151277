import moment from 'moment';

export const convertFiltersToSearchParameters = (filters, searchParameters) => {
  const newSearchParameters = { ...searchParameters };

  filters?.forEach((filter) => {
    newSearchParameters[filter.name] = filter;

    if (filter.type === 'sorting') {
      newSearchParameters[filter.name] = [filter];
    } else if (filter.type === 'component') {
      newSearchParameters[filter.name] =
        filter.value instanceof Array
          ? filter.value.map((v) => (typeof v === 'string' || v instanceof String ? v : v.id))
          : filter.value?.id || filter.value;
    } else if (filter.type === 'date' || filter.type === 'date-time') {
      if (filter.value) {
        newSearchParameters[filter.name] = filter.value ? moment(filter.value) : null;
      } else {
        delete newSearchParameters[filter.name];
      }
    } else if (filter.type === 'date-range') {
      const [start, end] = filter.value || [];

      newSearchParameters[filter.name]['start'] = start ? moment(start) : null;
      newSearchParameters[filter.name]['end'] = start ? moment(end) : null;
    } else if (filter.value) {
      newSearchParameters[filter.name] = filter.value;
    }

    if (filter.type !== 'sorting' && (!filter.value || (filter?.value?.length || 0) < 1)) {
      delete newSearchParameters[filter.name];
    }
  });

  return newSearchParameters;
};

export const activeFilterCount = (filters) => {
  return filters?.filter((f) => f.value != null && f.value?.length > 0)?.length || 0;
};
