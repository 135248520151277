import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateTripPage from '@components/trip/crud/UpdateTripPage.js';

import * as fileApi from '@api/fileApi';

import * as administrationActions from '@actions/administrationActions';
import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';
import * as vehicleActions from '@actions/vehicleActions';

class UpdateTripContainer extends PureComponent {
  render() {
    const { changeValue, changeQuoteValue, updateTrip, callback, ...props } = this.props;
    const { popStack } = this.props;
    return (
      <UpdateTripPage
        {...props}
        onChange={changeValue}
        changeValue={changeQuoteValue}
        updateTrip={(trip) =>
          updateTrip(trip).then((response) => {
            popStack?.();
            callback?.();
          })
        }
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    platform: state.platform.platform,
    simpleForms: state.settings?.settings?.userInterface?.simpleForms,
    dateFormat: state.settings?.settings?.user?.language?.dateFormat || 'DD/MM/YYYY',
    user: state.auth.user,
    variables: state.settings.variables,
    ...state.trip,
    ...state.administration,

    ownProps: ownProps,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateQuote: (quote) => dispatch(administrationActions.updateQuote(quote)),
    changeQuoteValue: (name, value) => dispatch(administrationActions.changeValue(name, value)),

    updateTrip: (trip) => dispatch(tripActions.updateTrip(trip)),
    changeValue: (name, value) => dispatch(tripActions.changeValue(name, value)),

    searchVehicles: (searchParameters, from, amount) =>
      dispatch(vehicleActions.searchVehicles(searchParameters, from, amount)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateTripContainer));
