import { renderRoutes } from '@routes';
import { lazy } from 'react';

const PlansContainer = lazy(() =>
  import('@containers/plan/PlansContainer')
);
const routes = [
  {
    paths: ['', '/:id', '/:id/update', '/create'],
    component: PlansContainer,
    type: 'portal',
  }
];

const PlanRoutes = (props) => renderRoutes(props, routes);
export default PlanRoutes;
