import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class ConnectionTypeSelector extends Component {
  static defaultProps = {
    enabledOptions: ['api', 'sql', 'ftp', 'sftp'],
  };
  render() {
    const { t, onChange, enabledOptions, placeholder, value } = this.props;
    const options = [
      { value: 'api', label: t('connectionType.api') },
      { value: 'sql', label: t('connectionType.sql') },
      { value: 'ftp', label: t('connectionType.ftp') },
      { value: 'sftp', label: t('connectionType.sftp') },
      { value: 'web_hook', label: t('connectionType.web_hook') },
      { value: 'email', label: t('connectionType.email') },
      { value: 'internal', label: t('connectionType.internal') },
    ];
    return (
      <Select
        value={options
          .filter((o) => enabledOptions.includes(o.value))
          .find((option) => option.value === value)}
        options={options.filter((o) => enabledOptions.includes(o.value))}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(ConnectionTypeSelector);
