import React, { Component } from 'react';

import ChatContainer from '@containers/chat/ChatContainer';
import CreateChatContainer from '@containers/chat/crud/CreateChatContainer';

import ChatsList from './ChatsList';

export default class ChatsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasProcessedUrl: false,
    };
  }

  componentDidMount() {
    const { match, getChats, chatsIsFetching } = this.props;
    this.setState({
      hasProcessedUrl: true,
    });
    if (!chatsIsFetching) getChats(0, 30);
  }

  render() {
    const { t, getChats, newChat, activeChats, changeValue } = this.props;
    return (
      <div className="chat chat-popups">
        <div className="sidebar">
          {newChat ? (
            <CreateChatContainer
              chatPopup={true}
              onChatCreated={(chat) => {
                changeValue('activeChats', [
                  ...activeChats,
                  { ...chat, maximize: true },
                ]);
                getChats(0, 30);
              }}
            />
          ) : (
            <ChatsList {...this.props} chatPopup={true} />
          )}
        </div>
        <div className="user-chats scrollable">
          {activeChats?.length
            ? activeChats.map((activeChat, index) => (
                <div key={index}>
                  <ChatContainer
                    key={index}
                    activeChat={activeChat}
                    chatPopup={true}
                  />
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}
