import React, { Component, createRef } from 'react';
import { Marker, Popup } from 'react-leaflet';
import DefaultMarker from '@uimarker/default/DefaultMarker';

class DraggableMarker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      draggable: false,
      position: [props.latLonGeoReference.lat, props.latLonGeoReference.lon],
    };
    this.markerRef = createRef();
  }

  toggleDraggable = () => {
    this.setState((prevState) => ({
      draggable: !prevState.draggable,
    }));
  };

  handleDragEnd = () => {
    const marker = this.markerRef.current;
    if (marker != null) {
      const newPosition = marker.getLatLng();
      this.setState({ position: newPosition });
      this.props.savePosition(newPosition); // Save new position to backend
    }
  };

  render() {
    const { draggable, position } = this.state;
    return (
      <Marker
        draggable={draggable}
        eventHandlers={{
          dragend: this.handleDragEnd,
        }}
        ref={this.markerRef}
        icon={DefaultMarker}
        position={position}
      >
        <Popup minWidth={90}>
          <span onClick={this.toggleDraggable}>
            {draggable ? 'Marker is draggable' : 'Click here to make marker draggable'}
          </span>
        </Popup>
      </Marker>
    );
  }
}

export default DraggableMarker;
