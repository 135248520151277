import { pricingConstants } from '@constants/pricing.constants';

const initialState = {
  isFetchingPricing: false,
  formPricing: null,
  pricing: null,

  isFetchingPricings: false,
  isFetchingPricingsFrom: 0,
  pricingsTotalResults: 0,
  pricingsLoaded: 0,
  pricings: [],
};

export default function pricing(state = initialState, action) {
  switch (action.type) {
    case pricingConstants.PRICING_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case pricingConstants.GET_PRICING_STARTED:
      return { ...state, isFetchingPricing: true };
    case pricingConstants.GET_PRICING_FAILURE:
      return {
        ...state,
        isFetchingPricing: false,
        error: action.message || '',
      };
    case pricingConstants.GET_PRICING_SUCCESS:
      return {
        ...state,
        isFetchingPricing: false,
        pricing: action.pricing,
      };

    case pricingConstants.GET_PRICINGS_STARTED:
      return {
        ...state,
        isFetchingPricings: true,
        isFetchingPricingsFrom: action.from || 0,
      };
    case pricingConstants.GET_PRICINGS_FAILURE:
      return {
        ...state,
        isFetchingPricings: false,
        error: action.message || '',
      };
    case pricingConstants.GET_PRICINGS_SUCCESS:
      return {
        ...state,
        isFetchingPricings: false,
        pricings: action.from > 0 ? [...state.pricings, ...action.pricings] : action.pricings,
        pricingsLoaded:
          action.from > 0 ? state.pricings.length + action.pricings.length : action.pricings.length,
        pricingsTotalResults: action.totalResults || 0,
      };

    case pricingConstants.SEARCH_PRICINGS_STARTED:
      return {
        ...state,
        isFetchingPricings: true,
        isFetchingPricingsFrom: action.from || 0,
      };
    case pricingConstants.SEARCH_PRICINGS_FAILURE:
      return {
        ...state,
        isFetchingPricings: false,
        error: action.message || '',
      };
    case pricingConstants.SEARCH_PRICINGS_SUCCESS:
      return {
        ...state,
        isFetchingPricings: false,
        pricings: action.from > 0 ? [...state.pricings, ...action.pricings] : action.pricings,
        pricingsLoaded:
          action.from > 0 ? state.pricings.length + action.pricings.length : action.pricings.length,
        pricingsTotalResults: action.totalResults || 0,
      };

    case pricingConstants.CREATING_PRICING_STARTED:
      return { ...state, isFetchingPricing: true };
    case pricingConstants.CREATING_PRICING_FAILURE:
      return {
        ...state,
        isFetchingPricing: false,
        error: action.message || '',
      };
    case pricingConstants.CREATING_PRICING_SUCCESS:
      return {
        ...state,
        isFetchingPricing: false,
        pricing: action.pricing,
        pricings: [action.pricing, ...state.pricings],
      };

    case pricingConstants.UPDATING_PRICING_STARTED:
      return { ...state, isFetchingPricing: true };
    case pricingConstants.UPDATING_PRICING_FAILURE:
      return {
        ...state,
        isFetchingPricing: false,
        error: action.message || '',
      };
    case pricingConstants.UPDATING_PRICING_SUCCESS:
      return {
        ...state,
        isFetchingPricing: false,
        pricing: action.pricing,
        pricings: [...state.pricings].map((pricing) => {
          if (action.pricing.id === pricing.id) {
            return action.pricing;
          }
          return pricing;
        }),
      };

    case pricingConstants.DELETE_PRICING_STARTED:
      return { ...state, isFetchingPricing: true };
    case pricingConstants.DELETE_PRICING_FAILURE:
      return {
        ...state,
        isFetchingPricing: false,
        message: action.message || '',
      };
    case pricingConstants.DELETE_PRICING_SUCCESS:
      return {
        ...state,
        isFetchingPricing: false,
        pricing: action.pricing,
        pricings: [...state.pricings].filter((pricing) => action.pricing.id !== pricing.id),
      };
    default:
      return state;
  }
}
