import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import ScheduleRoute from '@models/scheduleRoute/ScheduleRoute';

import ScheduleRouteForm from './ScheduleRouteForm';

export default class CreateScheduleRoutePage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newScheduleRoute = new ScheduleRoute('latLonPointGeoReference');
    newScheduleRoute.name = targetName || '';
    onChange('formScheduleRoute', newScheduleRoute);
  }

  render() {
    const {
      t,
      formScheduleRoute,
      isFetchingScheduleRoute,
      createScheduleRoute,
    } = this.props;

    if (!formScheduleRoute || isFetchingScheduleRoute) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('scheduleRoute.new')}</h1>
        <ScheduleRouteForm
          {...this.props}
          key={formScheduleRoute.id}
          onSubmit={(scheduleRoute) => createScheduleRoute(scheduleRoute)}
        />
      </AsideView>
    );
  }
}
