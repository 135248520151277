
export const certificateConstants = {
    CERTIFICATE_CHANGE_VALUE: "CERTIFICATE_CHANGE_VALUE",

    GET_CERTIFICATE_STARTED: "GET_CERTIFICATE_STARTED",
    GET_CERTIFICATE_SUCCESS: "GET_CERTIFICATE_SUCCESS",
    GET_CERTIFICATE_FAILURE: "GET_CERTIFICATE_FAILURE",

    GET_CERTIFICATES_STARTED: "GET_CERTIFICATES_STARTED",
    GET_CERTIFICATES_SUCCESS: "GET_CERTIFICATES_SUCCESS",
    GET_CERTIFICATES_FAILURE: "GET_CERTIFICATES_FAILURE",

    SEARCH_CERTIFICATES_STARTED: "SEARCH_CERTIFICATES_STARTED",
    SEARCH_CERTIFICATES_SUCCESS: "SEARCH_CERTIFICATES_SUCCESS",
    SEARCH_CERTIFICATES_FAILURE: "SEARCH_CERTIFICATES_FAILURE",

    CREATING_CERTIFICATE_STARTED: "CREATING_CERTIFICATE_STARTED",
    CREATING_CERTIFICATE_SUCCESS: "CREATING_CERTIFICATE_SUCCESS",
    CREATING_CERTIFICATE_FAILURE: "CREATING_CERTIFICATE_FAILURE",

    UPDATING_CERTIFICATE_STARTED: "UPDATING_CERTIFICATE_STARTED",
    UPDATING_CERTIFICATE_SUCCESS: "UPDATING_CERTIFICATE_SUCCESS",
    UPDATING_CERTIFICATE_FAILURE: "UPDATING_CERTIFICATE_FAILURE",

    DELETE_CERTIFICATE_STARTED: "DELETE_CERTIFICATE_STARTED",
    DELETE_CERTIFICATE_SUCCESS: "DELETE_CERTIFICATE_SUCCESS",
    DELETE_CERTIFICATE_FAILURE: "DELETE_CERTIFICATE_FAILURE",
};
