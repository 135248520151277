import api from '../api';

// CRUD operation
export const get = (id = '') => {
  return api.get(`/transportEquipment/${id}`);
};
export const create = (transportEquipment) => {
  return api.post(`/transportEquipment`, transportEquipment);
};
export const update = (transportEquipment) => {
  return api.put(`/transportEquipment`, transportEquipment);
};

export const remove = (transportEquipment) => {
  return api.delete(`/transportEquipment/${transportEquipment.id}`);
};

// Fetch and Search
export const all = (from = 0, amount = 20) => {
  return api.get(`/transportEquipments/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/transportEquipments/${from}/${amount}`, searchParameters);
};

export const searchExtended = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/transportEquipments:extended/${from}/${amount}`, searchParameters);
};

export const extended = (from = 0, amount = 20) => {
  return api.get(`/transportEquipments:extended/${from}/${amount}`);
};

export const trips = (id, fromDate, toDate) => {
  return api.get(`transportEquipment/${id}/trips:byDate/${fromDate}/${toDate}`);
};


export const getExtendedTransportEquipmentTrips = (transportEquipments, fromDate, toDate) => {
  return api.post(`/transportEquipments/trips:byDate/${fromDate}/${toDate}`, { transportEquipments })
}