import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

// import moment from 'moment';

import AttachTransportEquipmentContainer from '@containers/vehicle/crud/AttachTransportEquipmentContainer';

import Loader from '@uicomponents/Loader';
import MapPeek from '@uicomponents/MapPeek';
import NoData from '@uicomponents/NoData';
import { defaultDateTimeFormat } from '@utils/dateUtils';

class VehicleTransportEquipmentsView extends Component {
  load = () => {
    const { vehicle, getVehicleTransportEquipments } = this.props;
    getVehicleTransportEquipments(vehicle.id);
  };

  componentDidMount() {
    this.load();
  }

  render() {
    const {
      t,
      vehicle,
      transportEquipments,
      isFetchingTransportEquipments,
      isFetchingTransportEquipmentsFrom,
      addToStack,
      vehicleDetachTransportEquipment,
      dateFormat,
    } = this.props;

    return (
      <div className="vehicle-transportEquipments ">
        <div className="flex-container justify-between">
          <div />
          <div className="actions">
            <button
              onClick={(e) => {
                e.preventDefault();

                addToStack({
                  name: t('vehicle.transportEquipments.attach'),
                  component: (
                    <AttachTransportEquipmentContainer vehicle={vehicle} />
                  ),
                });
              }}
            >
              {t('form.label.attachTransportEquipment')}
            </button>
          </div>
        </div>
        <div className="scrollable">
          {isFetchingTransportEquipmentsFrom < 1 &&
          isFetchingTransportEquipments ? (
            <Loader />
          ) : transportEquipments.length > 0 ? (
            <>
              {transportEquipments.map((transportEquipment) => (
                <div
                  className="vehicle-transportEquipments__transportEquipment"
                  key={`vehicle.transportEquipment-${transportEquipment.id}`}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  <div className="flex-container justify-between">
                    <div className="vehicle-transportEquipments__name">
                      {transportEquipment.name}
                      {transportEquipment.equipmentType && (
                        <span className="vehicle-transportEquipments__type">
                          {t(
                            `equipmentType.${transportEquipment.equipmentType}`
                          )}
                        </span>
                      )}
                    </div>
                    <div className="">
                      <button
                        type="button"
                        onClick={() => {
                          vehicleDetachTransportEquipment(vehicle.id, {
                            type: 'detachTransportEquipment',
                            vehicle: vehicle,
                            transportEquipment: { ...transportEquipment },
                          });
                        }}
                      >
                        {t('form.detach')}
                      </button>
                    </div>
                  </div>
                  <div className="vehicle-transportEquipments__information">
                    <div className="vehicle-transportEquipments__information info-field">
                      <span>{t('transportEquipment.licensePlate')}: </span>{' '}
                      {transportEquipment.licensePlate}
                    </div>
                    {transportEquipment.vin && (
                      <div className="vehicle-transportEquipments__information info-field">
                        <span>{t('transportEquipment.vin')}: </span>{' '}
                        {transportEquipment.vin}
                      </div>
                    )}
                    {transportEquipment.equipmentId && (
                      <div className="vehicle-transportEquipments__information info-field">
                        <span>{t('transportEquipment.equipmentId')}: </span>{' '}
                        {transportEquipment.equipmentId}
                      </div>
                    )}
                  </div>
                  <div className="vehicle-transportEquipments__date">
                    {defaultDateTimeFormat(transportEquipment.createdAt, dateFormat)}
                  </div>
                </div>
              ))}
              {isFetchingTransportEquipments && <Loader />}
            </>
          ) : (
            <div className="not-found-action-box">
              <div className="icon">
                <ReactSVG src="/icons/space.svg" />
              </div>
              <NoData>{t('vehicle.transportEquipments.notFound')}</NoData>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(VehicleTransportEquipmentsView);
