import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/pricingVariableIndex/${id}`);
};

export const create = (pricingVariableIndex) => {
  return api.post(`/pricingVariableIndex`, pricingVariableIndex);
};

export const update = (pricingVariableIndex) => {
  return api.put(`/pricingVariableIndex`, pricingVariableIndex);
};

export const remove = (pricingVariableIndex) => {
  return api.delete(`/pricingVariableIndex/${pricingVariableIndex.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/pricingVariableIndexes/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(
    `/pricingVariableIndexes/${offset}/${amount}`,
    searchParameters
  );
};

// history
export const history = (id, offset, amount) => {
  return api.get(`/pricingVariableIndex/${id}/versions/${offset}/${amount}`);
};
