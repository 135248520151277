import api from '../api';

export const get = (id = '') => {
  return api.get(`/fleet/${id}`);
};

export const create = (fleet) => {
  return api.post(`/fleet`, fleet);
};

export const update = (fleet) => {
  return api.put(`/fleet`, fleet);
};

export const remove = (fleet) => {
  return api.delete(`/fleet/${fleet.id}`);
};

export const latest = (from = 0, amount = 20) => {
  return api.get(`/fleets/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/fleets/${from}/${amount}`, searchParameters);
};

export const light = () => {
  return api.get(`/fleets/light`);
};
