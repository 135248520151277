import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Administration from '@models/administration/Administration';

import AdministrationForm from './AdministrationForm';

export default class CreateAdministrationPage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formAdministration', new Administration());
  }

  render() {
    const {
      t,
      formAdministration,
      isFetchingAdministration,
      createAdministration,
    } = this.props;

    if (!formAdministration || isFetchingAdministration) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('administration.new')}</h1>
        <AdministrationForm
          {...this.props}
          key={formAdministration.id}
          onSubmit={(administration) => createAdministration(administration)}
        />
      </AsideView>
    );
  }
}
