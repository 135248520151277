import React, { Component } from 'react';

import ReactSVG from 'react-svg';
import { Tooltip } from 'react-tooltip';

export default class Tag extends Component {
  render() {
    const { label, className, icon, tooltip, clickEvent } = this.props;

    if (icon) {
      return (
        <>
          <div
            id={tooltip?.id}
            className={`svg-tag tag-${className || 'default'}   ${
              clickEvent ? 'cursor-pointer' : ''
            }`}
            onClick={clickEvent}
          >
            <ReactSVG src={icon} />
          </div>
          {tooltip ? (
            <Tooltip
              anchorId={tooltip.id}
              content={tooltip.content}
              place={tooltip.place}
            />
          ) : null}
        </>
      );
    }

    return (
      <>
        <div id={tooltip?.id} className={`tag tag-${className || 'default'}`}>
          {label}
        </div>
        {tooltip ? (
          <Tooltip
            anchorId={tooltip.id}
            content={tooltip.content}
            place={tooltip.place}
          />
        ) : null}
      </>
    );
  }
}
