import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import TransportEquipmentInput from '../../goods/TransportEquipmentInput';

export default class AttachTransportEquipmentPage extends Component {
  state = {
    action: {
      type: 'attachTransportEquipment',
    },
  };

  componentDidMount() {
    const { vehicle } = this.props;
    const { action } = this.state;
    this.setState({
      action: { ...action, vehicle: vehicle },
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { vehicle, vehicleAttachTransportEquipment, popStack } = this.props;
    const { action } = this.state;
    vehicleAttachTransportEquipment(vehicle.id, action);
    popStack?.();
  };

  render() {
    const { t, isAttachingTransportEquipment } = this.props;
    const { action } = this.state;

    if (isAttachingTransportEquipment) {
      return <Loader />;
    }

    return (
      <AsideView>
        <h1>{t('vehicle.transportEquipments.attach')}</h1>
        <div className="step-form" key={action.id}>
          <form
            className="form active no-padding-top"
            onSubmit={(e) => this.handleSubmit(e)}
          >
            <div className="input-container">
              <TransportEquipmentInput
                key={
                  action.transportEquipment ? action.transportEquipment : null
                }
                transportEquipment={
                  action.transportEquipment ? action.transportEquipment : null
                }
                onChange={(newTransportEquipment) => {
                  const newAction = { ...action };
                  newAction.transportEquipment = newTransportEquipment;

                  this.setState({
                    action: newAction,
                  });
                }}
              />
            </div>
            <div className="input-group more right">
              <input
                type="submit"
                onClick={(e) => this.handleSubmit(e)}
                value={t('form.attach')}
              />
            </div>
          </form>
        </div>
      </AsideView>
    );
  }
}
