import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/scheduleRoute/${id}`);
};

export const create = (scheduleRoute) => {
  return api.post(`/scheduleRoute`, scheduleRoute);
};

export const update = (scheduleRoute) => {
  return api.put(`/scheduleRoute`, scheduleRoute);
};

export const remove = (scheduleRoute) => {
  return api.delete(`/scheduleRoute/${scheduleRoute.id}`);
};

// Fetch and Search
export const latest = (offset = 0, amount = 20) => {
  return api.get(`/scheduleRoutes/${offset}/${amount}`);
};

export const search = (searchParameters = [], offset = 0, amount = 20) => {
  return api.post(`/scheduleRoutes/${offset}/${amount}`, searchParameters);
};
