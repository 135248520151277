import React, { Component } from 'react';

import Dropzone from 'react-dropzone';
import ReactSVG from 'react-svg';
import { Tooltip } from 'react-tooltip';

import FileViewer from '@uicomponents/FileViewer';

import * as fileApi from '@api/fileApi';

import { formatBytes } from '@utils/fileUtils';
import { getBase64 } from '@utils/imageUtils';

import FilePreview from './FilePreview';

export default class SmallAttachments extends Component {
  static defaultProps = {
    max: -1,
    accept: {
      'image/png': ['.png', '.jpg'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.oasis.opendocument.text': ['odt'],
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
      'application/xml': ['.xml'],
      'application/json': ['.json'],
    },
  };

  state = {
    files: this.props.files ? this.props.files : [],
    filePreviewOpen: false,
    focussedFile: null,
  };

  uploadFile = (file) => {
    const { onChange } = this.props;
    const { files } = this.state;
    const prevFileIndex =
      files.findIndex((f) => f.nonce === file.nonce) >= 0
        ? files.findIndex((f) => f.nonce === file.nonce)
        : 0;

    getBase64(file).then((base64) => {
      fileApi
        .uploadFile(file, 'files', (e) => {
          let newFiles = [...this.state.files];
          let newFile = { ...newFiles[prevFileIndex] };
          newFile.progress = (e.loaded / e.total) * 100;
          newFile.uploading = true;
          if (!newFile.base64) newFile.base64 = base64;

          newFiles[prevFileIndex] = newFile;
          this.setState({
            files: newFiles,
          });
        })
        .then((response) => {
          let newFiles = [...files];
          let newFile = { ...newFiles[prevFileIndex] };
          if (response.success) {
            newFile = { ...response.file, ...newFile };
          } else {
            newFile.error = response.message;
          }
          newFile.uploading = false;
          newFile.uploaded = true;
          newFiles[prevFileIndex] = newFile;

          this.setState(
            {
              files: newFiles,
            },
            () => {
              onChange(newFiles);
            }
          );
        });
    });
  };

  render() {
    const { t, max, accept, onChange } = this.props;
    const { files, filePreviewOpen, focussedFile } = this.state;

    return (
      <>
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        <div className="upload-files small gap-10">
          {(max < 0 || files.length < max) && (
            <Dropzone
              ref={this.dropzoneRef}
              style={{ border: 'none' }}
              activeStyle={{ border: 'none' }}
              onDrop={(acceptedFiles) => {
                const files = acceptedFiles.map((file) => {
                  file.nonce = Math.random().toString(36).substring(7);
                  return file;
                });
                this.setState(
                  (prevState) => ({ files: prevState.files.concat(files) }),
                  () => {
                    files.forEach((file) => {
                      this.uploadFile(file);
                    });
                  }
                );
              }}
              accept={accept}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                return (
                  <div
                    className={`input-group drag-and-drop${isDragActive ? ' dragging' : ''}${
                      isDragReject ? ' rejected' : ''
                    }`}
                    {...getRootProps()}
                  >
                    <input {...getInputProps()} />
                    <p>{isDragActive ? (isDragReject ? '-' : '+') : '+'}</p>
                  </div>
                );
              }}
            </Dropzone>
          )}
          {files.length > 0 && (
            <div className="input-group uploaded-files gap-10">
              {files.map((file) => {
                return (
                  <>
                    <div
                      className="file"
                      id={`f${file.id}`}
                      key={`${file.url}-${file.nonce}-${file.uplaoding}-${file.uploaded}`}
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          filePreviewOpen: true,
                          focussedFile: file,
                        });
                      }}
                    >
                      <FilePreview file={file} information={false} />

                      {file.uploading && <i className="fa fa-circle-o-notch fa-spin"></i>}
                      {(!file.uploading || file.uploaded) && (
                        <ReactSVG
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState(
                              {
                                files: files.filter((f) => f.id !== file.id),
                              },
                              () => {
                                onChange(this.state.files);
                              }
                            );
                          }}
                          className="remove-action"
                          src="/icons/cross.svg"
                        />
                      )}
                    </div>
                    <Tooltip anchorSelect={`#f${file.id}`} multiline={true} place="left">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span>
                          <b>{file.originalName}</b>
                        </span>
                        <span>{formatBytes(file.size)}</span>
                      </div>
                    </Tooltip>
                  </>
                );
              })}
            </div>
          )}
        </div>
      </>
    );
  }
}
