export const boardConstants = {
  HANDLE_BOARD_FORM: 'HANDLE_BOARD_FORM',

  GETTING_BOARDS_STARTED: 'GETTING_BOARDS_STARTED',
  GETTING_BOARDS_SUCCESS: 'GETTING_BOARDS_SUCCESS',
  GETTING_BOARDS_FAILURE: 'GETTING_BOARDS_FAILURE',

  GET_BOARD_STARTED: 'GET_BOARD_STARTED',
  GET_BOARD_SUCCESS: 'GET_BOARD_SUCCESS',
  GET_BOARD_FAILURE: 'GET_BOARD_FAILURE',

  CREATING_BOARD_STARTED: 'CREATING_BOARD_STARTED',
  CREATING_BOARD_SUCCESS: 'CREATING_BOARD_SUCCESS',
  CREATING_BOARD_FAILURE: 'CREATING_BOARD_FAILURE',

  UPDATING_BOARD_STARTED: 'UPDATING_BOARD_STARTED',
  UPDATING_BOARD_SUCCESS: 'UPDATING_BOARD_SUCCESS',
  UPDATING_BOARD_FAILURE: 'UPDATING_BOARD_FAILURE',

  DELETE_BOARD_STARTED: 'DELETE_BOARD_STARTED',
  DELETE_BOARD_SUCCESS: 'DELETE_BOARD_SUCCESS',
  DELETE_BOARD_FAILURE: 'DELETE_BOARD_FAILURE',
};
