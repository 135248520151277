export const actorConstants = {
  ACTOR_CHANGE_VALUE: 'ACTOR_CHANGE_VALUE',

  GET_ACTOR_STARTED: 'GET_ACTOR_STARTED',
  GET_ACTOR_SUCCESS: 'GET_ACTOR_SUCCESS',
  GET_ACTOR_FAILURE: 'GET_ACTOR_FAILURE',

  GET_ACTORS_STARTED: 'GET_ACTORS_STARTED',
  GET_ACTORS_SUCCESS: 'GET_ACTORS_SUCCESS',
  GET_ACTORS_FAILURE: 'GET_ACTORS_FAILURE',

  SEARCH_ACTORS_STARTED: 'SEARCH_ACTORS_STARTED',
  SEARCH_ACTORS_SUCCESS: 'SEARCH_ACTORS_SUCCESS',
  SEARCH_ACTORS_FAILURE: 'SEARCH_ACTORS_FAILURE',

  CREATING_ACTOR_STARTED: 'CREATING_ACTOR_STARTED',
  CREATING_ACTOR_SUCCESS: 'CREATING_ACTOR_SUCCESS',
  CREATING_ACTOR_FAILURE: 'CREATING_ACTOR_FAILURE',

  UPDATING_ACTOR_STARTED: 'UPDATING_ACTOR_STARTED',
  UPDATING_ACTOR_SUCCESS: 'UPDATING_ACTOR_SUCCESS',
  UPDATING_ACTOR_FAILURE: 'UPDATING_ACTOR_FAILURE',

  DELETE_ACTOR_STARTED: 'DELETE_ACTOR_STARTED',
  DELETE_ACTOR_SUCCESS: 'DELETE_ACTOR_SUCCESS',
  DELETE_ACTOR_FAILURE: 'DELETE_ACTOR_FAILURE',
};
