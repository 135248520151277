import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTenderPage from '@components/tender/crud/CreateTenderPage.js';

import * as fileApi from '@api/fileApi';
import Alert from '@models/Alert';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';
import * as tenderActions from '@actions/tenderActions';

class CreateTenderContainer extends PureComponent {
  render() {
    const { changeValue, createTender, searchBusinesses, callback, ...props } = this.props;
    const { t, popStack, createAlert } = this.props;

    return (
      <CreateTenderPage
        {...props}
        onChange={changeValue}
        createTender={(tender) =>
          createTender(tender)
            .then((response) => {
              popStack?.();
              callback?.(response.tender);
            })
            .catch((error) => {
              console.error(error);
              popStack?.();

              createAlert(
                new Alert(
                  t('tender.duplicate.error.title'),
                  t('tender.duplicate.error.description'),
                  'error'
                )
              );
            })
        }
        searchBusinesses={(searchParameters, from, amount) =>
          searchBusinesses(searchParameters, from, amount)
        }
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    currencies: state.platform.currencies,
    isFetchingCurrencies: state.platform.isFetchingCurrencies,
    ...state.business,
    ...state.tender,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTender: (tender) => dispatch(tenderActions.createTender(tender)),
    changeValue: (name, value) => dispatch(tenderActions.changeValue(name, value)),

    searchBusinesses: (searchParameters, from, amount) =>
      dispatch(businessActions.searchBusinesses(searchParameters, from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    createAlert: (alert) => dispatch(navigationActions.createAlert(alert)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTenderContainer));
