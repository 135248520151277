import * as cache from '@utils/cache.js';

import { CACHE_DATA } from '../../constants/constants.js';
import { authConstants } from '../constants';

const logoutState = {
  isFetchingUser: false,
  isFetching: false,
  isAuthenticated: false,
  error: '',

  loginStep: 0,

  socketId: '',
  bearer: '',
  session: null,

  registerStep: null,
  registerStepCount: 3,

  isFetchingSessions: false,
  sessions: [],

  isFetchingRole: false,
  role: null,
  formRole: null,
  redirectUrl: null,

  loginSession: null,

  isFetchingRoles: false,
  isFetchingRolesFrom: 0,
  rolesTotalResults: 0,
  rolesLoaded: 0,
  roles: [],
};

const initialState = (cache.read(CACHE_DATA) || {}).auth || logoutState;

export default function auth(state = initialState, action) {
  let cacheData = cache.read(CACHE_DATA) || {};

  switch (action.type) {
    case authConstants.CHANGE_VALUE:
      return {
        ...state,
        error: '',
        [action.name]: action.value,
      };
    case authConstants.REGISTER_FORM_CHANGE_VALUE:
    case authConstants.LOGIN_FORM_CHANGE_VALUE:
    case authConstants.RESET_FORM_CHANGE_VALUE:
    case authConstants.FORGOT_FORM_CHANGE_VALUE:
      state = {
        ...state,
        error: '',
        [action.name]: action.value,
      };
      return state;
    case authConstants.RESET_FORM_STARTED:
      return { ...state, isFetching: true };
    case authConstants.RESET_FORM_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.RESET_FORM_SUCCESS:
      return { ...state, isFetching: false };

    case authConstants.FORGOT_FORM_STARTED:
      return { ...state, isFetching: true };
    case authConstants.FORGOT_FORM_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.FORGOT_FORM_SUCCESS:
      return { ...state, isFetching: false };

    case authConstants.LOGIN_TWO_FACTOR_STARTED:
      return {
        ...state,
        isFetching: false,
        loginSession: action.session,
        bearer: action.session.access_token,
      };

    case authConstants.OTP_LOGIN_STARTED:
      return { ...state, isFetching: true };
    case authConstants.OTP_LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        message: action.message || '',
        loginStep: 0,
      };
    case authConstants.OTP_LOGIN_SUCCESS:
      state = {
        ...state,
        isFetching: false,
        isAuthenticated: action.session.user ? true : false,
        session: action.session,
        user: action.session.user,
        sessionId: action.session.id,
        username: '',
        email: '',
        password: '',
        repeatPassword: '',
        loginStep: 0,
      };
      cacheData.auth = state;
      cache.write(CACHE_DATA, cacheData);
      return state;

    case authConstants.LOGIN_FORM_STARTED:
      return { ...state, isFetching: true };
    case authConstants.LOGIN_FORM_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: action.message || '',
        loginStep: 0,
        password: '',
      };
    case authConstants.LOGIN_FORM_SUCCESS:
      if (action.session) {
        state = {
          ...state,
          isFetching: false,
          isAuthenticated: action.session.user ? true : false,
          session: action.session,
          user: action.session.user,
          bearer: action.session.access_token,
          sessionId: action.session.id,
          username: '',
          email: '',
          password: '',
          repeatPassword: '',
          loginStep: 0,
        };
        cacheData.auth = state;
        cache.write(CACHE_DATA, cacheData);
      } else {
        cacheData.auth = logoutState;
        cache.write(CACHE_DATA, cacheData);
      }
      return state;

    case authConstants.GET_USER_SESSION_STARTED:
      return { ...state, isFetching: true };
    case authConstants.GET_USER_SESSION_FAILURE:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: action.message || '',
      };
    case authConstants.GET_USER_SESSION_SUCCESS:
      if (action.session) {
        state = {
          ...state,
          isFetching: false,
          isAuthenticated: action.session.user ? true : false,
          session: action.session,
          user: action.session.user,
          bearer: action.session.access_token,
          sessionId: action.session.id,
          username: '',
          email: '',
          password: '',
          repeatPassword: '',
          loginStep: 0,
        };
        cacheData.auth = state;
        cache.write(CACHE_DATA, cacheData);
      } else {
        cacheData.auth = logoutState;
        cache.write(CACHE_DATA, cacheData);
      }
      return state;

    case authConstants.SET_REDIRECT_URL:
      return {
        ...state,
        isFetching: false,
        redirectUrl: action.url,
        loggedInUser: action.result,
      };

    case authConstants.SETUP_2FACTOR_STARTED:
      return { ...state, isFetching: true };
    case authConstants.SETUP_2FACTOR_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.SETUP_2FACTOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        qrcode: action.qr,
        recoveryCodes: action.recoveryCodes,
      };

    case authConstants.VERIFY_2FACTOR_STARTED:
      return { ...state, isFetching: true };
    case authConstants.VERIFY_2FACTOR_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.VERIFY_2FACTOR_SUCCESS:
      if (action) {
        if (action.verified) {
          state = {
            ...state,
            isFetching: false,
            isAuthenticated: action.session.user ? true : false,
            session: action.session,
            bearer: action.session.access_token,
            sessionId: action.session.id,
            username: '',
            email: '',
            password: '',
            repeatPassword: '',
            loginStep: 0,
            token: null,
            qrcode: null,
            recoveryCodes: null,
            user: { ...state.user, twoFactorAuthentication: action.verified },
          };
        } else {
          state = {
            ...state,
            isFetching: false,
            token: null,
            message: action.message,
          };
        }
        cacheData.auth = state;
        cache.write(CACHE_DATA, cacheData);
      }
      return state;

    case authConstants.DISABLE_2FACTOR_STARTED:
      return { ...state, isFetching: true };
    case authConstants.DISABLE_2FACTOR_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.DISABLE_2FACTOR_SUCCESS:
      if (action) {
        state = {
          ...state,
          isFetching: false,
          user: { ...state.user, twoFactorAuthentication: action.verified },
        };
        cacheData.auth = state;
        cache.write(CACHE_DATA, cacheData);
      }
      return state;

    case authConstants.RECOVER_2FACTOR_STARTED:
      return { ...state, isFetching: true };
    case authConstants.RECOVER_2FACTOR_FAILURE:
      return { ...state, isFetching: false, message: action.message || '' };
    case authConstants.RECOVER_2FACTOR_SUCCESS:
      return {
        ...state,
        isFetching: false,
        token: null,
      };

    case authConstants.REGISTER_FORM_STARTED:
      state = {
        ...state,
        isFetching: true,
      };
      return state;
    case authConstants.REGISTER_FORM_FAILURE:
      state = {
        ...state,
        isFetching: false,
        isAuthenticated: false,
        error: action.message,
        password: '',
        repeatPassword: '',
      };
      return state;
    case authConstants.REGISTER_FORM_SUCCESS:
      state = {
        ...state,
        isFetching: false,
        bearer: action.sessionId,
        email: '',
        password: '',
        repeatPassword: '',
      };
      return state;

    case authConstants.UPDATE_USER_SUCCESS:
      state = { ...state, user: action.user };
      cacheData.auth = state;
      cache.write(CACHE_DATA, cacheData);
      return state;
    case authConstants.GET_SESSIONS_STARTED:
      return { ...state, isFetchingSessions: true };
    case authConstants.GET_SESSIONS_FAILURE:
      return { ...state, isFetchingSessions: false, sessions: [] };
    case authConstants.GET_SESSIONS_SUCCESS:
      return { ...state, isFetchingSessions: false, sessions: action.sessions };

    case authConstants.LOGOUT_ALL_OTHERS_STARTED:
      return { ...state, isFetchingSessions: true };
    case authConstants.LOGOUT_ALL_OTHERS_FAILURE:
      return { ...state, isFetchingSessions: false, sessions: [] };
    case authConstants.LOGOUT_ALL_OTHERS_SUCCESS:
      return { ...state, isFetchingSessions: false, sessions: action.sessions };

    case authConstants.UPDATE_PASSWORD_STARTED:
      return { ...state, isFetchingUser: true };
    case authConstants.UPDATE_PASSWORD_FAILURE:
      return { ...state, isFetchingUser: false, sessions: [] };
    case authConstants.UPDATE_PASSWORD_SUCCESS:
      return { ...state, isFetchingUser: false, sessions: [state.session] };

    case authConstants.GET_ROLE_STARTED:
      return { ...state, isFetchingRole: true, error: null };
    case authConstants.GET_ROLE_FAILURE:
      return {
        ...state,
        isFetchingRole: false,
        role: null,
        error: 'Could not find role',
      };
    case authConstants.GET_ROLE_SUCCESS:
      return {
        ...state,
        isFetchingRole: false,
        role: action.role,
      };

    case authConstants.GET_ROLES_STARTED:
      return {
        ...state,
        isFetchingRoles: true,
        isFetchingRolesFrom: action.from || 0,
      };
    case authConstants.GET_ROLES_FAILURE:
      return {
        ...state,
        isFetchingRoles: false,
        roles: [],
        message: action.message || '',
      };
    case authConstants.GET_ROLES_SUCCESS:
      return {
        ...state,
        isFetchingRoles: false,
        roles: action.from > 0 ? [...state.roles, ...action.roles] : action.roles,
        rolesLoaded:
          action.from > 0 ? state.roles.length + action.roles.length : action.roles.length,
        rolesTotalResults: action.totalResults,
      };

    case authConstants.CREATE_ROLE_STARTED:
      return { ...state, isFetchingRole: true };
    case authConstants.CREATE_ROLE_FAILURE:
      return {
        ...state,
        isFetchingRole: false,
        role: null,
        message: action.message || '',
      };
    case authConstants.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        isFetchingRole: false,
        role: action.role,
        roles: [...state.roles, action.role],
      };

    case authConstants.LOGIN_METHOD_STARTED:
      return { ...state, isFetching: true };
    case authConstants.LOGIN_METHOD_FAILURE:
      return {
        ...state,
        isFetching: false,
        role: null,
        message: action.message || '',
        loginStep: 0,
      };
    case authConstants.LOGIN_METHOD_SUCCESS:
      return {
        ...state,
        loginStep: action.code === 302 ? 0 : 1,
        isFetching: false,
        twoFactorAuthenticationEnabled: action.twoFactorAuthenticationEnabled,
      };

    case authConstants.UPDATE_ROLE_STARTED:
      return { ...state, isFetchingRole: true };
    case authConstants.UPDATE_ROLE_FAILURE:
      return {
        ...state,
        isFetchingRole: false,
        role: null,
        message: action.message || '',
      };
    case authConstants.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        isFetchingRole: false,
        role: action.role,
        roles: [...state.roles].map((role) => (action.role.id === role.id ? action.role : role)),
      };
    case authConstants.DELETE_ROLE_STARTED:
      return { ...state, isFetchingRole: true };
    case authConstants.DELETE_ROLE_FAILURE:
      return {
        ...state,
        isFetchingRole: false,
        role: null,
        message: action.message || '',
      };
    case authConstants.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        isFetchingRole: false,
        role: action.role,
        roles: [...state.roles].filter((role) => action.role.id !== role.id),
      };

    case authConstants.LOGOUT_STARTED:
      cacheData.auth = state;
      cache.write(CACHE_DATA, cacheData);
      return state;
    case authConstants.LOGOUT_SUCCESS:
    case authConstants.LOGOUT_FAILURE:
      state = logoutState;
      cacheData.auth = state;
      cache.write(CACHE_DATA, cacheData);
      return state;
    default:
      return state;
  }
}
