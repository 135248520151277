import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateTeamContainer from '@containers/team/crud/CreateTeamContainer';

import * as navigationActions from '@actions/navigationActions';
import * as teamActions from '@actions/teamActions';
import { search as searchTeams } from '@api/teamApi';

let searchTimeout = null;

export const transformTeamToValue = (team) => ({
  value: team.id,
  label: team.name,
  id: team.id,
  team: team,
});

class TeamInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      team: this.props.value ? transformTeamToValue(this.props.value) : null,
    };
  }

  onChange = (newTeam) => {
    this.setState({
      team: transformTeamToValue(newTeam.team),
    });
    const { onChange } = this.props;
    onChange && onChange(newTeam ? newTeam.team : null);
  };

  render() {
    const { t, isFetchingTeams, addToStack } = this.props;
    const { team } = this.state;
    return (
      <div className="input-group no-margin-top">
        <AsyncCreatableSelect
          isMulti={false}
          isClearable
          cacheOptions
          placeholder={`${t('form.label.selectTeam')}`}
          noOptionsMessage={() => t('form.startTyping')}
          formatCreateLabel={(inputValue) =>
            t('form.addCreatable', { value: inputValue })
          }
          isLoading={isFetchingTeams}
          isDisabled={isFetchingTeams}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchTeams({ query: inputValue }, 0, 40).then((response) => {
                callback(
                  response.teams.map((team) => transformTeamToValue(team))
                );
              });
            }, 800);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          onCreateOption={(inputValue) => {
            addToStack({
              name: t('team.new'),
              component: (
                <CreateTeamContainer
                  targetName={inputValue}
                  callback={(newTeam) => {
                    this.onChange(transformTeamToValue(newTeam));
                  }}
                />
              ),
            });
          }}
          value={team}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(teamActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TeamInput));
