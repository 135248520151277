import React, { Component } from 'react';

import PopOver from './PopOver';

export default class FileViewer extends Component {
  render() {
    const { t, isActive, file, onClose } = this.props;

    return (
      <PopOver isActive={isActive} onClose={onClose}>
        <div className="container absolute-center filePreviewer-container">
          {isActive && file && (
            <iframe src={`${window.location.protocol}${file.url}`} />
          )}
        </div>
      </PopOver>
    );
  }
}
