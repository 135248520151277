import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ConsignmentTrips from '@components/consignment/ConsignmentTrips';

import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';

class ConsignmentTripsContainer extends PureComponent {
  render() {
    const { consignment } = this.props;
    return <ConsignmentTrips key={consignment.id} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.consignment,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    startTrip: (trip) => dispatch(tripActions.startTrip(trip)),
    finishTrip: (trip) => dispatch(tripActions.finishTrip(trip)),
    finishAndStartNewTrip: (trip, newTrip) =>
      dispatch(tripActions.finishAndStartNewTrip(trip, newTrip)),
    cancelTrip: (trip) => dispatch(tripActions.cancelTrip(trip)),
    updateTripActionLifeCycle: (trip, action, lifeCycle) =>
      dispatch(tripActions.updateTripActionLifeCycle(trip, action, lifeCycle)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ConsignmentTripsContainer));
