import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import TodoForm from '@components/todo/todo/TodoForm';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as laneActions from '@actions/laneActions';
import * as navigationActions from '@actions/navigationActions';
import * as todoActions from '@actions/todoActions';
import * as todoMainActions from '@actions/todoMainActions';
import * as transportEquipmentActions from '@actions/transportEquipmentActions';
import * as transportOrderActions from '@actions/transportOrderActions';
import * as userActions from '@actions/userActions';

class TodoFormContainer extends Component {
  componentDidMount() {
    const { todoForm, changeValue, relatedEntity, relatedEntityType } = this.props;

    if (relatedEntityType && relatedEntity) {
      const newTodoForm = { ...todoForm };
      newTodoForm.relatedEntityType = relatedEntityType;
      newTodoForm.relatedEntityId = relatedEntity?.id;
      newTodoForm.relatedEntityName = relatedEntity?.name;
      changeValue('todoForm', newTodoForm);
    }
  }
  render() {
    return <TodoForm {...this.props} />;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    ...state.todo,
    ...state.user,

    ...state.action,
    ...state.chauffeur,
    ...state.transportOrder,
    ...state.transportEquipment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTodo: (laneId, todo) => dispatch(todoActions.createTodo(laneId, todo)),
    updateTodo: (todo) => dispatch(todoActions.updateTodo(todo)),
    deleteTodo: (todo) => dispatch(todoActions.deleteTodo(todo)),

    changeValue: (name, data) => dispatch(todoMainActions.changeValue(name, data)),
    changeValue: (name, data) => dispatch(todoMainActions.changeValue(name, data)),
    popStack: () => dispatch(navigationActions.popStack()),
    setLane: (lane) => dispatch(laneActions.setLane(lane)),
    getUsers: (from, to) => dispatch(userActions.getUsers(from, to)),

    searchChauffeurs: (searchParameters, from, amount) =>
      dispatch(chauffeurActions.searchChauffeurs(searchParameters, from, amount)),
    searchTransportOrders: (searchParameters, from, to) =>
      dispatch(transportOrderActions.searchTransportOrders(searchParameters, from, to)),
    searchTransportEquipments: (searchParameters, from, to) =>
      dispatch(transportEquipmentActions.searchTransportEquipments(searchParameters, from, to)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(TodoFormContainer));
