import { messageTemplateConstants } from '@constants';

import * as messageTemplateApi from '@api/messageTemplateApi';

export const changeValue = (name, value) => ({
  type: messageTemplateConstants.MESSAGE_TEMPLATE_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching messageTemplate
 */
export const startGetMessageTemplate = () => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATE_STARTED,
});

export const getMessageTemplateSuccess = (result) => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATE_SUCCESS,
  ...result,
});

export const getMessageTemplateFailure = (error) => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATE_FAILURE,
  ...error,
});

export const getMessageTemplate = (id) => {
  return (dispatch) => {
    dispatch(startGetMessageTemplate());

    const promise = messageTemplateApi.get(id);

    promise
      .then((result) => {
        dispatch(getMessageTemplateSuccess(result));
      })
      .catch((error) => {
        dispatch(getMessageTemplateFailure(error));
      });

    return promise;
  };
};

/**
 * Creating messageTemplate
 */
export const changeCreateMessageTemplate = (name, value) => ({
  type: messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_CHANGE_VALUE,
  name,
  value,
});
export const startCreateMessageTemplate = () => ({
  type: messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_STARTED,
});

export const createMessageTemplateSuccess = (result) => ({
  type: messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_SUCCESS,
  ...result,
});

export const createMessageTemplateFailure = (error) => ({
  type: messageTemplateConstants.CREATING_MESSAGE_TEMPLATE_FAILURE,
  ...error,
});

export const createMessageTemplate = (messageTemplate) => {
  return (dispatch) => {
    dispatch(startCreateMessageTemplate());

    const promise = messageTemplateApi.create(messageTemplate);

    promise
      .then((result) => {
        dispatch(createMessageTemplateSuccess(result));
      })
      .catch((error) => {
        dispatch(createMessageTemplateFailure(error));
      });

    return promise;
  };
};

/**
 * Updating messageTemplate
 */
export const changeUpdateMessageTemplate = (name, value) => ({
  type: messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateMessageTemplate = () => ({
  type: messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_STARTED,
});

export const updateMessageTemplateSuccess = (result) => ({
  type: messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_SUCCESS,
  ...result,
});

export const updateMessageTemplateFailure = (error) => ({
  type: messageTemplateConstants.UPDATING_MESSAGE_TEMPLATE_FAILURE,
  ...error,
});

export const updateMessageTemplate = (messageTemplate) => {
  return (dispatch) => {
    dispatch(startUpdateMessageTemplate());

    const promise = messageTemplateApi.update(messageTemplate);

    promise
      .then((result) => {
        dispatch(updateMessageTemplateSuccess(result));
      })
      .catch((error) => {
        dispatch(updateMessageTemplateFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting messageTemplate
 */
export const startDeleteMessageTemplate = () => ({
  type: messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_STARTED,
});

export const deleteMessageTemplateSuccess = (result) => ({
  type: messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_SUCCESS,
  ...result,
});

export const deleteMessageTemplateFailure = (error) => ({
  type: messageTemplateConstants.DELETE_MESSAGE_TEMPLATE_FAILURE,
  ...error,
});
export const deleteMessageTemplate = (messageTemplate) => {
  return (dispatch) => {
    dispatch(startDeleteMessageTemplate());

    const promise = messageTemplateApi.remove(messageTemplate);

    promise
      .then((result) => {
        dispatch(deleteMessageTemplateSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteMessageTemplateFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching messageTemplates
 */
export const startGetMessageTemplates = (from) => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATES_STARTED,
  from: from,
});

export const getMessageTemplatesSuccess = (result, from) => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATES_SUCCESS,
  from: from,
  ...result,
});

export const getMessageTemplatesFailure = (error) => ({
  type: messageTemplateConstants.GET_MESSAGE_TEMPLATES_FAILURE,
  ...error,
});

export const getMessageTemplates = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetMessageTemplates(from));

    const promise = messageTemplateApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getMessageTemplatesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getMessageTemplatesFailure(error));
      });

    return promise;
  };
};

export const searchMessageTemplates = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetMessageTemplates(from));

    const promise = messageTemplateApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getMessageTemplatesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getMessageTemplatesFailure(error));
      });

    return promise;
  };
};
