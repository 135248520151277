import { locationConstants } from '@constants/location.constants';

const initialState = {
  isFetchingLocation: false,
  formLocation: null,
  location: null,

  isFetchingLocations: false,
  locationsTotalResults: 0,
  locationsLoaded: 0,
  locationsFrom: 0,
  locations: [],
};

export default function location(state = initialState, action) {
  switch (action.type) {
    case locationConstants.LOCATION_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case locationConstants.GET_LOCATION_STARTED:
      return { ...state, isFetchingLocation: true };
    case locationConstants.GET_LOCATION_FAILURE:
      return {
        ...state,
        isFetchingLocation: false,
        error: action.message || '',
      };
    case locationConstants.GET_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocation: false,
        location: action.location,
      };

    case locationConstants.GET_LOCATIONS_STARTED:
      return {
        ...state,
        isFetchingLocations: true,
        locationsFrom: action.from || 0,
      };
    case locationConstants.GET_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetchingLocations: false,
        error: action.message || '',
      };
    case locationConstants.GET_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations:
          action.from > 0
            ? [...state.locations, ...action.locations]
            : action.locations,
        locationsLoaded:
          action.from > 0
            ? state.locations.length + action.locations.length
            : action.locations.length,
        locationsTotalResults: action.totalResults || 0,
      };

    case locationConstants.SEARCH_LOCATIONS_STARTED:
      return {
        ...state,
        isFetchingLocations: true,
        locationsFrom: action.from || 0,
      };
    case locationConstants.SEARCH_LOCATIONS_FAILURE:
      return {
        ...state,
        isFetchingLocations: false,
        error: action.message || '',
      };
    case locationConstants.SEARCH_LOCATIONS_SUCCESS:
      return {
        ...state,
        isFetchingLocations: false,
        locations:
          action.from > 0
            ? [...state.locations, ...action.locations]
            : action.locations,
        locationsLoaded:
          action.from > 0
            ? state.locations.length + action.locations.length
            : action.locations.length,
        locationsTotalResults: action.totalResults || 0,
      };

    case locationConstants.CREATING_LOCATION_STARTED:
      return { ...state, isFetchingLocation: true };
    case locationConstants.CREATING_LOCATION_FAILURE:
      return {
        ...state,
        isFetchingLocation: false,
        error: action.message || '',
      };
    case locationConstants.CREATING_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocation: false,
        location: action.location,
        locations: [action.location, ...state.locations],
      };

    case locationConstants.UPDATING_LOCATION_STARTED:
      return { ...state, isFetchingLocation: true };
    case locationConstants.UPDATING_LOCATION_FAILURE:
      return {
        ...state,
        isFetchingLocation: false,
        error: action.message || '',
      };
    case locationConstants.UPDATING_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocation: false,
        location: action.location,
        locations: [...state.locations].map((location) => {
          if (action.location.id === location.id) {
            return action.location;
          }
          return location;
        }),
      };

    case locationConstants.DELETE_LOCATION_STARTED:
      return { ...state, isFetchingLocation: true };
    case locationConstants.DELETE_LOCATION_FAILURE:
      return {
        ...state,
        isFetchingLocation: false,
        message: action.message || '',
      };
    case locationConstants.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isFetchingLocation: false,
        location: action.location,
        locations: [...state.locations].filter(
          (location) => action.location.id !== location.id
        ),
      };
    default:
      return state;
  }
}
