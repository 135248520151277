import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateChauffeurPage from '@components/chauffeur/crud/CreateChauffeurPage.js';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as navigationActions from '@actions/navigationActions';

class CreateChauffeurContainer extends PureComponent {
  render() {
    const { changeValue, createChauffeur, ...props } = this.props;
    const { popStack } = this.props;

    return (
      <CreateChauffeurPage
        {...props}
        onChange={changeValue}
        createChauffeur={(chauffeur) =>
          createChauffeur(chauffeur).then(() => {
            popStack();
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    platform: state.platform.platform,
    user: state.auth.user,

    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.chauffeur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createChauffeur: (chauffeur) => dispatch(chauffeurActions.createChauffeur(chauffeur)),
    changeValue: (name, value) => dispatch(chauffeurActions.changeValue(name, value)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateChauffeurContainer));
