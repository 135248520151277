import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class FuelTypeSelector extends Component {
  static defaultProps = {
    isMulti: false,
  };
  render() {
    const { t, onChange, placeholder, value, isMulti } = this.props;
    const options = [
      { label: t('Battery'), value: 'battery' },
      { label: t('Biodiesel'), value: 'biodiesel' },
      { label: t('Diesel'), value: 'diesel' },
      { label: t('Diesel Battery Hybrid'), value: 'dieselBatteryHybrid' },
      { label: t('Ethanol'), value: 'ethanol' },
      { label: t('Hydrogen'), value: 'hydrogen' },
      { label: t('Liquid Gas'), value: 'liquidGas' },
      { label: t('LPG'), value: 'lpg' },
      { label: t('Methane'), value: 'methane' },
      { label: t('Petrol'), value: 'petrol' },
      { label: t('Petrol Battery Hybrid'), value: 'petrolBatteryHybrid' },
      { label: t('Not Applicable'), value: 'notApplicable' },
      { label: t('Unknown'), value: 'unknown' },
    ];
    return (
      <Select
        isMulti={isMulti}
        value={
          isMulti
            ? options.filter((option) => value && value.includes(option.value))
            : options.find((option) => option.value === value)
        }
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(FuelTypeSelector);
