import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import Progress from '@uicomponents/Progress';

import { momentToDuration } from '@utils/dateUtils';

class TimeRow extends Component {
  _formatValue = (value) => {
    const { t, format } = this.props;
    if (format === 'second') {
      return momentToDuration(moment.duration(value, 'seconds'));
    } else if (format === 'day') {
      return `${value} ${t('days')}`;
    } else return value;
  };

  render() {
    const { name, timeBreakDown, header } = this.props;
    if (!timeBreakDown) return null;
    const { minimum, maximum, total, remaining } = timeBreakDown;

    const percentageValue =
      minimum === null ? total / maximum : total / minimum;

    if (header) {
      return (
        <div className="flex-container time-row time-row--header">
          <div className="two time-row__name">{name}</div>
          {total !== null ? (
            <div className="one time-row__name">{total}</div>
          ) : null}
          {minimum !== null ? (
            <div className="one time-row__name">{minimum}</div>
          ) : null}
          {maximum !== null ? (
            <div className="one time-row__name">{maximum}</div>
          ) : null}
          {remaining !== null ? (
            <div className="one time-row__name">{remaining}</div>
          ) : null}

          <div className="two self-center progress-grid"></div>
          <div className="one"></div>
        </div>
      );
    }

    return (
      <div className="flex-container time-row">
        <div className="two time-row__name">{name}</div>
        {total !== null ? (
          <div className="one time-row__name">{this._formatValue(total)}</div>
        ) : null}
        {minimum ? (
          <div className="one time-row__name">{this._formatValue(minimum)}</div>
        ) : null}
        {maximum  ? (
          <div className="one time-row__name">{this._formatValue(maximum)}</div>
        ) : null}
        {remaining !== null ? (
          <div className="one time-row__name">
            {this._formatValue(remaining)}
          </div>
        ) : null}

        <div className="two self-center">
          <Progress
            progress={percentageValue * 100}
            total={total}
            minimum={minimum}
            maximum={maximum}
          />
        </div>
        <div className="one"></div>
      </div>
    );
  }
}
export default withTranslation('translation')(TimeRow);
