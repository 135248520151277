import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import VehicleForm from './VehicleForm';

export default class UpdateVehiclePage extends Component {
  componentDidMount() {
    const { onChange, targetVehicle } = this.props;
    onChange('formVehicle', targetVehicle);
  }

  render() {
    const { t, formVehicle, isFetchingVehicle, updateVehicle } = this.props;

    if (!formVehicle || isFetchingVehicle) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('vehicle.update')}</h1>
        <VehicleForm
          {...this.props}
          key={formVehicle.id}
          onSubmit={(vehicle) => updateVehicle(vehicle)}
        />
      </AsideView>
    );
  }
}
