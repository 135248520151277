import React, { Component } from 'react';

import Dropzone from 'react-dropzone';

import Progress from '@uicomponents/Progress';

import * as integrationApi from '@api/integrationApi';

import { formatBytes } from '@utils/fileUtils';
import { base64ToBlob, getBase64 } from '@utils/imageUtils';

export default class IntegrationFileUploadInput extends Component {
  static defaultProps = {
    max: 1,
    accept:
      'image/*,.pdf,.doc,.docx,.xml,.xlsx,.xls,.csv,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
  };

  state = {
    files: this.props.files ? this.props.files : [],
  };

  uploadFile = (file) => {
    const { onChange } = this.props;
    const { files } = this.state;
    const prevFileIndex =
      files.findIndex((f) => f.nonce === file.nonce) >= 0
        ? files.findIndex((f) => f.nonce === file.nonce)
        : 0;

    getBase64(file).then((base64) => {
      integrationApi
        .uploadFile(file, 'files', (e) => {
          let newFiles = [...this.state.files];
          let newFile = { ...newFiles[prevFileIndex] };
          newFile.progress = (e.loaded / e.total) * 100;
          newFile.uploading = true;
          if (!newFile.base64) newFile.base64 = base64;

          newFiles[prevFileIndex] = newFile;
          this.setState({
            files: newFiles,
          });
        })
        .then((response) => {
          let newFiles = [...files];
          let newFile = { ...newFiles[prevFileIndex] };
          if (response.success) {
            newFile = { ...response, ...newFile };
          } else {
            newFile.error = response.message;
          }
          newFile.uploading = false;
          newFile.uploaded = true;
          newFiles[prevFileIndex] = newFile;

          onChange(newFiles);
          this.setState({
            files: newFiles,
          });
        });
    });
  };
  render() {
    const { t, max, accept, onChange } = this.props;
    const { files } = this.state;

    return (
      <div className="upload-files">
        {files.length > 0 && (
          <div className="input-group uploaded-files">
            {files.map((file) => {
              return (
                <div
                  className="file"
                  key={`${file.id}-${file.nonce}-${file.uplaoding}-${file.uploaded}`}
                >
                  <img src={file.base64 || file.url} alt="" />
                  <div className="info">
                    <div className="name">
                      {file.error
                        ? file.error
                        : file.originalName || file.name || file.path}
                      <span>{formatBytes(file.size)}</span>
                    </div>
                    {file.uploaded ? (
                      <div className="state">{t('attachments.uploaded')}</div>
                    ) : (
                      <div className="state">
                        <span>{t('attachments.uploading')}</span>
                        <Progress progress={file.progress || 0} />
                      </div>
                    )}
                  </div>
                  <div className="actions">
                    {file.uploading && (
                      <i className="fa fa-circle-o-notch fa-spin"></i>
                    )}
                    {!file.uploading && (
                      <i
                        className="fa fa-times behind"
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.setState({
                            files: [...files].filter((f) => f.id !== file.id),
                          });
                        }}
                      ></i>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {(max < 0 || files.length < max) && (
          <Dropzone
            ref={this.dropzoneRef}
            style={{ border: 'none' }}
            activeStyle={{ border: 'none' }}
            onDrop={(acceptedFiles) => {
              const files = acceptedFiles.map((file) => {
                file.nonce = Math.random().toString(36).substring(7);
                return file;
              });
              this.setState(
                (prevState) => ({ files: prevState.files.concat(files) }),
                () => {
                  files.forEach((file) => {
                    this.uploadFile(file);
                  });
                }
              );
            }}
            accept={accept}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
              return (
                <div
                  className={`input-group drag-and-drop${
                    isDragActive ? ' dragging' : ''
                  }${isDragReject ? ' rejected' : ''}`}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <p>
                    {isDragActive
                      ? isDragReject
                        ? t('dragAndDrop.rejected')
                        : t('dragAndDrop.dragging')
                      : t('dragAndDrop.label')}
                  </p>
                </div>
              );
            }}
          </Dropzone>
        )}
      </div>
    );
  }
}
