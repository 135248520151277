import React from 'react';

import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import Footer from '@components/footer/Footer';
import MetaTags from '@components/ui/views/MetaTags';
import AlertStackContainer from '@containers/AlertStackContainer';
import ComponentStackContainer from '@containers/ComponentStackContainer';
import Header from '@containers/header/HeaderContainer';

export const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isAuthenticated ? <Redirect to="/dashboard" /> : <Component {...props} {...rest} />
      }
    />
  );
};

export const PortalRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      rest.isAuthenticated ? <Component {...props} {...rest} /> : <Redirect to="/auth/login" />
    }
  />
);

export const AlwaysAccessibleRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
);

export const PageShell = (parentProps, Page) => {
  return (props) => {
    return (
      <div className={'page ' + props.match.url.replace('/', '') + (props.standalone ? ' standalone' : '')}>
        <MetaTags {...props} {...parentProps} />

        <AlertStackContainer />
        <ComponentStackContainer />

        <Header {...props} {...parentProps} />
        <Page {...props} {...parentProps} />
        <Footer {...props} {...parentProps} />
      </div>
    );
  };
};

export const renderRoutes = (props, routes) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {routes.map((route) =>
        route.paths.map((slug) =>
          route.type === 'open' ? (
            <AlwaysAccessibleRoute
              exact
              path={`${path}${slug}`}
              isAuthenticated={props.isAuthenticated}
              component={PageShell({ ...props, ...route.props }, route.component)}
              {...route.props}
            />
          ) : route.type === 'portal' ? (
            <PortalRoute
              exact
              path={`${path}${slug}`}
              isAuthenticated={props.isAuthenticated}
              component={PageShell({ ...props, ...route.props }, route.component)}
              {...route.props}
            />
          ) : (
            <DefaultRoute
              exact
              path={`${path}${slug}`}
              isAuthenticated={props.isAuthenticated}
              component={PageShell({ ...props, ...route.props }, route.component)}
              {...route.props}
            />
          )
        )
      )}
    </Switch>
  );
};

export { default as AuthRoutes } from './AuthRoutes';
export { default as DeveloperRoutes } from './DeveloperRoutes';
export { default as IntegrationRoutes } from './IntegrationRoutes';
export { default as MessageRoutes } from './MessageRoutes';
export { default as SupportRoutes } from './SupportRoutes';
export { default as TransportOrderRoutes } from './TransportOrderRoutes';
export { default as TripRoutes } from './TripRoutes';
export { default as QuoteRoutes } from './QuoteRoutes';
export { default as DashboardRoutes } from './DashboardRoutes';
export { default as PlanBoardRoutes } from './PlanBoardRoutes';
export { default as PlannerRoutes } from './PlannerRoutes';
export { default as PlanRoutes } from './PlanRoutes';
