import React, { PureComponent } from 'react';

import ReactSVG from 'react-svg';

import { isExternal } from '@utils/userUtils';

import CreateSupportTicketContainer from '../../containers/support/crud/CreateSupportTicketContainer';
import CreateTransportOrderContainer from '../../containers/transportOrder/crud/CreateTransportOrderContainer';
import CreateTripContainer from '../../containers/trip/crud/CreateTripContainer';

export default class AsideCreateNew extends PureComponent {
  render() {
    const { t, history, popStack, user, addToStack, updateState, initialState } = this.props;
    return (
      <div className="aside-create-new">
        <div
          className="item create-order-aside-button"
          onClick={() => {
            addToStack({
              name: t('menu.new.transportOrder'),
              component: (
                <CreateTransportOrderContainer
                  onNavigateTo={(url) => {
                    popStack();
                    history.push(url);
                  }}
                />
              ),
            });
            updateState({ ...this.state, ...initialState });
          }}
        >
          <ReactSVG src="/icons/plus.svg" />
          <div className="item-label">{t('menu.new.transportOrder')}</div>
        </div>

        {!isExternal(user) && (
          <div
            className="item create-trip-aside-button"
            onClick={() => {
              addToStack({
                name: t('trip.new'),
                component: (
                  <CreateTripContainer
                    onNavigateTo={(url) => {
                      popStack();
                      history.push(url);
                    }}
                  />
                ),
              });
              updateState({ ...this.state, ...initialState });
            }}
          >
            <ReactSVG src="/icons/plus.svg" />
            <div className="item-label">{t('menu.new.trip')}</div>
          </div>
        )}

        <div
          className="item"
          onClick={() => {
            addToStack({
              name: t('support.new'),
              component: (
                <CreateSupportTicketContainer
                  onNavigateTo={(url) => {
                    popStack();
                    history.push(url);
                  }}
                />
              ),
            });
            updateState({ ...this.state, ...initialState });
          }}
        >
          <ReactSVG src="/icons/plus.svg" />
          <div className="item-label">{t('menu.new.support')}</div>
        </div>
      </div>
    );
  }
}
