import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class FieldsSelector extends Component {
  render() {
    const { t, onChange, placeholder, values, options, isFetchingFields } =
      this.props;
    let selectOptions = options?.length
      ? options.map((option) => ({ value: option.name, label: option.name }))
      : [];
    return (
      <Select
        value={
          values?.length
            ? values.map((value) => ({ value: value.name, label: value.name }))
            : []
        }
        isMulti={true}
        options={selectOptions}
        onChange={onChange}
        placeholder={placeholder}
        isLoading={isFetchingFields}
        isDisabled={isFetchingFields}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(FieldsSelector);
