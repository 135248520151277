export const sensorConstants = {
  SENSOR_CHANGE_VALUE: 'SENSOR_CHANGE_VALUE',

  GET_SENSOR_STARTED: 'GET_SENSOR_STARTED',
  GET_SENSOR_SUCCESS: 'GET_SENSOR_SUCCESS',
  GET_SENSOR_FAILURE: 'GET_SENSOR_FAILURE',

  GET_SENSORS_STARTED: 'GET_SENSORS_STARTED',
  GET_SENSORS_SUCCESS: 'GET_SENSORS_SUCCESS',
  GET_SENSORS_FAILURE: 'GET_SENSORS_FAILURE',

  SEARCH_SENSORS_STARTED: 'SEARCH_SENSORS_STARTED',
  SEARCH_SENSORS_SUCCESS: 'SEARCH_SENSORS_SUCCESS',
  SEARCH_SENSORS_FAILURE: 'SEARCH_SENSORS_FAILURE',

  CREATING_SENSOR_STARTED: 'CREATING_SENSOR_STARTED',
  CREATING_SENSOR_SUCCESS: 'CREATING_SENSOR_SUCCESS',
  CREATING_SENSOR_FAILURE: 'CREATING_SENSOR_FAILURE',

  UPDATING_SENSOR_STARTED: 'UPDATING_SENSOR_STARTED',
  UPDATING_SENSOR_SUCCESS: 'UPDATING_SENSOR_SUCCESS',
  UPDATING_SENSOR_FAILURE: 'UPDATING_SENSOR_FAILURE',

  DELETE_SENSOR_STARTED: 'DELETE_SENSOR_STARTED',
  DELETE_SENSOR_SUCCESS: 'DELETE_SENSOR_SUCCESS',
  DELETE_SENSOR_FAILURE: 'DELETE_SENSOR_FAILURE',
};
