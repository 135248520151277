import * as capacityContractApi from '@api/capacityContractApi';

import { capacityContractConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: capacityContractConstants.CAPACITYCONTRACT_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching capacityContract
 */
export const startGetCapacityContract = () => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACT_STARTED,
});

export const getCapacityContractSuccess = (result) => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACT_SUCCESS,
  ...result,
});

export const getCapacityContractFailure = (error) => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACT_FAILURE,
  ...error,
});

export const getCapacityContract = (id) => {
  return (dispatch) => {
    dispatch(startGetCapacityContract());

    const promise = capacityContractApi.get(id);

    promise
      .then((result) => {
        dispatch(getCapacityContractSuccess(result));
      })
      .catch((error) => {
        dispatch(getCapacityContractFailure(error));
      });

    return promise;
  };
};

/**
 * Creating capacityContract
 */
export const changeCreateCapacityContract = (name, value) => ({
  type: capacityContractConstants.CREATING_CAPACITYCONTRACT_CHANGE_VALUE,
  name,
  value,
});
export const startCreateCapacityContract = () => ({
  type: capacityContractConstants.CREATING_CAPACITYCONTRACT_STARTED,
});

export const createCapacityContractSuccess = (result) => ({
  type: capacityContractConstants.CREATING_CAPACITYCONTRACT_SUCCESS,
  ...result,
});

export const createCapacityContractFailure = (error) => ({
  type: capacityContractConstants.CREATING_CAPACITYCONTRACT_FAILURE,
  ...error,
});

export const createCapacityContract = (capacityContract) => {
  return (dispatch) => {
    dispatch(startCreateCapacityContract());

    const promise = capacityContractApi.create(capacityContract);

    promise
      .then((result) => {
        dispatch(createCapacityContractSuccess(result));
      })
      .catch((error) => {
        dispatch(createCapacityContractFailure(error));
      });

    return promise;
  };
};

/**
 * Updating capacityContract
 */
export const changeUpdateCapacityContract = (name, value) => ({
  type: capacityContractConstants.UPDATING_CAPACITYCONTRACT_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateCapacityContract = () => ({
  type: capacityContractConstants.UPDATING_CAPACITYCONTRACT_STARTED,
});

export const updateCapacityContractSuccess = (result) => ({
  type: capacityContractConstants.UPDATING_CAPACITYCONTRACT_SUCCESS,
  ...result,
});

export const updateCapacityContractFailure = (error) => ({
  type: capacityContractConstants.UPDATING_CAPACITYCONTRACT_FAILURE,
  ...error,
});

export const updateCapacityContract = (capacityContract) => {
  return (dispatch) => {
    dispatch(startUpdateCapacityContract());

    const promise = capacityContractApi.update(capacityContract);

    promise
      .then((result) => {
        dispatch(updateCapacityContractSuccess(result));
      })
      .catch((error) => {
        dispatch(updateCapacityContractFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting capacityContract
 */
export const startDeleteCapacityContract = () => ({
  type: capacityContractConstants.DELETE_CAPACITYCONTRACT_STARTED,
});

export const deleteCapacityContractSuccess = (result) => ({
  type: capacityContractConstants.DELETE_CAPACITYCONTRACT_SUCCESS,
  ...result,
});

export const deleteCapacityContractFailure = (error) => ({
  type: capacityContractConstants.DELETE_CAPACITYCONTRACT_FAILURE,
  ...error,
});
export const deleteCapacityContract = (capacityContract) => {
  return (dispatch) => {
    dispatch(startDeleteCapacityContract());

    const promise = capacityContractApi.remove(capacityContract);

    promise
      .then((result) => {
        dispatch(deleteCapacityContractSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteCapacityContractFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching capacityContracts
 */
export const startGetCapacityContracts = (from) => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACTS_STARTED,
  from: from,
});

export const getCapacityContractsSuccess = (result, from) => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACTS_SUCCESS,
  from: from,
  ...result,
});

export const getCapacityContractsFailure = (error) => ({
  type: capacityContractConstants.GET_CAPACITYCONTRACTS_FAILURE,
  ...error,
});

export const getCapacityContracts = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetCapacityContracts(from));

    const promise = capacityContractApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getCapacityContractsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getCapacityContractsFailure(error));
      });

    return promise;
  };
};

export const searchCapacityContracts = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetCapacityContracts(from));

    const promise = capacityContractApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getCapacityContractsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getCapacityContractsFailure(error));
      });

    return promise;
  };
};
