import * as pricingVariableIndexApi from '@api/pricingVariableIndexApi';

import { pricingVariableIndexConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: pricingVariableIndexConstants.PRICINGVARIABLEINDEX_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching pricingVariableIndex
 */
export const startGetPricingVariableIndex = () => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_STARTED,
});

export const getPricingVariableIndexSuccess = (result) => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_SUCCESS,
  ...result,
});

export const getPricingVariableIndexFailure = (error) => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEX_FAILURE,
  ...error,
});

export const getPricingVariableIndex = (id) => {
  return (dispatch) => {
    dispatch(startGetPricingVariableIndex());

    const promise = pricingVariableIndexApi.get(id);

    promise
      .then((result) => {
        dispatch(getPricingVariableIndexSuccess(result));
      })
      .catch((error) => {
        dispatch(getPricingVariableIndexFailure(error));
      });

    return promise;
  };
};

/**
 * Creating pricingVariableIndex
 */
export const changeCreatePricingVariableIndex = (name, value) => ({
  type: pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_CHANGE_VALUE,
  name,
  value,
});
export const startCreatePricingVariableIndex = () => ({
  type: pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_STARTED,
});

export const createPricingVariableIndexSuccess = (result) => ({
  type: pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_SUCCESS,
  ...result,
});

export const createPricingVariableIndexFailure = (error) => ({
  type: pricingVariableIndexConstants.CREATING_PRICINGVARIABLEINDEX_FAILURE,
  ...error,
});

export const createPricingVariableIndex = (pricingVariableIndex) => {
  return (dispatch) => {
    dispatch(startCreatePricingVariableIndex());

    const promise = pricingVariableIndexApi.create(pricingVariableIndex);

    promise
      .then((result) => {
        dispatch(createPricingVariableIndexSuccess(result));
      })
      .catch((error) => {
        dispatch(createPricingVariableIndexFailure(error));
      });

    return promise;
  };
};

/**
 * Updating pricingVariableIndex
 */
export const changeUpdatePricingVariableIndex = (name, value) => ({
  type: pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_CHANGE_VALUE,
  name,
  value,
});
export const startUpdatePricingVariableIndex = () => ({
  type: pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_STARTED,
});

export const updatePricingVariableIndexSuccess = (result) => ({
  type: pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_SUCCESS,
  ...result,
});

export const updatePricingVariableIndexFailure = (error) => ({
  type: pricingVariableIndexConstants.UPDATING_PRICINGVARIABLEINDEX_FAILURE,
  ...error,
});

export const updatePricingVariableIndex = (pricingVariableIndex) => {
  return (dispatch) => {
    dispatch(startUpdatePricingVariableIndex());

    const promise = pricingVariableIndexApi.update(pricingVariableIndex);

    promise
      .then((result) => {
        dispatch(updatePricingVariableIndexSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePricingVariableIndexFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting pricingVariableIndex
 */
export const startDeletePricingVariableIndex = () => ({
  type: pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_STARTED,
});

export const deletePricingVariableIndexSuccess = (result) => ({
  type: pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_SUCCESS,
  ...result,
});

export const deletePricingVariableIndexFailure = (error) => ({
  type: pricingVariableIndexConstants.DELETE_PRICINGVARIABLEINDEX_FAILURE,
  ...error,
});
export const deletePricingVariableIndex = (pricingVariableIndex) => {
  return (dispatch) => {
    dispatch(startDeletePricingVariableIndex());

    const promise = pricingVariableIndexApi.remove(pricingVariableIndex);

    promise
      .then((result) => {
        dispatch(deletePricingVariableIndexSuccess(result));
      })
      .catch((error) => {
        dispatch(deletePricingVariableIndexFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching pricingVariableIndexes
 */
export const startGetPricingVariableIndexes = (from) => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_STARTED,
  from: from,
});

export const getPricingVariableIndexesSuccess = (result, from) => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_SUCCESS,
  from: from,
  ...result,
});

export const getPricingVariableIndexesFailure = (error) => ({
  type: pricingVariableIndexConstants.GET_PRICINGVARIABLEINDEXES_FAILURE,
  ...error,
});

export const getPricingVariableIndexes = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetPricingVariableIndexes(from));

    const promise = pricingVariableIndexApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getPricingVariableIndexesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPricingVariableIndexesFailure(error));
      });

    return promise;
  };
};

export const searchPricingVariableIndexes = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetPricingVariableIndexes(from));

    const promise = pricingVariableIndexApi.search(
      searchParameters,
      from,
      amount
    );

    promise
      .then((result) => {
        dispatch(getPricingVariableIndexesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPricingVariableIndexesFailure(error));
      });

    return promise;
  };
};
