export const attributeConstants = {
  GET_ATTRIBUTE_STARTED: 'GET_ATTRIBUTE_STARTED',
  GET_ATTRIBUTE_SUCCESS: 'GET_ATTRIBUTE_SUCCESS',
  GET_ATTRIBUTE_FAILURE: 'GET_ATTRIBUTE_FAILURE',

  GET_ATTRIBUTES_STARTED: 'GET_ATTRIBUTES_STARTED',
  GET_ATTRIBUTES_SUCCESS: 'GET_ATTRIBUTES_SUCCESS',
  GET_ATTRIBUTES_FAILURE: 'GET_ATTRIBUTES_FAILURE',

  CREATING_ATTRIBUTE_CHANGE_VALUE: 'CREATING_ATTRIBUTE_CHANGE_VALUE',
  CREATING_ATTRIBUTE_STARTED: 'CREATING_ATTRIBUTE_STARTED',
  CREATING_ATTRIBUTE_SUCCESS: 'CREATING_ATTRIBUTE_SUCCESS',
  CREATING_ATTRIBUTE_FAILURE: 'CREATING_ATTRIBUTE_FAILURE',

  UPDATING_ATTRIBUTE_CHANGE_VALUE: 'UPDATING_ATTRIBUTE_CHANGE_VALUE',
  UPDATING_ATTRIBUTE_STARTED: 'UPDATING_ATTRIBUTE_STARTED',
  UPDATING_ATTRIBUTE_SUCCESS: 'UPDATING_ATTRIBUTE_SUCCESS',
  UPDATING_ATTRIBUTE_FAILURE: 'UPDATING_ATTRIBUTE_FAILURE',

  DELETE_ATTRIBUTE_STARTED: 'DELETE_ATTRIBUTE_STARTED',
  DELETE_ATTRIBUTE_SUCCESS: 'DELETE_ATTRIBUTE_SUCCESS',
  DELETE_ATTRIBUTE_FAILURE: 'DELETE_ATTRIBUTE_FAILURE',
};
