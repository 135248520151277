import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

export const deliveryTerms = [
  { label: 'EXW', value: 'EXW' },
  { label: 'FCA', value: 'FCA' },
  { label: 'FAS', value: 'FAS' },
  { label: 'FOB', value: 'FOB' },
  { label: 'CPT', value: 'CPT' },
  { label: 'CIP', value: 'CIP' },
  { label: 'CFR', value: 'CFR' },
  { label: 'CIF', value: 'CIF' },
  { label: 'DAP', value: 'DAP' },
  { label: 'DPU', value: 'DPU' },
  { label: 'DDP', value: 'DDP' },
];
class DeliveryTermsSelector extends Component {
  render() {
    const { t, onChange, placeholder, value, defaultValue } = this.props;
    const options = deliveryTerms.map((deliveryTerm) => ({
      value: deliveryTerm.value,
      label: t(`deliveryTerms.${deliveryTerm.label}`),
    }));
    return (
      <Select
        value={options.find(
          (option) => option.value === value || option.value === defaultValue
        )}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(DeliveryTermsSelector);
