import React, { Component, Suspense, lazy } from 'react';

import { withTranslation } from 'react-i18next';
import ScrollContainer from 'react-indiana-drag-scroll';
import { Circle, MapContainer, Marker, Polygon } from 'react-leaflet';
import ReactSVG from 'react-svg';

import UpdateLocationContainer from '@containers/location/crud/UpdateLocationContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';
import DefaultMarker from '@uimarker/default/DefaultMarker';
import TileLayers from '@uiviews/TileLayers';

import ContactDetailsView from '../general/ContactDetailsView';
import InformationComponent from '../ui/components/InformationComponent';
import LocationActionAverageDurationView from './LocationActionAverageDurationView';

const ComplaintsContainer = lazy(() => import('@containers/complaint/ComplaintsContainer'));

class LocationView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tab: 'location',
    };
  }

  getCoordinates = (latLonPoints) => {
    let coordinates = [];
    latLonPoints?.forEach((point) => coordinates.push([point[0], point[1]]));
    return coordinates;
  };

  getCenter = (center) => {
    return [center.centerX, center.centerY];
  };

  render() {
    const { tab } = this.state;
    const { t, location, isFetchingLocation, addToStack } = this.props;

    if (isFetchingLocation || !location) return <Loader />;

    const latLonGeoReference = location.geoReference;

    const fieldsToRender = [
      { name: 'type', type: 'type', entityType: 'location' },
      { name: 'administrativeReference', type: 'administrativeReference', entityType: 'location' },
      { name: 'latLon', type: 'latLon', entityType: 'location' },
      { name: 'remark', type: 'remark', entityType: 'location' },
    ];

    return (
      <div className="location-view" key={`location-${location.id}`}>
        <div className="flex-container justify-between">
          <div>
            <div className="flex-container justify-between">
              <h1 className="no-margin with-flair">
                {location.name}
                <span>{t(`locationType.${location.type}`)}</span>
              </h1>
            </div>
            <h3 className="no-margin-top">#{location.id}</h3>
          </div>
          <div className="self-center flex-container action-icons">
            <div
              className="action-icon"
              onClick={(e) => {
                e.preventDefault();
                addToStack({
                  name: t('location.update.header'),
                  component: <UpdateLocationContainer location={{ ...location }} />,
                });
              }}
            >
              <ReactSVG src="/icons/pencil.svg" />
            </div>
          </div>
        </div>

        <ScrollContainer
          verticle={false}
          hideScrollbars={false}
          className="align-items-center d-flex gap-10 tab-links-container my-1"
        >
          <div
            className={`tab-links${tab === 'location' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'location' })}
          >
            {t('locations.header')}
          </div>
          <div
            className={`tab-links${tab === 'complaints' ? ' tab-active' : ''}`}
            onClick={() => this.setState({ tab: 'complaints' })}
          >
            {t('complaints.header')}
          </div>
        </ScrollContainer>

        <section className={`chauffeur-view__box activity${tab === 'complaints' ? ' active' : ''}`}>
          {tab === 'complaints' ? (
            <Suspense fallback={<>{<Loader />}</>}>
              <ComplaintsContainer entityType="location" entity={location} />
            </Suspense>
          ) : null}
        </section>

        <section className={`chauffeur-view__box activity${tab === 'location' ? ' active' : ''}`}>
          {tab === 'location' ? (
            <>
              {latLonGeoReference?.type === 'Feature' ? (
                <MapContainer
                  center={
                    latLonGeoReference?.properties &&
                    Object.keys(latLonGeoReference?.properties).length > 1
                      ? this.getCenter(latLonGeoReference.properties)
                      : [52.0907, 5.1214]
                  }
                  zoom={11}
                  zoomControl={false}
                  preferCanvas={false}
                  attributionControl={false}
                  className="location-view__map"
                  boundsOptions={{ padding: [35, 35] }}
                >
                  <TileLayers />
                  {latLonGeoReference.geometry &&
                    latLonGeoReference.geometry?.coordinates &&
                    latLonGeoReference.geometry.coordinates?.length > 0 && (
                      <>
                        {latLonGeoReference.geometry?.type === 'Point' ? (
                          <Circle
                            center={{
                              lat: latLonGeoReference.geometry.coordinates[0],
                              lng: latLonGeoReference.geometry.coordinates[1],
                            }}
                            radius={latLonGeoReference.properties?.radius || 100}
                          />
                        ) : (
                          <>
                            <Polygon
                              positions={this.getCoordinates(
                                latLonGeoReference.geometry.coordinates[0]
                              )}
                            />
                          </>
                        )}
                      </>
                    )}
                </MapContainer>
              ) : latLonGeoReference?.lat && latLonGeoReference.lon ? (
                <MapContainer
                  className="location-view__map"
                  preferCanvas={false}
                  zoomControl={false}
                  boundsOptions={{ padding: [35, 35] }}
                  center={
                    latLonGeoReference
                      ? [latLonGeoReference.lat, latLonGeoReference.lon]
                      : [52.0907, 5.1214]
                  }
                  zoom={12}
                  attributionControl={false}
                >
                  <TileLayers />
                  {latLonGeoReference && (
                    <Marker
                      key={`${latLonGeoReference.id}`}
                      icon={DefaultMarker}
                      position={[latLonGeoReference.lat, latLonGeoReference.lon]}
                    />
                  )}
                </MapContainer>
              ) : null}
              <div className="location-info">
                <InformationComponent entity={location} fields={fieldsToRender} />
              </div>
              <div className="location-info">
                <Collapsible
                  name={`${t('location.actionsAverageDuration')}${
                    location.contactDetails.length > 0 ? ` (${location.contactDetails.length})` : ''
                  }`}
                  className="collapsible-element"
                  defaultIsOpen={false}
                >
                  <LocationActionAverageDurationView
                    location={location}
                    type="load"
                    label={t('location.averageLoadDuration')}
                  />
                  <LocationActionAverageDurationView
                    location={location}
                    type="unload"
                    label={t('location.averageUnloadDuration')}
                  />
                  <LocationActionAverageDurationView
                    location={location}
                    type="stop"
                    label={t('location.averageStopDuration')}
                  />
                </Collapsible>
              </div>
              <div className="location-info">
                <Collapsible
                  name={`${t('contactDetails.header')}${
                    location.contactDetails.length > 0 ? ` (${location.contactDetails.length})` : ''
                  }`}
                  className="collapsible-element"
                  defaultIsOpen={false}
                >
                  <ContactDetailsView contactDetails={location.contactDetails} />
                </Collapsible>
              </div>
            </>
          ) : null}
        </section>
      </div>
    );
  }
}
export default withTranslation('translation')(LocationView);
