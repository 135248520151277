import * as administrationApi from '@api/administrationApi';
import Alert from '@models/Alert';

import { administrationConstants } from '../constants';
import * as navigationActions from './navigationActions';

/**
 * Change redux value
 */
export const changeValue = (name, value) => ({
  type: administrationConstants.ADMINISTRATION_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching administration
 */
export const startGetAdministration = () => ({
  type: administrationConstants.GET_ADMINISTRATION_STARTED,
});

export const getAdministrationSuccess = (result) => ({
  type: administrationConstants.GET_ADMINISTRATION_SUCCESS,
  ...result,
});

export const getAdministrationFailure = (error) => ({
  type: administrationConstants.GET_ADMINISTRATION_FAILURE,
  ...error,
});

export const getAdministration = (id) => {
  return (dispatch) => {
    dispatch(startGetAdministration());

    const promise = administrationApi.get(id);

    promise
      .then((result) => {
        dispatch(getAdministrationSuccess(result));
      })
      .catch((error) => {
        dispatch(getAdministrationFailure(error));
      });

    return promise;
  };
};

/**
 * Creating administration
 */
export const startCreateAdministration = () => ({
  type: administrationConstants.CREATING_ADMINISTRATION_STARTED,
});

export const createAdministrationSuccess = (result) => ({
  type: administrationConstants.CREATING_ADMINISTRATION_SUCCESS,
  ...result,
});

export const createAdministrationFailure = (error) => ({
  type: administrationConstants.CREATING_ADMINISTRATION_FAILURE,
  ...error,
});

export const createAdministration = (administration) => {
  return (dispatch) => {
    dispatch(startCreateAdministration());

    const promise = administrationApi.create(administration);

    promise
      .then((result) => {
        dispatch(createAdministrationSuccess(result));
      })
      .catch((error) => {
        dispatch(createAdministrationFailure(error));
      });

    return promise;
  };
};

/**
 * Updating administration
 */
export const startUpdateAdministration = () => ({
  type: administrationConstants.UPDATING_ADMINISTRATION_STARTED,
});

export const updateAdministrationSuccess = (result) => ({
  type: administrationConstants.UPDATING_ADMINISTRATION_SUCCESS,
  ...result,
});

export const updateAdministrationFailure = (error) => ({
  type: administrationConstants.UPDATING_ADMINISTRATION_FAILURE,
  ...error,
});

export const updateAdministration = (administration) => {
  return (dispatch) => {
    dispatch(startUpdateAdministration());

    const promise = administrationApi.update(administration);

    promise
      .then((result) => {
        dispatch(updateAdministrationSuccess(result));
      })
      .catch((error) => {
        dispatch(updateAdministrationFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting administration
 */
export const startDeleteAdministration = () => ({
  type: administrationConstants.DELETE_ADMINISTRATION_STARTED,
});

export const deleteAdministrationSuccess = (result) => ({
  type: administrationConstants.DELETE_ADMINISTRATION_SUCCESS,
  ...result,
});

export const deleteAdministrationFailure = (error) => ({
  type: administrationConstants.DELETE_ADMINISTRATION_FAILURE,
  ...error,
});
export const deleteAdministration = (administration) => {
  return (dispatch) => {
    dispatch(startDeleteAdministration());

    const promise = administrationApi.remove(administration);

    promise
      .then((result) => {
        dispatch(deleteAdministrationSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteAdministrationFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching administrations
 */
export const startGetAdministrations = (from) => ({
  type: administrationConstants.GET_ADMINISTRATIONS_STARTED,
  from: from,
});

export const getAdministrationsSuccess = (result, from) => ({
  type: administrationConstants.GET_ADMINISTRATIONS_SUCCESS,
  from: from,
  ...result,
});

export const getAdministrationsFailure = (error) => ({
  type: administrationConstants.GET_ADMINISTRATIONS_FAILURE,
  ...error,
});

export const getAdministrations = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetAdministrations(from));

    const promise = administrationApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getAdministrationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getAdministrationsFailure(error));
      });

    return promise;
  };
};

export const searchAdministrations = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetAdministrations(from));

    const promise = administrationApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getAdministrationsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getAdministrationsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching currencies
 */
export const startGetCurrencies = () => ({
  type: administrationConstants.GET_CURRENCIES_STARTED,
});

export const getCurrenciesSuccess = (result) => ({
  type: administrationConstants.GET_CURRENCIES_SUCCESS,
  ...result,
});

export const getCurrenciesFailure = (error) => ({
  type: administrationConstants.GET_CURRENCIES_FAILURE,
  ...error,
});

export const getCurrencies = () => {
  return (dispatch) => {
    dispatch(startGetCurrencies());

    const promise = administrationApi.getCurrencies();

    promise
      .then((result) => {
        dispatch(getCurrenciesSuccess(result));
      })
      .catch((error) => {
        dispatch(getCurrenciesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transactions
 */
export const startGetTransactions = () => ({
  type: administrationConstants.GET_TRANSACTIONS_STARTED,
});

export const getTransactionsSuccess = (result, from) => ({
  type: administrationConstants.GET_TRANSACTIONS_SUCCESS,
  from: from,
  ...result,
});

export const getTransactionsFailure = (error) => ({
  type: administrationConstants.GET_TRANSACTIONS_FAILURE,
  ...error,
});

export const getTransactions = (from, to) => {
  return (dispatch) => {
    dispatch(startGetTransactions());

    const promise = administrationApi.getTransactions(from, to);

    promise
      .then((result) => {
        dispatch(getTransactionsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getTransactionsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching transactions by date
 */
export const startGetTransactionsByDate = () => ({
  type: administrationConstants.GET_TRANSACTIONS_BY_DATE_STARTED,
});

export const getTransactionsByDateSuccess = (result) => ({
  type: administrationConstants.GET_TRANSACTIONS_BY_DATE_SUCCESS,
  ...result,
});

export const getTransactionsByDateFailure = (error) => ({
  type: administrationConstants.GET_TRANSACTIONS_BY_DATE_FAILURE,
  ...error,
});

export const getTransactionsByDate = (startDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetTransactionsByDate());

    const promise = administrationApi.getTransactionsByDate(startDate, toDate);

    promise
      .then((result) => {
        dispatch(getTransactionsByDateSuccess(result));
      })
      .catch((error) => {
        dispatch(getTransactionsByDateFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching invoices
 */
export const startGetInvoices = (from) => ({
  type: administrationConstants.GET_INVOICES_STARTED,
  from: from,
});

export const getInvoicesSuccess = (result, from) => ({
  type: administrationConstants.GET_INVOICES_SUCCESS,
  from: from,
  ...result,
});

export const getInvoicesFailure = (error) => ({
  type: administrationConstants.GET_INVOICES_FAILURE,
  ...error,
});

export const getInvoices = (administrationId, from, to) => {
  return (dispatch) => {
    dispatch(startGetInvoices(from));
    const promise = administrationApi.getInvoices(administrationId, from, to);

    promise
      .then((result) => {
        dispatch(getInvoicesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getInvoicesFailure(error));
      });

    return promise;
  };
};

export const searchInvoices = (administrationId, searchParameters, from, to) => {
  return (dispatch) => {
    dispatch(startGetInvoices(from));
    const promise = administrationApi.searchInvoices(administrationId, searchParameters, from, to);

    promise
      .then((result) => {
        dispatch(getInvoicesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getInvoicesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching invoice
 */
export const startGetInvoice = () => ({
  type: administrationConstants.GET_INVOICE_STARTED,
});

export const getInvoiceSuccess = (result) => ({
  type: administrationConstants.GET_INVOICE_SUCCESS,
  ...result,
});

export const getInvoiceFailure = (error) => ({
  type: administrationConstants.GET_INVOICE_FAILURE,
  ...error,
});

export const getInvoice = (id) => {
  return (dispatch) => {
    dispatch(startGetInvoice());

    const promise = administrationApi.getInvoice(id);

    promise
      .then((result) => {
        dispatch(getInvoiceSuccess(result));
      })
      .catch((error) => {
        dispatch(getInvoiceFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting invoice
 */
export const startDeleteInvoice = () => ({
  type: administrationConstants.DELETE_INVOICE_STARTED,
});

export const deleteInvoiceSuccess = (result) => ({
  type: administrationConstants.DELETE_INVOICE_SUCCESS,
  ...result,
});

export const deleteInvoiceFailure = (error) => ({
  type: administrationConstants.DELETE_INVOICE_FAILURE,
  ...error,
});

export const deleteInvoice = (invoice) => {
  return (dispatch) => {
    dispatch(startDeleteInvoice());

    const promise = administrationApi.deleteInvoice(invoice);

    promise
      .then((result) => {
        dispatch(deleteInvoiceSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteInvoiceFailure(error));
      });

    return promise;
  };
};

/**
 * Creating invoice
 */

export const startCreateInvoice = () => ({
  type: administrationConstants.CREATE_INVOICE_STARTED,
});

export const createInvoiceSuccess = (result) => ({
  type: administrationConstants.CREATE_INVOICE_SUCCESS,
  ...result,
});

export const createInvoiceFailure = (error) => ({
  type: administrationConstants.CREATE_INVOICE_FAILURE,
  ...error,
});

export const createInvoiceRequest = (invoice) => {
  return (dispatch) => {
    dispatch(startCreateInvoice());
    const promise = administrationApi.createInvoice(invoice);

    promise
      .then((result) => {
        dispatch(createInvoiceSuccess(result));
      })
      .catch((error) => {
        dispatch(createInvoiceFailure(error));
      });

    return promise;
  };
};

/**
 * Updating invoice
 */
export const startUpdateInvoice = () => ({
  type: administrationConstants.UPDATE_INVOICE_STARTED,
});

export const updateInvoiceSuccess = (result) => ({
  type: administrationConstants.UPDATE_INVOICE_SUCCESS,
  ...result,
});

export const updateInvoiceFailure = (error) => ({
  type: administrationConstants.UPDATE_INVOICE_FAILURE,
  ...error,
});

export const updateInvoiceRequest = (invoice) => {
  return (dispatch) => {
    dispatch(startUpdateInvoice());

    const promise = administrationApi.updateInvoice(invoice);

    promise
      .then((result) => {
        dispatch(updateInvoiceSuccess(result));
      })
      .catch((error) => {
        dispatch(updateInvoiceFailure(error));
      });

    return promise;
  };
};

export const sendInvoice = (invoice) => {
  return (dispatch) => {
    dispatch(startUpdateInvoice());

    const promise = administrationApi.sendInvoice(invoice);

    promise
      .then((result) => {
        dispatch(updateInvoiceSuccess(result));
      })
      .catch((error) => {
        dispatch(updateInvoiceFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching quotes
 */
export const startGetQuotes = (from) => ({
  type: administrationConstants.GET_QUOTES_STARTED,
  from: from,
});

export const getQuotesSuccess = (result, from) => ({
  type: administrationConstants.GET_QUOTES_SUCCESS,
  from: from,
  ...result,
});

export const getQuotesFailure = (error) => ({
  type: administrationConstants.GET_QUOTES_FAILURE,
  ...error,
});

export const getQuotes = (administrationId, from, to) => {
  return (dispatch) => {
    dispatch(startGetQuotes(from));

    const promise = administrationApi.getQuotes(administrationId, from, to);

    promise
      .then((result) => {
        dispatch(getQuotesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getQuotesFailure(error));
      });

    return promise;
  };
};

export const searchQuotes = (administrationId, searchParameters, from, to) => {
  return (dispatch) => {
    dispatch(startGetQuotes(from));

    const promise = administrationApi.searchQuotes(administrationId, searchParameters, from, to);

    promise
      .then((result) => {
        dispatch(getQuotesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getQuotesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching quote
 */
export const startGetQuote = () => ({
  type: administrationConstants.GET_QUOTE_STARTED,
});

export const getQuoteSuccess = (result) => ({
  type: administrationConstants.GET_QUOTE_SUCCESS,
  ...result,
});

export const getQuoteFailure = (error) => ({
  type: administrationConstants.GET_QUOTE_FAILURE,
  ...error,
});

export const getQuote = (id) => {
  return (dispatch) => {
    dispatch(startGetQuote());

    const promise = administrationApi.getQuote(id);

    promise
      .then((result) => {
        dispatch(getQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(getQuoteFailure(error));
      });

    return promise;
  };
};
export const getPublicQuote = (id, shareCode) => {
  return (dispatch) => {
    dispatch(startGetQuote());

    const promise = administrationApi.getPublicQuote(id, shareCode);

    promise
      .then((result) => {
        dispatch(getQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(getQuoteFailure(error));
      });

    return promise;
  };
};

/**
 * Creating quote
 */

export const startCreateQuote = () => ({
  type: administrationConstants.CREATE_QUOTE_STARTED,
});

export const createQuoteSuccess = (result) => ({
  type: administrationConstants.CREATE_QUOTE_SUCCESS,
  ...result,
});

export const createQuoteFailure = (error) => ({
  type: administrationConstants.CREATE_QUOTE_FAILURE,
  ...error,
});

export const createQuote = (quote) => {
  return (dispatch) => {
    dispatch(startCreateQuote());

    const promise = administrationApi.createQuote(quote);

    promise
      .then((result) => {
        dispatch(createQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(createQuoteFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting Quote
 */
export const startDeleteQuote = () => ({
  type: administrationConstants.DELETE_QUOTE_STARTED,
});

export const deleteQuoteSuccess = (result) => ({
  type: administrationConstants.DELETE_QUOTE_SUCCESS,
  ...result,
});

export const deleteQuoteFailure = (error) => ({
  type: administrationConstants.DELETE_QUOTE_FAILURE,
  ...error,
});

export const deleteQuote = (quote) => {
  return (dispatch) => {
    dispatch(startDeleteQuote());

    const promise = administrationApi.deleteQuote(quote);

    promise
      .then((result) => {
        dispatch(deleteQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteQuoteFailure(error));
      });

    return promise;
  };
};

/**
 * Updating quote
 */
export const startUpdateQuote = () => ({
  type: administrationConstants.UPDATE_QUOTE_STARTED,
});

export const updateQuoteSuccess = (result) => ({
  type: administrationConstants.UPDATE_QUOTE_SUCCESS,
  ...result,
});

export const updateQuoteFailure = (error) => ({
  type: administrationConstants.UPDATE_QUOTE_FAILURE,
  ...error,
});

export const updateQuote = (quote) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.updateQuote(quote);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

export const sendQuote = (quote) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.sendQuote(quote);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

export const acceptPublicQuote = (quote, shareCode) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.publicAcceptQuote(quote, shareCode);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

export const declinePublicQuote = (quote, shareCode) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.publicDeclineQuote(quote, shareCode);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

export const acceptQuote = (quote) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.acceptQuote(quote);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

export const declineQuote = (quote) => {
  return (dispatch) => {
    dispatch(startUpdateQuote());

    const promise = administrationApi.declineQuote(quote);

    promise
      .then((result) => {
        dispatch(updateQuoteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateQuoteFailure(error));
      });

    return promise;
  };
};

/**
 * Creating charge
 */
export const startCreateCharge = () => ({
  type: administrationConstants.CREATING_CHARGE_STARTED,
});

export const createChargeSuccess = (result) => ({
  type: administrationConstants.CREATING_CHARGE_SUCCESS,
  ...result,
});

export const createChargeFailure = (error) => ({
  type: administrationConstants.CREATING_CHARGE_FAILURE,
  ...error,
});

export const createCharge = (charge) => {
  return (dispatch) => {
    dispatch(startCreateCharge());

    const promise = administrationApi.createCharge(charge);

    promise
      .then((result) => {
        dispatch(createChargeSuccess(result));
      })
      .catch((error) => {
        dispatch(createChargeFailure(error));
      });

    return promise;
  };
};

/**
 * Updating charge
 */
export const startUpdateCharge = () => ({
  type: administrationConstants.UPDATING_CHARGE_STARTED,
});

export const updateChargeSuccess = (result) => ({
  type: administrationConstants.UPDATING_CHARGE_SUCCESS,
  ...result,
});

export const updateChargeFailure = (error) => ({
  type: administrationConstants.UPDATING_CHARGE_FAILURE,
  ...error,
});

export const updateCharge = (charge) => {
  return (dispatch) => {
    dispatch(startUpdateCharge());

    const promise = administrationApi.updateCharge(charge);

    promise
      .then((result) => {
        dispatch(updateChargeSuccess(result));
      })
      .catch((error) => {
        dispatch(updateChargeFailure(error));
      });

    return promise;
  };
};

/**
 * Charge the source
 */

export const startExecuteCharge = () => ({
  type: administrationConstants.EXECUTE_CHARGE_STARTED,
});

export const updateExecuteSuccess = (result) => ({
  type: administrationConstants.EXECUTE_CHARGE_SUCCESS,
  ...result,
});

export const updateExecuteFailure = (error) => ({
  type: administrationConstants.EXECUTE_CHARGE_FAILURE,
  ...error,
});

export const executeCharge = (charge) => {
  return (dispatch) => {
    dispatch(startExecuteCharge());

    const promise = administrationApi.executeCharge(charge);

    promise
      .then((result) => {
        dispatch(updateExecuteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateExecuteFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching payouts
 */
export const startGetPayouts = () => ({
  type: administrationConstants.GET_PAYOUTS_STARTED,
});

export const getPayoutsSuccess = (result, from) => ({
  type: administrationConstants.GET_PAYOUTS_SUCCESS,
  from: from,
  ...result,
});

export const getPayoutsFailure = (error) => ({
  type: administrationConstants.GET_PAYOUTS_FAILURE,
  ...error,
});

export const getPayouts = (from, to) => {
  return (dispatch) => {
    dispatch(startGetPayouts());

    const promise = administrationApi.getPayouts(from, to);

    promise
      .then((result) => {
        dispatch(getPayoutsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getPayoutsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching payout
 */
export const startGetPayout = () => ({
  type: administrationConstants.GET_PAYOUT_STARTED,
});

export const getPayoutSuccess = (result) => ({
  type: administrationConstants.GET_PAYOUT_SUCCESS,
  ...result,
});

export const getPayoutFailure = (error) => ({
  type: administrationConstants.GET_PAYOUT_FAILURE,
  ...error,
});

export const getPayout = (id) => {
  return (dispatch) => {
    dispatch(startGetPayout());

    const promise = administrationApi.getPayout(id);

    promise
      .then((result) => {
        dispatch(getPayoutSuccess(result));
      })
      .catch((error) => {
        dispatch(getPayoutFailure(error));
      });

    return promise;
  };
};

/**
 * Creating payout
 */

export const startCreatePayout = () => ({
  type: administrationConstants.CREATING_PAYOUT_STARTED,
});

export const createPayoutSuccess = (result) => ({
  type: administrationConstants.CREATING_PAYOUT_SUCCESS,
  ...result,
});

export const createPayoutFailure = (error) => ({
  type: administrationConstants.CREATING_PAYOUT_FAILURE,
  ...error,
});

export const createPayout = (payout, t) => {
  return (dispatch) => {
    dispatch(startCreatePayout());

    const promise = administrationApi.createPayout(payout);

    promise
      .then((result) => {
        dispatch(createPayoutSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.payout.success.title'),
              t('notification.payout.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(createPayoutFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.payout.error.title'),
              t('notification.payout.error.description', {
                message: error.message,
              }),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

/**
 * Updating payout
 */
export const startUpdatePayout = () => ({
  type: administrationConstants.UPDATING_PAYOUT_STARTED,
});

export const updatePayoutSuccess = (result) => ({
  type: administrationConstants.UPDATING_PAYOUT_SUCCESS,
  ...result,
});

export const updatePayoutFailure = (error) => ({
  type: administrationConstants.UPDATING_PAYOUT_FAILURE,
  ...error,
});

export const updatePayout = (payout) => {
  return (dispatch) => {
    dispatch(startUpdatePayout());

    const promise = administrationApi.updatePayout(payout);

    promise
      .then((result) => {
        dispatch(updatePayoutSuccess(result));
      })
      .catch((error) => {
        dispatch(updatePayoutFailure(error));
      });

    return promise;
  };
};

/**
 * Check if he has funds available
 */

export const startHasFunds = () => ({
  type: administrationConstants.CREATING_INVOICE_STARTED,
});

export const hasFundsSuccess = (result) => ({
  type: administrationConstants.CREATING_INVOICE_SUCCESS,
  ...result,
});

export const hasFundsFailure = (error) => ({
  type: administrationConstants.CREATING_INVOICE_FAILURE,
  ...error,
});

export const hasFunds = (administration, amount) => {
  return (dispatch) => {
    dispatch(startHasFunds());

    const promise = administrationApi.hasFunds(administration.id, amount);

    promise
      .then((result) => {
        dispatch(hasFundsSuccess(result));
      })
      .catch((error) => {
        dispatch(hasFundsFailure(error));
      });

    return promise;
  };
};
