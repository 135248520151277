import { sensorConstants } from '@constants/sensor.constants';

const initialState = {
  isFetchingSensor: false,
  formSensor: null,
  sensor: null,

  isFetchingSensors: false,
  isFetchingSensorsFrom: 0,
  sensorsTotalResults: 0,
  sensorsLoaded: 0,
  sensors: [],
};

export default function sensor(state = initialState, action) {
  switch (action.type) {
    case sensorConstants.SENSOR_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case sensorConstants.GET_SENSORS_STARTED:
      return {
        ...state,
        isFetchingSensors: true,
        isFetchingSensorsFrom: action.from || 0,
      };
    case sensorConstants.GET_SENSORS_FAILURE:
      return {
        ...state,
        isFetchingSensors: false,
        error: action.message || '',
      };
    case sensorConstants.GET_SENSORS_SUCCESS:
      return {
        ...state,
        isFetchingSensors: false,
        sensors: action.from > 0 ? [...state.sensors, ...action.sensors] : action.sensors,
        sensorsLoaded:
          action.from > 0 ? state.sensors.length + action.sensors.length : action.sensors.length,
        sensorsTotalResults: action.totalResults || 0,
      };

    case sensorConstants.SEARCH_SENSORS_STARTED:
      return {
        ...state,
        isFetchingSensors: true,
        isFetchingSensorsFrom: action.from || 0,
      };
    case sensorConstants.SEARCH_SENSORS_FAILURE:
      return {
        ...state,
        isFetchingSensors: false,
        error: action.message || '',
      };
    case sensorConstants.SEARCH_SENSORS_SUCCESS:
      return {
        ...state,
        isFetchingSensors: false,
        sensors: action.from > 0 ? [...state.sensors, ...action.sensors] : action.sensors,
        sensorsLoaded:
          action.from > 0 ? state.sensors.length + action.sensors.length : action.sensors.length,
        sensorsTotalResults: action.totalResults || 0,
      };

    case sensorConstants.CREATING_SENSOR_STARTED:
      return { ...state, isFetchingSensor: true };
    case sensorConstants.CREATING_SENSOR_FAILURE:
      return { ...state, isFetchingSensor: false, error: action.message || '' };
    case sensorConstants.CREATING_SENSOR_SUCCESS:
      return {
        ...state,
        isFetchingSensor: false,
        sensor: action.sensor,
        sensors: [action.sensor, ...state.sensors],
      };

    case sensorConstants.UPDATING_SENSOR_STARTED:
      return { ...state, isFetchingSensor: true };
    case sensorConstants.UPDATING_SENSOR_FAILURE:
      return { ...state, isFetchingSensor: false, error: action.message || '' };
    case sensorConstants.UPDATING_SENSOR_SUCCESS:
      return {
        ...state,
        isFetchingSensor: false,
        sensor: action.sensor,
        sensors: [...state.sensors].map((sensor) => {
          if (action.sensor.id === sensor.id) {
            return action.sensor;
          }
          return sensor;
        }),
      };

    case sensorConstants.DELETE_SENSOR_STARTED:
      return { ...state, isFetchingSensor: true };
    case sensorConstants.DELETE_SENSOR_FAILURE:
      return {
        ...state,
        isFetchingSensor: false,
        message: action.message || '',
      };
    case sensorConstants.DELETE_SENSOR_SUCCESS:
      return {
        ...state,
        isFetchingSensor: false,
        sensor: action.sensor,
        sensors: [...state.sensors].filter((sensor) => action.sensor.id !== sensor.id),
      };
    default:
      return state;
  }
}
