export default class Administration {
  id;
  nonce;

  numberFormat;
  numberLength;

  dueDays;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.numberFormat = '{{year}}-{{id}}';
    this.numberLength = 9;

    this.dueDays = 14;
  }
}
