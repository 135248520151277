import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import moment from 'moment';

import CreateTransportOrderContainer from '@containers/transportOrder/crud/CreateTransportOrderContainer';

import FilePreview from '@uicomponents/FilePreview';
import FileViewer from '@uicomponents/FileViewer';
import Loader from '@uicomponents/Loader';

import * as mailApi from '@api/mailApi';
import Association from '@models/general/Association';
import TransportOrder from '@models/transportOrder/TransportOrder';

class MailView extends Component {
  state = {
    files: [],
    focussedFile: null,
    filePreviewOpen: false,
    isConvertingTransportOrder: false,
  };
  render() {
    const {
      t,
      mail,
      history,
      isFetchingMail,
      addToStack,
      popStack,
      archiveMail,
    } = this.props;
    const { isConvertingTransportOrder, filePreviewOpen, files, focussedFile } =
      this.state;

    if (isConvertingTransportOrder) {
      return <Loader text={t('mail.convertingTransportOrder')} />;
    }
    if (isFetchingMail && !mail) {
      return <Loader />;
    }

    return (
      <div className="mail-view">
        <FileViewer
          t={t}
          isActive={filePreviewOpen}
          files={files}
          file={focussedFile}
          onClose={() => {
            this.setState({
              files: [],
              focussedFile: null,
              filePreviewOpen: false,
            });
          }}
        />
        <div className="flex-container justify-between">
          <div>
            <h1>{mail.subject}</h1>
            <div>
              {mail.sender.name} -{' '}
              <a href={`mailto:${mail.sender.email}`}>{mail.sender.email}</a>
            </div>
          </div>
          <p className="self-center">
            {moment(mail.receivedDate).format('HH:mm DD/MM/YYYY')}
          </p>
        </div>
        <hr></hr>
        <p dangerouslySetInnerHTML={{ __html: mail.text }}></p>
        <div className="attachments">
          {mail.attachments.length > 0
            ? mail.attachments.map((attachment) => (
                <div
                  className="attachment"
                  onClick={() => {
                    this.setState({
                      filePreviewOpen: true,
                      files: mail.attachments,
                      focussedFile: attachment,
                    });
                  }}
                >
                  <FilePreview file={attachment} />
                </div>
              ))
            : null}
        </div>
        <div className="actions">
          <button
            onClick={(e) => {
              e.preventDefault();

              this.setState({
                isConvertingTransportOrder: true,
              });

              let transportOrder = new TransportOrder();
              mailApi
                .convertToTransportOrder(mail.id)
                .then((response) => {
                  transportOrder = {
                    ...transportOrder,
                    ...response.transportOrder,
                  };
                  transportOrder.mail = mail;
                  transportOrder.documents = [...mail.attachments].map(
                    (attachment) => {
                      const newAttachment = new Association('inline', {
                        name: attachment.originalName || attachment.name,
                        mimeType: attachment.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: attachment.url,
                        },
                        file: { ...attachment },
                      });
                      newAttachment.uploading = false;
                      newAttachment.uploaded = true;
                      return newAttachment;
                    }
                  );

                  this.setState({
                    isConvertingTransportOrder: false,
                  });
                  addToStack({
                    name: t('transportOrder.new'),
                    component: (
                      <CreateTransportOrderContainer
                        targetTransportOrder={transportOrder}
                        onNavigateTo={(url) => {
                          popStack();
                          history.push(url);
                        }}
                      />
                    ),
                  });
                })
                .catch((exception) => {
                  transportOrder.name = mail.subject;
                  transportOrder.description = mail.plainText;
                  transportOrder.mail = mail;
                  transportOrder.documents = [...mail.attachments].map(
                    (attachment) => {
                      const newAttachment = new Association('inline', {
                        name: attachment.originalName || attachment.name,
                        mimeType: attachment.mimeType,
                        content: {
                          contentType: 'uri',
                          uri: attachment.url,
                        },
                        file: { ...attachment },
                      });
                      newAttachment.uploading = false;
                      newAttachment.uploaded = true;
                      return newAttachment;
                    }
                  );

                  this.setState({
                    isConvertingTransportOrder: false,
                  });
                  addToStack({
                    name: t('transportOrder.new'),
                    component: (
                      <CreateTransportOrderContainer
                        targetTransportOrder={transportOrder}
                        onNavigateTo={(url) => {
                          popStack();
                          history.push(url);
                        }}
                      />
                    ),
                  });
                });
            }}
          >
            {t('mail.createOrder')}
          </button>
          {!mail.archive && (
            <button
              onClick={(e) => {
                e.preventDefault();

                // archive e-mail
                archiveMail(mail.id).then(() => {
                  popStack();
                });
              }}
            >
              {t('mail.archive')}
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('translation')(MailView);
