import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/attribute/${id}`);
};

export const create = (attribute) => {
  return api.post(`/attribute`, attribute);
};

export const update = (attribute) => {
  return api.put(`/attribute`, attribute);
};

export const remove = (attribute) => {
  return api.delete(`/attribute/${attribute.id}`);
};

// Fetching all and searching
export const latest = (from = 0, amount = 20) => {
  return api.get(`/attributes/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/attributes/${from}/${amount}`, searchParameters);
};
