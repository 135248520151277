import React, { Component } from 'react';

import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';

import Loader from '@uicomponents/Loader';

const debounce = (fn, delay = 500) => {
  let time;
  return (...args) => {
    clearTimeout(time);
    time = setTimeout(() => fn(...args), delay);
  };
};

const Item = ({ entity }) => <div>{entity}</div>;

export default class VariableContentEditable extends Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.state = {
      textAreaContent: this.props.value,
      caretPosition: 0,
    };
    this.fetchSuggestionsDebounced = debounce(this.fetchSuggestions).bind(this);
  }

  handleChange = (e) => {
    const { onChange } = this.props;
    this.setState({ textAreaContent: e.target.value });
    onChange && onChange(e.target.value);
  };

  fetchSuggestions = async (query) => {
    try {
      const { searchFieldsRequest, formMessageTemplate, entityType } = this.props;
      let entity = formMessageTemplate?.contextEntity || entityType;
      const response = await searchFieldsRequest(entity, query, 20);
      let result = [];

      if (response.fields) {
        response.fields.forEach((f) => {
          result.push(f.name);
        });
        return result;
      }
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  findCaretPosition = () => {
    function createCopy(textArea) {
      var copy = document.createElement('div');
      copy.textContent = textArea.value;
      var style = getComputedStyle(textArea);
      [
        'fontFamily',
        'fontSize',
        'fontWeight',
        'wordWrap',
        'whiteSpace',
        'borderLeftWidth',
        'borderTopWidth',
        'borderRightWidth',
        'borderBottomWidth',
      ].forEach(function (key) {
        copy.style[key] = style[key];
      });
      copy.style.overflow = 'auto';
      copy.style.width = textArea.offsetWidth + 'px';
      copy.style.height = textArea.offsetHeight + 'px';
      copy.style.position = 'absolute';
      copy.style.left = textArea.offsetLeft + 'px';
      copy.style.top = textArea.offsetTop + 'px';
      document.body.appendChild(copy);
      return copy;
    }

    function getCaretPosition(textArea) {
      var start = textArea.selectionStart;
      var end = textArea.selectionEnd;
      var copy = createCopy(textArea);
      var range = document.createRange();
      range.setStart(copy.firstChild, start);
      range.setEnd(copy.firstChild, end);
      var selection = document.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
      var rect = range.getBoundingClientRect();
      document.body.removeChild(copy);
      textArea.selectionStart = start;
      textArea.selectionEnd = end;
      textArea.focus();
      return {
        x: rect.left - textArea.scrollLeft,
        y: rect.top - textArea.scrollTop,
      };
    }
    var position = getCaretPosition(this.ref);
    this.setState({ caretPosition: position.x });
  };

  render() {
    const { caretPosition, textAreaContent } = this.state;
    return (
      <>
        <ReactTextareaAutocomplete
          value={textAreaContent}
          innerRef={(e) => (this.ref = e)}
          onChange={(e) => this.handleChange(e)}
          className="variable-suggestions"
          loadingComponent={() => <Loader />}
          minChar={1}
          trigger={{
            '{{': {
              dataProvider: async (token) => {
                return this.fetchSuggestions(token.substring(1));
              },
              component: Item,
              output: (item) => `{{${item}}}`,
            },
          }}
          loaderStyle={{
            right: `${Math.round(caretPosition)}px`,
          }}
          listClassName="variable-suggestions-list"
          itemClassName="variable-suggestions-item"
          dropdownClassName="variable-suggestions-dropdown"
          onCaretPositionChange={(position) => {
            this.findCaretPosition();
          }}
        />
      </>
    );
  }
}
