import * as scheduleRouteApi from '@api/scheduleRouteApi';

import { scheduleRouteConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: scheduleRouteConstants.SCHEDULEROUTE_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching scheduleRoute
 */
export const startGetScheduleRoute = () => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTE_STARTED,
});

export const getScheduleRouteSuccess = (result) => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTE_SUCCESS,
  ...result,
});

export const getScheduleRouteFailure = (error) => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTE_FAILURE,
  ...error,
});

export const getScheduleRoute = (id) => {
  return (dispatch) => {
    dispatch(startGetScheduleRoute());

    const promise = scheduleRouteApi.get(id);

    promise
      .then((result) => {
        dispatch(getScheduleRouteSuccess(result));
      })
      .catch((error) => {
        dispatch(getScheduleRouteFailure(error));
      });

    return promise;
  };
};

/**
 * Creating scheduleRoute
 */
export const changeCreateScheduleRoute = (name, value) => ({
  type: scheduleRouteConstants.CREATING_SCHEDULEROUTE_CHANGE_VALUE,
  name,
  value,
});
export const startCreateScheduleRoute = () => ({
  type: scheduleRouteConstants.CREATING_SCHEDULEROUTE_STARTED,
});

export const createScheduleRouteSuccess = (result) => ({
  type: scheduleRouteConstants.CREATING_SCHEDULEROUTE_SUCCESS,
  ...result,
});

export const createScheduleRouteFailure = (error) => ({
  type: scheduleRouteConstants.CREATING_SCHEDULEROUTE_FAILURE,
  ...error,
});

export const createScheduleRoute = (scheduleRoute) => {
  return (dispatch) => {
    dispatch(startCreateScheduleRoute());

    const promise = scheduleRouteApi.create(scheduleRoute);

    promise
      .then((result) => {
        dispatch(createScheduleRouteSuccess(result));
      })
      .catch((error) => {
        dispatch(createScheduleRouteFailure(error));
      });

    return promise;
  };
};

/**
 * Updating scheduleRoute
 */
export const changeUpdateScheduleRoute = (name, value) => ({
  type: scheduleRouteConstants.UPDATING_SCHEDULEROUTE_CHANGE_VALUE,
  name,
  value,
});
export const startUpdateScheduleRoute = () => ({
  type: scheduleRouteConstants.UPDATING_SCHEDULEROUTE_STARTED,
});

export const updateScheduleRouteSuccess = (result) => ({
  type: scheduleRouteConstants.UPDATING_SCHEDULEROUTE_SUCCESS,
  ...result,
});

export const updateScheduleRouteFailure = (error) => ({
  type: scheduleRouteConstants.UPDATING_SCHEDULEROUTE_FAILURE,
  ...error,
});

export const updateScheduleRoute = (scheduleRoute) => {
  return (dispatch) => {
    dispatch(startUpdateScheduleRoute());

    const promise = scheduleRouteApi.update(scheduleRoute);

    promise
      .then((result) => {
        dispatch(updateScheduleRouteSuccess(result));
      })
      .catch((error) => {
        dispatch(updateScheduleRouteFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting scheduleRoute
 */
export const startDeleteScheduleRoute = () => ({
  type: scheduleRouteConstants.DELETE_SCHEDULEROUTE_STARTED,
});

export const deleteScheduleRouteSuccess = (result) => ({
  type: scheduleRouteConstants.DELETE_SCHEDULEROUTE_SUCCESS,
  ...result,
});

export const deleteScheduleRouteFailure = (error) => ({
  type: scheduleRouteConstants.DELETE_SCHEDULEROUTE_FAILURE,
  ...error,
});
export const deleteScheduleRoute = (scheduleRoute) => {
  return (dispatch) => {
    dispatch(startDeleteScheduleRoute());

    const promise = scheduleRouteApi.remove(scheduleRoute);

    promise
      .then((result) => {
        dispatch(deleteScheduleRouteSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteScheduleRouteFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching scheduleRoutes
 */
export const startGetScheduleRoutes = (from) => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTES_STARTED,
  from: from,
});

export const getScheduleRoutesSuccess = (result, from) => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTES_SUCCESS,
  from: from,
  ...result,
});

export const getScheduleRoutesFailure = (error) => ({
  type: scheduleRouteConstants.GET_SCHEDULEROUTES_FAILURE,
  ...error,
});

export const getScheduleRoutes = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetScheduleRoutes(from));

    const promise = scheduleRouteApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getScheduleRoutesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getScheduleRoutesFailure(error));
      });

    return promise;
  };
};

export const searchScheduleRoutes = (
  searchParameters = [],
  from = 0,
  amount = 20
) => {
  return (dispatch) => {
    dispatch(startGetScheduleRoutes(from));

    const promise = scheduleRouteApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getScheduleRoutesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getScheduleRoutesFailure(error));
      });

    return promise;
  };
};
