import { Component } from 'react';

export default class Tour extends Component {
  componentDidMount() {
    this.props.context.show(this.props.startTourFrom);
    this.props.context.on('cancel', () => this.props.onTourEnd());
    this.props.context.on('complete', () => {
      if (this.props.displayWelcome) {
        const { t, settings, changeValue, updateSettings } = this.props;
        const newSettings = { ...settings };
        const newUserInterfaceSettings = { ...settings.userInterface };
        newUserInterfaceSettings.tutorials = false;
        newSettings.userInterface = newUserInterfaceSettings;
        changeValue('settings', newSettings);
        updateSettings(t, newSettings);
      }
      this.props.onTourEnd();
    });
  }

  render() {
    return null;
  }
}
