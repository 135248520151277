import { goodConstants } from '@constants';

import * as goodApi from '@api/goodApi';

export const changeValue = (name, value) => ({
  type: goodConstants.GOOD_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching good
 */
export const startGetGood = () => ({
  type: goodConstants.GET_GOOD_STARTED,
});

export const getGoodSuccess = (result) => ({
  type: goodConstants.GET_GOOD_SUCCESS,
  ...result,
});

export const getGoodFailure = (error) => ({
  type: goodConstants.GET_GOOD_FAILURE,
  ...error,
});

export const getGood = (id) => {
  return (dispatch) => {
    dispatch(startGetGood());

    const promise = goodApi.get(id);

    promise
      .then((result) => {
        dispatch(getGoodSuccess(result));
      })
      .catch((error) => {
        dispatch(getGoodFailure(error));
      });

    return promise;
  };
};

/**
 * Creating good
 */
export const startCreateGood = () => ({
  type: goodConstants.CREATING_GOOD_STARTED,
});

export const createGoodSuccess = (result) => ({
  type: goodConstants.CREATING_GOOD_SUCCESS,
  ...result,
});

export const createGoodFailure = (error) => ({
  type: goodConstants.CREATING_GOOD_FAILURE,
  ...error,
});

export const createGood = (good) => {
  return (dispatch) => {
    dispatch(startCreateGood());

    const promise = goodApi.create(good);

    promise
      .then((result) => {
        dispatch(createGoodSuccess(result));
      })
      .catch((error) => {
        dispatch(createGoodFailure(error));
      });

    return promise;
  };
};

/**
 * Updating good
 */
export const startUpdateGood = () => ({
  type: goodConstants.UPDATING_GOOD_STARTED,
});

export const updateGoodSuccess = (result) => ({
  type: goodConstants.UPDATING_GOOD_SUCCESS,
  ...result,
});

export const updateGoodFailure = (error) => ({
  type: goodConstants.UPDATING_GOOD_FAILURE,
  ...error,
});

export const updateGood = (good) => {
  return (dispatch) => {
    dispatch(startUpdateGood());

    const promise = goodApi.update(good);

    promise
      .then((result) => {
        dispatch(updateGoodSuccess(result));
      })
      .catch((error) => {
        dispatch(updateGoodFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting good
 */
export const startDeleteGood = () => ({
  type: goodConstants.DELETE_GOOD_STARTED,
});

export const deleteGoodSuccess = (result) => ({
  type: goodConstants.DELETE_GOOD_SUCCESS,
  ...result,
});

export const deleteGoodFailure = (error) => ({
  type: goodConstants.DELETE_GOOD_FAILURE,
  ...error,
});
export const deleteGood = (good) => {
  return (dispatch) => {
    dispatch(startDeleteGood());

    const promise = goodApi.remove(good);

    promise
      .then((result) => {
        dispatch(deleteGoodSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteGoodFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching goods
 */
export const startGetGoods = () => ({
  type: goodConstants.GET_GOODS_STARTED,
});

export const getGoodsSuccess = (result, from) => ({
  type: goodConstants.GET_GOODS_SUCCESS,
  from: from,
  ...result,
});

export const getGoodsFailure = (error) => ({
  type: goodConstants.GET_GOODS_FAILURE,
  ...error,
});

export const getGoods = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetGoods());

    const promise = goodApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getGoodsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getGoodsFailure(error));
      });

    return promise;
  };
};

export const searchGoods = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetGoods());

    const promise = goodApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getGoodsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getGoodsFailure(error));
      });

    return promise;
  };
};
