export const mailConstants = {
  MAIL_CHANGE_VALUE: 'MAIL_CHANGE_VALUE',

  GET_MAIL_STARTED: 'GET_MAIL_STARTED',
  GET_MAIL_SUCCESS: 'GET_MAIL_SUCCESS',
  GET_MAIL_FAILURE: 'GET_MAIL_FAILURE',

  GET_MAILS_STARTED: 'GET_MAILS_STARTED',
  GET_MAILS_SUCCESS: 'GET_MAILS_SUCCESS',
  GET_MAILS_FAILURE: 'GET_MAILS_FAILURE',

  SEARCH_MAILS_STARTED: 'SEARCH_MAILS_STARTED',
  SEARCH_MAILS_SUCCESS: 'SEARCH_MAILS_SUCCESS',
  SEARCH_MAILS_FAILURE: 'SEARCH_MAILS_FAILURE',

  CREATING_MAIL_STARTED: 'CREATING_MAIL_STARTED',
  CREATING_MAIL_SUCCESS: 'CREATING_MAIL_SUCCESS',
  CREATING_MAIL_FAILURE: 'CREATING_MAIL_FAILURE',

  UPDATING_MAIL_STARTED: 'UPDATING_MAIL_STARTED',
  UPDATING_MAIL_SUCCESS: 'UPDATING_MAIL_SUCCESS',
  UPDATING_MAIL_FAILURE: 'UPDATING_MAIL_FAILURE',

  DELETE_MAIL_STARTED: 'DELETE_MAIL_STARTED',
  DELETE_MAIL_SUCCESS: 'DELETE_MAIL_SUCCESS',
  DELETE_MAIL_FAILURE: 'DELETE_MAIL_FAILURE',

  ARCHIVE_MAIL_STARTED: 'ARCHIVE_MAIL_STARTED',
  ARCHIVE_MAIL_SUCCESS: 'ARCHIVE_MAIL_SUCCESS',
  ARCHIVE_MAIL_FAILURE: 'ARCHIVE_MAIL_FAILURE',
};
