export const tenderBidConstants = {
  TENDER_BID_CHANGE_VALUE: 'TENDER_BID_CHANGE_VALUE',

  GET_TENDER_BID_STARTED: 'GET_TENDER_BID_STARTED',
  GET_TENDER_BID_SUCCESS: 'GET_TENDER_BID_SUCCESS',
  GET_TENDER_BID_FAILURE: 'GET_TENDER_FAILURE',

  GET_TENDER_BIDS_STARTED: 'GET_TENDER_BIDS_STARTED',
  GET_TENDER_BIDS_SUCCESS: 'GET_TENDER_BIDS_SUCCESS',
  GET_TENDER_BIDS_FAILURE: 'GET_TENDER_BIDS_FAILURE',

  SEARCH_TENDER_BIDS_STARTED: 'SEARCH_TENDER_BIDS_STARTED',
  SEARCH_TENDER_BIDS_SUCCESS: 'SEARCH_TENDER_BIDS_SUCCESS',
  SEARCH_TENDER_BIDS_FAILURE: 'SEARCH_TENDER_BIDS_FAILURE',

  CREATING_TENDER_BID_STARTED: 'CREATING_TENDER_BID_STARTED',
  CREATING_TENDER_BID_SUCCESS: 'CREATING_TENDER_BID_SUCCESS',
  CREATING_TENDER_BID_FAILURE: 'CREATING_TENDER_BID_FAILURE',

  UPDATING_TENDER_BID_STARTED: 'UPDATING_TENDER_BID_STARTED',
  UPDATING_TENDER_BID_SUCCESS: 'UPDATING_TENDER_BID_SUCCESS',
  UPDATING_TENDER_BID_FAILURE: 'UPDATING_TENDER_BID_FAILURE',

  DELETE_TENDER_BID_STARTED: 'DELETE_TENDER_BID_STARTED',
  DELETE_TENDER_BID_SUCCESS: 'DELETE_TENDER_BID_SUCCESS',
  DELETE_TENDER_BID_FAILURE: 'DELETE_TENDER_BID_FAILURE',

  ACCEPT_TENDER_BID_STARTED: 'ACCEPT_TENDER_BID_STARTED',
  ACCEPT_TENDER_BID_SUCCESS: 'ACCEPT_TENDER_BID_SUCCESS',
  ACCEPT_TENDER_BID_FAILURE: 'ACCEPT_TENDER_BID_FAILURE',

  DECLINE_TENDER_BID_STARTED: 'DECLINE_TENDER_BID_STARTED',
  DECLINE_TENDER_BID_SUCCESS: 'DECLINE_TENDER_BID_SUCCESS',
  DECLINE_TENDER_BID_FAILURE: 'DECLINE_TENDER_BID_FAILURE',

  COUNTER_TENDER_BID_STARTED: 'COUNTER_TENDER_BID_STARTED',
  COUNTER_TENDER_BID_SUCCESS: 'COUNTER_TENDER_BID_SUCCESS',
  COUNTER_TENDER_BID_FAILURE: 'COUNTER_TENDER_BID_FAILURE',
};
