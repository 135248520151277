import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import LocationView from '@components/location/LocationView';

import * as locationActions from '@actions/locationActions';
import * as navigationActions from '@actions/navigationActions';

class LocationContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getLocation } = this.props;
    if (ownProps.location) {
      changeValue('location', ownProps.location);
    } else if (ownProps.locationId) {
      getLocation(ownProps.locationId);
    }
  }

  render() {
    const { changeValue, getLocation, deleteLocation, ...props } = this.props;
    return (
      <LocationView
        {...props}
        onChange={changeValue}
        getLocation={(id = '') => getLocation(id)}
        deleteLocation={(location) => deleteLocation(location)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.location,

    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(locationActions.changeValue(name, value)),

    getLocation: (id) => dispatch(locationActions.getLocation(id)),
    deleteLocation: (location) =>
      dispatch(locationActions.deleteLocation(location)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(LocationContainer));
