import React, { Component } from 'react';

import FlipMove from 'react-flip-move';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import TripContentContainer from '@containers/trip/TripContentContainer';
import CreateTripContainer from '@containers/trip/crud/CreateTripContainer';
import UpdateTripContainer from '@containers/trip/crud/UpdateTripContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import OptionsMenu from '@uicomponents/OptionsMenu';

import { isExternal } from '@utils/userUtils';

class ConsignmentTrips extends Component {
  state = {
    type: 'future',

    finishCheckPopOver: false,
    startNextTrip: false,

    trips: this.props.consignment.trips,
  };

  handleScroll = (e) => {
    const { isFetchingTrips, tripsTotalResults, tripsLoaded } = this.props;
    const isAtBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;

    if (isAtBottom && !isFetchingTrips) {
      // fetch more
      if (tripsTotalResults > tripsLoaded) {
        this.load(tripsLoaded, 10);
      }
    }
  };

  load(consignment, offset, amount) {
    const { trips } = this.props;
    const { type } = this.state;
    const states =
      type === 'future'
        ? ['accepted', 'planned', 'confirmed', 'requested', 'in_transit']
        : ['cancelled', 'completed', 'realized'];
    this.setState({
      trips: trips.filter((t) => states.includes(t.status)),
    });
  }

  componentDidMount() {
    this.load(0, 10);
  }

  render() {
    const {
      t,
      history,
      user,
      consignment,
      isFetchingTrips,
      isFetchingTripsFrom,
      cancelTrip,

      addToStack,
      popStack,
    } = this.props;

    const { type, trips } = this.state;
    const latLonPointGeoReference = consignment.currentLocation
      ? consignment.currentLocation
      : null;

    return (
      <>
        <div className="flex-container justify-between">
          <div className="d-flex gap-10 tab-links-container no-padding">
            <div
              className={`tab-links smaller dark${type === 'future' ? ' tab-active' : ''}`}
              onClick={() => {
                this.setState({ type: 'future' }, () => {
                  this.load(consignment, 0, 10);
                });
              }}
            >
              {t('vehicle.trips.future')}
            </div>
            <div
              className={`tab-links smaller dark${type === 'past' ? ' tab-active' : ''}`}
              onClick={() => {
                this.setState({ type: 'past' }, () => {
                  this.load(consignment, 0, 10);
                });
              }}
            >
              {t('vehicle.trips.past')}
            </div>
          </div>
          <div className="actions">
            {isExternal(user) && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  addToStack({
                    name: t('trip.create'),
                    component: (
                      <CreateTripContainer
                        targetVehicle={{ ...vehicle }}
                        callback={() => {
                          this.load(consignment, 0, 20);
                        }}
                        onNavigateTo={(url) => {
                          popStack();
                          history.push(url);
                        }}
                      />
                    ),
                  });
                }}
              >
                {t('trip.create')}
              </button>
            )}
          </div>
        </div>
        {isFetchingTrips && isFetchingTripsFrom === 0 ? (
          <Loader />
        ) : (
          <div className="vehicle-trips scrollable" onScroll={(e) => this.handleScroll(e)}>
            {trips.length > 0 ? (
              <>
                {trips.map((trip, index) => {
                  const isActive = trip.status === 'in_transit';

                  if (trip.isLoading) return <Loader />;
                  return (
                    <FlipMove key={`trip-${trip.id}`}>
                      <Collapsible
                        name={trip.name}
                        className={`vehicle-trips__trip${isActive ? ' active' : ''}`}
                        isOpen={type === 'future' || index === 0}
                        rightSideElements={
                          trip.status !== 'completed' &&
                          trip.status !== 'cancelled' &&
                          !isExternal(user) ? (
                            <OptionsMenu
                              className="self-center"
                              items={[
                                {
                                  name: t('form.edit'),
                                  onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    addToStack({
                                      name: trip.name,
                                      component: <UpdateTripContainer trip={trip} />,
                                    });
                                  },
                                },
                                {
                                  name: t('form.cancel'),
                                  onClick: (e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    cancelTrip(trip);
                                  },
                                },
                              ]}
                            />
                          ) : null
                        }
                        key={`consignment.trip-${trip.id}`}
                      >
                        <TripContentContainer
                          trip={trip}
                          trips={trips}
                          vehicle={trip?.vehicle?.entity}
                          latLonPointGeoReference={
                            trip.vehicle?.entity?.latestLocation?.geoReference
                          }
                        />
                      </Collapsible>
                    </FlipMove>
                  );
                })}
                {isFetchingTrips && <Loader />}
              </>
            ) : (
              <div className="not-found-action-box">
                <div className="icon">
                  <ReactSVG src="/icons/space.svg" />
                </div>
                <NoData>{t('trips.notFound')}</NoData>
                <div className="create">
                  <div
                    className="as-link"
                    onClick={(e) => {
                      addToStack({
                        name: t('trip.create'),
                        component: (
                          <CreateTripContainer
                            targetVehicle={{ ...trip.vehicle }}
                            callback={() => {
                              this.load(consignment, 0, 20);
                            }}
                            onNavigateTo={(url) => {
                              popStack();
                              history.push(url);
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    {t('trip.new')}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(ConsignmentTrips);
