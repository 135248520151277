
export const appConstants = {
    APP_CHANGE_VALUE: "APP_CHANGE_VALUE",

    GET_APP_STARTED: "GET_APP_STARTED",
    GET_APP_SUCCESS: "GET_APP_SUCCESS",
    GET_APP_FAILURE: "GET_APP_FAILURE",

    GET_APPS_STARTED: "GET_APPS_STARTED",
    GET_APPS_SUCCESS: "GET_APPS_SUCCESS",
    GET_APPS_FAILURE: "GET_APPS_FAILURE",

    SEARCH_APPS_STARTED: "SEARCH_APPS_STARTED",
    SEARCH_APPS_SUCCESS: "SEARCH_APPS_SUCCESS",
    SEARCH_APPS_FAILURE: "SEARCH_APPS_FAILURE",

    CREATING_APP_STARTED: "CREATING_APP_STARTED",
    CREATING_APP_SUCCESS: "CREATING_APP_SUCCESS",
    CREATING_APP_FAILURE: "CREATING_APP_FAILURE",

    UPDATING_APP_STARTED: "UPDATING_APP_STARTED",
    UPDATING_APP_SUCCESS: "UPDATING_APP_SUCCESS",
    UPDATING_APP_FAILURE: "UPDATING_APP_FAILURE",

    DELETE_APP_STARTED: "DELETE_APP_STARTED",
    DELETE_APP_SUCCESS: "DELETE_APP_SUCCESS",
    DELETE_APP_FAILURE: "DELETE_APP_FAILURE",



    GET_APPVERSION_STARTED: "GET_APPVERSION_STARTED",
    GET_APPVERSION_SUCCESS: "GET_APPVERSION_SUCCESS",
    GET_APPVERSION_FAILURE: "GET_APPVERSION_FAILURE",

    GET_APPVERSIONS_STARTED: "GET_APPVERSIONS_STARTED",
    GET_APPVERSIONS_SUCCESS: "GET_APPVERSIONS_SUCCESS",
    GET_APPVERSIONS_FAILURE: "GET_APPVERSIONS_FAILURE",

    SEARCH_APPVERSIONS_STARTED: "SEARCH_APPVERSIONS_STARTED",
    SEARCH_APPVERSIONS_SUCCESS: "SEARCH_APPVERSIONS_SUCCESS",
    SEARCH_APPVERSIONS_FAILURE: "SEARCH_APPVERSIONS_FAILURE",

    CREATING_APPVERSION_STARTED: "CREATING_APPVERSION_STARTED",
    CREATING_APPVERSION_SUCCESS: "CREATING_APPVERSION_SUCCESS",
    CREATING_APPVERSION_FAILURE: "CREATING_APPVERSION_FAILURE",

    UPDATING_APPVERSION_STARTED: "UPDATING_APPVERSION_STARTED",
    UPDATING_APPVERSION_SUCCESS: "UPDATING_APPVERSION_SUCCESS",
    UPDATING_APPVERSION_FAILURE: "UPDATING_APPVERSION_FAILURE",

    DELETE_APPVERSION_STARTED: "DELETE_APPVERSION_STARTED",
    DELETE_APPVERSION_SUCCESS: "DELETE_APPVERSION_SUCCESS",
    DELETE_APPVERSION_FAILURE: "DELETE_APPVERSION_FAILURE",
};
