import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

export default class MetaTags extends Component {
  render() {
    const { tags } = this.props;
    return (
      <Helmet>
        <title>{tags.title}</title>
        <meta name="twitter:title" content={`${tags.title}`} />
        <meta property="og:title" content={`${tags.title}`} />
        <meta property="og:site_name" content={`${tags.title}`} />
        <meta itemProp="name" content={`${tags.title}`} />

        <meta name="twitter:site" content="" />

        <meta
          property="og:image"
          content={`${tags.card || '/images/transportial-background.png'}`}
        />
        <meta
          name="twitter:image:src"
          content={`${tags.card || '/images/transportial-background.png'}`}
        />
        <meta
          name="twitter:card"
          content={`${tags.card || '/images/transportial-background.png'}`}
        />

        <meta name="description" content={`${tags.description || ''}`} />
        <meta itemProp="description" content={`${tags.description || ''}`} />
        <meta name="twitter:description" content={`${tags.description || ''}`} />
        <meta property="og:description" content={`${tags.description || ''}`} />

        <meta property="og:type" content={`${tags.type || 'website'}`} />
        <meta property="og:url" content={window.location.href} />
        <meta name="keywords" content={tags.keywords} />

        <link
          rel="shortcut icon"
          href={`${tags.favicon || `/favicon.ico`}`}
        />
      </Helmet>
    );
  }
}
