import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class ComparisonTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = [
      { value: 'equal', label: t('comparisonType.if') },
      { value: 'not_equal', label: t('comparisonType.unless') },
      { value: 'greater_than', label: t('comparisonType.greater_than') },
      {
        value: 'greater_or_equal_than',
        label: t('comparisonType.greater_or_equal_than'),
      },
      { value: 'lesser_than', label: t('comparisonType.lesser_than') },
      {
        value: 'lesser_or_equal_than',
        label: t('comparisonType.lesser_or_equal_than'),
      },
      { value: 'in', label: t('comparisonType.in') },
      { value: 'not_in', label: t('comparisonType.not_in') },
      { value: 'contains', label: t('comparisonType.contains') },
      { value: 'starts_with', label: t('comparisonType.starts_with') },
      { value: 'ends_with', label: t('comparisonType.ends_with') },
      { value: 'is_blank', label: t('comparisonType.is_blank') },
      { value: 'is_not_blank', label: t('comparisonType.is_not_blank') },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(ComparisonTypeSelector);
