import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncCreatableSelect from 'react-select/async-creatable';

import CreateScheduleRouteContainer from '@containers/scheduleRoute/crud/CreateScheduleRouteContainer';

import * as navigationActions from '@actions/navigationActions';
import * as scheduleRouteActions from '@actions/scheduleRouteActions';
import { search as searchScheduleRoutes } from '@api/scheduleRouteApi';

const transformScheduleRouteToValue = (scheduleRoute) => ({
  id: scheduleRoute.id,
  value: scheduleRoute.id,
  label: `${scheduleRoute.name}`,
  scheduleRoute: { ...scheduleRoute },
});

class ScheduleRouteInput extends Component {
  state = {
    scheduleRoute: this.props.value
      ? transformScheduleRouteToValue(this.props.value)
      : null,
  };

  onChange = (newScheduleRoute) => {
    this.setState({
      scheduleRoute: newScheduleRoute,
    });
    const { onChange } = this.props;
    onChange &&
      onChange(newScheduleRoute ? newScheduleRoute.scheduleRoute : null);
  };

  render() {
    const { t, addToStack } = this.props;
    const { scheduleRoute } = this.state;
    return (
      <div className="input-group no-margin-top">
        <div className="input-group no-margin-top">
          <AsyncCreatableSelect
            isClearable
            noOptionsMessage={() => t('scheduleRoutees.notFound')}
            placeholder={t('form.label.selectScheduleRoute')}
            loadingMessage={() => t('loading')}
            loadOptions={(inputValue, callback) => {
              if (this.searchTimeout) clearTimeout(this.searchTimeout);
              this.searchTimeout = setTimeout(() => {
                searchScheduleRoutes({ query: inputValue }, 0, 40).then(
                  (response) => {
                    callback(
                      response.scheduleRoutees.map((scheduleRoute) =>
                        transformScheduleRouteToValue(scheduleRoute)
                      )
                    );
                  }
                );
              }, 400);
            }}
            value={scheduleRoute}
            onChange={(e) => this.onChange(e)}
            onCreateOption={(inputValue) => {
              addToStack({
                name: t('scheduleRoute.new'),
                component: (
                  <CreateScheduleRouteContainer
                    targetName={inputValue}
                    callback={(data) => {
                      const newScheduleRoute = {
                        id: data.scheduleRoute.id,
                        value: data.scheduleRoute.id,
                        label: data.scheduleRoute.name,
                        scheduleRoute: { ...data.scheduleRoute },
                      };
                      this.setState({
                        scheduleRoute: newScheduleRoute,
                      });

                      this.onChange(newScheduleRoute);
                    }}
                  />
                ),
              });
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.scheduleRoute,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(scheduleRouteActions.changeCreateScheduleRoute(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ScheduleRouteInput));
