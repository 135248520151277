import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import CloseButton from '@uicomponents/CloseButton';

import Module from '@models/pricing/PricingModule';

import { formatAmount } from '@utils/moneyUtils';

import PricingModuleFormPage from './PricingModuleFormPage';

class PricingModulesInput extends Component {
  state = {
    modules: this.props.modules || [new Module()],
  };

  addNewModule = () => {
    const { modules } = this.state;
    this.setState({
      modules: [...modules, new Module()],
    });
  };

  updateModule = (newModule, index) => {
    const { onChange } = this.props;

    const { modules } = this.state;
    const newModules = [...modules];
    newModules[index] = newModule;

    this.setState({
      modules: newModules,
    });

    onChange?.(newModules);
  };

  render() {
    const {
      t,
      user,
      addToStack,
      popStack,
      onChange,
      platform,
      dataTypes,
      currencies,
      pricingModuleSubUnits,
      entityType,
      taxRates,
      pricingCategories,
      pricingEntityTypes,
      pricingModuleTypes,
    } = this.props;
    const { modules } = this.state;

    return (
      <>
        {modules.map((module, index) => (
          <div
            className="list-sector pricing-form-element"
            key={`input-pricing-${index}-${module.id}-${module.nonce}`}
            onClick={(e) => {
              e.preventDefault();
              addToStack({
                name: t('pricing.module.header', { count: index + 1 }),
                component: (
                  <PricingModuleFormPage
                    platform={platform}
                    popStack={popStack}
                    addToStack={addToStack}
                    currencies={currencies}
                    pricingModuleSubUnits={pricingModuleSubUnits}
                    entityType={entityType}
                    user={user}
                    taxRates={taxRates}
                    pricingEntityTypes={pricingEntityTypes}
                    pricingModuleTypes={pricingModuleTypes}
                    headerName={t('pricing.module.header', {
                      count: index + 1,
                    })}
                    module={{ ...module, sequenceNr: index + 1 }}
                    dataTypes={dataTypes}
                    pricingCategories={pricingCategories}
                    onChange={(newModule) => {
                      this.updateModule(newModule, index);
                    }}
                  />
                ),
              });
            }}
          >
            <div className="flex-container justify-between">
              <h2>
                {t('pricing.module.header', { count: index + 1 })}{' '}
                {module.name ? ` - ${module.name}` : null}
              </h2>

              <div className="list-actions">
                <div></div>
                <ReactSVG
                  src="/icons/duplicate.svg"
                  className="close-button"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const newModules = [...modules];
                    newModules.splice(index, 0, { ...module, id: null, nonce: null });

                    this.setState({ modules: newModules });
                    onChange?.(newModules);
                  }}
                />
                <CloseButton
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();

                    const newModules = [...modules];
                    newModules.splice(index, 1);

                    this.setState({ modules: newModules });
                    onChange?.(newModules);
                  }}
                />
                <div className="go">
                  <ReactSVG src="/icons/back.svg" />
                </div>
              </div>
            </div>
            <div>
              <div className="flex-container value-list">
                {module.type && (
                  <p className="no-margin-bottom value-list-item">
                    <b>{t('pricing.module.type')}:</b> {module.type}
                  </p>
                )}
                {module.targetEntityType && (
                  <p className="no-margin-bottom value-list-item">
                    <b>{t('pricing.module.type')}:</b> {module.targetEntityType}
                  </p>
                )}
                {module.amount && (
                  <p className="no-margin-bottom value-list-item">
                    <b>{t('pricing.module.amount')}:</b>{' '}
                    {formatAmount(
                      Number(module.amount),
                      2,
                      user?.language?.decimalDelimiter || '.',
                      user?.language?.thousandDelimiter || ','
                    )}
                  </p>
                )}
              </div>
              {module.description && (
                <p className="no-margin">
                  <b>{t('pricing.module.description')}:</b> {module.description}
                </p>
              )}
            </div>
          </div>
        ))}
        <div className="input-group left">
          <button type="button" onClick={(e) => this.addNewModule(e)}>
            {t('form.label.addPricingModule')}
          </button>
        </div>
      </>
    );
  }
}

export default withTranslation('translation')(PricingModulesInput);
