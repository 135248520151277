export const scheduleRouteConstants = {
  SCHEDULEROUTE_CHANGE_VALUE: 'SCHEDULEROUTE_CHANGE_VALUE',

  GET_SCHEDULEROUTE_STARTED: 'GET_SCHEDULEROUTE_STARTED',
  GET_SCHEDULEROUTE_SUCCESS: 'GET_SCHEDULEROUTE_SUCCESS',
  GET_SCHEDULEROUTE_FAILURE: 'GET_SCHEDULEROUTE_FAILURE',

  GET_SCHEDULEROUTES_STARTED: 'GET_SCHEDULEROUTES_STARTED',
  GET_SCHEDULEROUTES_SUCCESS: 'GET_SCHEDULEROUTES_SUCCESS',
  GET_SCHEDULEROUTES_FAILURE: 'GET_SCHEDULEROUTES_FAILURE',

  SEARCH_SCHEDULEROUTES_STARTED: 'SEARCH_SCHEDULEROUTES_STARTED',
  SEARCH_SCHEDULEROUTES_SUCCESS: 'SEARCH_SCHEDULEROUTES_SUCCESS',
  SEARCH_SCHEDULEROUTES_FAILURE: 'SEARCH_SCHEDULEROUTES_FAILURE',

  CREATING_SCHEDULEROUTE_STARTED: 'CREATING_SCHEDULEROUTE_STARTED',
  CREATING_SCHEDULEROUTE_SUCCESS: 'CREATING_SCHEDULEROUTE_SUCCESS',
  CREATING_SCHEDULEROUTE_FAILURE: 'CREATING_SCHEDULEROUTE_FAILURE',

  UPDATING_SCHEDULEROUTE_STARTED: 'UPDATING_SCHEDULEROUTE_STARTED',
  UPDATING_SCHEDULEROUTE_SUCCESS: 'UPDATING_SCHEDULEROUTE_SUCCESS',
  UPDATING_SCHEDULEROUTE_FAILURE: 'UPDATING_SCHEDULEROUTE_FAILURE',

  DELETE_SCHEDULEROUTE_STARTED: 'DELETE_SCHEDULEROUTE_STARTED',
  DELETE_SCHEDULEROUTE_SUCCESS: 'DELETE_SCHEDULEROUTE_SUCCESS',
  DELETE_SCHEDULEROUTE_FAILURE: 'DELETE_SCHEDULEROUTE_FAILURE',
};
