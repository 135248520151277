import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

class IntegrationHtmlView extends Component {
  render() {
    const { html } = this.props;
    return (
      <div className="html-view scrollable">
        <pre>{html}</pre>
      </div>
    );
  }
}

export default withTranslation('translation')(IntegrationHtmlView);
