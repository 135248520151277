import * as todoApi from '../../api/todoApi';
import { todoConstants } from '../constants';

/**
 * Todo Actions
 */

export const todoActionStarted = () => ({
  type: todoConstants.TODO_ACTION_STARTED,
});

export const todoActionFailed = (error) => ({
  type: todoConstants.TODO_ACTION_FAILED,
  ...error,
});

/**
 * Creating Todos
 */

export const createTodoSuccess = (result, todo) => ({
  type: todoConstants.CREATE_TODO_SUCCESS,
  todo: todo,
  ...result,
});

export const createTodo = (laneId, todo) => {
  return (dispatch) => {
    dispatch(todoActionStarted());

    const promise = todoApi.createTodo(laneId, todo);
    promise
      .then((result) => {
        dispatch(createTodoSuccess(result.lane, todo));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};

/**
 * Update Todo
 */

export const updateTodoSuccess = (result, previousLane) => ({
  type: todoConstants.UPDATING_TODO_SUCCESS,
  previousLane: previousLane,
  ...result,
});

export const updateTodo = (todo, previousLane) => {
  return (dispatch) => {
    dispatch(todoActionStarted());

    const promise = todoApi.updateTodo(todo);
    promise
      .then((result) => {
        dispatch(updateTodoSuccess(result, previousLane));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};

export const updateTodoPosition = (lane, todo, position) => {
  return (dispatch) => {
    dispatch(todoActionStarted());

    const promise = todoApi.updateTodoPosition(lane, todo, position);
    promise
      .then((result) => {
        dispatch(updateTodoSuccess(result, todo));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};

/* Delete ToDo */
export const deleteTodoStarted = () => ({
  type: todoConstants.DELETE_TODO_STARTED,
});

export const deleteTodoSuccess = (result) => ({
  type: todoConstants.DELETE_TODO_SUCCESS,
  ...result,
});

export const deleteTodoFailure = (error) => ({
  type: todoConstants.DELETE_TODO_FAILURE,
  ...error,
});

export const deleteTodo = (todo) => {
  return (dispatch) => {
    dispatch(deleteTodoStarted());

    const promise = todoApi.deleteTodo(todo);
    promise
      .then((result) => {
        dispatch(deleteTodoSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteTodoFailure(error));
      });
    return promise;
  };
};

/* Get ToDo */

export const getTodoStarted = () => ({
  type: todoConstants.GET_TODO_STARTED,
});

export const getTodoSuccess = (result) => ({
  type: todoConstants.GET_TODO_SUCCESS,
  ...result,
});

export const getTodoFailure = (error) => ({
  type: todoConstants.GET_TODO_FAILURE,
  ...error,
});

export const getTodo = (todo) => {
  return (dispatch) => {
    dispatch(getTodoStarted());

    const promise = todoApi.getTodo(todo);
    promise
      .then((result) => {
        dispatch(getTodoSuccess(result));
      })
      .catch((error) => {
        dispatch(getTodoFailure(error));
      });

    return promise;
  };
};

/* Get All ToDos */

export const getTodosStarted = (from) => ({
  type: todoConstants.GET_TODOS_STARTED,
  from: from,
});

export const getTodosSuccess = (result, from) => ({
  type: todoConstants.GET_TODOS_SUCCESS,
  from: from,
  ...result,
});

export const getTodos = (offset, amount) => {
  return (dispatch) => {
    dispatch(getTodosStarted(offset));

    const promise = todoApi.getTodos(offset, amount);
    promise
      .then((result) => {
        dispatch(getTodosSuccess(result, offset));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};

/* Get User's ToDos */

export const getUserTodos = (user, offset, amount) => {
  return (dispatch) => {
    dispatch(getTodosStarted(offset));

    const promise = todoApi.getUserTodos(user, offset, amount);

    promise
      .then((result) => {
        dispatch(getTodosSuccess(result, offset));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });

    return promise;
  };
};

/* Get All ToDos in Date Range */

export const getTodosInDateRange = (fromDate, toDate) => {
  return (dispatch) => {
    dispatch(getTodosStarted());

    const promise = todoApi.getTodosInDateRange(fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getTodosSuccess(result));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });

    return promise;
  };
};

/* Get User's ToDos in Date Range */

export const getUserTodosInDateRange = (user, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(getTodosStarted());

    const promise = todoApi.getUserTodosInDateRange(user, fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getTodosSuccess(result));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });

    return promise;
  };
};

/* Get ToDos Associated with an Entity */

export const getEntityTodosStarted = (from) => ({
  type: todoConstants.GET_ENTITY_TODOS_STARTED,
  from: from,
});

export const getEntityTodosSuccess = (result, from) => ({
  type: todoConstants.GET_ENTITY_TODOS_SUCCESS,
  from: from,
  ...result,
});

export const getEntityTodos = (entityType, entityId, offset, amount) => {
  return (dispatch) => {
    dispatch(getEntityTodosStarted(offset));

    const promise = todoApi.getEntityTodos(entityType, entityId, offset, amount);

    promise
      .then((result) => {
        dispatch(getEntityTodosSuccess(result, offset));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });

    return promise;
  };
};

/* Search ToDo */
export const searchTodos = (searchParameters, from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(getTodosStarted(from));

    const promise = todoApi.searchTodos(searchParameters, from, amount);
    promise
      .then((result) => {
        dispatch(getTodosSuccess(result, from));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });

    return promise;
  };
};

/* Get ToDo Statistics */
export const getTodoStatisticsSuccess = (result) => ({
  type: todoConstants.GET_TODO_STATISTICS_SUCCESS,
  ...result,
});

export const getTodoStatistics = () => {
  return (dispatch) => {
    dispatch(todoActionStarted());

    const promise = todoApi.getTodoStatistics();
    promise
      .then((result) => {
        dispatch(getTodoStatisticsSuccess(result));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};

export const getUserTodoStatistics = (user) => {
  return (dispatch) => {
    dispatch(todoActionStarted());

    const promise = todoApi.getUserTodoStatistics(user);
    promise
      .then((result) => {
        dispatch(getTodoStatisticsSuccess(result));
      })
      .catch((error) => {
        dispatch(todoActionFailed(error));
      });
    return promise;
  };
};
