import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import VehicleTripsView from '@components/vehicle/VehicleTripsView';

import * as navigationActions from '@actions/navigationActions';
import * as tripActions from '@actions/tripActions';
import * as vehicleActions from '@actions/vehicleActions';

class VehicleTripsContainer extends PureComponent {
  render() {
    const { vehicle } = this.props;
    return <VehicleTripsView key={vehicle.id} {...this.props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.vehicle,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getVehicle: (id) => dispatch(vehicleActions.getVehicle(id)),
    getExtendedVehicle: (id) => dispatch(vehicleActions.getExtendedVehicle(id)),
    getVehicleActiveTrips: (id, from, to) =>
      dispatch(vehicleActions.getVehicleActiveTrips(id, from, to)),
    getVehicleHistoryTrips: (id, from, to) =>
      dispatch(vehicleActions.getVehicleHistoryTrips(id, from, to)),
    updateVehicleTripsOrder: (id, trip1, trip2) =>
      dispatch(vehicleActions.updateVehicleTripsOrder(id, trip1, trip2)),

    startTrip: (trip) => dispatch(tripActions.startTrip(trip)),
    finishTrip: (trip) => dispatch(tripActions.finishTrip(trip)),
    finishAndStartNewTrip: (trip, newTrip) =>
      dispatch(tripActions.finishAndStartNewTrip(trip, newTrip)),
    cancelTrip: (trip) => dispatch(tripActions.cancelTrip(trip)),
    updateTripActionLifeCycle: (trip, action, lifeCycle) =>
      dispatch(tripActions.updateTripActionLifeCycle(trip, action, lifeCycle)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(VehicleTripsContainer));
