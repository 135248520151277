export const pricingConstants = {
  PRICING_CHANGE_VALUE: 'PRICING_CHANGE_VALUE',

  GET_PRICING_STARTED: 'GET_PRICING_STARTED',
  GET_PRICING_SUCCESS: 'GET_PRICING_SUCCESS',
  GET_PRICING_FAILURE: 'GET_PRICING_FAILURE',

  GET_PRICINGS_STARTED: 'GET_PRICINGS_STARTED',
  GET_PRICINGS_SUCCESS: 'GET_PRICINGS_SUCCESS',
  GET_PRICINGS_FAILURE: 'GET_PRICINGS_FAILURE',

  SEARCH_PRICINGS_STARTED: 'SEARCH_PRICINGS_STARTED',
  SEARCH_PRICINGS_SUCCESS: 'SEARCH_PRICINGS_SUCCESS',
  SEARCH_PRICINGS_FAILURE: 'SEARCH_PRICINGS_FAILURE',

  CREATING_PRICING_STARTED: 'CREATING_PRICING_STARTED',
  CREATING_PRICING_SUCCESS: 'CREATING_PRICING_SUCCESS',
  CREATING_PRICING_FAILURE: 'CREATING_PRICING_FAILURE',

  UPDATING_PRICING_STARTED: 'UPDATING_PRICING_STARTED',
  UPDATING_PRICING_SUCCESS: 'UPDATING_PRICING_SUCCESS',
  UPDATING_PRICING_FAILURE: 'UPDATING_PRICING_FAILURE',

  DELETE_PRICING_STARTED: 'DELETE_PRICING_STARTED',
  DELETE_PRICING_SUCCESS: 'DELETE_PRICING_SUCCESS',
  DELETE_PRICING_FAILURE: 'DELETE_PRICING_FAILURE',
};
