import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import CapacityContract from '@models/capacityContract/capacityContract';

import CapacityContractForm from './CapacityContractForm';

export default class CreateCapacityContractPage extends Component {
  componentDidMount() {
    const { onChange, targetName } = this.props;
    const newCapacityContract = new CapacityContract('latLonPointGeoReference');
    newCapacityContract.name = targetName || '';
    onChange('formCapacityContract', newCapacityContract);
  }

  render() {
    const {
      t,
      formCapacityContract,
      isFetchingCapacityContract,
      createCapacityContract,
    } = this.props;

    if (!formCapacityContract || isFetchingCapacityContract) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('capacityContract.new')}</h1>
        <CapacityContractForm
          {...this.props}
          key={formCapacityContract.id}
          onSubmit={(capacityContract) =>
            createCapacityContract(capacityContract)
          }
        />
      </AsideView>
    );
  }
}
