import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import AsideView from '@uicomponents/AsideView';

import PricingTaxRateForm from './PricingTaxRateForm';

class PricingTaxRateFormPage extends Component {
  render() {
    const { t, headerName } = this.props;
    return (
      <AsideView>
        <div className="step-form">
          <form className="form active no-padding-top">
            <h1 className="center">
              {headerName ? headerName : t('pricing.taxrate.create.header')}
            </h1>
            <PricingTaxRateForm {...this.props} />
          </form>
        </div>
      </AsideView>
    );
  }
}
export default withTranslation('translation')(PricingTaxRateFormPage);
