import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateBusinessPage from '@components/business/crud/UpdateBusinessPage';

import * as fileApi from '@api/fileApi';

import * as businessActions from '@actions/businessActions';
import * as navigationActions from '@actions/navigationActions';

class UpdateBusinessContainer extends PureComponent {
  render() {
    const { changeValue, updateBusiness, popStack, ...props } = this.props;

    return (
      <UpdateBusinessPage
        {...props}
        popStack={popStack}
        onChange={changeValue}
        updateBusiness={(business) => updateBusiness(business)}
        uploadFile={(file, purpose) => fileApi.uploadFile(file, purpose)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    industries: state.platform.industries,
    ...state.business,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(businessActions.changeUpdateBusiness(name, value)),
    updateBusiness: (business) =>
      dispatch(businessActions.updateBusiness(business)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateBusinessContainer));
