import { fleetConstants } from '../constants/fleet.constants';

const initialState = {
  isFetchingFleet: false,
  formFleet: null,
  fleet: null,

  isFetchingFleets: false,
  isFetchingFleetsFrom: 0,
  fleetsTotalResults: 0,
  fleetsLoaded: 0,
  fleets: [],

  isFetchingFleetsLight: false,
  fleetsLight: [],
};

export default function fleet(state = initialState, action) {
  switch (action.type) {
    case fleetConstants.FLEET_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case fleetConstants.GET_FLEETS_STARTED:
      return {
        ...state,
        isFetchingFleets: true,
        isFetchingFleetsFrom: action.from || 0,
      };
    case fleetConstants.GET_FLEETS_FAILURE:
      return { ...state, isFetchingFleets: false, error: action.message || '' };
    case fleetConstants.GET_FLEETS_SUCCESS:
      return {
        ...state,
        isFetchingFleets: false,
        fleets:
          action.from > 0 ? [...state.fleets, ...action.fleets] : action.fleets,
        fleetsLoaded:
          action.from > 0
            ? state.fleets.length + action.fleets.length
            : action.fleets.length,
        fleetsTotalResults: action.totalResults || 0,
      };

    case fleetConstants.GET_FLEETS_LIGHT_STARTED:
      return { ...state, isFetchingFleetsLight: true };
    case fleetConstants.GET_FLEETS_LIGHT_FAILURE:
      return {
        ...state,
        isFetchingFleetsLight: false,
        error: action.message || '',
      };
    case fleetConstants.GET_FLEETS_LIGHT_SUCCESS:
      return {
        ...state,
        isFetchingFleetsLight: false,
        fleetsLight: action.fleets || [],
      };

    case fleetConstants.SEARCH_FLEETS_STARTED:
      return {
        ...state,
        isFetchingFleets: true,
        isFetchingFleetsFrom: action.from || 0,
      };
    case fleetConstants.SEARCH_FLEETS_FAILURE:
      return { ...state, isFetchingFleets: false, error: action.message || '' };
    case fleetConstants.SEARCH_FLEETS_SUCCESS:
      return {
        ...state,
        isFetchingFleets: false,
        fleets:
          action.from > 0 ? [...state.fleets, ...action.fleets] : action.fleets,
        fleetsLoaded:
          action.from > 0
            ? state.fleets.length + action.fleets.length
            : action.fleets.length,
        fleetsTotalResults: action.totalResults || 0,
      };

    case fleetConstants.CREATING_FLEET_STARTED:
      return { ...state, isFetchingFleet: true };
    case fleetConstants.CREATING_FLEET_FAILURE:
      return { ...state, isFetchingFleet: false, error: action.message || '' };
    case fleetConstants.CREATING_FLEET_SUCCESS:
      return {
        ...state,
        isFetchingFleet: false,
        fleet: action.fleet,
        fleets: [action.fleet, ...state.fleets],
      };

    case fleetConstants.UPDATING_FLEET_STARTED:
      return { ...state, isFetchingFleet: true };
    case fleetConstants.UPDATING_FLEET_FAILURE:
      return { ...state, isFetchingFleet: false, error: action.message || '' };
    case fleetConstants.UPDATING_FLEET_SUCCESS:
      return {
        ...state,
        isFetchingFleet: false,
        fleet: action.fleet,
        fleets: [...state.fleets].map((fleet) => {
          if (action.fleet.id === fleet.id) {
            return action.fleet;
          }
          return fleet;
        }),
      };

    case fleetConstants.DELETE_FLEET_STARTED:
      return { ...state, isFetchingFleet: true };
    case fleetConstants.DELETE_FLEET_FAILURE:
      return {
        ...state,
        isFetchingFleet: false,
        message: action.message || '',
      };
    case fleetConstants.DELETE_FLEET_SUCCESS:
      return {
        ...state,
        isFetchingFleet: false,
        fleet: action.fleet,
        fleets: [...state.fleets].filter(
          (fleet) => action.fleet.id !== fleet.id
        ),
      };
    default:
      return state;
  }
}
