import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class ValueTypeSelector extends Component {
  render() {
    const { t, onChange, placeholder, value } = this.props;
    const options = [
      { value: 'string', label: t('valueType.string') },
      { value: 'array', label: t('valueType.array') },
      { value: 'object', label: t('valueType.object') },
      { value: 'filters', label: t('valueType.filters') },
      { value: 'boolean', label: t('valueType.boolean') },
      { value: 'datetime', label: t('valueType.datetime') },
      { value: 'date', label: t('valueType.date') },
      { value: 'time', label: t('valueType.time') },
      { value: 'float', label: t('valueType.float') },
    ];
    return (
      <Select
        value={options.find((option) => option.value === value)}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}

export default withTranslation('translation')(ValueTypeSelector);
