import { chauffeurConstants } from '@constants';

import * as chauffeurApi from '@api/chauffeurApi';

export const changeValue = (name, value) => ({
  type: chauffeurConstants.CHAUFFEUR_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching chauffeur
 */
export const startGetChauffeur = () => ({
  type: chauffeurConstants.GET_CHAUFFEUR_STARTED,
});

export const getChauffeurSuccess = (result) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_SUCCESS,
  ...result,
});

export const getChauffeurFailure = (error) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_FAILURE,
  ...error,
});

export const getChauffeur = (id) => {
  return (dispatch) => {
    dispatch(startGetChauffeur());

    const promise = chauffeurApi.get(id);

    promise
      .then((result) => {
        dispatch(getChauffeurSuccess(result));
      })
      .catch((error) => {
        dispatch(getChauffeurFailure(error));
      });

    return promise;
  };
};

/**
 * Creating chauffeur
 */
export const startCreateChauffeur = () => ({
  type: chauffeurConstants.CREATING_CHAUFFEUR_STARTED,
});

export const createChauffeurSuccess = (result) => ({
  type: chauffeurConstants.CREATING_CHAUFFEUR_SUCCESS,
  ...result,
});

export const createChauffeurFailure = (error) => ({
  type: chauffeurConstants.CREATING_CHAUFFEUR_FAILURE,
  ...error,
});

export const createChauffeur = (chauffeur) => {
  return (dispatch) => {
    dispatch(startCreateChauffeur());

    const promise = chauffeurApi.create(chauffeur);

    promise
      .then((result) => {
        dispatch(createChauffeurSuccess(result));
      })
      .catch((error) => {
        dispatch(createChauffeurFailure(error));
      });

    return promise;
  };
};

/**
 * Updating chauffeur
 */
export const startUpdateChauffeur = () => ({
  type: chauffeurConstants.UPDATING_CHAUFFEUR_STARTED,
});

export const updateChauffeurSuccess = (result) => ({
  type: chauffeurConstants.UPDATING_CHAUFFEUR_SUCCESS,
  ...result,
});

export const updateChauffeurFailure = (error) => ({
  type: chauffeurConstants.UPDATING_CHAUFFEUR_FAILURE,
  ...error,
});

export const updateChauffeur = (chauffeur) => {
  return (dispatch) => {
    dispatch(startUpdateChauffeur());

    const promise = chauffeurApi.update(chauffeur);

    promise
      .then((result) => {
        dispatch(updateChauffeurSuccess(result));
      })
      .catch((error) => {
        dispatch(updateChauffeurFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting chauffeur
 */
export const startDeleteChauffeur = () => ({
  type: chauffeurConstants.DELETE_CHAUFFEUR_STARTED,
});

export const deleteChauffeurSuccess = (result) => ({
  type: chauffeurConstants.DELETE_CHAUFFEUR_SUCCESS,
  ...result,
});

export const deleteChauffeurFailure = (error) => ({
  type: chauffeurConstants.DELETE_CHAUFFEUR_FAILURE,
  ...error,
});

export const deleteChauffeur = (chauffeur) => {
  return (dispatch) => {
    dispatch(startDeleteChauffeur());

    const promise = chauffeurApi.remove(chauffeur);

    promise
      .then((result) => {
        dispatch(deleteChauffeurSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteChauffeurFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching extendedChauffeurs
 */
export const startGetExtendedChauffeurs = (from) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_STARTED,
  from: from,
});

export const getExtendedChauffeursSuccess = (result, from) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_SUCCESS,
  from: from,
  ...result,
});

export const getExtendedChauffeursFailure = (error) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_FAILURE,
  ...error,
});

export const getExtendedChauffeurs = (from = 0, amount = 20, payload = []) => {
  return (dispatch) => {
    dispatch(startGetExtendedChauffeurs(from));

    const promise = chauffeurApi.extended(from, amount);

    promise
      .then(async (result) => {
        dispatch(getExtendedChauffeursSuccess(result, from));
      })
      .catch((error) => {
        console.error(error);
        dispatch(getExtendedChauffeursFailure(error));
      });

    return promise;
  };
};

/**
 * Search extended chauffeurs
 */

export const searchExtendedChauffeurs = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetExtendedChauffeurs(from));

    const promise = chauffeurApi.searchExtended(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getExtendedChauffeursSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getExtendedChauffeursFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching chauffeurs
 */
export const startGetChauffeurs = (from) => ({
  type: chauffeurConstants.GET_CHAUFFEURS_STARTED,
  from: from,
});

export const getChauffeursSuccess = (result, from) => ({
  type: chauffeurConstants.GET_CHAUFFEURS_SUCCESS,
  from: from,
  ...result,
});

export const getChauffeursFailure = (error) => ({
  type: chauffeurConstants.GET_CHAUFFEURS_FAILURE,
  ...error,
});

export const getChauffeurs = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetChauffeurs(from));

    const promise = chauffeurApi.latest(from, amount);

    promise
      .then(async (result) => {
        dispatch(getChauffeursSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getChauffeursFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching chauffeur trips
 */

export const getChauffeurTripsSuccess = (result) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_TRIPS_SUCCESS,
  ...result,
});

export const getChauffeurTrips = (chauffeur, fromDate, toDate) => {
  return async (dispatch) => {
    let updatedChauffeur = await chauffeurApi
      .timeslots(chauffeur.id, fromDate, toDate)
      .then((result) => ({
        ...chauffeur,
        timeslots: result.chauffeurTimeslots,
      }));

    await chauffeurApi.getChauffeurTrips(chauffeur.id, fromDate, toDate).then((result) =>
      dispatch(
        getChauffeurTripsSuccess({
          ...result,
          chauffeur: { ...updatedChauffeur, trips: result.trips },
        })
      )
    );
  };
};

/**
 * Fetching chauffeur timeslots
 */

export const getChauffeurTimeslotsSuccess = (result) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_TIMESLOTS_SUCCESS,
  ...result,
});

export const getChauffeurTimeslots = (chauffeur, fromDate, toDate) => {
  return async (dispatch) => {
    await chauffeurApi.timeslots(chauffeur.id, fromDate, toDate).then((result) =>
      dispatch(
        getChauffeurTimeslotsSuccess({
          ...result,
          chauffeur: { ...chauffeur, timeslots: result.chauffeurTimeslots },
        })
      )
    );
  };
};

/**
 * Search chauffeurs
 */

export const searchChauffeurs = (searchParameters = {}, from = 0, amount = 20, timeslots) => {
  return (dispatch) => {
    dispatch(startGetChauffeurs(from));

    const promise = chauffeurApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getChauffeursSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getChauffeursFailure(error));
      });

    return promise;
  };
};

/**
 * Get Activities
 */

export const startGetChauffeurActivities = (from) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_STARTED,
  from: from,
});

export const getChauffeurActivitiesSuccess = (result, from) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_SUCCESS,
  from: from,
  ...result,
});

export const getChauffeurActivitiesFailure = (error) => ({
  type: chauffeurConstants.GET_CHAUFFEUR_ACTIVITIES_FAILURE,
  ...error,
});

export const getChauffeurActivities = (chauffeur, fromDate, endDate) => {
  return (dispatch) => {
    dispatch(startGetChauffeurActivities());

    const promise = chauffeurApi.getActivities(chauffeur, fromDate, endDate);

    promise
      .then((result) => {
        dispatch(getChauffeurActivitiesSuccess(result));
      })
      .catch((error) => {
        dispatch(getChauffeurActivitiesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching extendedChauffeurs Trips
 */
export const startGetExtendedChauffeursTrips = () => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_STARTED,
});

export const getExtendedChauffeursTripsSuccess = (result) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_SUCCESS,
  ...result,
});

export const getExtendedChauffeursTripsFailure = (error) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TRIPS_FAILURE,
  ...error,
});

export const getExtendedChauffeurTrips = (chauffeurs, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetExtendedChauffeursTrips());

    const promise = chauffeurApi.getExtendedChauffeurTrips(chauffeurs, fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getExtendedChauffeursTripsSuccess(result));
      })
      .catch((error) => {
        dispatch(getExtendedChauffeursTripsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching extendedChauffeurs Timeslots
 */
export const startGetExtendedChauffeursTimeslots = () => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_STARTED,
});

export const getExtendedChauffeursTimeslotsSuccess = (result) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_SUCCESS,
  ...result,
});

export const getExtendedChauffeursTimeslotsFailure = (error) => ({
  type: chauffeurConstants.GET_EXTENDED_CHAUFFEURS_TIMESLOTS_FAILURE,
  ...error,
});

export const getExtendedChauffeurTimeslots = (chauffeurs, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetExtendedChauffeursTimeslots());

    const promise = chauffeurApi.getExtendedChauffeurTimeslots(chauffeurs, fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getExtendedChauffeursTimeslotsSuccess(result));
      })
      .catch((error) => {
        dispatch(getExtendedChauffeursTimeslotsFailure(error));
      });

    return promise;
  };
};
