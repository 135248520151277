import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Select from 'react-select';

class TriggerEventSelector extends Component {
  render() {
    const { t, onChange, placeholder, value, triggerEvents } = this.props;

    const triggerEventOptions = triggerEvents.map((type) => ({
      value: type.event,
      label: t(`triggerEvent.${type.event}`),
      entity: type.entityType,
    }));
    return (
      <Select
        value={triggerEventOptions.find((option) => option.value === value)}
        options={triggerEventOptions}
        onChange={onChange}
        placeholder={placeholder || t('form.label.selectTriggerEvent')}
        noOptionsMessage={() => t('noOptions')}
      />
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    triggerEvents: state.platform.triggerEvents,
  };
};

export default connect(mapStateToProps)(withTranslation('translation')(TriggerEventSelector));
