import Consignment from '../consignment/Consignment';
import Association from '../general/Association';
import Money from '../general/Money';

export default class TransportOrder {
  id;
  nonce;

  name;
  value;

  constraints;
  notes;
  contactDetails;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);
    this.value = new Money();

    this.consignments = [new Association('inline', new Consignment())];
    this.documents = [];

    this.notes = [];
    this.contactDetails = [];
  }
}
