import '@firebase/messaging';

import firebase from 'firebase/app';

const firebaseApp = firebase.initializeApp({
  apiKey: 'AIzaSyBw1yX06hBk3679WoEOT6CkeGplXNlHoEY',
  authDomain: 'transportial-8642a.firebaseapp.com',
  projectId: 'transportial-8642a',
  storageBucket: 'transportial-8642a.appspot.com',
  messagingSenderId: '260812482484',
  appId: '1:260812482484:web:a38c8a28a78d46c86174e1',
  measurementId: 'G-33QCEKF1KN',
});

const messaging = firebase.messaging.isSupported()
  ? firebaseApp.messaging()
  : null;
export { messaging };
