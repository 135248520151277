import { renderToString } from 'react-dom/server';

import moment from 'moment';
import XLSX from 'xlsx';

/*
   @description uui for object
 */
export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

export const hasParentNode = (element, classNames) => {
  let parentNode = element.parentNode;
  if (parentNode) {
    if (typeof parentNode.className === 'string' || parentNode.className instanceof String) {
      if (parentNode.className.includes(classNames)) {
        return true;
      }
    }
    return hasParentNode(parentNode, classNames);
  }
  return false;
};

export const getContentOnly = (value) => {
  if (typeof value === 'object') {
    return getTextContentOnly(renderToString(value));
  }

  return value;
};

const getTextContentOnly = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT, null, false);
  const texts = [];
  let node;
  while ((node = walker.nextNode())) {
    texts.push(node.nodeValue);
  }
  return texts.join();
};

export const sortObjectKeys = (obj, order) => {
  // order true mean asc, false mean descending
  const sortedKeys = Object.keys(obj)
    .filter((key) => key !== 'subTotal') // Exclude 'subTotal' key from sorting
    .sort((a, b) => {
      return order === true ? a.localeCompare(b) : b.localeCompare(a);
    });

  sortedKeys.push('subTotal'); // Add 'subTotal' key at the end for descending order

  const sortedObject = {};
  sortedKeys.forEach((key) => {
    sortedObject[key] = obj[key];
  });

  return sortedObject;
};

export const downloadXLS = (name, data) => {
  let ws = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'sheet');
  XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' });
  XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  XLSX.writeFile(wb, `${name.toLowerCase()}-${moment().format('DDMMYYYY')}-export.xlsx`);
};
