export const transportEquipmentConstants = {
  TRANSPORT_EQUIPMENT_CHANGE_VALUE: 'TRANSPORT_EQUIPMENT_CHANGE_VALUE',

  GET_TRANSPORT_EQUIPMENT_STARTED: 'GET_TRANSPORT_EQUIPMENT_STARTED',
  GET_TRANSPORT_EQUIPMENT_SUCCESS: 'GET_TRANSPORT_EQUIPMENT_SUCCESS',
  GET_TRANSPORT_EQUIPMENT_FAILURE: 'GET_TRANSPORT_EQUIPMENT_FAILURE',

  GET_TRANSPORT_EQUIPMENTS_STARTED: 'GET_TRANSPORT_EQUIPMENTS_STARTED',
  GET_TRANSPORT_EQUIPMENTS_SUCCESS: 'GET_TRANSPORT_EQUIPMENTS_SUCCESS',
  GET_TRANSPORT_EQUIPMENTS_FAILURE: 'GET_TRANSPORT_EQUIPMENTS_FAILURE',

  GET_EXTENDED_TRANSPORT_EQUIPMENTS_STARTED: 'GET_EXTENDED_TRANSPORT_EQUIPMENTS_STARTED',
  GET_EXTENDED_TRANSPORT_EQUIPMENTS_SUCCESS: 'GET_EXTENDED_TRANSPORT_EQUIPMENTS_SUCCESS',
  GET_EXTENDED_TRANSPORT_EQUIPMENTS_FAILURE: 'GET_EXTENDED_TRANSPORT_EQUIPMENTS_FAILURE',

  GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_STARTED:
    'GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_STARTED',
  GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_SUCCESS:
    'GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_SUCCESS',
  GET_EXTENDED_TRANSPORT_EQUIPMENTS_TRIPS_FAILURE:
    'GET_EXTENDED_TRANSPORT_EQUIPMENTS_FAILURE',

  GET_TRANSPORT_EQUIPMENT_TRIPS_SUCCESS:
    'GET_TRANSPORT_EQUIPMENT_TRIPS_SUCCESS',

  SEARCH_TRANSPORT_EQUIPMENTS_STARTED: 'SEARCH_TRANSPORT_EQUIPMENTS_STARTED',
  SEARCH_TRANSPORT_EQUIPMENTS_SUCCESS: 'SEARCH_TRANSPORT_EQUIPMENTS_SUCCESS',
  SEARCH_TRANSPORT_EQUIPMENTS_FAILURE: 'SEARCH_TRANSPORT_EQUIPMENTS_FAILURE',

  CREATING_TRANSPORT_EQUIPMENT_STARTED: 'CREATING_TRANSPORT_EQUIPMENT_STARTED',
  CREATING_TRANSPORT_EQUIPMENT_SUCCESS: 'CREATING_TRANSPORT_EQUIPMENT_SUCCESS',
  CREATING_TRANSPORT_EQUIPMENT_FAILURE: 'CREATING_TRANSPORT_EQUIPMENT_FAILURE',

  UPDATING_TRANSPORT_EQUIPMENT_STARTED: 'UPDATING_TRANSPORT_EQUIPMENT_STARTED',
  UPDATING_TRANSPORT_EQUIPMENT_SUCCESS: 'UPDATING_TRANSPORT_EQUIPMENT_SUCCESS',
  UPDATING_TRANSPORT_EQUIPMENT_FAILURE: 'UPDATING_TRANSPORT_EQUIPMENT_FAILURE',

  DELETE_TRANSPORT_EQUIPMENT_STARTED: 'DELETE_TRANSPORT_EQUIPMENT_STARTED',
  DELETE_TRANSPORT_EQUIPMENT_SUCCESS: 'DELETE_TRANSPORT_EQUIPMENT_SUCCESS',
  DELETE_TRANSPORT_EQUIPMENT_FAILURE: 'DELETE_TRANSPORT_EQUIPMENT_FAILURE',
};
