import { renderRoutes } from '@routes';
import { lazy } from 'react';

const QuoteContainer = lazy(() => import('@containers/administration/quote/QuoteContainer'));
const PublicQuoteContainer = lazy(() =>
  import('@containers/administration/quote/PublicQuoteContainer')
);

const routes = [
  {
    paths: ['/:id/share/:token'],
    component: PublicQuoteContainer,
    type: 'open',
  },
  {
    paths: ['', '/:id'],
    component: QuoteContainer,
    type: 'portal',
  },
];

const QuoteRoutes = (props) => renderRoutes(props, routes);
export default QuoteRoutes;
