import moment from 'moment';

import Action from '@models/action/Action';
import Constraint from '@models/constraint/Constraint';
import Association from '@models/general/Association';

import { flatten } from '@utils/arrayUtils';

export const getOppositeActionType = (type) => {
  switch (type) {
    case 'unload':
      return 'load';
    case 'load':
      return 'unload';
    case 'attachTransportEquipment':
      return 'detachTransportEquipment';
    case 'detachTransportEquipment':
      return 'attachTransportEquipment';
    default:
      return type;
  }
};

export const updateConsignmentActionsWithSequence = (consignment, actions) => {
  const newConsignment = { ...consignment };
  const sortedActions = [...actions].sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr);

  let sequenceNr = 1;

  newConsignment.actions = [...sortedActions]
    .filter((action) => !action.entity.isChild)
    .map((action) => {
      const newAction = { ...action, entity: { ...action.entity, sequenceNr: sequenceNr } };
      sequenceNr += 1;
      return newAction;
    });

  const updateGoods = (goods, actions, sequenceNr) => {
    if (!Array.isArray(goods)) return [];

    return goods.map((association) => {
      const good = { ...association.entity };
      const newGood = { ...good };

      const newActions = [...actions]
        .filter((action) => action.entity.childOfNonce === (good.id || good.nonce))
        .map((action) => {
          const newAction = { ...action, entity: { ...action.entity, sequenceNr: sequenceNr } };
          sequenceNr += 1;
          return newAction;
        });

      newGood.actions = newActions;

      newGood.containedGoods = updateGoods(newGood.containedGoods, actions, sequenceNr);
      return { ...association, entity: { ...newGood } };
    });
  };

  newConsignment.goods = updateGoods(consignment.goods, sortedActions, sequenceNr);

  return newConsignment;
};

export const updateConsignmentActions = (consignment, actions) => {
  const newConsignment = { ...consignment };
  newConsignment.actions = [...actions].filter((action) => !action.entity.isChild);

  const updateGoods = (goods, actions) => {
    if (goods?.length > 0) {
      return goods.map((association) => {
        const good = { ...association.entity };
        const newGood = { ...good };

        newGood.actions = [...actions].filter((action) => {
          const { childOfNonce } = action.entity || {};
          const goodNonce = good.id || good.nonce;

          if (childOfNonce === undefined || goodNonce === undefined) {
            return false;
          }

          return childOfNonce === goodNonce;
        });

        newGood.containedGoods = updateGoods(newGood.containedGoods || [], actions);
        return { ...association, entity: { ...newGood } };
      });
    }
    return [];
  };

  newConsignment.goods = updateGoods(consignment.goods || [], actions);

  return newConsignment;
};

export const consignmentGetAllChildActions = (consignment) => {
  return consignment?.goods?.length > 0
    ? getActionsFromGoods(consignment?.goods?.map((good) => good.entity))
    : [];
};

export const getActionsFromGoods = (goods) => {
  return flatten(
    goods?.map((good) =>
      flatten([
        ...(good?.actions?.length > 0
          ? good?.actions?.map((action) => {
              const newAction = { ...action };
              newAction.entity = {
                ...newAction.entity,
                isChild: true,
                childOfNonce: good.id || good.nonce,
              };
              return newAction;
            })
          : []),
        ...(good?.containedGoods?.length > 0
          ? getActionsFromGoods(good.containedGoods?.map((good) => good.entity))
          : []),
      ])
    )
  );
};

export const copyToNewAndReverse = (action, index = 0) => ({
  ...action,
  lifeCycle: 'requested',
  id: _,
  nonce: _,
  versionNumber: _,
  sequenceNr: index - 1,
  actions: action.actions.map((innerAction) => ({
    associationType: 'inline',
    entity: {
      ...innerAction.entity,
      type: getOppositeActionType(innerAction.entity.type),
      id: _,
      nonce: _,
      versionNumber: _,
      constraints: innerAction.entity.constraints.map((constraint) => ({
        associationType: 'inline',
        entity: {
          ...constraint.entity,
          id: _,
          nonce: _,
          versionNumber: _,
          value: {
            ...constraint.entity.value,
            id: _,
            nonce: _,
            versionNumber: _,
          },
        },
      })),
    },
  })),
  constraints: action.constraints.map((constraint) => ({
    associationType: 'inline',
    entity: {
      ...constraint.entity,
      id: _,
      nonce: _,
      versionNumber: _,
      value: {
        ...constraint.entity.value,
        id: _,
        nonce: _,
        versionNumber: _,
      },
    },
  })),
});

export const getToBePlannedActions = (consignments) => {
  return flatten(
    consignments.map((consignment, index) => {
      const actions = [
        ...(consignment?.actions || []),
        ...consignmentGetAllChildActions(consignment),
      ].map((action) => {
        const newAction = { ...action };
        const newEntity = { ...newAction.entity };

        newEntity.sequenceNr = index + newEntity?.sequenceNr;
        newAction.entity = newEntity;
        return newAction;
      });

      const goodsActions = flatten(
        consignment?.goods?.map((goods) => {
          const subActions = goods?.entity?.actions?.map((action) => {
            const newAction = { ...action };
            const newEntity = { ...action.entity };

            newEntity.sequenceNr = index + action.entity.sequenceNr;
            newAction.entity = newEntity;
            return newAction;
          });

          if (goods?.entity?.containedGoods) {
            const containedGoodsActions = flatten(
              goods?.entity?.containedGoods?.map((containedGoods) =>
                containedGoods?.entity?.actions?.map((action) => {
                  const newAction = { ...action };
                  const newEntity = { ...action.entity };

                  newEntity.sequenceNr = index + action.entity.sequenceNr;
                  newAction.entity = newEntity;
                  return newAction;
                })
              )
            );

            return [...(subActions || []), ...containedGoodsActions];
          } else {
            return [...(subActions || [])];
          }
        })
      );

      // Combine actions and remove duplicates based on a unique identifier (e.g., action id)
      const allActions = [...actions, ...goodsActions];
      const uniqueActions = Array.from(
        new Map(allActions.map((action) => [action.entity.id, action])).values()
      );

      // add previous location
      if (
        !['accepted', 'confirmed'].includes(consignment.status) &&
        (consignment.lastAction || consignment.lastPlannedAction)
      ) {
        uniqueActions.push({
          associationType: 'inline',
          entity: {
            ...copyToNewAndReverse(consignment.lastAction || consignment.lastPlannedAction, index),
            startTime: null,
            endTime: null,
            lifeCycle: 'requested',
          },
        });
      }

      return uniqueActions;
    })
  )
    .filter((action) => action.entity.lifeCycle === 'requested')
    .sort((a, b) => a.entity.sequenceNr - b.entity.sequenceNr);
};

export const renderActionConstraints = (action, dateFormat) => {
  if (!action) return;

  const startDateTimeConstraint = action.constraints
    .map((association) => association.entity)
    .find((constraint) => constraint.value.type === 'startDateTimeConstraint');
  const endDateTimeConstraint = action.constraints
    .map((association) => association.entity)
    .find((constraint) => constraint.value.type === 'endDateTimeConstraint');

  return (
    <>
      <span>
        {startDateTimeConstraint && startDateTimeConstraint.value
          ? `${moment(startDateTimeConstraint.value.startDateTime).format('DD/MM/YYYY HH:mm')} - `
          : ''}
      </span>
      <span>
        {endDateTimeConstraint && endDateTimeConstraint.value
          ? moment(endDateTimeConstraint.value.endDateTime).format('DD/MM/YYYY HH:mm')
          : ''}
      </span>
    </>
  );
};

export const basicSubAction = () => {
  const startDateTimeConstraint = new Constraint();
  startDateTimeConstraint.value.type = 'startDateTimeConstraint';
  startDateTimeConstraint.value.startDateTime = moment().startOf('day').add(8, 'hour').toDate();

  const endDateTimeConstraint = new Constraint();
  endDateTimeConstraint.value.type = 'endDateTimeConstraint';
  endDateTimeConstraint.value.endDateTime = moment().startOf('day').add(17, 'hour').toDate();

  const genericAction = new Action('genericAction');
  genericAction.constraints[0] = new Association('inline', startDateTimeConstraint);
  genericAction.constraints[1] = new Association('inline', endDateTimeConstraint);

  return genericAction;
};

export const basicAction = (sequenceNr) => {
  const startDateTimeConstraint = new Constraint();
  startDateTimeConstraint.value.type = 'startDateTimeConstraint';
  startDateTimeConstraint.value.startDateTime = moment().startOf('day').add(8, 'hour').toDate();

  const endDateTimeConstraint = new Constraint();
  endDateTimeConstraint.value.type = 'endDateTimeConstraint';
  endDateTimeConstraint.value.endDateTime = moment().startOf('day').add(17, 'hour').toDate();

  const stopAction = new Action('stop', [new Association('inline', basicSubAction())]);
  stopAction.constraints[0] = new Association('inline', startDateTimeConstraint);
  stopAction.constraints[1] = new Association('inline', endDateTimeConstraint);
  stopAction.sequenceNr = sequenceNr || 1;

  return new Association('inline', stopAction);
};
