import api from '../api';

export const getPaginatedBoards = (from, amount) => {
  return api.get(`/boards/${from}/${amount}`);
};

export const searchBoards = (searchParameters = {}, from, amount) => {
  return api.post(`/boards/${from}/${amount}`, searchParameters);
};

export const getBoardById = (id) => {
  return api.get(`/board/${id}`);
};

export const createBoard = (board) => {
  return api.post(`/board`, board);
};

export const updateBoard = (board) => {
  return api.put(`/board/${board.id}`, board);
};

export const deleteBoard = (board) => {
  return api.delete(`/board/${board.id}`);
};
