import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import UpdateLocationPage from '@components/location/crud/UpdateLocationPage.js';

import * as locationActions from '@actions/locationActions';
import * as navigationActions from '@actions/navigationActions';

class UpdateLocationContainer extends PureComponent {
  render() {
    const { changeValue, updateLocation, popStack, callback, ...props } =
      this.props;

    return (
      <UpdateLocationPage
        {...props}
        onChange={changeValue}
        updateLocation={(location) =>
          updateLocation(location).then((response) => {
            popStack?.();
            callback?.(response.location);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,

    ...state.location,

    ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateLocation: (location) =>
      dispatch(locationActions.updateLocation(location)),
    changeValue: (name, value) =>
      dispatch(locationActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(UpdateLocationContainer));
