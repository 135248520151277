export default class Alert {
  id;
  type;

  title;
  description;

  constructor(title, description, type) {
    this.id = Math.random().toString(36).substring(7);

    this.title = title;
    this.description = description;
    this.type = type;
  }
}
