import { eventConstants } from '@constants/event.constants';

const initialState = {
  isFetchingEvent: false,
  formEvent: null,
  event: null,

  isFetchingEvents: false,
  isFetchingEventsFrom: 0,
  eventsTotalResults: 0,
  eventsLoaded: 0,
  events: [],
};

export default function event(state = initialState, action) {
  switch (action.type) {
    case eventConstants.EVENT_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case eventConstants.GET_EVENTS_STARTED:
      return { ...state, isFetchingEvents: true };
    case eventConstants.GET_EVENTS_FAILURE:
      return { ...state, isFetchingEvents: false, error: action.message || '' };
    case eventConstants.GET_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingEvents: false,
        events:
          action.from > 0 ? [...state.events, ...action.events] : action.events,
        eventsLoaded:
          action.from > 0
            ? state.events.length + action.events.length
            : action.events.length,
        eventsTotalResults: action.totalResults || 0,
      };

    case eventConstants.SEARCH_EVENTS_STARTED:
      return { ...state, isFetchingEvents: true };
    case eventConstants.SEARCH_EVENTS_FAILURE:
      return { ...state, isFetchingEvents: false, error: action.message || '' };
    case eventConstants.SEARCH_EVENTS_SUCCESS:
      return {
        ...state,
        isFetchingEvents: false,
        events:
          action.from > 0 ? [...state.events, ...action.events] : action.events,
        eventsLoaded:
          action.from > 0
            ? state.events.length + action.events.length
            : action.events.length,
        eventsTotalResults: action.totalResults || 0,
      };

    case eventConstants.CREATING_EVENT_STARTED:
      return { ...state, isFetchingEvent: true };
    case eventConstants.CREATING_EVENT_FAILURE:
      return { ...state, isFetchingEvent: false, error: action.message || '' };
    case eventConstants.CREATING_EVENT_SUCCESS:
      return {
        ...state,
        isFetchingEvent: false,
        event: action.event,
        events: [action.event, ...state.events],
      };

    case eventConstants.UPDATING_EVENT_STARTED:
      return { ...state, isFetchingEvent: true };
    case eventConstants.UPDATING_EVENT_FAILURE:
      return { ...state, isFetchingEvent: false, error: action.message || '' };
    case eventConstants.UPDATING_EVENT_SUCCESS:
      return {
        ...state,
        isFetchingEvent: false,
        event: action.event,
        events: [...state.events].map((event) => {
          if (action.event.id === event.id) {
            return action.event;
          }
          return event;
        }),
      };

    case eventConstants.DELETE_EVENT_STARTED:
      return { ...state, isFetchingEvent: true };
    case eventConstants.DELETE_EVENT_FAILURE:
      return {
        ...state,
        isFetchingEvent: false,
        message: action.message || '',
      };
    case eventConstants.DELETE_EVENT_SUCCESS:
      return {
        ...state,
        isFetchingEvent: false,
        event: action.event,
        events: [...state.events].filter(
          (event) => action.event.id !== event.id
        ),
      };
    default:
      return state;
  }
}
