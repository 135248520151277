import React, { Component } from 'react';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import MessageTemplate from '@models/messageTemplate/MessageTemplate';

import MessageTemplateForm from './MessageTemplateForm';

export default class CreateMessageTemplatePage extends Component {
  componentDidMount() {
    const { onChange } = this.props;
    onChange('formMessageTemplate', new MessageTemplate());
  }

  render() {
    const {
      t,
      formMessageTemplate,
      isFetchingMessageTemplate,
      createMessageTemplate,
    } = this.props;

    if (!formMessageTemplate || isFetchingMessageTemplate) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('messageTemplate.new')}</h1>
        <MessageTemplateForm
          {...this.props}
          key={formMessageTemplate.id}
          onSubmit={(messageTemplate) => createMessageTemplate(messageTemplate)}
        />
      </AsideView>
    );
  }
}
