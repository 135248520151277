import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactSVG from 'react-svg';

import ContractForm from '@components/contract/crud/ContractForm';

import AsideView from '@uicomponents/AsideView';

import * as contractActions from '@actions/contractActions';
import * as navigationActions from '@actions/navigationActions';

class ContractModule extends Component {
  componentDidMount() {
    const { value, changeValue } = this.props;
    changeValue('formContract', value);
  }

  render() {
    const {
      t,
      type,
      languages,
      onChange,
      changeValue,
      formContract,
      isFetchingContract,
      addToStack,
      popStack,
    } = this.props;

    if (!formContract || isFetchingContract) {
      return null;
    }

    return (
      <div
        className="list-sector pricing-form-element"
        onClick={(e) => {
          e.preventDefault();
          addToStack({
            name: formContract.name,
            component: (
              <AsideView>
                <h1>{t('contract.new')}</h1>
                <ContractForm
                  t={t}
                  type={type}
                  languages={languages}
                  formContract={formContract}
                  onSubmit={(contract) => {
                    changeValue && changeValue('formContract', contract);
                    onChange && onChange(contract);
                    popStack?.();
                  }}
                />
              </AsideView>
            ),
          });
        }}
      >
        <div className="flex-container justify-between">
          <h2>
            {t('contract.header')}
            {formContract.title ? ` - ${formContract.title}` : ''}
          </h2>
          <div className="list-actions">
            <div className="go">
              <ReactSVG src="/icons/back.svg" />
            </div>
          </div>
        </div>
        <div>
          {formContract.description && (
            <p className="no-margin">
              <b>{t('contract.description')}:</b> {formContract.description}
            </p>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    languages: state.platform.languages,

    ...state.contract,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(contractActions.changeValue(name, value)),

    popStack: () => dispatch(navigationActions.popStack()),
    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ContractModule));
