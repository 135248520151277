import React, { Component } from 'react';

import { i18n, store } from '@/index';

import Switch from '@uicomponents/Switch';

import { activateInputs, deactivateInputs } from '@utils/formUtils';

class FormInput extends Component {
  static defaultProps = {
    type: 'text',
  };

  constructor(props) {
    super(props);
    this.state = { hiddenFields: [] };
  }

  componentDidMount() {
    activateInputs();

    const { hiddenTypeFields } = this.props;
    const userHiddenFields = store.getState().settings?.settings?.userInterface?.hiddenFields || [];
    const platformHiddenFields =
      store.getState().platform?.platform?.uiSettings?.hiddenFields || [];

    this.setState({
      hiddenFields: hiddenTypeFields
        ? [...new Set([...hiddenTypeFields, ...userHiddenFields, ...platformHiddenFields])]
        : [...new Set([...userHiddenFields, ...platformHiddenFields])],
    });
  }

  componentDidUpdate(prevProps) {
    activateInputs();

    const { hiddenTypeFields } = this.props;

    // Compare the actual content of hiddenTypeFields instead of just the length
    if (JSON.stringify(prevProps.hiddenTypeFields) !== JSON.stringify(hiddenTypeFields)) {
      const userHiddenFields =
        store.getState().settings?.settings?.userInterface?.hiddenFields || [];
      const platformHiddenFields =
        store.getState().platform?.platform?.uiSettings?.hiddenFields || [];

      this.setState({
        hiddenFields: hiddenTypeFields
          ? [...new Set([...hiddenTypeFields, ...userHiddenFields, ...platformHiddenFields])]
          : [...new Set([...userHiddenFields, ...platformHiddenFields])],
      });
    }
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  handleChange = (e) => {
    const { onChange, setHasChanged } = this.props;
    if (setHasChanged) {
      setHasChanged(true);
    }
    if (onChange) {
      onChange(e);
    } else {
      console.log('missing onChange');
    }
  };

  render() {
    const {
      id,
      max,
      min,
      type,
      name,
      label,
      value,
      onBlur,
      onFocus,
      onChange,
      inputRef,
      readOnly,
      disabled,
      required,
      maxLength,
      className,
      isDisabled,
      placeholder,
      wrapperClass,
      defaultValue,
    } = this.props;
    const { hiddenFields } = this.state;

    return (
      <>
        {!hiddenFields.includes(name || label?.split('.').pop()) && (
          <div className={`input-group${wrapperClass ? ` ${wrapperClass}` : ''}`}>
            {type === 'switch' ? (
              <Switch
                ref={inputRef}
                id={id}
                name={name}
                className={`${className || ''}`}
                checked={value}
                onChange={(e, payload) =>
                  onChange ? onChange(e, payload) : console.log('missing onChange')
                }
              />
            ) : type === 'textarea' ? (
              <textarea
                ref={inputRef}
                id={id}
                name={name}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                maxLength={maxLength}
                placeholder={placeholder || ''}
                className={`${className || ''}`}
                readOnly={readOnly}
                disabled={isDisabled || disabled}
                onChange={(e, payload) =>
                  onChange ? onChange(e, payload) : console.log('missing onChange')
                }
              ></textarea>
            ) : (
              <input
                ref={inputRef}
                id={id}
                max={max}
                min={min}
                name={name}
                value={value}
                onBlur={onBlur}
                onFocus={onFocus}
                readOnly={readOnly}
                type={type || 'text'}
                maxLength={maxLength}
                defaultValue={defaultValue}
                placeholder={placeholder || ''}
                className={`${className || ''}`}
                disabled={isDisabled || disabled}
                onChange={(e, payload) =>
                  onChange ? onChange(e, payload) : console.log('missing onChange')
                }
              />
            )}
            {label && (
              <label>
                {i18n.t(label)}
                {required ? '*' : ''}
              </label>
            )}
          </div>
        )}
      </>
    );
  }
}

export default React.forwardRef((props, ref) => <FormInput {...props} inputRef={ref} />);
