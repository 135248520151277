import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import NoData from '@uicomponents/NoData';

class VehicleCamerasView extends Component {
  render() {
    const { t, vehicle } = this.props;
    const { cameras } = vehicle;

    return (
      <div className="vehicle-cameras scrollable">
        {(cameras && cameras.length > 0) || true ? (
          <>
            {/* {cameras.map((camera) => (
                            <></>
                        ))} */}
            <iframe
              border="0"
              src={`https://telenormic.com/808gps/open/player/video.html?lang=en&devIdno=${vehicle.licensePlate}&jsession=77154d5c345f41e4b23ef417f3265156`}
            />
          </>
        ) : (
          <div className="not-found-action-box">
            <div className="icon">
              <ReactSVG src="/icons/space.svg" />
            </div>
            <NoData>{t('vehicle.cameras.notFound')}</NoData>
          </div>
        )}
      </div>
    );
  }
}
export default withTranslation('translation')(VehicleCamerasView);
