import { tenderConstants } from '@constants/tender.constants';

const initialState = {
  isFetchingTender: false,
  formTender: null,
  tender: null,

  isFetchingTenders: false,
  isFetchingTendersFrom: 0,
  tendersTotalResults: 0,
  tendersLoaded: 0,
  tenders: [],
};

export default function tender(state = initialState, action) {
  switch (action.type) {
    case tenderConstants.TENDER_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case tenderConstants.GET_TENDER_STARTED:
      return { ...state, isFetchingTender: true };
    case tenderConstants.GET_TENDER_FAILURE:
      return { ...state, isFetchingTender: false, error: action.message || '' };
    case tenderConstants.GET_TENDER_SUCCESS:
      return {
        ...state,
        isFetchingTender: false,
        tender: action.tender,
      };

    case tenderConstants.GET_TENDERS_STARTED:
      return {
        ...state,
        isFetchingTenders: true,
        isFetchingTendersFrom: action.from || 0,
      };
    case tenderConstants.GET_TENDERS_FAILURE:
      return {
        ...state,
        isFetchingTenders: false,
        error: action.message || '',
      };
    case tenderConstants.GET_TENDERS_SUCCESS:
      return {
        ...state,
        isFetchingTenders: false,
        tenders:
          action.from > 0
            ? [...state.tenders, ...action.tenders]
            : action.tenders,
        tendersLoaded:
          action.from > 0
            ? state.tenders.length + action.tenders.length
            : action.tenders.length,
        tendersTotalResults: action.totalResults || 0,
      };

    case tenderConstants.SEARCH_TENDERS_STARTED:
      return {
        ...state,
        isFetchingTenders: true,
        isFetchingTendersFrom: action.from || 0,
      };
    case tenderConstants.SEARCH_TENDERS_FAILURE:
      return {
        ...state,
        isFetchingTenders: false,
        error: action.message || '',
      };
    case tenderConstants.SEARCH_TENDERS_SUCCESS:
      return {
        ...state,
        isFetchingTenders: false,
        tenders:
          action.from > 0
            ? [...state.tenders, ...action.tenders]
            : action.tenders,
        tendersLoaded:
          action.from > 0
            ? state.tenders.length + action.tenders.length
            : action.tenders.length,
        tendersTotalResults: action.totalResults || 0,
      };

    case tenderConstants.CREATING_TENDER_STARTED:
      return { ...state, isFetchingTender: true };
    case tenderConstants.CREATING_TENDER_FAILURE:
      return { ...state, isFetchingTender: false, error: action.message || '' };
    case tenderConstants.CREATING_TENDER_SUCCESS:
      return {
        ...state,
        isFetchingTender: false,
        tender: action.tender,
        tenders: [action.tender, ...state.tenders],
      };

    case tenderConstants.UPDATING_TENDER_STARTED:
      return { ...state, isFetchingTender: true };
    case tenderConstants.UPDATING_TENDER_FAILURE:
      return { ...state, isFetchingTender: false, error: action.message || '' };
    case tenderConstants.UPDATING_TENDER_SUCCESS:
      return {
        ...state,
        isFetchingTender: false,
        tender: action.tender,
        tenders: [...state.tenders].map((tender) => {
          if (action.tender.id === tender.id) {
            return action.tender;
          }
          return tender;
        }),
      };

    case tenderConstants.DELETE_TENDER_STARTED:
      return { ...state, isFetchingTender: true };
    case tenderConstants.DELETE_TENDER_FAILURE:
      return {
        ...state,
        isFetchingTender: false,
        message: action.message || '',
      };
    case tenderConstants.DELETE_TENDER_SUCCESS:
      return {
        ...state,
        isFetchingTender: false,
        tender: action.tender,
        tenders: [...state.tenders].filter(
          (tender) => action.tender.id !== tender.id
        ),
      };
    default:
      return state;
  }
}
