export default class PricingElement {
  id;
  nonce;

  columns;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);
  }
}
