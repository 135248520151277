import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import Select from 'react-select';

class PricingModuleTypeSelector extends Component {
  render() {
    const { types, value, onChange, placeholder } = this.props;

    const moduleTypes = types.map((type) => ({
      label: type.type,
      value: type.type,
      type,
    }));
    return (
      <Select
        value={moduleTypes.find((option) => option.value === value)}
        options={moduleTypes}
        onChange={onChange}
        placeholder={placeholder}
      />
    );
  }
}

export default withTranslation('translation')(PricingModuleTypeSelector);
