import React, { Component } from 'react';

import { MapContainer, Marker, Polyline } from 'react-leaflet';
import ReactSVG from 'react-svg';

import { latLngBounds } from 'leaflet';
import PolyUtil from 'polyline-encoded';

import TileLayers from '@uiviews/TileLayers';

import { flatten } from '@utils/arrayUtils';

import DefaultMarker from '../marker/default/DefaultMarker';

export default class MapPeek extends Component {
  state = {
    mouseHasEntered: false,
    map: null,
  };

  static defaultProps = {
    direction: '',
  };

  componentWillUnmount() {
    const { map } = this.state;

    if (map) {
      map.off();
      map.remove();
      this.setState({
        map: null,
      });
    }
  }

  render() {
    const {
      latLonPointGeoReference,
      latLonArrayPointGeoReference,
      direction,
      positions,
      encodedLineString,
    } = this.props;
    const { mouseHasEntered } = this.state;

    const encodedLineStringBounds = [
      ...(encodedLineString
        ?.split(',')
        ?.filter((s) => s)
        ?.map((lineString) => PolyUtil.decode(lineString)) || []),
    ];

    const bounds =
      encodedLineStringBounds?.length > 0
        ? encodedLineStringBounds
        : positions?.length > 0
        ? latLngBounds(positions)
        : latLonArrayPointGeoReference
        ? latLngBounds(
            latLonArrayPointGeoReference &&
              latLonArrayPointGeoReference.points &&
              latLonArrayPointGeoReference.points.length > 0
              ? latLonArrayPointGeoReference.points.map((point) => [point.lat || 0, point.lon || 0])
              : [[0, 0]]
          )
        : latLonPointGeoReference
        ? latLngBounds([
            [
              (latLonPointGeoReference.lat || 0) - 0.005,
              (latLonPointGeoReference.lon || 0) - 0.005,
            ],
            [
              (latLonPointGeoReference.lat || 0) + 0.005,
              (latLonPointGeoReference.lon || 0) + 0.005,
            ],
          ])
        : latLngBounds([[0, 0]]);

    let directionClass = '';
    switch (direction) {
      case 'top':
        directionClass = 'to-top';
        break;
      case 'right':
        directionClass = 'to-right';
        break;
      case 'left':
        directionClass = 'to-left';
        break;
      default:
        directionClass = 'to-bottom';
        break;
    }

    return (
      <div
        className={`peek-map ${directionClass}`}
        onMouseEnter={(e) => {
          if (!mouseHasEntered) {
            this.setState({ mouseHasEntered: true });
          }
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ReactSVG src="/icons/map.svg" className="peek-map__icon" />
        <div className="peek-map__map-container">
          {mouseHasEntered && (
            <div className="peek-map__map-wrapper">
              <MapContainer
                className="peek-map__map"
                preferCanvas={false}
                zoomControl={false}
                boundsOptions={{ padding: [35, 35] }}
                bounds={bounds}
                zoom={10}
                whenCreated={(map) => this.setState({ map })}
                attributionControl={false}
              >
                <TileLayers mapTypeSelection={false} />
                {latLonPointGeoReference && (
                  <Marker
                    key={`${latLonPointGeoReference.id}`}
                    icon={DefaultMarker}
                    position={[latLonPointGeoReference.lat || 0, latLonPointGeoReference.lon || 0]}
                  />
                )}
                {positions && <Polyline positions={positions} color={'red'} />}
                {encodedLineString
                  ?.split(',')
                  ?.filter((s) => s)
                  ?.map((lineString) => (
                    <Polyline positions={PolyUtil.decode(lineString)} color={'red'} />
                  ))}
              </MapContainer>
            </div>
          )}
        </div>
      </div>
    );
  }
}
