import React, { Component } from 'react';

export default class DashboardTabLink extends Component {
  render() {
    const { label, name, link, tab, platformName, setState, display } = this.props;
    return (
      <>
        {display ? (
          <div
            className={`tab-links ${tab === name ? 'tab-active' : ''} ${name}-button`}
            onClick={() => {
              window.history.replaceState({}, platformName, link);
              setState();
            }}
          >
            {label}
          </div>
        ) : null}
      </>
    );
  }
}
