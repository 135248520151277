import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { searchBoards } from '@api/boardApi';

let searchTimeout = null;

export const transformBoardToValue = (board) => ({
  id: board.id,
  board: board,
  value: board.id,
  label: board.title,
});

class BoardInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      board: this.props.board ? transformBoardToValue(this.props.board) : null,
      boards: [],
    };
  }

  componentDidMount() {
    searchBoards({}, 0, 40).then((response) => {
      this.setState({
        boards: response.boards,
      });
    });
  }

  onChange = (newBoard) => {
    this.setState({
      board: newBoard,
    });
    const { onChange } = this.props;
    onChange && onChange(newBoard ? newBoard.board : null);
  };

  render() {
    const { t, isFetchingBoards, placeholder } = this.props;
    const { board, boards } = this.state;

    return (
      <div className="input-group no-margin-top">
        <AsyncSelect
          isMulti={false}
          isClearable
          cacheOptions
          defaultOptions={boards.map((board) => transformBoardToValue(board))}
          placeholder={placeholder ? placeholder : `${t('form.label.search')}`}
          noOptionsMessage={() => t('form.startTyping')}
          isLoading={isFetchingBoards}
          isDisabled={isFetchingBoards}
          loadOptions={(inputValue, callback) => {
            if (searchTimeout) clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
              searchBoards({ query: inputValue }, 0, 40).then((response) => {
                callback(response.boards.map((board) => transformBoardToValue(board)));
              });
            }, 1000);
          }}
          components={{
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }}
          onChange={(e) => {
            this.onChange(e);
          }}
          value={board}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.todo,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(vehicleActions.changeValue(name, value)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(BoardInput));
