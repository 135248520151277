export const formatAmount = (integer, n = 2, c, s) => {
  const amount = parseFloat(integer.toFixed(2));
  var re = '\\d(?=(\\d{' + 3 + '})+' + (n > 0 ? '\\D' : '$') + ')',
    num = amount.toFixed(Math.max(0, ~~n));

  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export const convertToEnLocale = (value, delimeters) => {
  const normalizedValue = parseFloat(
    value
      ?.replace(new RegExp(`\\${delimeters.thousandDelimiter}`, 'g'), '')
      ?.replace(new RegExp(`\\${delimeters.decimalDelimiter}`), '.')
  );

  const formatter = new Intl.NumberFormat('en-GB', {
    useGrouping: false,
    minimumFractionDigits: value?.includes(delimeters.decimalDelimiter) ? 2 : 0,
  });

  return formatter.format(normalizedValue);
};
