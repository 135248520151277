import { supportConstants } from '@constants';

import * as supportApi from '@api/supportApi';
import Alert from '@models/Alert';

import * as navigationActions from './navigationActions';

/**
 * Change support values
 */
export const changeValue = (name, value) => ({
  type: supportConstants.SUPPORT_TICKET_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching support tickets
 */
export const startGetSupportTickets = (from) => ({
  type: supportConstants.GET_SUPPORT_TICKETS_STARTED,
  from,
});

export const getSupportTicketsSuccess = (result) => ({
  type: supportConstants.GET_SUPPORT_TICKETS_SUCCESS,
  ...result,
});

export const getSupportTicketsFailure = (error) => ({
  type: supportConstants.GET_SUPPORT_TICKETS_FAILURE,
  ...error,
});

export const getTickets = (from = 0, to = 20) => {
  return (dispatch) => {
    dispatch(startGetSupportTickets(from));

    const promise = supportApi.latest(from, to);

    promise
      .then((result) => {
        dispatch(getSupportTicketsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketsFailure(error));
      });

    return promise;
  };
};

export const getStatusTickets = (status, from = 0, to = 20) => {
  return (dispatch) => {
    dispatch(startGetSupportTickets(from));

    const promise = supportApi.latestWithStatus(status, from, to);

    promise
      .then((result) => {
        dispatch(getSupportTicketsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketsFailure(error));
      });

    return promise;
  };
};

export const searchTickets = (searchParameters, from = 0, to = 20) => {
  return (dispatch) => {
    dispatch(startGetSupportTickets(from));

    const promise = supportApi.search(searchParameters, from, to);

    promise
      .then((result) => {
        dispatch(getSupportTicketsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketsFailure(error));
      });

    return promise;
  };
};

export const searchStatusTickets = (
  status,
  searchParameters,
  from = 0,
  to = 20
) => {
  return (dispatch) => {
    dispatch(startGetSupportTickets(from));

    const promise = supportApi.searchWithStatus(
      status,
      searchParameters,
      from,
      to
    );

    promise
      .then((result) => {
        dispatch(getSupportTicketsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketsFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching support ticket
 */
export const startGetSupportTicket = () => ({
  type: supportConstants.GET_SUPPORT_TICKET_STARTED,
});

export const getSupportTicketSuccess = (result) => ({
  type: supportConstants.GET_SUPPORT_TICKET_SUCCESS,
  ...result,
});

export const getSupportTicketFailure = (error) => ({
  type: supportConstants.GET_SUPPORT_TICKET_FAILURE,
  ...error,
});

export const getTicket = (id, token = '') => {
  return (dispatch) => {
    dispatch(startGetSupportTicket());
    const promise = supportApi.getTicket(id, token);

    promise
      .then((result) => {
        dispatch(getSupportTicketSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching errors from support ticket
 */
export const startGetSupportRequestsTicket = () => ({
  type: supportConstants.GET_SUPPORT_TICKET_REQUESTS_STARTED,
});

export const getSupportTicketRequestsSuccess = (result) => ({
  type: supportConstants.GET_SUPPORT_TICKET_REQUESTS_SUCCESS,
  ...result,
});

export const getSupportTicketRequestsFailure = (error) => ({
  type: supportConstants.GET_SUPPORT_TICKET_REQUESTS_FAILURE,
  ...error,
});

export const getSupportTicketRequests = (supportTicket, from, amount) => {
  return (dispatch) => {
    dispatch(startGetSupportRequestsTicket());

    const promise = supportApi.getTicketRequests(supportTicket, from, amount);

    promise
      .then((result) => {
        dispatch(getSupportTicketRequestsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketRequests(error));
      });

    return promise;
  };
};

/**
 * Fetching request from support ticket
 */
export const startGetSupportErrorsTicket = () => ({
  type: supportConstants.GET_SUPPORT_TICKET_ERRORS_STARTED,
});

export const getSupportTicketErrorsSuccess = (result) => ({
  type: supportConstants.GET_SUPPORT_TICKET_ERRORS_SUCCESS,
  ...result,
});

export const getSupportTicketErrorsFailure = (error) => ({
  type: supportConstants.GET_SUPPORT_TICKET_ERRORS_FAILURE,
  ...error,
});

export const getSupportTicketErrors = (supportTicket, from, amount) => {
  return (dispatch) => {
    dispatch(startGetSupportErrorsTicket());

    const promise = supportApi.getTicketErrors(supportTicket, from, amount);

    promise
      .then((result) => {
        dispatch(getSupportTicketErrorsSuccess(result));
      })
      .catch((error) => {
        dispatch(getSupportTicketErrorsFailure(error));
      });

    return promise;
  };
};

/**
 * Create support ticket
 */
export const startCreateSupportTicket = () => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_STARTED,
});

export const createSupportTicketSuccess = (result) => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_SUCCESS,
  ...result,
});

export const createSupportTicketFailure = (error) => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_FAILURE,
  ...error,
});

export const createTicket = (ticket) => {
  return (dispatch) => {
    dispatch(startCreateSupportTicket());

    const promise = supportApi.createTicket(ticket);

    promise
      .then((result) => {
        dispatch(createSupportTicketSuccess(result));
      })
      .catch((error) => {
        dispatch(createSupportTicketFailure(error));
      });

    return promise;
  };
};

/**
 * Update support ticket
 */
export const startUpdateSupportTicket = () => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_STARTED,
});

export const updateSupportTicketSuccess = (result) => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_SUCCESS,
  ...result,
});

export const updateSupportTicketFailure = (error) => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_FAILURE,
  ...error,
});

export const updateTicket = (t, ticket) => {
  return (dispatch) => {
    dispatch(startUpdateSupportTicket());

    const promise = supportApi.updateTicket(ticket);

    promise
      .then((result) => {
        dispatch(updateSupportTicketSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.support.ticket.update.success.title'),
              t('notification.support.ticket.update.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(updateSupportTicketFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.support.ticket.update.error.title'),
              t('notification.support.ticket.update.error.description'),
              'error'
            )
          )
        );
      });

    return promise;
  };
};

/**
 * Create support ticket action
 */
export const startCreateSupportTicketAction = () => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_ACTION_STARTED,
});

export const createSupportTicketActionSuccess = (result) => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_ACTION_SUCCESS,
  ...result,
});

export const createSupportTicketActionFailure = (error) => ({
  type: supportConstants.CREATE_SUPPORT_TICKET_ACTION_FAILURE,
  ...error,
});

export const createTicketAction = (ticketAction, token = null) => {
  return (dispatch) => {
    dispatch(startCreateSupportTicketAction());

    const promise = supportApi.createTicketAction(ticketAction, token);

    promise
      .then((result) => {
        dispatch(createSupportTicketActionSuccess(result));
      })
      .catch((error) => {
        dispatch(createSupportTicketActionFailure(error));
      });

    return promise;
  };
};

/**
 * Update support ticket action
 */
export const startUpdateSupportTicketAction = () => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_ACTION_STARTED,
});

export const updateSupportTicketActionSuccess = (result) => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_ACTION_SUCCESS,
  ...result,
});

export const updateSupportTicketActionFailure = (error) => ({
  type: supportConstants.UPDATE_SUPPORT_TICKET_ACTION_FAILURE,
  ...error,
});

export const updateTicketAction = (ticketAction, token = null) => {
  return (dispatch) => {
    dispatch(startUpdateSupportTicketAction());

    const promise = supportApi.updateTicketAction(ticketAction, token);

    promise
      .then((result) => {
        dispatch(updateSupportTicketActionSuccess(result));
      })
      .catch((error) => {
        dispatch(updateSupportTicketActionFailure(error));
      });

    return promise;
  };
};
