import React, { Component } from 'react';

import moment from 'moment';

import AsideView from '@uicomponents/AsideView';
import Loader from '@uicomponents/Loader';

import Constraint from '@models/constraint/Constraint';
import Association from '@models/general/Association';
import Trip from '@models/trip/Trip';

import TripForm from './TripForm';

export default class CreateTripPage extends Component {
  componentDidMount() {
    const { onChange, targetVehicle, targetActions, targetConsignments } =
      this.props;
    const newTrip = new Trip();
    newTrip.vehicle = targetVehicle ? targetVehicle : null;
    newTrip.actions = targetActions ? targetActions : [];
    newTrip.consignments = targetConsignments ? targetConsignments : [];
    newTrip.constraints = [
      new Association(
        'entity',
        new Constraint({
          type: 'startDateTimeConstraint',
          startDateTime: moment(),
        })
      ),
    ];
    onChange('formTrip', newTrip);
  }

  render() {
    const { t, formTrip, isFetchingTrip, createTrip } = this.props;

    if (!formTrip || isFetchingTrip) {
      return (
        <div className="absolute-center">
          <Loader />
        </div>
      );
    }

    return (
      <AsideView>
        <h1>{t('trip.new')}</h1>
        <TripForm
          {...this.props}
          key={`${formTrip.nonce}-${formTrip.id}`}
          onSubmit={(trip) => createTrip(trip)}
        />
      </AsideView>
    );
  }
}
