import React, { Component } from 'react';

import { withTranslation } from 'react-i18next';

import Avatar from '@uicomponents/Avatar';

class ChatInfoView extends Component {
  render() {
    const { t, chat } = this.props;
    return (
      <div className="chat-info">
        <div className="chat-info__image">
          <Avatar imageUrl={chat.image.url} name={chat.title} />
        </div>
        <div className="chat-info__title">{chat.title}</div>
        {chat.users.length > 2 && (
          <div className="chat-info__users">
            <h3>{t('chat.users.header')}</h3>
            {chat.users.map((chatUser) => {
              let imageUrl =
                chatUser.user && chatUser.user.avatar
                  ? chatUser.user.avatar.url
                  : '/images/user.svg';
              let name = chatUser.user
                ? `${chatUser.user.firstName} ${chatUser.user.lastName}`
                : chatUser.vehicle
                ? chatUser.vehicle.name
                : chatUser.chauffeur
                ? `${chatUser.chauffeur.firstName} ${chatUser.chauffeur.lastName}`
                : t('chat.user.noname');
              return (
                <div className="chat-info__user">
                  <div className="chat-info__user__avatar">
                    <Avatar imageUrl={imageUrl} name={name} />
                  </div>
                  <div className="chat-info__user__name">{name}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('translation')(ChatInfoView);
