import React, { Component } from 'react';

import Select from 'react-select';

import CapacityContractInput from '@components/capacityContract/CapacityContractInput';
import ChauffeurInput from '@components/chauffeur/ChauffeurInput';
import ConsignmentInput from '@components/consignment/ConsignmentInput';
import TransportEquipmentInput from '@components/goods/TransportEquipmentInput';
import TransportOrderInput from '@components/transportOrder/TransportOrderInput';
import TripInput from '@components/trip/TripInput';
import VehicleInput from '@components/vehicle/VehicleInput';

export default class EntityInput extends Component {
  relatedEntityInput = () => {
    const { entityType, entityId, onEntitySelection } = this.props;

    switch (entityType) {
      case 'vehicle':
        return (
          <VehicleInput
            {...this.props}
            vehicleId={entityId}
            onChange={(newVehicle) => {
              onEntitySelection(newVehicle?.id);
            }}
          />
        );
      case 'transportOrder':
        return (
          <TransportOrderInput
            {...this.props}
            transportOrderId={entityId}
            onChange={(newTransportOrder) => {
              onEntitySelection(newTransportOrder.id);
            }}
          />
        );
      case 'consignment':
        return (
          <ConsignmentInput
            {...this.props}
            consignmentId={entityId}
            onChange={(newConsignment) => {
              onEntitySelection(newConsignment.id);
            }}
          />
        );
      case 'trip':
        return (
          <TripInput
            {...this.props}
            tripId={entityId}
            onChange={(newTrip) => {
              onEntitySelection(newTrip.id);
            }}
          />
        );
      case 'chauffeur':
        return (
          <ChauffeurInput
            {...this.props}
            chauffeurId={entityId}
            onChange={(newChauffeur) => {
              onEntitySelection(newChauffeur.id);
            }}
          />
        );
      case 'transportEquipment':
        return (
          <TransportEquipmentInput
            {...this.props}
            transportEquipmentId={entityId}
            onChange={(newTransportEquipment) => {
              onEntitySelection(newTransportEquipment.id);
            }}
          />
        );
      case 'capacityContract':
        return (
          <CapacityContractInput
            capacityContractId={entityId}
            onChange={(newCapacityContract) => {
              onEntitySelection(newCapacityContract.id);
            }}
          />
        );
    }
  };

  render() {
    const { t, entities, entityType, entityId, onEntityTypeSelection } = this.props;

    const entityTypes = entities?.map((entity) => {
      return { label: t(entity), value: entity };
    });

    return (
      <div>
        <div className="input-group no-margin-top">
          <Select
            options={entityTypes}
            placeholder={t('form.placeholder.attributableEntity')}
            value={entityTypes.filter((type) => type.value === entityType)}
            onChange={(e) => {
              onEntityTypeSelection(e);
            }}
          />
        </div>

        {entityType && (
          <div className="input-group no-margin-top">
            <div className="my-10">{this.relatedEntityInput()}</div>
          </div>
        )}
      </div>
    );
  }
}
