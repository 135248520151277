
import { publisherConstants } from "@constants/publisher.constants";

const initialState = {
    isFetchingPublisher: false,
    formPublisher: null,
    publisher: null,

    isFetchingPublishers: false,
    isFetchingPublishersFrom: 0,
    publishersTotalResults: 0,
    publishersLoaded: 0,
    publishers: [],
};

export default function publisher(state = initialState, action) {
    switch (action.type) {
        case publisherConstants.PUBLISHER_CHANGE_VALUE:
            return { ...state, error: "", [action.name]: action.value };

        case publisherConstants.GET_PUBLISHER_STARTED:
            return { ...state, isFetchingPublisher: true };
        case publisherConstants.GET_PUBLISHER_FAILURE:
            return { ...state, isFetchingPublisher: false, publisher: null, error: action.message || "" };
        case publisherConstants.GET_PUBLISHER_SUCCESS:
            return {
                ...state,
                isFetchingPublisher: false,
                publisher: action.publisher,
            };
            
        case publisherConstants.GET_PUBLISHERS_STARTED:
            return { ...state, isFetchingPublishers: true,  isFetchingPublishersFrom: action.from || 0 };
        case publisherConstants.GET_PUBLISHERS_FAILURE:
            return { ...state, isFetchingPublishers: false, publishers: [], error: action.message || "" };
        case publisherConstants.GET_PUBLISHERS_SUCCESS:
            return {
                ...state,
                isFetchingPublishers: false,
                publishers: action.from > 0 ? [...state.publishers, ...action.publishers] : action.publishers,
                publishersLoaded:
                    action.from > 0
                        ? state.publishers.length + action.publishers.length
                        : action.publishers.length,
                publishersTotalResults: action.totalResults || 0,
            };

        case publisherConstants.SEARCH_PUBLISHERS_STARTED:
            return { ...state, isFetchingPublishers: true, isFetchingPublishersFrom: action.from || 0  };
        case publisherConstants.SEARCH_PUBLISHERS_FAILURE:
            return { ...state, isFetchingPublishers: false, publishers: [], error: action.message || "" };
        case publisherConstants.SEARCH_PUBLISHERS_SUCCESS:
            return {
                ...state,
                isFetchingPublishers: false,
                publishers: action.from > 0 ? [...state.publishers, ...action.publishers] : action.publishers,
                publishersLoaded:
                    action.from > 0
                        ? state.publishers.length + action.publishers.length
                        : action.publishers.length,
                publishersTotalResults: action.totalResults || 0,
            };

        case publisherConstants.CREATING_PUBLISHER_STARTED:
            return { ...state, isFetchingPublisher: true };
        case publisherConstants.CREATING_PUBLISHER_FAILURE:
            return { ...state, isFetchingPublisher: false, publisher: null, error: action.message || "" };
        case publisherConstants.CREATING_PUBLISHER_SUCCESS:
            return {
                ...state,
                isFetchingPublisher: false,
                publisher: action.publisher,
                publishers: [action.publisher, ...state.publishers],
            };

        case publisherConstants.UPDATING_PUBLISHER_STARTED:
            return { ...state, isFetchingPublisher: true };
        case publisherConstants.UPDATING_PUBLISHER_FAILURE:
            return { ...state, isFetchingPublisher: false, publisher: null, error: action.message || "" };
        case publisherConstants.UPDATING_PUBLISHER_SUCCESS:
            return {
                ...state,
                isFetchingPublisher: false,
                publisher: action.publisher,
                publishers: [...state.publishers].map((publisher) => {
                    if (action.publisher.id === publisher.id) {
                        return action.publisher;
                    }
                    return publisher;
                }),
            };

        case publisherConstants.DELETE_PUBLISHER_STARTED:
            return { ...state, isFetchingPublisher: true };
        case publisherConstants.DELETE_PUBLISHER_FAILURE:
            return { ...state, isFetchingPublisher: false, publisher: null, message: action.message || "" };
        case publisherConstants.DELETE_PUBLISHER_SUCCESS:
            return {
                ...state,
                isFetchingPublisher: false,
                publisher: action.publisher,
                publishers: [...state.publishers].filter((publisher) => action.publisher.id !== publisher.id),
            };
        default:
            return state;
    }
}    
    