export default class ContactDetail {
  id;
  nonce;

  type;
  value;
  remarks;

  updatedAt;
  createdAt;
  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.updatedAt = new Date();
  }
}
