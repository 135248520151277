import * as vehicleApi from '@api/vehicleApi';

import { vehicleConstants } from '../constants';

export const changeValue = (name, value) => ({
  type: vehicleConstants.VEHICLE_CHANGE_VALUE,
  name,
  value,
});

/**
 * Fetching vehicle
 */
export const startGetVehicle = () => ({
  type: vehicleConstants.GET_VEHICLE_STARTED,
});
export const getVehicleSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLE_SUCCESS,
  ...result,
});
export const getVehicleFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_FAILURE,
  ...error,
});

export const getVehicle = (id) => {
  return (dispatch) => {
    dispatch(startGetVehicle());

    const promise = vehicleApi.get(id);

    promise
      .then((result) => {
        dispatch(getVehicleSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching vehicle
 */
export const startGetExtendedVehicle = () => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLE_STARTED,
});
export const getExtendedVehicleSuccess = (result) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLE_SUCCESS,
  ...result,
});
export const getExtendedVehicleFailure = (error) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLE_FAILURE,
  ...error,
});

export const getExtendedVehicle = (id) => {
  return (dispatch) => {
    dispatch(startGetExtendedVehicle());

    const promise = vehicleApi.getExtended(id);

    promise
      .then((result) => {
        dispatch(getExtendedVehicleSuccess(result));
      })
      .catch((error) => {
        dispatch(getExtendedVehicleFailure(error));
      });

    return promise;
  };
};

/**
 * Creating vehicle
 */
export const startCreateVehicle = () => ({
  type: vehicleConstants.CREATING_VEHICLE_STARTED,
});

export const createVehicleSuccess = (result) => ({
  type: vehicleConstants.CREATING_VEHICLE_SUCCESS,
  ...result,
});

export const createVehicleFailure = (error) => ({
  type: vehicleConstants.CREATING_VEHICLE_FAILURE,
  ...error,
});

export const createVehicle = (vehicle) => {
  return (dispatch) => {
    dispatch(startCreateVehicle());

    const promise = vehicleApi.create(vehicle);

    promise
      .then((result) => {
        dispatch(createVehicleSuccess(result));
      })
      .catch((error) => {
        dispatch(createVehicleFailure(error));
      });

    return promise;
  };
};

/**
 * Updating vehicle
 */
export const startUpdateVehicle = () => ({
  type: vehicleConstants.UPDATING_VEHICLE_STARTED,
});

export const updateVehicleSuccess = (result) => ({
  type: vehicleConstants.UPDATING_VEHICLE_SUCCESS,
  ...result,
});

export const updateVehicleFailure = (error) => ({
  type: vehicleConstants.UPDATING_VEHICLE_FAILURE,
  ...error,
});

export const updateVehicle = (vehicle) => {
  return (dispatch) => {
    dispatch(startUpdateVehicle());

    const promise = vehicleApi.update(vehicle);

    promise
      .then((result) => {
        dispatch(updateVehicleSuccess(result));
      })
      .catch((error) => {
        dispatch(updateVehicleFailure(error));
      });

    return promise;
  };
};

/**
 * Deleting vehicle
 */
export const startDeleteVehicle = () => ({
  type: vehicleConstants.DELETE_VEHICLE_STARTED,
});

export const deleteVehicleSuccess = (result) => ({
  type: vehicleConstants.DELETE_VEHICLE_SUCCESS,
  ...result,
});

export const deleteVehicleFailure = (error) => ({
  type: vehicleConstants.DELETE_VEHICLE_FAILURE,
  ...error,
});
export const deleteVehicle = (vehicle) => {
  return (dispatch) => {
    dispatch(startDeleteVehicle());

    const promise = vehicleApi.remove(vehicle);

    promise
      .then((result) => {
        dispatch(deleteVehicleSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteVehicleFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching Vehicles
 */
export const startGetVehicles = (from) => ({
  type: vehicleConstants.GET_VEHICLES_STARTED,
  from: from,
});

export const getVehiclesSuccess = (result, from) => ({
  type: vehicleConstants.GET_VEHICLES_SUCCESS,
  from: from,
  ...result,
});

export const getVehiclesFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLES_FAILURE,
  ...error,
});

export const getVehicles = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetVehicles(from));

    const promise = vehicleApi.latest(from, amount);

    promise
      .then((result) => {
        dispatch(getVehiclesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehiclesFailure(error));
      });

    return promise;
  };
};

export const searchVehicles = (searchParameters = [], from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetVehicles(from));

    const promise = vehicleApi.search(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getVehiclesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehiclesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching extended Vehicles
 */
export const startGetExtendedVehicles = (from) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_STARTED,
  from: from,
});

export const getExtendedVehiclesSuccess = (result, from) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_SUCCESS,
  from: from,
  ...result,
});

export const getExtendedVehiclesFailure = (error) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_FAILURE,
  ...error,
});

export const getExtendedVehicles = (from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetExtendedVehicles(from));

    const promise = vehicleApi.extendedLatest(from, amount);

    promise
      .then((result) => {
        dispatch(getExtendedVehiclesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getExtendedVehiclesFailure(error));
      });

    return promise;
  };
};
export const searchExtendedVehicles = (searchParameters = {}, from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(startGetExtendedVehicles(from));

    const promise = vehicleApi.searchExtendedLatest(searchParameters, from, amount);

    promise
      .then((result) => {
        dispatch(getExtendedVehiclesSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getExtendedVehiclesFailure(error));
      });

    return promise;
  };
};

/**
 * Fetching vehicles location
 */
export const startGetVehiclesLocation = () => ({
  type: vehicleConstants.GET_VEHICLES_LOCATION_STARTED,
});

export const getVehiclesLocationSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLES_LOCATION_SUCCESS,
  ...result,
});

export const getVehiclesLocationFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLES_LOCATION_FAILURE,
  ...error,
});

export const getVehiclesLocation = () => {
  return (dispatch) => {
    dispatch(startGetVehiclesLocation());

    const promise = vehicleApi.vehiclesLocation();

    promise
      .then((result) => {
        dispatch(getVehiclesLocationSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehiclesLocationFailure(error));
      });

    return promise;
  };
};

/**
 * Get vehicle chauffeurs
 */
export const startGetVehicleChauffeurs = (from) => ({
  type: vehicleConstants.GET_VEHICLE_CHAUFFEURS_STARTED,
  from,
});

export const getVehicleChauffeursSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLE_CHAUFFEURS_SUCCESS,
  ...result,
});

export const getVehicleChauffeursFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_CHAUFFEURS_FAILURE,
  ...error,
});

export const getVehicleChauffeurs = (id) => {
  return (dispatch) => {
    dispatch(startGetVehicleChauffeurs(0));

    const promise = vehicleApi.getChauffeurs(id);

    promise
      .then((result) => {
        dispatch(getVehicleChauffeursSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleChauffeursFailure(error));
      });

    return promise;
  };
};

/**
 * Vehicle attach chauffeur
 */
export const startVehicleAttachChauffeur = (action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_STARTED,
  action: action,
});

export const vehicleAttachChauffeurSuccess = (result, action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_SUCCESS,
  action: action,
  ...result,
});

export const vehicleAttachChauffeurFailure = (error, action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_CHAUFFEUR_FAILURE,
  ...error,
  action: action,
});

export const vehicleAttachChauffeur = (id, action) => {
  return (dispatch) => {
    dispatch(startVehicleAttachChauffeur(action));

    const promise = vehicleApi.attachChauffeur(id, action);

    promise
      .then((result) => {
        dispatch(vehicleAttachChauffeurSuccess(result, action));
      })
      .catch((error) => {
        dispatch(vehicleAttachChauffeurFailure(error, action));
      });

    return promise;
  };
};

/**
 * Vehicle attach chauffeur
 */
export const startVehicleDetachChauffeur = (action) => ({
  type: vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_STARTED,
  action: action,
});

export const vehicleDetachChauffeurSuccess = (result) => ({
  type: vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_SUCCESS,
  ...result,
});

export const vehicleDetachChauffeurFailure = (error, action) => ({
  type: vehicleConstants.VEHICLE_DETACH_CHAUFFEUR_FAILURE,
  ...error,
  action: action,
});

export const vehicleDetachChauffeur = (id, action) => {
  return (dispatch) => {
    dispatch(startVehicleDetachChauffeur(action));

    const promise = vehicleApi.detachChauffeur(id, action);

    promise
      .then((result) => {
        dispatch(vehicleDetachChauffeurSuccess(result, action));
      })
      .catch((error) => {
        dispatch(vehicleDetachChauffeurFailure(error, action));
      });

    return promise;
  };
};

/**
 * Get vehicle transport equipments
 */
export const startGetVehicleTransportEquipments = (from) => ({
  type: vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_STARTED,
  from,
});

export const getVehicleTransportEquipmentsSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_SUCCESS,
  ...result,
});

export const getVehicleTransportEquipmentsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_TRANSPORT_EQUIPMENTS_FAILURE,
  ...error,
});

export const getVehicleTransportEquipments = (id) => {
  return (dispatch) => {
    dispatch(startGetVehicleTransportEquipments(0));

    const promise = vehicleApi.getTransportEquipments(id);

    promise
      .then((result) => {
        dispatch(getVehicleTransportEquipmentsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleTransportEquipmentsFailure(error));
      });

    return promise;
  };
};

/**
 * Vehicle attach transportEquipment
 */
export const startVehicleAttachTransportEquipment = (action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_STARTED,
  action: action,
});

export const vehicleAttachTransportEquipmentSuccess = (result, action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
  action: action,
});

export const vehicleAttachTransportEquipmentFailure = (error, action) => ({
  type: vehicleConstants.VEHICLE_ATTACH_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
  action: action,
});

export const vehicleAttachTransportEquipment = (id, action) => {
  return (dispatch) => {
    dispatch(startVehicleAttachTransportEquipment(action));

    const promise = vehicleApi.attachTransportEquipment(id, action);

    promise
      .then((result) => {
        dispatch(vehicleAttachTransportEquipmentSuccess(result, action));
      })
      .catch((error) => {
        dispatch(vehicleAttachTransportEquipmentFailure(error, action));
      });

    return promise;
  };
};

/**
 * Vehicle attach transportEquipment
 */
export const startVehicleDetachTransportEquipment = (action) => ({
  type: vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_STARTED,
  action: action,
});

export const vehicleDetachTransportEquipmentSuccess = (result, action) => ({
  type: vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_SUCCESS,
  ...result,
  action: action,
});

export const vehicleDetachTransportEquipmentFailure = (error, action) => ({
  type: vehicleConstants.VEHICLE_DETACH_TRANSPORT_EQUIPMENT_FAILURE,
  ...error,
  action: action,
});

export const vehicleDetachTransportEquipment = (id, action) => {
  return (dispatch) => {
    dispatch(startVehicleDetachTransportEquipment(action));

    const promise = vehicleApi.detachTransportEquipment(id, action);

    promise
      .then((result) => {
        dispatch(vehicleDetachTransportEquipmentSuccess(result, action));
      })
      .catch((error) => {
        dispatch(vehicleDetachTransportEquipmentFailure(error, action));
      });

    return promise;
  };
};

/**
 * Get vehicle actions
 */
export const startGetVehicleActions = (from) => ({
  type: vehicleConstants.GET_VEHICLE_ACTIONS_STARTED,
  from: from,
});

export const getVehicleActionsSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLE_ACTIONS_SUCCESS,
  ...result,
});

export const getVehicleActionsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_ACTIONS_FAILURE,
  ...error,
});

export const getVehicleActions = (id, from, amount) => {
  return (dispatch) => {
    dispatch(startGetVehicleActions(from));

    const promise = vehicleApi.getActions(id, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleActionsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleActionsFailure(error));
      });

    return promise;
  };
};

/**
 * Get vehicle events
 */
export const startGetVehicleEvents = (from) => ({
  type: vehicleConstants.GET_VEHICLE_EVENTS_STARTED,
  from: from,
});

export const getVehicleEventsSuccess = (result, from) => ({
  type: vehicleConstants.GET_VEHICLE_EVENTS_SUCCESS,
  from: from,
  ...result,
});

export const getVehicleEventsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_EVENTS_FAILURE,
  ...error,
});

export const getVehicleEvents = (id, from, amount) => {
  return (dispatch) => {
    dispatch(startGetVehicleEvents(from));

    const promise = vehicleApi.getEvents(id, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleEventsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleEventsFailure(error));
      });

    return promise;
  };
};

/**
 * Get vehicle location update events
 */
export const startGetVehicleLocationUpdateEvents = () => ({
  type: vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_STARTED,
});

export const getVehicleLocationUpdateEventsSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_SUCCESS,
  ...result,
});

export const getVehicleLocationUpdateEventsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_LOCATION_UPDATE_EVENTS_FAILURE,
  ...error,
});

export const getVehicleLocationUpdateEvents = (id, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetVehicleLocationUpdateEvents());

    const promise = vehicleApi.getLocationUpdateEvents(id, fromDate, amount);

    promise
      .then((result) => {
        dispatch(getVehicleLocationUpdateEventsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehicleLocationUpdateEventsFailure(error));
      });

    return promise;
  };
};

/**
 * Get vehicle trips
 */
export const startGetVehicleTrips = (from) => ({
  type: vehicleConstants.GET_VEHICLE_TRIPS_STARTED,
  from: from,
});

export const getVehicleTripsSuccess = (result, from) => ({
  type: vehicleConstants.GET_VEHICLE_TRIPS_SUCCESS,
  from: from,
  ...result,
});

export const getVehicleTripsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLE_TRIPS_FAILURE,
  ...error,
});

export const getVehicleTrips = (id, from, amount) => {
  return (dispatch) => {
    dispatch(startGetVehicleTrips(from));

    const promise = vehicleApi.getTrips(id, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleTripsFailure(error));
      });

    return promise;
  };
};

export const getVehicleActiveTrips = (id, from, amount) => {
  return (dispatch) => {
    dispatch(startGetVehicleTrips(from));

    const promise = vehicleApi.getActiveTrips(id, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleTripsFailure(error));
      });

    return promise;
  };
};

export const getVehicleHistoryTrips = (id, from, amount) => {
  return (dispatch) => {
    dispatch(startGetVehicleTrips(from));

    const promise = vehicleApi.getHistoryTrips(id, from, amount);

    promise
      .then((result) => {
        dispatch(getVehicleTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleTripsFailure(error));
      });

    return promise;
  };
};

export const getVehicleTripsByDate = (id, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetVehicleTrips(0));

    const promise = vehicleApi.getTripsByDate(id, fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getVehicleTripsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(getVehicleTripsFailure(error));
      });

    return promise;
  };
};

/**
 * Update Vehicle
 */
export const startUpdateVehicleTripsOrder = () => ({
  type: vehicleConstants.UPDATING_VEHICLE_TRIPS_ORDER_STARTED,
});

export const updateVehicleTripsOrderSuccess = (result) => ({
  type: vehicleConstants.UPDATING_VEHICLE_TRIPS_ORDER_SUCCESS,
  ...result,
});

export const updateVehicleTripsOrderFailure = (error) => ({
  type: vehicleConstants.UPDATING_VEHICLE_TRIPS_ORDER_FAILURE,
  ...error,
});

export const updateVehicleTripsOrder = (id, trip1, trip2) => {
  return (dispatch) => {
    dispatch(startUpdateVehicleTripsOrder());

    const promise = vehicleApi.updateVehicleTripsOrder(id, trip1, trip2);

    promise
      .then((result) => {
        dispatch(updateVehicleTripsOrderSuccess(result));
      })
      .catch((error) => {
        dispatch(updateVehicleTripsOrderFailure(error));
      });

    return promise;
  };
};

/**
 * Get ExtendedVehicles Trips
 */
export const startGetExtendedVehiclesTrips = () => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_STARTED,
});

export const getExtendedVehiclesTripsSuccess = (result) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_SUCCESS,
  ...result,
});

export const getExtendedVehiclesTripsFailure = (error) => ({
  type: vehicleConstants.GET_EXTENDED_VEHICLES_TRIPS_FAILURE,
  ...error,
});

export const getExtendedVehiclesTrips = (vehicles, fromDate, toDate) => {
  return (dispatch) => {
    dispatch(startGetExtendedVehiclesTrips());

    const promise = vehicleApi.getExtendedVehicleTrips(vehicles, fromDate, toDate);

    promise
      .then((result) => {
        dispatch(getExtendedVehiclesTripsSuccess(result));
      })
      .catch((error) => {
        dispatch(getExtendedVehiclesTripsFailure(error));
      });

    return promise;
  };
};

export const startGetVehiclesNextTrips = () => ({
  type: vehicleConstants.GET_VEHICLES_NEXT_TRIPS_STARTED,
});

export const getVehiclesNextTripsSuccess = (result) => ({
  type: vehicleConstants.GET_VEHICLES_NEXT_TRIPS_SUCCESS,
  ...result,
});

export const getVehiclesNextTripsFailure = (error) => ({
  type: vehicleConstants.GET_VEHICLES_NEXT_TRIPS_FAILURE,
  ...error,
});

export const getVehiclesNextTrips = (vehicles, offset, amount) => {
  return (dispatch) => {
    dispatch(startGetVehiclesNextTrips());

    const promise = vehicleApi.getVehiclesNextTrips(vehicles, offset, amount);

    promise
      .then((result) => {
        dispatch(getVehiclesNextTripsSuccess(result));
      })
      .catch((error) => {
        dispatch(getVehiclesNextTripsFailure(error));
      });

    return promise;
  };
};
