export default class Vehicle {
  id;
  nonce;

  name;
  description;

  loadCapacities;

  operational;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.loadCapacities = [];

    this.operational = true;
  }
}
