import { renderRoutes } from '@routes';
import { lazy } from 'react';

const PlannerContainer = lazy(() => import('@containers/planning/planner/PlannerContainer'));
const SidebarSearchContainer = lazy(() => import('@containers/calendar/SidebarSearchContainer'));

const routes = [
  {
    paths: ['/sidesearch'],
    component: SidebarSearchContainer,
    type: 'portal',
    props: { standalone: true, noHeader: true, noFooter: true },
  },
  {
    paths: ['', '/:subtab'],
    component: PlannerContainer,
    type: 'portal',
    props: { standalone: true, noHeader: true, noFooter: true },
  },
];

const PlannerRoutes = (props) => renderRoutes(props, routes);
export default PlannerRoutes;
