import GeoReference from './GeoReference';

export default class Location {
  id;
  uuid;

  name;
  geoReference;

  constraints;

  constructor(geoReferenceType) {
    this.nonce = Math.random().toString(36).substring(7);
    this.type = 'customer';

    this.geoReference = new GeoReference(geoReferenceType);
  }
}
