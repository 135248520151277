import { commentConstants } from '@constants';

import * as commentApi from '../../api/commentApi';

/**
 * Creating Comment
 */
export const createCommentStarted = () => ({
  type: commentConstants.CREATING_COMMENT_STARTED,
});

export const createCommentSuccess = (result) => ({
  type: commentConstants.CREATING_COMMENT_SUCCESS,
  ...result,
});

export const createCommentFailure = (error) => ({
  type: commentConstants.CREATING_COMMENT_FAILURE,
  ...error,
});

export const createComment = (comment) => {
  return (dispatch) => {
    dispatch(createCommentStarted());

    const promise = commentApi.createComment(comment);
    promise
      .then((result) => {
        dispatch(createCommentSuccess(result));
      })
      .catch((error) => {
        dispatch(createCommentFailure(error));
      });
    return promise;
  };
};

/**
 * Updating Comment
 */
export const updateCommentStarted = () => ({
  type: commentConstants.UPDATING_COMMENT_STARTED,
});

export const updateCommentSuccess = (result) => ({
  type: commentConstants.UPDATING_COMMENT_SUCCESS,
  ...result,
});

export const updateCommentFailure = (error) => ({
  type: commentConstants.UPDATING_COMMENT_FAILURE,
  ...error,
});

export const updateComment = (comment) => {
  return (dispatch) => {
    dispatch(updateCommentStarted());

    const promise = commentApi.updateComment(comment);

    promise
      .then((result) => {
        dispatch(updateCommentSuccess(result));
      })
      .catch((error) => {
        dispatch(updateCommentFailure(error));
      });

    return promise;
  };
};

/**
 * Delete a Comment
 */
export const deleteCommentStarted = () => ({
  type: commentConstants.DELETE_COMMENT_STARTED,
});

export const deleteCommentSuccess = (result) => ({
  type: commentConstants.DELETE_COMMENT_SUCCESS,
  ...result,
});

export const deleteCommentFailure = (error) => ({
  type: commentConstants.DELETE_COMMENT_FAILURE,
  ...error,
});

export const deleteComment = (comment) => {
  return (dispatch) => {
    dispatch(deleteCommentStarted());

    const promise = commentApi.deleteComment(comment);
    promise
      .then((result) => {
        dispatch(deleteCommentSuccess(result));
      })
      .catch((error) => {
        dispatch(deleteCommentFailure(error));
      });
    return promise;
  };
};

/**
 * getting paginated comments
 */
export const gettingCommentsStarted = (from) => ({
  type: commentConstants.GETTING_COMMENTS_STARTED,
  from: from,
});

export const gettingCommentsSuccess = (result, from) => ({
  type: commentConstants.GETTING_COMMENTS_SUCCESS,
  from: from,
  ...result,
});

export const gettingCommentsFailure = (error) => ({
  type: commentConstants.GETTING_COMMENTS_FAILURE,
  ...error,
});

export const getComments = (todo, from = 0, amount = 20) => {
  return (dispatch) => {
    dispatch(gettingCommentsStarted(from));

    const promise = commentApi.getComments(todo, from, amount);
    promise
      .then((result) => {
        dispatch(gettingCommentsSuccess(result, from));
      })
      .catch((error) => {
        dispatch(gettingCommentsFailure(error));
      });

    return promise;
  };
};
