import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import GoodsView from '@components/goods/GoodsView';

import * as goodActions from '@actions/goodActions';
import * as navigationActions from '@actions/navigationActions';

class GoodsContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getGoods } = this.props;
    if (ownProps.transportEquipment) {
      changeValue('goods', ownProps.transportEquipment);
    } else if (ownProps.goodsId) {
      getGoods(ownProps.goodsId);
    }
  }

  render() {
    const { getGoods, ...props } = this.props;
    return <GoodsView {...props} getGoods={(id = '') => getGoods(id)} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.good,
    ownProps: ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(goodActions.changeValue(name, value)),

    getGoods: (id) => dispatch(goodActions.getGoods(id)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(GoodsContainer));
