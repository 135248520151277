import React, { Component } from 'react';

import CurrencyInput from 'react-currency-input-field';
import { withTranslation } from 'react-i18next';
import Select from 'react-select';

import { activateInputs, deactivateInputs } from '@utils/formUtils';
import { convertToEnLocale } from '@utils/moneyUtils';

class MoneyInput extends Component {
  static defaultProps = {
    value: null,
    currencies: [],
  };

  componentDidMount() {
    activateInputs();
  }

  componentDidUpdate() {
    activateInputs();
  }

  componentWillUnmount() {
    deactivateInputs();
  }

  onChange(money) {
    const { onChange } = this.props;
    onChange(money);
  }

  render() {
    const { t, user, currencies, value, formattedAmount, label } = this.props;

    const labeledCurrencies = currencies.map((currency) => ({
      value: currency.short,
      label: currency.name,
      sign: currency.sign,
    }));

    const selectedCurrency = value?.currency
      ? labeledCurrencies.find((lc) => lc.value === value.currency)
      : currencies.length > 0
      ? labeledCurrencies[0]
      : null;

    return (
      <>
        <div className="input-group fifth no-margin-top">
          <Select
            options={labeledCurrencies}
            value={selectedCurrency}
            onChange={(e) => {
              const newValue = { ...value };
              newValue.currency = e.value;
              this.onChange(newValue);
            }}
          />
        </div>
        <div className="input-group eighty no-margin-top">
          <CurrencyInput
            value={value?.amount || value?.value}
            decimalScale={2}
            intlConfig={{
              locale: user?.language?.iso3166 || 'en-GB',
              currency: 'EUR',
            }}
            onValueChange={(value) => {
              const parsedAmount = convertToEnLocale(value, {
                thousandDelimiter: user?.language?.thousandDelimiter || ',',
                decimalDelimiter: user?.language?.decimalDelimiter || '.',
              });
              this.onChange({
                ...this.props.value,
                amount: parsedAmount,
              });
            }}
          />
          <label>{label || t('form.label.amount')}</label>
        </div>
      </>
    );
  }
}
export default withTranslation('translation')(MoneyInput);
