import { settingsConstants } from '@constants';

import * as settingsApi from '@api/settingsApi';
import Alert from '@models/Alert';

import * as navigationActions from './navigationActions';

/**
 * Change support values
 */
export const changeValue = (name, value) => ({
  type: settingsConstants.SETTINGS_ON_CHANGE,
  name,
  value,
});

export const setTutorial = (tutorial) => ({
  type: settingsConstants.UPDATE_TUTORIAL,
  value: tutorial,
});

export const setTutorialErrorMessage = (errorMessage) => ({
  type: settingsConstants.UPDATE_TUTORIAL_ERROR_MESSAGE,
  value: errorMessage,
});

export const setPaymentRetryConfig = (config) => ({
  type: settingsConstants.SET_PAYMENT_RETRY_CONFIG,
  value: config,
});

/**
 * Fetching settings
 */
export const startGetSettings = () => ({
  type: settingsConstants.GET_SETTINGS_STARTED,
});

export const getSettingsSuccess = (result) => ({
  type: settingsConstants.GET_SETTINGS_SUCCESS,
  ...result,
});

export const getsettingsFailure = (error) => ({
  type: settingsConstants.GET_SETTINGS_FAILURE,
  ...error,
});

export const getSettings = (token = '') => {
  return (dispatch) => {
    dispatch(startGetSettings());

    const promise = settingsApi.get(token);

    promise
      .then((result) => {
        dispatch(getSettingsSuccess(result));
      })
      .catch((error) => {
        dispatch(getsettingsFailure(error));
      });

    return promise;
  };
};

/**
 * Update settings
 */
export const startUpdateSettings = () => ({
  type: settingsConstants.UPDATE_SETTINGS_STARTED,
});

export const updateSettingsSuccess = (result) => ({
  type: settingsConstants.UPDATE_SETTINGS_SUCCESS,
  ...result,
});

export const updateSettingsFailure = (error) => ({
  type: settingsConstants.UPDATE_SETTINGS_FAILURE,
  ...error,
});

export const updateSettings = (t, settings) => {
  return (dispatch) => {
    dispatch(startUpdateSettings());

    const promise = settingsApi.update(settings);

    promise
      .then((result) => {
        dispatch(updateSettingsSuccess(result));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.settings.update.success.title'),
              t('notification.settings.update.success.description'),
              'success'
            )
          )
        );
      })
      .catch((error) => {
        dispatch(updateSettingsFailure(error));
        dispatch(
          navigationActions.createAlert(
            new Alert(
              t('notification.settings.update.error.title'),
              t('notification.settings.update.error.description'),
              'failure'
            )
          )
        );
      });

    return promise;
  };
};
