import { i18n } from '@/index';
import CreateTransportOrderContainer from '@containers/transportOrder/crud/CreateTransportOrderContainer';
import CreateTripContainer from '@containers/trip/crud/CreateTripContainer';

export const inputElements = ['SELECT', 'INPUT', 'TEXTAREA', 'DIV'];

export const createBasicKeybindings = (e, history, addToStack, popStack) => {
  e.preventDefault();
  e.stopPropagation();

  const activeElement = document.activeElement;
  if (inputElements.includes(activeElement.tagName)) return;

  if (e.altKey && !e.ctrlKey) {
    switch (e.which) {
      case 67:
        return history.push('/chauffeurs');
      case 68:
        return history.push('/dashboard');
      case 70:
        return history.push('/fleets');
      case 76:
        return history.push('/locations');
      case 79:
        return history.push('/transportOrders');

      case 78:
        addToStack({
          name: i18n.t('transportOrder.new'),
          component: (
            <CreateTransportOrderContainer
              onNavigateTo={(url) => {
                popStack();
                history.push(url);
              }}
            />
          ),
        });
        return;
      case 77:
        return history.push('/messages');
      case 84:
        addToStack({
          name: i18n.t('trip.new'),
          component: (
            <CreateTripContainer
              onNavigateTo={(url) => {
                popStack();
                history.push(url);
              }}
            />
          ),
        });
        return;
      case 86:
        return history.push('/vehicles');
    }
  } else {
    switch (e.which) {
      case 48:
      case 49:
      case 50:
      case 51:
      case 52:
      case 53:
      case 54:
      case 55:
      case 56:
      case 57:
        let tabs = document.querySelectorAll(
          '.tab-links-container  .tab-links'
        );

        if (document.querySelector('.vehicle-detail')) {
          tabs = document.querySelectorAll('.vehicle-detail .tabs .tab');
        }

        if (tabs.length >= parseInt(e.key)) {
          tabs[parseInt(e.key) - 1].click();
        }
        break;
    }
  }
};
