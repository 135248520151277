import React, { Component } from 'react';

import FlipMove from 'react-flip-move';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';

import TripContentContainer from '@containers/trip/TripContentContainer';
import CreateTripContainer from '@containers/trip/crud/CreateTripContainer';
import UpdateTripContainer from '@containers/trip/crud/UpdateTripContainer';

import Collapsible from '@uicomponents/Collapsible';
import Loader from '@uicomponents/Loader';
import NoData from '@uicomponents/NoData';
import OptionsMenu from '@uicomponents/OptionsMenu';

import { isExternal } from '@utils/userUtils';

class VehicleTripsView extends Component {
  state = {
    type: 'future',

    finishCheckPopOver: false,
    startNextTrip: false,
  };

  handleScroll = (e) => {
    const { vehicle, isFetchingTrips, tripsTotalResults, tripsLoaded } = this.props;
    const isAtBottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;

    if (isAtBottom && !isFetchingTrips) {
      // fetch more
      if (tripsTotalResults > tripsLoaded) {
        this.load(vehicle, tripsLoaded, 10);
      }
    }
  };

  load(vehicle, offset, amount) {
    const { type } = this.state;
    const { getVehicleActiveTrips, getVehicleHistoryTrips } = this.props;

    if (type === 'future') {
      getVehicleActiveTrips(vehicle.id, offset, amount);
    } else {
      getVehicleHistoryTrips(vehicle.id, offset, amount);
    }
  }

  componentDidMount() {
    const { vehicle } = this.props;
    this.load(vehicle, 0, 10);
  }

  render() {
    const {
      t,
      history,
      user,
      vehicle,
      isFetchingTrips,
      isFetchingTripsFrom,
      trips,
      cancelTrip,
      getExtendedVehicle,
      updateVehicleTripsOrder,

      addToStack,
      popStack,
    } = this.props;

    const { type } = this.state;
    const latLonPointGeoReference = vehicle.latestLocation
      ? vehicle.latestLocation.geoReference
      : null;

    return (
      <>
        <div className="flex-container justify-between">
          <div className="d-flex gap-10 tab-links-container no-padding">
            <div
              className={`tab-links smaller dark${type === 'future' ? ' tab-active' : ''}`}
              onClick={() => {
                this.setState({ type: 'future' }, () => {
                  this.load(vehicle, 0, 10);
                });
              }}
            >
              {t('vehicle.trips.future')}
            </div>
            <div
              className={`tab-links smaller dark${type === 'past' ? ' tab-active' : ''}`}
              onClick={() => {
                this.setState({ type: 'past' }, () => {
                  this.load(vehicle, 0, 10);
                });
              }}
            >
              {t('vehicle.trips.past')}
            </div>
          </div>
          <div className="actions">
            {!isExternal(user) && (
              <button
                onClick={(e) => {
                  e.preventDefault();

                  addToStack({
                    name: t('trip.create'),
                    component: (
                      <CreateTripContainer
                        targetVehicle={{ ...vehicle }}
                        callback={() => {
                          this.load(vehicle, 0, 20);
                        }}
                        onNavigateTo={(url) => {
                          popStack();
                          history.push(url);
                        }}
                      />
                    ),
                  });
                }}
              >
                {t('trip.create')}
              </button>
            )}
          </div>
        </div>
        {isFetchingTrips && isFetchingTripsFrom === 0 ? (
          <Loader />
        ) : (
          <div className="vehicle-trips scrollable" onScroll={(e) => this.handleScroll(e)}>
            {trips.length > 0 ? (
              <>
                {[...trips]
                  .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                  .map((trip, index) => {
                    const isActive =
                      (vehicle.trip && vehicle.trip.id === trip.id) || trip.status === 'in_transit';

                    if (trip.isLoading) return <Loader />;
                    return (
                      <FlipMove key={`trip-${trip.id}-${trip.updatedAt}`}>
                        <Collapsible
                          name={`${trip.tripNr} - ${trip.name}`}
                          className={`vehicle-trips__trip${isActive ? ' active' : ''}`}
                          isOpen={type === 'future' || index === 0}
                          rightSideElements={
                            trip.status !== 'completed' &&
                            trip.status !== 'cancelled' &&
                            !isExternal(user) ? (
                              <>
                                {index !== 0 &&
                                  !isActive &&
                                  trips[index - 1].status !== 'in_transit' &&
                                  trips.length > 1 && (
                                    <ReactSVG
                                      src="/icons/up.svg"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        updateVehicleTripsOrder(
                                          trip.vehicle?.entity?.id,
                                          trips[index - 1],
                                          trip
                                        );
                                      }}
                                    />
                                  )}
                                {index !== trips.length - 1 && !isActive && trips.length > 1 && (
                                  <ReactSVG
                                    src="/icons/down.svg"
                                    className=""
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();

                                      updateVehicleTripsOrder(
                                        trip.vehicle?.entity?.id,
                                        trip,
                                        trips[index + 1]
                                      );
                                    }}
                                  />
                                )}
                                <OptionsMenu
                                  className="self-center"
                                  items={[
                                    {
                                      name: t('form.edit'),
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        addToStack({
                                          name: trip.name,
                                          component: <UpdateTripContainer trip={trip} />,
                                        });
                                      },
                                    },
                                    {
                                      name: t('form.cancel'),
                                      onClick: (e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        cancelTrip(trip).then(() => {
                                          getExtendedVehicle(vehicle.id);
                                        });
                                      },
                                    },
                                  ]}
                                />
                              </>
                            ) : null
                          }
                          key={`vehicle.trip-${trip.id}`}
                        >
                          <TripContentContainer
                            trip={trip}
                            trips={trips}
                            vehicle={vehicle}
                            latLonPointGeoReference={latLonPointGeoReference}
                          />
                        </Collapsible>
                      </FlipMove>
                    );
                  })}
                {isFetchingTrips && <Loader />}
              </>
            ) : (
              <div className="not-found-action-box">
                <div className="icon">
                  <ReactSVG src="/icons/space.svg" />
                </div>
                <NoData>{t('trips.notFound')}</NoData>
                <div className="create">
                  <div
                    className="as-link"
                    onClick={(e) => {
                      addToStack({
                        name: t('trip.create'),
                        component: (
                          <CreateTripContainer
                            targetVehicle={{ ...vehicle }}
                            callback={() => {
                              this.load(vehicle, 0, 20);
                            }}
                            onNavigateTo={(url) => {
                              popStack();
                              history.push(url);
                            }}
                          />
                        ),
                      });
                    }}
                  >
                    {t('trip.new')}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation('translation')(VehicleTripsView);
