import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateScheduleRoutePage from '@components/scheduleRoute/crud/CreateScheduleRoutePage.js';

import * as navigationActions from '@actions/navigationActions';
import * as scheduleRouteActions from '@actions/scheduleRouteActions';

class CreateScheduleRouteContainer extends PureComponent {
  render() {
    const { changeValue, createScheduleRoute, callback, ...props } = this.props;
    const { popStack } = this.props;
    return (
      <CreateScheduleRoutePage
        {...props}
        onChange={changeValue}
        createScheduleRoute={(scheduleRoute) =>
          createScheduleRoute(scheduleRoute).then((response) => {
            popStack?.();
            callback?.(response.scheduleRoute);
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,

    languages: state.platform.languages,
    dataTypes: state.platform.dataTypes,
    currencies: state.platform.currencies,
    pricingEntityTypes: state.platform.pricingEntityTypes,
    pricingTriggerTypes: state.platform.pricingTriggerTypes,
    pricingModuleTypes: state.platform.pricingModuleTypes,
    pricingModuleSubUnits: state.platform.pricingModuleSubUnits,

    taxRates: state.settings.variables.taxRates,
    pricingCategories: state.settings.variables.pricingCategories,

    ...state.scheduleRoute,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createScheduleRoute: (scheduleRoute) =>
      dispatch(scheduleRouteActions.createScheduleRoute(scheduleRoute)),
    changeValue: (name, value) => dispatch(scheduleRouteActions.changeValue(name, value)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateScheduleRouteContainer));
