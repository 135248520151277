export default class Business {
  id;
  nonce;

  locations;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.locations = [];
  }
}
