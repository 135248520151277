import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import MailView from '@components/mail/MailView';

import * as mailActions from '@actions/mailActions';
import * as navigationActions from '@actions/navigationActions';

class MailContainer extends PureComponent {
  componentDidMount() {
    const { changeValue, targetMail, mail } = this.props;
    changeValue('mail', targetMail || mail);
  }

  render() {
    const { getMail, ...props } = this.props;
    return <MailView {...props} getMail={(id) => getMail(id)} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.mail,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) =>
      dispatch(mailActions.changeValue(name, value)),
    getMail: (id) => dispatch(mailActions.getMail(id)),
    archiveMail: (id) => dispatch(mailActions.archiveMail(id)),

    addToStack: (component) =>
      dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(MailContainer));
