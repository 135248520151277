export default class Goods {
  id;
  nonce;

  type;
  constraints;

  constructor(type = 'items') {
    this.nonce = Math.random().toString(36).substring(7);
    this.type = type;

    this.constraints = [];
  }
}
