import IntegrationCredentials from './IntegrationCredentials';

export default class Integration {
  nonce;

  credentials;
  enabled;

  constructor() {
    this.nonce = Math.random().toString(36).substring(7);

    this.credentials = new IntegrationCredentials();
    this.enabled = true;
  }
}
