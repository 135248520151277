import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/boardComputer/${id}`);
};

export const create = (boardComputer) => {
  return api.post(`/boardComputer`, boardComputer);
};

export const update = (boardComputer) => {
  return api.put(`/boardComputer`, boardComputer);
};

export const remove = (boardComputer) => {
  return api.delete(`/boardComputer/${boardComputer.id}`);
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/boardComputers/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/boardComputers/${from}/${amount}`, searchParameters);
};
