
export const typeConstants = {
    TYPE_CHANGE_VALUE: "TYPE_CHANGE_VALUE",

    GET_TYPE_STARTED: "GET_TYPE_STARTED",
    GET_TYPE_SUCCESS: "GET_TYPE_SUCCESS",
    GET_TYPE_FAILURE: "GET_TYPE_FAILURE",

    GET_TYPES_STARTED: "GET_TYPES_STARTED",
    GET_TYPES_SUCCESS: "GET_TYPES_SUCCESS",
    GET_TYPES_FAILURE: "GET_TYPES_FAILURE",

    SEARCH_TYPES_STARTED: "SEARCH_TYPES_STARTED",
    SEARCH_TYPES_SUCCESS: "SEARCH_TYPES_SUCCESS",
    SEARCH_TYPES_FAILURE: "SEARCH_TYPES_FAILURE",

    CREATING_TYPE_STARTED: "CREATING_TYPE_STARTED",
    CREATING_TYPE_SUCCESS: "CREATING_TYPE_SUCCESS",
    CREATING_TYPE_FAILURE: "CREATING_TYPE_FAILURE",

    UPDATING_TYPE_STARTED: "UPDATING_TYPE_STARTED",
    UPDATING_TYPE_SUCCESS: "UPDATING_TYPE_SUCCESS",
    UPDATING_TYPE_FAILURE: "UPDATING_TYPE_FAILURE",

    DELETE_TYPE_STARTED: "DELETE_TYPE_STARTED",
    DELETE_TYPE_SUCCESS: "DELETE_TYPE_SUCCESS",
    DELETE_TYPE_FAILURE: "DELETE_TYPE_FAILURE",
};
