import { widgetConstants } from '@constants/widget.constants';

const initialState = {
  isFetchingWidget: false,
  hasPublicWidgetLoaded: false,
  formWidget: null,
  widget: null,

  isFetchingWidgets: false,
  isFetchingWidgetsFrom: 0,
  widgetsTotalResults: 0,
  widgetsLoaded: 0,
  widgets: [],
};

export default function widget(state = initialState, action) {
  switch (action.type) {
    case widgetConstants.WIDGET_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case widgetConstants.GET_WIDGET_STARTED:
      return { ...state, isFetchingWidget: true };
    case widgetConstants.GET_WIDGET_FAILURE:
      return { ...state, isFetchingWidget: false, error: action.message || '' };
    case widgetConstants.GET_WIDGET_SUCCESS:
      return {
        ...state,
        isFetchingWidget: false,
        widget: action.widget,
        hasPublicWidgetLoaded: false,
      };

    case widgetConstants.GET_PUBLIC_WIDGET_STARTED:
      return { ...state, isFetchingWidget: true };
    case widgetConstants.GET_PUBLIC_WIDGET_FAILURE:
      return { ...state, isFetchingWidget: false, error: action.message || '' };
    case widgetConstants.GET_PUBLIC_WIDGET_SUCCESS:
      return {
        ...state,
        isFetchingWidget: false,
        widget: action.widget,
        hasPublicWidgetLoaded: true,
      };

    case widgetConstants.GET_WIDGETS_STARTED:
      return {
        ...state,
        isFetchingWidgets: true,
        isFetchingWidgetsFrom: action.from || 0,
      };
    case widgetConstants.GET_WIDGETS_FAILURE:
      return {
        ...state,
        isFetchingWidgets: false,
        error: action.message || '',
      };
    case widgetConstants.GET_WIDGETS_SUCCESS:
      return {
        ...state,
        isFetchingWidgets: false,
        widgets:
          action.from > 0
            ? [...state.widgets, ...action.widgets]
            : action.widgets,
        widgetsLoaded:
          action.from > 0
            ? state.widgets.length + action.widgets.length
            : action.widgets.length,
        widgetsTotalResults: action.totalResults || 0,
      };

    case widgetConstants.SEARCH_WIDGETS_STARTED:
      return {
        ...state,
        isFetchingWidgets: true,
        isFetchingWidgetsFrom: action.from || 0,
      };
    case widgetConstants.SEARCH_WIDGETS_FAILURE:
      return {
        ...state,
        isFetchingWidgets: false,
        error: action.message || '',
      };
    case widgetConstants.SEARCH_WIDGETS_SUCCESS:
      return {
        ...state,
        isFetchingWidgets: false,
        widgets:
          action.from > 0
            ? [...state.widgets, ...action.widgets]
            : action.widgets,
        widgetsLoaded:
          action.from > 0
            ? state.widgets.length + action.widgets.length
            : action.widgets.length,
        widgetsTotalResults: action.totalResults || 0,
      };

    case widgetConstants.CREATING_WIDGET_STARTED:
      return { ...state, isFetchingWidget: true };
    case widgetConstants.CREATING_WIDGET_FAILURE:
      return { ...state, isFetchingWidget: false, error: action.message || '' };
    case widgetConstants.CREATING_WIDGET_SUCCESS:
      return {
        ...state,
        isFetchingWidget: false,
        widget: action.widget,
        widgets: [action.widget, ...state.widgets],
      };

    case widgetConstants.UPDATING_WIDGET_STARTED:
      return { ...state, isFetchingWidget: true };
    case widgetConstants.UPDATING_WIDGET_FAILURE:
      return { ...state, isFetchingWidget: false, error: action.message || '' };
    case widgetConstants.UPDATING_WIDGET_SUCCESS:
      return {
        ...state,
        isFetchingWidget: false,
        widget: action.widget,
        widgets: [...state.widgets].map((widget) => {
          if (action.widget.id === widget.id) {
            return action.widget;
          }
          return widget;
        }),
      };

    case widgetConstants.DELETE_WIDGET_STARTED:
      return { ...state, isFetchingWidget: true };
    case widgetConstants.DELETE_WIDGET_FAILURE:
      return {
        ...state,
        isFetchingWidget: false,
        message: action.message || '',
      };
    case widgetConstants.DELETE_WIDGET_SUCCESS:
      return {
        ...state,
        isFetchingWidget: false,
        widget: action.widget,
        widgets: [...state.widgets].filter(
          (widget) => action.widget.id !== widget.id
        ),
      };
    default:
      return state;
  }
}
