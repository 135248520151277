export const authConstants = {
  CHANGE_VALUE: 'CHANGE_VALUE',

  USER_INFORMATION: 'USER_INFORMATION',
  BILLING_INFORMATION: 'BILLING_INFORMATION',
  BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
  REVIEW_INFORMATION: 'REVIEW_INFORMATION',

  LOGIN_METHOD_STARTED: 'LOGIN_METHOD_STARTED',
  LOGIN_METHOD_SUCCESS: 'LOGIN_METHOD_SUCCESS',
  LOGIN_METHOD_FAILURE: 'LOGIN_METHOD_FAILURE',

  LOGIN_FORM_CHANGE_VALUE: 'LOGIN_FORM_CHANGE_VALUE',
  LOGIN_FORM_STARTED: 'LOGIN_FORM_STARTED',
  LOGIN_FORM_SUCCESS: 'LOGIN_FORM_SUCCESS',
  LOGIN_FORM_FAILURE: 'LOGIN_FORM_FAILURE',
  LOGIN_TWO_FACTOR_STARTED: 'LOGIN_TWO_FACTOR_STARTED',

  SET_LOGIN_SESSION: 'SET_LOGIN_SESSION',

  OTP_LOGIN_STARTED: 'OTP_LOGIN_STARTED',
  OTP_LOGIN_SUCCESS: 'OTP_LOGIN_SUCCESS',
  OTP_LOGIN_FAILURE: 'OTP_LOGIN_FAILURE',

  REGISTER_FORM_CHANGE_VALUE: 'REGISTER_FORM_CHANGE_VALUE',
  REGISTER_FORM_STARTED: 'REGISTER_FORM_STARTED',
  REGISTER_FORM_SUCCESS: 'REGISTER_FORM_SUCCESS',
  REGISTER_FORM_FAILURE: 'REGISTER_FORM_FAILURE',

  FORGOT_FORM_CHANGE_VALUE: 'FORGOT_FORM_CHANGE_VALUE',
  FORGOT_FORM_STARTED: 'FORGOT_FORM_STARTED',
  FORGOT_FORM_SUCCESS: 'FORGOT_FORM_SUCCESS',
  FORGOT_FORM_FAILURE: 'FORGOT_FORM_FAILURE',

  RESET_FORM_CHANGE_VALUE: 'RESET_FORM_CHANGE_VALUE',
  RESET_FORM_STARTED: 'RESET_FORM_STARTED',
  RESET_FORM_SUCCESS: 'RESET_FORM_SUCCESS',
  RESET_FORM_FAILURE: 'RESET_FORM_FAILURE',

  UPDATE_USER_FORM_VALUE: 'UPDATE_USER_FORM_VALUE',
  UPDATE_USER_STARTED: 'UPDATE_USER_STARTED',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE',

  SETUP_2FACTOR_STARTED: 'SETUP_2FACTOR_STARTED',
  SETUP_2FACTOR_SUCCESS: 'SETUP_2FACTOR_SUCCESS',
  SETUP_2FACTOR_FAILURE: 'SETUP_2FACTOR_FAILURE',

  VERIFY_2FACTOR_STARTED: 'VERIFY_2FACTOR_STARTED',
  VERIFY_2FACTOR_SUCCESS: 'VERIFY_2FACTOR_SUCCESS',
  VERIFY_2FACTOR_FAILURE: 'VERIFY_2FACTOR_FAILURE',

  DISABLE_2FACTOR_STARTED: 'DISABLE_2FACTOR_STARTED',
  DISABLE_2FACTOR_SUCCESS: 'DISABLE_2FACTOR_SUCCESS',
  DISABLE_2FACTOR_FAILURE: 'DISABLE_2FACTOR_FAILURE',

  RECOVER_2FACTOR_STARTED: 'RECOVER_2FACTOR_STARTED',
  RECOVER_2FACTOR_SUCCESS: 'RECOVER_2FACTOR_SUCCESS',
  RECOVER_2FACTOR_FAILURE: 'RECOVER_2FACTOR_FAILURE',

  UPDATE_PASSWORD_FORM_VALUE: 'UPDATE_PASSWORD_FORM_VALUE',
  UPDATE_PASSWORD_STARTED: 'UPDATE_PASSWORD_STARTED',
  UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
  UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE',

  LOGOUT_STARTED: 'LOGOUT_STARTED',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  LOGOUT_ALL_OTHERS_STARTED: 'LOGOUT_ALL_OTHERS_STARTED',
  LOGOUT_ALL_OTHERS_SUCCESS: 'LOGOUT_ALL_OTHERS_SUCCESS',
  LOGOUT_ALL_OTHERS_FAILURE: 'LOGOUT_ALL_OTHERS_FAILURE',

  CONFIRM_EMAIL_STARTED: 'CONFIRM_EMAIL_STARTED',
  CONFIRM_EMAIL_SUCCESS: 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE: 'CONFIRM_EMAIL_FAILURE',

  GET_SESSIONS_STARTED: 'GET_SESSIONS_STARTED',
  GET_SESSIONS_SUCCESS: 'GET_SESSIONS_SUCCESS',
  GET_SESSIONS_FAILURE: 'GET_SESSIONS_FAILURE',

  GET_ROLES_STARTED: 'GET_ROLES_STARTED',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

  SEARCH_ROLES_STARTED: 'SEARCH_ROLES_STARTED',
  SEARCH_ROLES_SUCCESS: 'SEARCH_ROLES_SUCCESS',
  SEARCH_ROLES_FAILURE: 'SEARCH_ROLES_FAILURE',

  GET_ROLE_STARTED: 'GET_ROLE_STARTED',
  GET_ROLE_SUCCESS: 'GET_ROLE_SUCCESS',
  GET_ROLE_FAILURE: 'GET_ROLE_FAILURE',

  CREATE_ROLE_STARTED: 'CREATE_ROLE_STARTED',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_FAILURE: 'CREATE_ROLE_FAILURE',

  UPDATE_ROLE_STARTED: 'UPDATE_ROLE_STARTED',
  UPDATE_ROLE_SUCCESS: 'UPDATE_ROLE_SUCCESS',
  UPDATE_ROLE_FAILURE: 'UPDATE_ROLE_FAILURE',

  DELETE_ROLE_STARTED: 'DELETE_ROLE_STARTED',
  DELETE_ROLE_SUCCESS: 'DELETE_ROLE_SUCCESS',
  DELETE_ROLE_FAILURE: 'DELETE_ROLE_FAILURE',

  GET_USER_SESSION_STARTED: 'GET_USER_SESSION_STARTED',
  GET_USER_SESSION_SUCCESS: 'GET_USER_SESSION_SUCCESS',
  GET_USER_SESSION_FAILURE: 'GET_USER_SESSION_FAILURE',
};
