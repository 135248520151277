import api from '../api';

// CRUD
export const get = (id = '') => {
  return api.get(`/tender/${id}`);
};

export const create = (tender) => {
  return api.post(`/tender`, tender);
};

export const update = (tender) => {
  return api.put(`/tender`, tender);
};

export const remove = (tender) => {
  return api.delete(`/tender/${tender.id}`);
};

// Fetch and search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/tenders/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/tenders/${from}/${amount}`, searchParameters);
};
