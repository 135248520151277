import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import CreateTeamPage from '@components/team/crud/CreateTeamPage.js';

import * as navigationActions from '@actions/navigationActions';
import * as teamActions from '@actions/teamActions';
import * as userActions from '@actions/userActions';

class CreateTeamContainer extends PureComponent {
  render() {
    const { changeValue, createTeam, popStack, ...props } = this.props;
    return (
      <CreateTeamPage
        {...props}
        onChange={changeValue}
        createTeam={(team) =>
          createTeam(team).then(() => {
            popStack();
          })
        }
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    vehicles: state.vehicle.vehicles,
    isFetchingVehicles: state.vehicle.isFetchingVehicles,
    ...state.team,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createTeam: (team) => dispatch(teamActions.createTeam(team)),
    changeValue: (name, value) =>
      dispatch(teamActions.changeValue(name, value)),

    searchUsers: (searchParameters, from, amount) =>
      dispatch(userActions.searchUsers(searchParameters, from, amount)),
    getUsers: (from, amount) => dispatch(userActions.getUsers(from, amount)),

    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(CreateTeamContainer));
