import { teamConstants } from '../constants/team.constants';

const initialState = {
  isFetchingTeam: false,
  formTeam: null,
  team: null,

  isFetchingTeams: false,
  isFetchingTeamsFrom: 0,
  teamsTotalResults: 0,
  teamsLoaded: 0,
  teams: [],
};

export default function team(state = initialState, action) {
  switch (action.type) {
    case teamConstants.TEAM_CHANGE_VALUE:
      return { ...state, error: '', [action.name]: action.value };

    case teamConstants.GET_TEAMS_STARTED:
      return {
        ...state,
        isFetchingTeams: true,
        isFetchingTeamsFrom: action.from || 0,
      };
    case teamConstants.GET_TEAMS_FAILURE:
      return { ...state, isFetchingTeams: false, error: action.message || '' };
    case teamConstants.GET_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchingTeams: false,
        teams:
          action.from > 0 ? [...state.teams, ...action.teams] : action.teams,
        teamsLoaded:
          action.from > 0
            ? state.teams.length + action.teams.length
            : action.teams.length,
        teamsTotalResults: action.totalResults || 0,
      };

    case teamConstants.SEARCH_TEAMS_STARTED:
      return {
        ...state,
        isFetchingTeams: true,
        isFetchingTeamsFrom: action.from || 0,
      };
    case teamConstants.SEARCH_TEAMS_FAILURE:
      return { ...state, isFetchingTeams: false, error: action.message || '' };
    case teamConstants.SEARCH_TEAMS_SUCCESS:
      return {
        ...state,
        isFetchingTeams: false,
        teams:
          action.from > 0 ? [...state.teams, ...action.teams] : action.teams,
        teamsLoaded:
          action.from > 0
            ? state.teams.length + action.teams.length
            : action.teams.length,
        teamsTotalResults: action.totalResults || 0,
      };

    case teamConstants.CREATING_TEAM_STARTED:
      return { ...state, isFetchingTeam: true };
    case teamConstants.CREATING_TEAM_FAILURE:
      return { ...state, isFetchingTeam: false, error: action.message || '' };
    case teamConstants.CREATING_TEAM_SUCCESS:
      return {
        ...state,
        isFetchingTeam: false,
        team: action.team,
        teams: [action.team, ...state.teams],
      };

    case teamConstants.UPDATING_TEAM_STARTED:
      return { ...state, isFetchingTeam: true };
    case teamConstants.UPDATING_TEAM_FAILURE:
      return { ...state, isFetchingTeam: false, error: action.message || '' };
    case teamConstants.UPDATING_TEAM_SUCCESS:
      return {
        ...state,
        isFetchingTeam: false,
        team: action.team,
        teams: [...state.teams].map((team) => {
          if (team.id === action.team.id) {
            return action.team;
          }
          return team;
        }),
      };

    case teamConstants.DELETE_TEAM_STARTED:
      return { ...state, isFetchingVehicle: true };
    case teamConstants.DELETE_TEAM_FAILURE:
      return {
        ...state,
        isFetchingVehicle: false,
        message: action.message || '',
      };
    case teamConstants.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        isFetchingTeam: false,
        team: action.team,
        teams: [...state.teams].filter((team) => action.team.id !== team.id),
      };
    default:
      return state;
  }
}
