import React, { PureComponent } from 'react';

import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';

export default class AsideListItem extends PureComponent {
  render() {
    const { label, icon, navigate, onClick, identifier } = this.props;

    if (navigate) {
      return (
        <Link to={navigate}>
          <div className={`${identifier} aside-list-item`}>
            {icon ? <ReactSVG src={icon} /> : null}
            <div>{label}</div>
          </div>
        </Link>
      );
    }

    return (
      <div className={`${identifier} aside-list-item`} onClick={() => (onClick ? onClick() : null)}>
        {icon ? <ReactSVG src={icon} /> : null}
        <div>{label}</div>
      </div>
    );
  }
}
