import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChatsPopupPage from '@components/chat/ChatsPopupPage';

import * as chatActions from '@actions/chatActions';

class ChatsContainer extends PureComponent {
  render() {
    const { ...props } = this.props;
    return <ChatsPopupPage {...props} />;
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    platform: state.platform.platform,
    chatsIsFetching: state.chat.chatsIsFetching,
    chatsTotalResults: state.chat.chatsTotalResults,
    chatsLoaded: state.chat.chatsLoaded,
    ...state.chat,
    ...ownProps,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getChats: (from, to) => dispatch(chatActions.getChats(from, to)),
    changeValue: (name, value) =>
      dispatch(chatActions.changeChatValue(name, value)),
    searchChats: (searchParameters, from, amount) =>
      dispatch(chatActions.searchChats(searchParameters, from, amount)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ChatsContainer));
