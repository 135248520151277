export const contractConstants = {
  CONTRACT_CHANGE_VALUE: 'CONTRACT_CHANGE_VALUE',

  GET_CONTRACT_STARTED: 'GET_CONTRACT_STARTED',
  GET_CONTRACT_SUCCESS: 'GET_CONTRACT_SUCCESS',
  GET_CONTRACT_FAILURE: 'GET_CONTRACT_FAILURE',

  GET_CONTRACTS_STARTED: 'GET_CONTRACTS_STARTED',
  GET_CONTRACTS_SUCCESS: 'GET_CONTRACTS_SUCCESS',
  GET_CONTRACTS_FAILURE: 'GET_CONTRACTS_FAILURE',

  SEARCH_CONTRACTS_STARTED: 'SEARCH_CONTRACTS_STARTED',
  SEARCH_CONTRACTS_SUCCESS: 'SEARCH_CONTRACTS_SUCCESS',
  SEARCH_CONTRACTS_FAILURE: 'SEARCH_CONTRACTS_FAILURE',

  CREATING_CONTRACT_STARTED: 'CREATING_CONTRACT_STARTED',
  CREATING_CONTRACT_SUCCESS: 'CREATING_CONTRACT_SUCCESS',
  CREATING_CONTRACT_FAILURE: 'CREATING_CONTRACT_FAILURE',

  UPDATING_CONTRACT_STARTED: 'UPDATING_CONTRACT_STARTED',
  UPDATING_CONTRACT_SUCCESS: 'UPDATING_CONTRACT_SUCCESS',
  UPDATING_CONTRACT_FAILURE: 'UPDATING_CONTRACT_FAILURE',

  DELETE_CONTRACT_STARTED: 'DELETE_CONTRACT_STARTED',
  DELETE_CONTRACT_SUCCESS: 'DELETE_CONTRACT_SUCCESS',
  DELETE_CONTRACT_FAILURE: 'DELETE_CONTRACT_FAILURE',
};
