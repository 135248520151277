import React, { Component } from 'react';

import PopOver from './PopOver';

export default class PopOverStack extends Component {
  state = {
    animationHasEnded: false,
  };
  addExtraProps(Component, extraProps) {
    return Component ? (
      <Component.type {...Component.props} {...extraProps} />
    ) : null;
  }
  render() {
    const { popOvers, popPopOver } = this.props;

    const onClose = () => {
      popPopOver && popPopOver();
    };
    const popOversLength = popOvers.length;

    return (
      <div className="popOver-stack">
        {popOvers.map((popOver, index) => {
          return (
            <PopOver isActive={transportEquipment} onClose={() => onClose()}>
              {popOver.component}
            </PopOver>
          );
        })}
      </div>
    );
  }
}
