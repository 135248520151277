import api from '../api';

export const get = (id = '') => {
  return api.get(`/action/${id}`);
};

export const create = (action) => {
  return api.post(`/action`, action);
};

export const update = (action) => {
  return api.put(`/action`, action);
};

export const remove = (action) => {
  return api.delete(`/action/${action.id}`);
};

// more crud type operations
export const updateTimes = (actionId, startTime, endTime) => {
  return api.put(`/action/${actionId}/times`, { startTime, endTime });
};

// Fetch and Search
export const latest = (from = 0, amount = 20) => {
  return api.get(`/actions/${from}/${amount}`);
};

export const search = (searchParameters = [], from = 0, amount = 20) => {
  return api.post(`/actions/${from}/${amount}`, searchParameters);
};
