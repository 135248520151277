import React, { PureComponent } from 'react';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import ChauffeurView from '@components/chauffeur/ChauffeurView';

import * as chauffeurActions from '@actions/chauffeurActions';
import * as navigationActions from '@actions/navigationActions';

class ChauffeurContainer extends PureComponent {
  componentDidMount() {
    const { ownProps, changeValue, getChauffeur } = this.props;
    if (ownProps.chauffeur) {
      changeValue('chauffeur', ownProps.chauffeur);
    } else if (ownProps.chauffeurId) {
      getChauffeur(ownProps.chauffeurId);
    }
  }

  render() {
    const { getChauffeur, deleteChauffeur, changeValue, ...props } = this.props;
    return (
      <ChauffeurView
        {...props}
        onChange={changeValue}
        getChauffeur={(id = '') => getChauffeur(id)}
        deleteChauffeur={(chauffeur) => deleteChauffeur(chauffeur)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.auth.user,
    ...state.chauffeur,
    ownProps: ownProps,
    platform: state.platform.platform,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (name, value) => dispatch(chauffeurActions.changeValue(name, value)),

    getChauffeur: (id) => dispatch(chauffeurActions.getChauffeur(id)),
    deleteChauffeur: (chauffeur) => dispatch(chauffeurActions.deleteChauffeur(chauffeur)),

    addToStack: (component) => dispatch(navigationActions.addToStack(component)),
    popStack: () => dispatch(navigationActions.popStack()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation('translation')(ChauffeurContainer));
